export default (dailyData, budget) => {
    if(dailyData && budget) {
      const percentage = ((parseFloat(dailyData.toString().replace(/,/g, '')) / parseFloat(budget.toString().replace(/,/g, ''))) * 100).toFixed(2) + "%"
      if(percentage === "NaN%" || percentage === 'Infinity%') {
        return '0%'
      } else {
        return percentage
      }
    } else {
      return '0%';
    }
  };