import React, { useEffect, useState } from "react";
import './Demo.css';
import axios from "axios";
import Links from "../../Data/Links";
import CreateRowForUsed from "../../component/Budget/CreateRowForUsed";
import Cookies from 'js-cookie';
import DealerYearMonthSelector from "../../component/Budget/DealerYearMonthSelector";
import TotalRowForUsed from "../../component/Budget/TotalRowForUsed";
import THForUsed from "../../component/DataEntry/THForUsed";
const months = [
  'Sep',
  'Oct',
  'Nov',
  'Dec',
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
]

const BudgetCarooga = ({ props }) => {
  const [stores, setStores] = useState(localStorage.getItem('stores') ? JSON.parse(localStorage.getItem('stores')) : "");
  const [budgetData, setBudgetData] = useState(localStorage.getItem('stores') ? JSON.parse(localStorage.getItem('stores')) : "");
  const [filterVal, setFilterVal] = useState({
    store_id: 37,
    store_name: 'BC',
    division: "Carooga",
    company: 'FFUN',
    year: new Date().getFullYear()
  });

  let userData;
  if (Cookies.get('user_data')) {
    userData = JSON.parse(Cookies.get('user_data'));
  }

  useEffect(() => {
    let storeObj = {};
    stores.length > 0 && stores?.map(i => {
      if (i.division === "Carooga") {
        storeObj[i.id] = i.name;
      }
    });
    setStores(storeObj);
    setFilterVal({
      store_id: Object.keys(storeObj)[0],
      store_name: Object.values(storeObj)[0],
      division: "Carooga",
      company: 'FFUN',
      year: 'FY' + new Date().getFullYear().toString().substring(2),
    })
  }, []);

  useEffect(() => {
    loadData();
  }, [filterVal])

  const loadData = (filterData = null) => {
    if (filterData) {
      setFilterVal(filterData);
    }
    axios.post(Links.getYearlyFilteredBudgetForUsed, filterVal)
      .then(res => {
        setBudgetData(res.data.budget);
      }).catch(err => {
        console.log(err);
      });
  }

  let total = {
    used_retail_delivered: 0,
    used_retail_front_GP: 0,
    used_retail_back_GP: 0,
    service_total_hours: 0,
    service_CP_hours: 0,
    service_CP_ROs: 0,
    service_total_GP: 0,
    appt_made: 0,
    appt_show: 0,
    store_visit: 0,
    lead: 0
  };

  return (
    <div style={{ margin: "2rem 2rem 2rem 0rem" }} >
      <DealerYearMonthSelector stores={stores} filterVal={filterVal} setFilterVal={setFilterVal} loadData={() => loadData()} />

      <table class="table table-bordered border-black" style={{ marginTop: 30 }}>
        <THForUsed />
        <tbody>
          {
            months.map((i, n) => {
              total.used_retail_delivered = (budgetData[i] && budgetData[i].used_retail_delivered !== '') ? total.used_retail_delivered + parseInt(budgetData[i].used_retail_delivered) : total.used_retail_delivered;
              total.used_retail_front_GP = (budgetData[i] && budgetData[i].used_retail_front_GP !== '') ? total.used_retail_front_GP + parseInt(budgetData[i].used_retail_front_GP) : total.used_retail_front_GP;
              total.used_retail_back_GP = (budgetData[i] && budgetData[i].used_retail_back_GP !== '') ? total.used_retail_back_GP + parseInt(budgetData[i].used_retail_back_GP) : total.used_retail_back_GP;
              total.service_total_hours = (budgetData[i] && budgetData[i].service_total_hours !== '') ? total.service_total_hours + parseInt(budgetData[i].service_total_hours) : total.service_total_hours;
              total.service_CP_hours = (budgetData[i] && budgetData[i].service_CP_hours !== '') ? total.service_CP_hours + parseInt(budgetData[i].service_CP_hours) : total.service_CP_hours;
              total.service_CP_ROs = (budgetData[i] && budgetData[i].service_CP_ROs !== '') ? total.service_CP_ROs + parseInt(budgetData[i].service_CP_ROs) : total.service_CP_ROs;
              total.service_total_GP = (budgetData[i] && budgetData[i].service_total_GP !== '') ? total.service_total_GP + parseInt(budgetData[i].service_total_GP) : total.service_total_GP;
              total.appt_made = (budgetData[i] && budgetData[i].appt_made !== '') ? total.appt_made + parseInt(budgetData[i].appt_made) : total.appt_made;
              total.appt_show = (budgetData[i] && budgetData[i].appt_show !== '') ? total.appt_show + parseInt(budgetData[i].appt_show) : total.appt_show;
              total.store_visit = (budgetData[i] && budgetData[i].store_visit !== '') ? total.store_visit + parseInt(budgetData[i].store_visit) : total.store_visit;
              total.lead = (budgetData[i] && budgetData[i].lead !== '') ? total.lead + parseInt(budgetData[i].lead) : total.lead;
              return <CreateRowForUsed row={budgetData} userData={userData} month={i} filterData={filterVal} loadData={() => loadData()} />
            })
          }
          <TotalRowForUsed total={total} />
        </tbody>
      </table>
    </div>
  );
};

export default BudgetCarooga
