export default (data) => {

  const totalNewFrontGP = data.total_new_front_GP ? parseInt(data.total_new_front_GP) : 0;
  const totalNewBackGP = data.total_new_back_GP ? parseInt(data.total_new_back_GP) : 0;
  const totalUsedFrontGP = data.total_used_front_GP ? parseInt(data.total_used_front_GP) : 0;
  const totalUsedBackGP = data.total_used_back_GP ? parseInt(data.total_used_back_GP) : 0;
  const totalServiceGP = data.total_service_GP ? parseInt(data.total_service_GP) : 0;
  const totalApparelGross=data.total_apparel_gross? parseInt(data.total_apparel_gross) : 0;
  const totalBoatsFrontGP = data.total_boats_front_GP ? parseInt(data.total_boats_front_GP) : 0;
  const totalBoatsBackGP = data.total_boats_back_GP ? parseInt(data.total_boats_back_GP) : 0;
  const totalTrailersFrontGP = data.total_trailers_front_GP ? parseInt(data.total_trailers_front_GP) : 0;
  const totalTrailersBackGP = data.total_trailers_back_GP ? parseInt(data.total_trailers_back_GP) : 0;
  const totalWholeSaleGP = data.total_parts_whole_sale_GP ? parseInt(data.total_parts_whole_sale_GP) : 0;


  // const totalPartsGP = data.total_parts_retail_GP ? parseInt(data.total_parts_retail_GP) : 0;
  const totalPartsRetailGP = data.total_parts_retail_GP ? parseInt(data.total_parts_retail_GP) : 0;
  const total =  totalNewFrontGP + totalNewBackGP + totalUsedFrontGP + totalUsedBackGP + totalServiceGP +totalPartsRetailGP+totalApparelGross+totalBoatsFrontGP+totalBoatsBackGP+totalTrailersFrontGP+totalTrailersBackGP + totalWholeSaleGP;

  if(total === 'NaN' || total === 'Infinity') {
    return '0'
  } else {
    return parseFloat(total?.toFixed());
  }
};