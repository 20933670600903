import React, { useEffect, useState } from "react";
import { calculatePercentageOfBudget } from "../../utils";
import axios from "axios";
import Links from "../../../src/Data/Links.js";

const SummaryReportsTable = (props) => {
  const { division, budgetThisMonth, dailyDataThisMonth, type } = props;

  let [autoStoresIds, setAutoStoresIds] = useState([]);
  const [autoStores, setAutoStores] = useState([]);
  const [divisions, setDivisions] = useState([]);
  const usedDealerships = ['DriveNation', 'FFUN Cars'];

  useEffect(() => {
    getAutoStores();
  }, []);

  const getAutoStores = () => {
    const autoStoresIds = [];
    const divisions = [];
    const autoStores = [];
    axios
      .get(Links.getAllStores)
      .then((response) => {
        response.data.stores.forEach((store) => {
          if (division === "Used") {
            if (usedDealerships.includes(store.division.trim())) {
              autoStoresIds.push(store.id);
              autoStores.push(store)
              divisions.push(store.division);
            }
          } else {
            if (store.division.trim().toLowerCase() === division.toLowerCase()) {
              autoStoresIds.push(store.id);
              divisions.push(store.division);
              autoStores.push(store)
            }
          }
        });
      })
      .then(() => {
        setAutoStores(autoStores);
        setAutoStoresIds(autoStoresIds);
        setDivisions(divisions);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const renderTotal = (data) => {
    if (data) {
      if (data.total_gross_profit) {
        return data.total_gross_profit.toLocaleString("en-CA", {
          maximumFractionDigits: 0,
          minimumFractionDigits: 0,
          style: "currency",
          currency: "CAD",
        });
      } else {
        return data.total_sold;
      }
    } else {
      return 0;
    }
  };

  const divisionTitle = (division) => {
    let title;
    switch (division) {
      case "Auto":
        title = "Automotive";
        break;
      // case "Drive Nation":
      //   title = division;
      //   break;
      case "Rec":
        title = "Recreational";
        break;
      case "Used":
        title = "Used";
        break;
      case "FFUN Financial":
        title = "FFUN Financial";
        break;
      default:
        title = "Automotive";
    }
    return title + " Dealerships";
  };

  const caluclateTotal = (data) => {
    let total = 0;
    if (data.length > 0) {
      data.map((data) => {
        if (data.total_gross_profit) {
          total += data.total_gross_profit;
        } else if (data.total_sold) {
          total += data.total_sold;
        } else {
          total += 0;
        }
      });

    }
    return total;
  };
  const totalDailyData = caluclateTotal(dailyDataThisMonth);
  const totalBudget = caluclateTotal(budgetThisMonth);

  autoStoresIds = type === "gross" ? Object.keys(dailyDataThisMonth).length > 0 && Object.keys(budgetThisMonth).length > 0 && autoStoresIds.sort((a, b) => {
    let dailyDataforCurrentStoreA = dailyDataThisMonth.find(key => a === key.store_id);
    let budgetforCurrentStoreA = budgetThisMonth.find(key => a === key.store_id);
    let dailyDataforCurrentStoreB = dailyDataThisMonth.find(key => b === key.store_id);
    let budgetforCurrentStoreB = budgetThisMonth.find(key => b === key.store_id);
    dailyDataforCurrentStoreA = dailyDataforCurrentStoreA === undefined ? {} : dailyDataforCurrentStoreA;
    budgetforCurrentStoreA = budgetforCurrentStoreA === undefined ? {} : budgetforCurrentStoreA;
    dailyDataforCurrentStoreB = dailyDataforCurrentStoreB === undefined ? {} : dailyDataforCurrentStoreB;
    budgetforCurrentStoreB = budgetforCurrentStoreB === undefined ? {} : budgetforCurrentStoreB;

    return (parseInt(calculatePercentageOfBudget(dailyDataforCurrentStoreA?.total_gross_profit, budgetforCurrentStoreA?.total_gross_profit) || 0) < parseInt(calculatePercentageOfBudget(dailyDataforCurrentStoreB?.total_gross_profit, budgetforCurrentStoreB?.total_gross_profit) || 0)) ? 1
      : (parseInt(calculatePercentageOfBudget(dailyDataforCurrentStoreB?.total_gross_profit, budgetforCurrentStoreB?.total_gross_profit) || 0) < parseInt(calculatePercentageOfBudget(dailyDataforCurrentStoreA?.total_gross_profit, budgetforCurrentStoreA?.total_gross_profit) || 0) ? -1 : 0)

  }) :
    Object.keys(dailyDataThisMonth).length > 0 && Object.keys(budgetThisMonth).length > 0 && autoStoresIds.sort((a, b) => {
      let dailyDataforCurrentStoreA = dailyDataThisMonth.find(key => a === key.store_id);
      let budgetforCurrentStoreA = budgetThisMonth.find(key => a === key.store_id);
      let dailyDataforCurrentStoreB = dailyDataThisMonth.find(key => b === key.store_id);
      let budgetforCurrentStoreB = budgetThisMonth.find(key => b === key.store_id);
      dailyDataforCurrentStoreA = dailyDataforCurrentStoreA === undefined ? {} : dailyDataforCurrentStoreA;
      budgetforCurrentStoreA = budgetforCurrentStoreA === undefined ? {} : budgetforCurrentStoreA;
      dailyDataforCurrentStoreB = dailyDataforCurrentStoreB === undefined ? {} : dailyDataforCurrentStoreB;
      budgetforCurrentStoreB = budgetforCurrentStoreB === undefined ? {} : budgetforCurrentStoreB;

      return (parseInt(calculatePercentageOfBudget(dailyDataforCurrentStoreA?.total_sold, budgetforCurrentStoreA?.total_sold) || 0) < parseInt(calculatePercentageOfBudget(dailyDataforCurrentStoreB?.total_sold, budgetforCurrentStoreB?.total_sold) || 0)) ? 1
        : (parseInt(calculatePercentageOfBudget(dailyDataforCurrentStoreB?.total_sold, budgetforCurrentStoreB?.total_sold) || 0) < parseInt(calculatePercentageOfBudget(dailyDataforCurrentStoreA?.total_sold, budgetforCurrentStoreA?.total_sold) || 0) ? -1 : 0)
    })
    ;
 

  return (
    <>
      <h4>{divisionTitle(division)}:</h4>
      <table className="mb-5 mt-3 table table-bordered table-fit table-striped">
        <thead>
          <tr className="text-center">
            <th
              colSpan="1"
              className="dn-header-top"
              style={{ whiteSpace: "nowrap", backgroundColor: "orange" }}
            >
              Division
            </th>
            <th
              colSpan="1"
              className="dn-header-top"
              style={{ whiteSpace: "nowrap", backgroundColor: "orange" }}
            >
              Store name
            </th>
            <th
              colSpan="1"
              className="dn-header-top"
              style={{
                whiteSpace: "nowrap",
                backgroundColor: "rgb(139, 139, 181)",
              }}
            >
              {type === "gross" ? "Actual Gross" : "Sold Units"}
            </th>
            <th
              colSpan="1"
              className="dn-header-top"
              style={{ whiteSpace: "nowrap", backgroundColor: "#a2c1a2" }}
            >
              Monthly Budget
            </th>
            <th
              colSpan="1"
              className="dn-header-top"
              style={{ whiteSpace: "nowrap", backgroundColor: "#b1d5dd" }}
            >
              KPI Indicator
            </th>
          </tr>
        </thead>
        <tbody>
          {autoStoresIds &&
            autoStoresIds.map((autoStoreId, index) => {
              if (budgetThisMonth.length > 0 && dailyDataThisMonth.length > 0) {
                const budgetThisMonthCurrentStore = budgetThisMonth.find((key) => key.store_id === autoStoreId);
                const dailyDataForCurrentStore = dailyDataThisMonth.find((key) => key.store_id === autoStoreId);
                return (
                  <>
                    <tr>
                      <td style={{ textAlign: "center" }}>{ autoStores.find(a => a.id === autoStoreId).division}</td>
                      <td style={{ textAlign: "center" }}>{autoStores.find(a => a.id === autoStoreId).name}</td>
                      <td style={{ textAlign: "center" }}>
                        {renderTotal(dailyDataForCurrentStore)}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {renderTotal(budgetThisMonthCurrentStore)}
                      </td>
                      <td style={{ fontWeight: 500, textAlign: "center", position: "relative" }} >
                        <div
                          class="progress"
                          style={{ height: "100%", width: "100%", position: "absolute", right: "0%", top: "0%", zIndex: "-1" }}
                        >
                          <div
                            class="progress-bar"
                            role="progressbar"
                            style={{
                              backgroundColor: "#7CEF7C ",
                              width:
                                dailyDataForCurrentStore &&
                                  budgetThisMonthCurrentStore
                                  ? calculatePercentageOfBudget(
                                    type === "gross"
                                      ? dailyDataForCurrentStore.total_gross_profit
                                      : dailyDataForCurrentStore.total_sold,
                                    type === "gross"
                                      ? budgetThisMonthCurrentStore.total_gross_profit
                                      : budgetThisMonthCurrentStore.total_sold
                                  )
                                  : "0",
                            }}
                            aria-valuenow="25"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                        {dailyDataForCurrentStore && budgetThisMonthCurrentStore
                          ? calculatePercentageOfBudget(
                            type === "gross"
                              ? dailyDataForCurrentStore.total_gross_profit
                              : dailyDataForCurrentStore.total_sold,
                            type === "gross"
                              ? budgetThisMonthCurrentStore.total_gross_profit
                              : budgetThisMonthCurrentStore.total_sold
                          )
                          : 0}
                      </td>
                    </tr>
                  </>
                );
              }
            })}
          <tr>
            <td></td>
            <td style={{ textAlign: "center" }}>Total</td>
            <td style={{ textAlign: "center" }}>
              {type === "gross"
                ? "$" + totalDailyData.toLocaleString()
                : totalDailyData.toLocaleString()}
            </td>
            <td style={{ textAlign: "center" }}>
              {type === "gross"
                ? "$" + totalBudget.toLocaleString()
                : totalBudget.toLocaleString()}
            </td>
            <td style={{ textAlign: "center" }}>
              {calculatePercentageOfBudget(totalDailyData, totalBudget)}
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default SummaryReportsTable;
