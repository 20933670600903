import React, { useEffect, useState } from "react";
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import axios from 'axios';
import Links from "../../../src/Data/Links.js";
import { MONTHS, YEARS, PIE_COLORS } from "../../../src/constants.js"
import moment from 'moment';
import { Bar, Pie } from 'react-chartjs-2';
import 'chartjs-adapter-moment';
import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { getNumberOfDaysTillToday, getNumberOfMonthsTillToday } from '../../utils'
import SummaryDefaultTable from "../../component/Reports/SummaryDefaultTable.js";
import GroupRecapTable from "../../component/Reports/GroupRecapTable.js";
import GroupRecapYTDTable from "../../component/Reports/GroupRecapYTDTable.js";
import MTDDealerComparisonTable from "../../component/Reports/MTDDealerComparisonTable.js"
import DailyDealerComparison from "../../component/Reports/DailyDealerComparison.js";
import CarLoading from '../../images/487.gif'
import getFiscals from '../../utils/getFiscals';

Chart.register(ChartDataLabels);

export default function DivisionalReportsPage(props) {
  const division = props.division;
  const currentYear = moment().format('YYYY');
  const currentMonth = moment().format('MMM');
  const [month, setMonth] = useState(currentMonth);
  const [year, setYear] = useState(currentYear);
  // const [autoStores, setAutoStores] = useState([]);
  const [autoStoresNames, setAutoStoresNames] = useState([]);
  const [years, setYears] = useState([]);
  const [dailyDataThisMonth, setDailyDataThisMonth] = useState([]);
  // const [dailyDataLastYearSameMonth, setDailyDataLastYearSameMonth] = useState([]);
  const [budgetThisMonth, setBudgetThisMonth] = useState([]);
  // const [dealerBudgetLastYearSameMonth, setDealerBudgetLastYearSameMonth] = useState([]);
  // const [ytd, setytd] = useState({});
  // const [lastYearYtd, setLastYearYtd] = useState({});
  // const [budgetYTD, setbudgetYTD] = useState({});
  // const [budgetLastYearYtd, setBudgetLastYearYtd] = useState({});
  // const [numberOfDaysTillToday, setNumberOfDaysTillToday] = useState(undefined);
  // const [numberOfMonthsTillToday, setNumberOfMonthsTillToday] = useState(undefined);
  const [totalSold, setTotalSold] = useState([]);
  const [totalSoldLastYear, setTotalSoldLastYear] = useState([]);
  const [totalSoldBudget, setTotalSoldBudget] = useState([]);
  const [totalSoldBudgetLastYear, setTotalSoldBudgetLastYear] = useState([]);
  const [totalAppt, setTotalAppt] = useState([]);
  // const [totalApptLastYear, setTotalApptLastYear] = useState([]);
  // const [dailyDataForDivision, setDailyDataForDivison] = useState({});
  // const [mtd, setMtd] = useState({});
  const [showNumbers, setShowNumbers] = useState(true);
  const [showCharts, setShowCharts] = useState(false);
  const [loading, setLoading] = useState(true);
  const [dailyDataGrossProfit, setDailyDataGrossProfit] = useState([]);
  const [dailyDataGrossProfitLastYearSameMonth, setDailyDataGrossProfitLastYearSameMonth] = useState([]);
  const [budgetTotalGrossProfit, setBudgetTotalGrossProfit] = useState([]);
  const [budgetTotalGrossProfitLastYearSameMonth, setBudgetTotalGrossProfitLastYearSameMonth] = useState([]);
  const [budgetTotalGrossProfitYTD, setBudgetTotalGrossProfitYTD] = useState([]);
  const [budgetTotalGrossProfitYTDLastYear, setBudgetTotalGrossProfitYTDLastYear] = useState([]);
  const [dailyDataTotalGrossProfitYTD, setDailyDataTotalGrossProfitYTD] = useState([]);
  const [dailyDataTotalGrossProfitYTDLastYear, setDailyDataTotalGrossProfitYTDLastYear] = useState([]);
  const [dailyDataThisMonthDealerComparison, setDailyDataThisMonthDealerComparison] = useState([]);
  const [usedStores, setUsedStores] = useState([]);
  const usedDealerships = ['DriveNation', 'FFUN Cars'];
  useEffect(() => {
    window.scrollTo(0, 0)
    getAutoStores();
    setYears(YEARS);
    // setNumberOfMonthsTillToday(getNumberOfMonthsTillToday());
    getNumberOfDaysTillToday(getNumberOfDaysTillToday());
  }, []);

  useEffect(() => {
    getDailyData(year, month);
    getDealerBudgetData(year, month);
  }, [autoStoresNames]);

  const getAutoStores = () => {
    const autoStores = [];
    const autoStoresNames = [];
    const usedStores = []
    axios.get(Links.getAllStores)
      .then(response => {
        if (response) {
          response.data.stores.forEach(store => {
            if (division === 'Used') {
              if (usedDealerships.includes(store.division)) {
                autoStores.push(store);
                autoStoresNames.push(store.name);
                usedStores.push(store)
              }
            } else {
              if (store.division.trim().toLowerCase() === division.toLowerCase()) {
                autoStores.push(store);
                autoStoresNames.push(store.name);
              }

            }
          })
        }
        // setAutoStores(autoStores);
        setAutoStoresNames(autoStoresNames);
        setUsedStores(usedStores);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const passRightUrl = (division) => {
    switch (division) {
      case 'Used':
        return Links.getDivisionalReportForUsed
      case 'Rec':
        return Links.getDivisionalReportForRec
      case 'Auto':
        return Links.getDivisionalReport
      default:
        return Links.getDivisionalReport
    }
  };

  const passRightUrlForBudget = (division) => {
    switch (division) {
      case 'Used':
        return Links.getFilteredBudgetForDivisionalReportForUsed
      case 'Rec':
        return Links.getFilteredBudgetForDivisionalReportForRec
      case 'Auto':
        return Links.getFilteredBudgetForDivisionalReport
      default:
        return Links.getFilteredBudgetForDivisionalReport
    }
  };

  const getDailyData = (year, month) => {
    if (autoStoresNames.length > 0) {
      const requestBody = {
        year: year,
        month: month,
        store_names: autoStoresNames,
        division: division.toLowerCase()
      }

      axios.post(passRightUrl(division), requestBody)
        .then(result => {

          setDailyDataThisMonth(result.data.dailyDataThisMonth);
          // setDailyDataLastYearSameMonth(result.data.dailyDataLastYearSameMonth);
          setDailyDataGrossProfit(result.data.totalGrossProfit);
          setDailyDataGrossProfitLastYearSameMonth(result.data.totalGrossProfitLastYearSameMonth);
          setDailyDataTotalGrossProfitYTD(result.data.totalGrossProfitYTD);
          setDailyDataTotalGrossProfitYTDLastYear(result.data.totalGrossProfitYTDLastYear);
          setDailyDataThisMonthDealerComparison(result.data.dailyDataThisMonthDealerComparison)
          // setytd(result.data.ytd[0]);
          // setLastYearYtd(result.data.ytdLastYear[0][0]);
          // setMtd(result.data.dailyDataThisMonth[0][0]);
          setTotalSold(result.data.totalSold);
          setTotalSoldLastYear(result.data.totalSoldLastYear);
          setTotalAppt(result.data.totalAppt);
          // setTotalApptLastYear(result.data.totalApptLastYear);
          setLoading(false);
        })
        .catch(err => {
          console.log(err);
        });

    }
  };

  const getDealerBudgetData = (year, month) => {
    if (autoStoresNames.length > 0) {
      const stringifiedYear = getFiscals(month, year)
      const requestBody = {
        fiscal_year: "FY" + stringifiedYear.toString().slice(-2),
        month: month,
        store_names: autoStoresNames,
        division: division.toLowerCase()
      }

      axios.post(passRightUrlForBudget(division), requestBody)
        .then(result => {
          setBudgetThisMonth(result.data.budgetThisMonth);
          // setDealerBudgetLastYearSameMonth(result.data.dealerBudgetLastYearSameMonth);
          setBudgetTotalGrossProfit(result.data.totalGrossProfit);
          setBudgetTotalGrossProfitLastYearSameMonth(result.data.totalGrossProfitLastYear);
          setBudgetTotalGrossProfitYTD(result.data.totalGrossProfitYTD);
          setBudgetTotalGrossProfitYTDLastYear(result.data.totalGrossProfitYTDLastYear);
          // setbudgetYTD(result.data.budgetYTD[0]);
          // setBudgetLastYearYtd(result.data.olderYearBudgetYTD[0]);
          setTotalSoldBudget(result.data.totalBudgetSold);
          setTotalSoldBudgetLastYear(result.data.totalBudgetSoldLastYear);

        }).catch(err => {
          console.log(err);
        });
    }
  };

  const handleYearChange = (event) => {
    setYear(event.target.value);
  };

  const handleMonthChange = (event) => {
    setMonth(event.target.value);
  };

  const showResults = () => {
    setLoading(true);
    getDailyData(year, month);
    getDealerBudgetData(year, month);
    // setNumberOfMonthsTillToday(getNumberOfMonthsTillToday());
    getNumberOfDaysTillToday(getNumberOfDaysTillToday());
  };

  const calculateTotalGPFromBudget = () => {
    const dataForChart = [];
    if (budgetTotalGrossProfit.length === autoStoresNames.length) {
      budgetTotalGrossProfit.forEach(budget => {
        dataForChart.push(calculateGrossProfit(budget))
      })
    } else {
      autoStoresNames.map(store => {
        const toAdd = budgetTotalGrossProfit.find(key => store.toLowerCase() === key.store_name.toLowerCase());
        if (!toAdd) {
          dataForChart.push("0")
        } else {
          dataForChart.push(toAdd.total_gross_profit.toFixed());
        }
      })
    }
    return dataForChart;
  }
  const calculateTotalGPFromDailyData = () => {
    const dataForChart = [];
    if (dailyDataGrossProfit.length > 0) {
      autoStoresNames.map(store => {
        const toAdd = dailyDataGrossProfit.find(key => store.toLowerCase() === key.store_name.toLowerCase());
        if (!toAdd) {
          dataForChart.push("0")
        } else {
          dataForChart.push(toAdd.total_gross_profit.toFixed());
        }
      });
      return dataForChart;
    }
  };

  const calculateTotalGPFromDailyDataYTD = () => {
    const dataForChart = [];
    if (dailyDataTotalGrossProfitYTD.length > 0) {
      autoStoresNames.map(store => {
        const toAdd = dailyDataTotalGrossProfitYTD.find(key => store.toLowerCase() === key.store_name.toLowerCase());
        if (!toAdd) {
          dataForChart.push("0")
        } else {
          dataForChart.push(toAdd.total_gross_profit.toFixed());
        }
      });
      return dataForChart;
    }
  }
  const calculateTotalGPFromBudgetLastYear = () => {
    const dataForChart = [];
    if (budgetTotalGrossProfitLastYearSameMonth.length === autoStoresNames.length) {
      budgetTotalGrossProfitLastYearSameMonth.forEach(budget => {
        dataForChart.push(calculateGrossProfit(budget))
      })
    } else {
      autoStoresNames.map(store => {
        const toAdd = budgetTotalGrossProfitLastYearSameMonth.find(key => store.toLowerCase() === key.store_name.toLowerCase());
        if (!toAdd) {
          dataForChart.push("0")
        } else {
          dataForChart.push(toAdd.total_gross_profit);
        }
      })
    }
    return dataForChart;
  }

  const calculateTotalGPFromDailyDataLastYear = () => {
    const dataForChart = [];
    autoStoresNames.map(store => {
      const toAdd = dailyDataGrossProfitLastYearSameMonth.find(key => store.toLowerCase() === key.store_name.toLowerCase());
      if (!toAdd) {
        dataForChart.push("0")
      } else {
        dataForChart.push(toAdd.total_gross_profit);
      }
    })
    return dataForChart;
  };

  const calculateGrossProfit = (data) => {

    const newFrontGP = data.new_retail_front_GP ? parseInt(data.new_retail_front_GP) : 0;
    const newBackGP = data.new_retail_back_GP ? parseInt(data.new_retail_back_GP) : 0;
    const usedFrontGP = data.used_retail_front_GP ? parseInt(data.used_retail_front_GP) : 0;
    const usedBacktGP = data.used_retail_back_GP ? parseInt(data.used_retail_back_GP) : 0;
    const serviceGP = data.service_total_GP ? parseInt(data.service_total_GP) : 0;
    const partsWSGP = data.parts_W_S_GP ? parseInt(data.parts_W_S_GP) : 0;
    const partsRetailsGP = data.parts_retail_GP ? parseInt(data.parts_retail_GP) : 0;
    const collisionGP = data.collision_GP || typeof data.collision_GP !== 'undefined' ? parseInt(data.collision_GP) : 0;
    const grossProfit = newFrontGP + newBackGP + usedFrontGP + usedBacktGP + serviceGP + partsWSGP + partsRetailsGP;
    return grossProfit;
  };

  const caluclateTotalGPAndBudget = () => {
    const dataForChart = [];
    const totalGP = calculateTotalGPFromDailyData();
    const totalGPBudget = calculateTotalGPFromBudget();
    if (totalGP) {
      totalGP.forEach((data, index) => {
        dataForChart.push(parseInt(parseInt(data) / totalGPBudget[index] * 100).toFixed(2))
      })
      return dataForChart;
    }
  };

  const caluclateTotalGPAndBudgetLastYear = () => {
    const dataForChart = [];
    const totalGP = calculateTotalGPFromDailyDataLastYear();
    const totalGPBudget = calculateTotalGPFromBudgetLastYear();
    totalGP.forEach((data, index) => {
      dataForChart.push(parseInt(parseInt(data) / totalGPBudget[index] * 100).toFixed(2))
    })
    return dataForChart;
  };

  const calculateAchievedSoldBudget = () => {
    if (totalSoldBudget && totalSold) {
      const dataForChart = [];
      autoStoresNames.map(store => {
        const toAdd = totalSold.find(key => store === key.store_name);
        const toAddBudget = totalSoldBudget.find(key => store === key.store_name);
        if (toAdd && toAddBudget) {
          const percentage = (toAdd.total_sold / toAddBudget.total_budget_sold * 100).toFixed(2);
          if (percentage !== 'Infinity' || percentage !== 'NaN') {
            dataForChart.push(percentage)
          } else {
            dataForChart.push("0");
          }
        } else {
          dataForChart.push("0")
        }
      })
      return dataForChart;
    }
  }

  const calculateAchievedSoldBudgetLastYear = () => {
    if (totalSoldBudgetLastYear && totalSoldLastYear) {
      const dataForChart = [];
      autoStoresNames.map(store => {
        const toAdd = totalSoldLastYear.find(key => store === key.store_name);
        const toAddBudget = totalSoldBudgetLastYear.find(key => store === key.store_name);
        if (toAdd && toAddBudget) {
          const percentage = (toAdd.total_sold / toAddBudget.total_budget_sold * 100).toFixed(2);
          if (percentage !== 'Infinity' || percentage !== 'NaN') {
            dataForChart.push(percentage)
          } else {
            dataForChart.push("0");
          }
        } else {
          dataForChart.push("0")
        }
      })
      return dataForChart;
    }
  }

  const calculateAchievedAppt = () => {
    if (totalAppt && totalSold) {
      const dataForChart = [];
      autoStoresNames.map(store => {
        const toAdd = totalSold.find(key => store.toLowerCase() === key.store_name.toLowerCase());
        const toAddAppt = totalAppt.find(key => store.toLowerCase() === key.store_name.toLowerCase());
        if (toAdd && toAddAppt) {
          dataForChart.push((toAdd.total_sold / toAddAppt.total_appt * 100).toFixed(2));
        } else {
          dataForChart.push("0")
        }
      })
      return dataForChart;
    }
  }

  const calculateAchievedApptLastYear = () => {
    if (totalSoldBudgetLastYear && totalSoldLastYear) {
      const dataForChart = [];
      autoStoresNames.map(store => {
        const toAdd = totalSoldLastYear.find(key => store.toLowerCase() === key.store_name.toLowerCase());
        const toAddBudget = totalSoldBudgetLastYear.find(key => store.toLowerCase() === key.store_name.toLowerCase());
        if (toAdd && toAddBudget) {
          dataForChart.push((toAdd.total_sold / toAddBudget.total_appt * 100).toFixed(2));
        } else {
          dataForChart.push("0")
        }
      })
      return dataForChart;
    }
  }

  const chartData = {
    labels: autoStoresNames,
    datasets: [
      {
        label: 'current year',
        backgroundColor: '#161853',
        borderColor: '#161853',
        borderWidth: 1,
        hoverBackgroundColor: '#160040',
        hoverBorderColor: '#160040',
        data: caluclateTotalGPAndBudget(),
      },
      {
        label: 'last year',
        backgroundColor: '#EC255A',
        borderColor: '#EC255A',
        borderWidth: 2,
        hoverBackgroundColor: '#EC255A',
        hoverBorderColor: '#EC255A',
        data: caluclateTotalGPAndBudgetLastYear()
      }
    ],
  }

  const chartDataGP = {
    labels: autoStoresNames,
    datasets: [
      {
        label: 'current year',
        backgroundColor: '#161853',
        borderColor: '#161853',
        borderWidth: 1,
        hoverBackgroundColor: '#161853',
        hoverBorderColor: '#161853',
        data: calculateTotalGPFromDailyData()
      },
      {
        label: 'last year',
        backgroundColor: '#EC255A',
        borderColor: '#EC255A',
        borderWidth: 1,
        hoverBackgroundColor: '#EC255A',
        hoverBorderColor: '#EC255A',
        data: calculateTotalGPFromDailyDataLastYear()
      }
    ],
  }

  const normalChartOptions = {
    plugins: {
      datalabels: {
        formatter: (v) => {
          return "$" + v
        },
        align: 'end',
        anchor: 'end'
      }
    },
    scales: {
      y: {
        ticks: {
          callback: function (label) {
            return "$" + label
          }
        }
      }
    },
  };

  const percentageChartOptions = {
    plugins: {
      datalabels: {
        formatter: (v) => {
          return v + "%"
        },
        align: 'end',
        anchor: 'end'
      }
    },
    scales: {
      y: {
        ticks: {
          callback: function (label) {
            return (label) + "%"
          }
        }
      }
    },
  };

  const percentageChartOptionsForAppt = {
    plugins: {
      datalabels: {
        formatter: (v) => {
          return v + "%"
        },
        align: 'end',
        anchor: 'end'
      }
    },
    scales: {
      y: {
        ticks: {
          callback: function (label) {
            return label + "%"
          },
        }
      }
    },
  };

  const chartDataTotalSold = {
    labels: autoStoresNames,
    datasets: [
      {
        label: 'current year',
        backgroundColor: '#161853',
        borderColor: '#161853',
        borderWidth: 1,
        hoverBackgroundColor: '#161853',
        hoverBorderColor: '#161853',
        data: calculateAchievedSoldBudget()
      },
      {
        label: 'last year',
        backgroundColor: '#EC255A',
        borderColor: '#EC255A',
        borderWidth: 1,
        hoverBackgroundColor: '#EC255A',
        hoverBorderColor: '#EC255A',
        data: calculateAchievedSoldBudgetLastYear()
      }
    ],
  }

  const chartDataTotalAppt = {
    labels: autoStoresNames,
    datasets: [
      {
        label: 'current year',
        backgroundColor: '#161853',
        borderColor: '#161853',
        borderWidth: 1,
        hoverBackgroundColor: '#161853',
        hoverBorderColor: '#161853',
        data: calculateAchievedAppt()
      },
      {
        label: 'last year',
        backgroundColor: '#EC255A',
        borderColor: '#EC255A',
        borderWidth: 1,
        hoverBackgroundColor: '#EC255A',
        hoverBorderColor: '#EC255A',
        data: calculateAchievedApptLastYear()
      }
    ],
  };

  const renderAutomativeReportsChartGP = () => {
    if (dailyDataThisMonth) {
      return (
        <>
          <h3 className="text-center">Total Gross Profit</h3>
          <Bar
            datasetIdKey='id'
            data={chartDataGP}
            options={normalChartOptions}
          />
        </>
      )
    } else {
      return (
        <>
        </>
      )
    }
  };

  const renderAutomativeReportsChartBudget = () => {
    if (budgetThisMonth) {
      return (
        <>
          <h3 className="text-center">% Achieved</h3>
          <Bar
            datasetIdKey='id'
            data={chartData}
            options={percentageChartOptions}
          />
        </>
      )
    } else {
      return (
        <>
        </>
      )
    }
  };

  const renderAutomativeReportsChartBudgetSold = () => {
    if (totalSoldBudget) {
      return (
        <>
          <h3 className="text-center">Sold Units / Budgeted Units</h3>
          <Bar
            datasetIdKey='id'
            data={chartDataTotalSold}
            options={percentageChartOptionsForAppt}
          />
        </>
      )
    } else {
      return (
        <>
        </>
      )
    }
  };

  const renderAutomativeReportsChartSoldAppointment = () => {
    if (totalSold) {
      return (
        <>
          <h3 className="text-center">Sold Units / Appointments</h3>
          <Bar
            datasetIdKey='id'
            data={chartDataTotalAppt}
            options={percentageChartOptionsForAppt}
          />
        </>
      )
    } else {
      return (
        <>
        </>
      )
    }
  };

  const pieDataGP = {
    labels: autoStoresNames,
    datasets: [
      {
        label: 'Gross Profit',
        backgroundColor: Object.values(PIE_COLORS),
        borderColor: Object.values(PIE_COLORS),
        borderWidth: 2,
        borderColor: 'white',
        hoverBackgroundColor: Object.values(PIE_COLORS),
        hoverBorderColor: Object.values(PIE_COLORS),
        data: calculateTotalGPFromDailyData()
      },
    ],
  }

  const pieDataGPYTD = {
    labels: autoStoresNames,
    datasets: [
      {
        label: 'Gross Profit',
        backgroundColor: Object.values(PIE_COLORS),
        borderColor: Object.values(PIE_COLORS),
        borderWidth: 2,
        borderColor: 'white',
        hoverBackgroundColor: Object.values(PIE_COLORS),
        hoverBorderColor: Object.values(PIE_COLORS),
        data: calculateTotalGPFromDailyDataYTD()
      },
    ],
  }
  // const alwaysShowTooltip = {
  //   id: 'alwaysShowTooltip',
  // };

  const theOptions = {
    elements: {
      arc: {
        borderWidth: 2
      }
    },
    showTooltips: true,
    interaction: {
      intersect: true
    },
    hover: {
      mode: false,
    },
    plugins: {
      datalabels: {
        color: 'white',
        formatter: (v) => {
          return "$" + v.toLocaleString('en-CA', {
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
            style: 'currency',
            currency: 'CAD',
          })
        }
      },
      tooltip: {
      },
    },

  }
  const renderAutomativeReportsPieBudgetGP = () => {
    return (
      <>
        <h3 className="text-center">Month To Date: Total Gross Profit</h3>
        <Pie
          // datasetIdKey='id'
          options={theOptions}
          data={pieDataGP}
        />
      </>
    )
  };

  const renderAutomativeReportsPieBudgetGPYTD = () => {
    return (
      <>
        <h3 className="text-center">Year To Date: Total Gross Profit</h3>
        <Pie
          // datasetIdKey='id'
          data={pieDataGPYTD}
          options={{
            elements: {
              arc: {
                borderWidth: 2
              }
            },
            plugins: {
              datalabels: {
                color: 'white',
                formatter: (v) => {
                  return "$" + v
                }
              }
            },
            showAllTooltips: true
          }}
        />
      </>
    )
  };

  const changeToggle = (e) => {
    if (e.target.value === 'Numbers') {
      setShowNumbers(true);
      setShowCharts(false);
    } else {
      setShowNumbers(false);
      setShowCharts(true);
    }
  };


  return (
    <div style={{  marginTop: "3rem" }}>
      <Grid container spacing={2}>
        <Grid item  container spacing={1}>

          <Grid item  xs={10} sm={6} md={2} xl={1}  spacing={1}>
            <FormControl variant="standard" sx={{ m: 1, width: "100%" }}>
              <InputLabel id="demo-simple-select-label">Year</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={year}
                label="Year"
                onChange={handleYearChange}
              >
                {years.map((year, index) => (
                  <MenuItem key={index} value={year}>{year}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item  xs={10} sm={6} md={2} xl={1}  spacing={1}>
            <FormControl variant="standard" sx={{ m: 1, width: "100%" }}>
              <InputLabel id="demo-simple-select-label">Month</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={month}
                label="Age"
                onChange={handleMonthChange}
              >
                {Object.values(MONTHS).map((month, index) => (
                  <MenuItem key={index} value={month}>{month}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item className="mt-3">
            <button className="p-2 mt-1 btn btn-secondary mx-3" type="submit"
              onClick={() => {
                showResults()
              }}
            >Show Details</button>
          </Grid>
          <Grid item className="mt-2">
            <button className={`p-2 mt-2 btn toggle ${showNumbers ? 'active' : ''}`} onClick={(e) => changeToggle(e)} value="Numbers">Numbers</button>
            <button className={`p-2 mt-2 btn toggle ${showCharts ? 'active' : ''}`} onClick={(e) => changeToggle(e)} value="Charts">Charts</button>
          </Grid>

        </Grid>
        <Grid item container xs={12} spacing={2}>
          {showCharts && <>
            <Grid item xs={5} style={{ marginTop: '100px' }}>
              {renderAutomativeReportsChartBudget()}
            </Grid>
            <Grid item xs={5} style={{ marginTop: '100px' }}>
              {renderAutomativeReportsChartGP()}
            </Grid>
            <Grid item xs={5} style={{ marginTop: '100px' }}>
              {renderAutomativeReportsChartBudgetSold()}
            </Grid>
            <Grid item xs={5} style={{ marginTop: '100px' }}>
              {renderAutomativeReportsChartSoldAppointment()}
            </Grid>
            <Grid item xs={5} style={{ marginTop: '100px' }}>
              {renderAutomativeReportsPieBudgetGP()}
            </Grid>
            <Grid item xs={5} style={{ marginTop: '100px' }}>
              {renderAutomativeReportsPieBudgetGPYTD()}
            </Grid>
          </>
          }

        </Grid>

        <Grid item container>
          {loading && <div style={{  marginTop: "6rem", right: '50%', top: '10%' }} className="text-center position-absolute">
            <img src={CarLoading} />
          </div>
          }
          {!loading && showNumbers && <>
            <Grid item style={{ borderRadius: 4, padding: 20, marginTop: 10, border: '0px solid #adadad', boxShadow: '0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%)' }}>
              <h4>Divisional Summary:</h4>

              <SummaryDefaultTable dailyDataThisMonth={dailyDataThisMonth[0]} budgetThisMonth={budgetThisMonth[0]} division={division} reportType="divisional" />
            </Grid>
            <div className="w-100"></div>
            <Grid item container style={{ width: "auto", borderRadius: 4, padding: 20, marginTop: 100, border: '0px solid #adadad', boxShadow: '0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%)' }}>
              <Grid item marginTop={4} >
                <h4>Divisional Recap: Month To Date</h4>
                <GroupRecapTable
                  division={division}
                  stores={autoStoresNames}
                  dailyDataGrossProfit={dailyDataGrossProfit}
                  dailyDataGrossProfitLastYearSameMonth={dailyDataGrossProfitLastYearSameMonth}
                  budgetTotalGrossProfit={budgetTotalGrossProfit}
                  budgetTotalGrossProfitLastYearSameMonth={budgetTotalGrossProfitLastYearSameMonth}
                  usedStores={usedStores}
                />
              </Grid>
              <Grid item marginTop={4}>
                <h4 style={{ paddingLeft: '5px' }}>Divisional Recap: Year to Date</h4>
                <GroupRecapYTDTable
                  division={division}
                  stores={autoStoresNames}
                  dailyDataGrossProfitYTD={dailyDataTotalGrossProfitYTD}
                  dailyDataGrossProfitYTDLastYear={dailyDataTotalGrossProfitYTDLastYear}
                  budgetTotalGrossProfitYTD={budgetTotalGrossProfitYTD}
                  budgetTotalGrossProfitYTDLastYear={budgetTotalGrossProfitYTDLastYear}
                  usedStores={usedStores}
                />
              </Grid>
            </Grid>
            <div className="w-100"></div>
            <Grid item marginTop={4} style={{ borderRadius: 4, padding: 20, marginTop: 100, border: '0px solid #adadad', boxShadow: '0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%)' }}>
              <h4>MTD Dealer Comparison:</h4>
              <MTDDealerComparisonTable
                division={division}
                usedStores={usedStores}
                stores={autoStoresNames}
                dailyDataThisMonth={dailyDataThisMonthDealerComparison}
              />
            </Grid>
            <div className="w-100"></div>
            <Grid item marginTop={4} style={{ borderRadius: 4, padding: 20, marginTop: 100, border: '0px solid #adadad', boxShadow: '0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%)' }}>
              <h4>Daily Dealer Comparison</h4>
              <DailyDealerComparison stores={autoStoresNames} division={division} usedStores={usedStores} />
            </Grid>
          </>
          }
        </Grid>
      </Grid>
    </div>
  );
};