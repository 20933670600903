import React, { useEffect, useState } from "react";
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import axios from 'axios';
import Links from "../../Data/Links.js";
import { MONTHS, YEARS } from "../../constants"
import moment from 'moment';
import { Line, Doughnut, Pie } from 'react-chartjs-2';
import { getNumberOfDaysTillToday, getNumberOfMonthsTillToday } from '../../utils'
import MTDRow from '../../component/Reports/MTDRow'
import PercentageRow from '../../component/Reports/PercentageRow'
import LineChart from '../../component/Reports/LineChart'
import './style.css';
import SummaryDefaultTable from "../../component/Reports/SummaryDefaultTable.js";
import Cookies from 'js-cookie';
import CarLoading from '../../images/487.gif'
import getFiscals from '../../utils/getFiscals';
import MTDDealerComparisonTable from "../../component/Reports/MTDDealerComparisonTable.js"


export default function DealerReports() {

  const initialDivisionName = "";
  let initialStoreName = "";

  // if (division === "dealer-reports") {
  //   window.location = '/home';
  // }
  // else if (division === "Auto") {
  // initialStoreName = "Acura";
  // }
  // else if (division === "Rec") {
  //   initialStoreName = "Rock HD";
  // }
  // else if (division === "FFUNCars") {
  //   initialStoreName = "Saskatoon";
  // }
  // else if (division === "DriveNation") {
  //   initialStoreName = "Saskatoon";
  // }
  // else {

  //   initialStoreName = "BC";

  // }


  const currentYear = moment().format('YYYY');
  const currentMonth = moment().format('MMM');
  const [month, setMonth] = useState(currentMonth);
  const [year, setYear] = useState(currentYear);
  const [storeName, setStoreName] = useState(initialStoreName);
  const [division, setDivision] = useState(initialDivisionName);
  const [stores, setStores] = useState(localStorage.getItem('stores') ? JSON.parse(localStorage.getItem('stores')) : "");
  const [years, setYears] = useState([]);
  const [dailyDataThisMonth, setDailyDataThisMonth] = useState({});
  const [dailyDataLastYearSameMonth, setDailyDataLastYearSameMonth] = useState({});
  const [budgetThisMonth, setBudgetThisMonth] = useState({});
  const [dealerBudgetLastYearSameMonth, setDealerBudgetLastYearSameMonth] = useState({});
  const [ytd, setytd] = useState({});
  const [ytdCharts, setytdCharts] = useState([]);
  const [mtdCharts, setmtdCharts] = useState([])
  const [ytdChartsLastYear, setytdChartsLastYear] = useState([]);
  const [mtdChartsLastYear, setmtdChartsLastYear] = useState([])
  const [lastYearYtd, setLastYearYtd] = useState({});
  const [budgetYTD, setbudgetYTD] = useState({});
  const [budgetLastYearYtd, setBudgetLastYearYtd] = useState({});
  const [numberOfDaysTillToday, setNumberOfDaysTillToday] = useState(undefined);
  const [numberOfMonthsTillToday, setNumberOfMonthsTillToday] = useState(undefined);
  const [totalGrossProfit, setTotalGrossProfit] = useState({});
  const [totalGrossProfitLastYear, setTotalGrossProfitLastYear] = useState({});
  const [totalGrossProfitBudget, setTotalGrossProfitBudget] = useState({});
  const [totalGrossProfitLastYearBudget, setTotalGrossProfitLastYearBudget] = useState({});
  const [showNumbers, setShowNumbers] = useState(true);
  const [showCharts, setShowCharts] = useState(false);
  const [loading, setLoading] = useState(true);
  const [dailyDataThisMonthDealerComparison, setDailyDataThisMonthDealerComparison] = useState([]);
  const [autoStores, setAutoStores] = useState([]);
  const [autoStoresNames, setAutoStoresNames] = useState([]);
  const [usedStores, setUsedStores] = useState([]);
  const usedDealerships = ['DriveNation', 'FFUN Cars'];


  useEffect(() => {
    // getAllStores();
    window.scrollTo(0, 0)
    setYears(YEARS);
    setNumberOfMonthsTillToday(getNumberOfMonthsTillToday())
    setNumberOfDaysTillToday(getNumberOfDaysTillToday());
  }, []);

  useEffect(() => {


    if (storeName === "") {
      for (var i = 0; i < Object.keys(stores).length; i++) {
        if ((stores[i].division) === "DriveNation" || (stores[i].division) === "FFUN Cars") {

          setStoreName(stores[i].name)
          setDivision(stores[i].division)
          getDailyData(year, month, stores[i].name);
          getDealerBudgetData(year, month, stores[i].name);
          break;
        }
      }
    }
    else {
      getDailyData(year, month, storeName);
      getDealerBudgetData(year, month, storeName);
    }

    getAutoStores();

  }, [division]);
  useEffect(() => {
    getAllDailyData(year, month);
  }, [autoStoresNames]);

  const passRightUrl = (division) => {
    switch (division) {
      case 'Used':
        return Links.getDivisionalReportForUsed
      case 'Rec':
        return Links.getDivisionalReportForRec
      case 'Auto':
        return Links.getDivisionalReport
      default:
        return Links.getDivisionalReport
    }
  };
  const getAutoStores = () => {
    const autoStores = [];
    const autoStoresNames = [];
    const usedStores = []
    const theDivision = "Used"
    axios.get(Links.getAllStores)
      .then(response => {
        if (response) {
          response.data.stores.forEach(store => {
            if (theDivision === 'Used') {
              if (usedDealerships.includes(store.division)) {
                autoStores.push(store);
                autoStoresNames.push(store.name);
                usedStores.push(store)
              }
            } else {
              if (store.division.trim().toLowerCase() === theDivision.toLowerCase()) {
                autoStores.push(store);
                autoStoresNames.push(store.name);
              }

            }
          })
        }
        setAutoStores(autoStores);
        setAutoStoresNames(autoStoresNames);
        setUsedStores(usedStores);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const getAllDailyData = (year, month) => {
    if (autoStoresNames.length > 0) {
      const requestBody = {
        year: year,
        month: month,
        store_names: autoStoresNames,
        division: "used"
      }

      axios.post(passRightUrl("Used"), requestBody)
        .then(result => {

          setDailyDataThisMonthDealerComparison(result.data.dailyDataThisMonthDealerComparison)

        })
        .catch(err => {
          console.log(err);
        });

    }
  };
  const getAllStores = () => {
    axios.get(Links.getAllStores)
      .then(response => {
        if (response) {
          setStores(response.data.stores)
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  const getDailyData = (year, month, storeName) => {
    const requestBody = {
      store_name: storeName,
      year: year,
      month: month,
      division: division

    }


    let theDealerReportLink = Links.getDealerReportUsed;



    axios.post(theDealerReportLink, requestBody)
      .then(result => {
        setDailyDataThisMonth(result.data.dailyDataThisMonth[0]);
        setDailyDataLastYearSameMonth(result.data.dailyDataLastYearSameMonth[0]);
        setytd(result.data.ytd[0]);
        setLastYearYtd(result.data.ytdLastYear[0]);
        setTotalGrossProfit(result.data.totalGrossProfit[0]);
        setTotalGrossProfitLastYear(result.data.totalGrossProfitLastYear[0]);
        setytdCharts(result.data.ytdCharts);
        setmtdCharts(result.data.mtdCharts);
        setytdChartsLastYear(result.data.ytdChartsLastYear);
        setmtdChartsLastYear(result.data.mtdChartsLastYear);
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const getDealerBudgetData = (year, month, storeName) => {
    const stringifiedYear = getFiscals(month, year)
    const requestBody = {
      store_name: storeName,
      fiscal_year: "FY" + stringifiedYear.toString().slice(-2),
      month: month,
      division: stores.find((store) => store.name === storeName).division,
      store_id: stores.find((store) => store.name === storeName).id
    }

    axios.post(Links.getFilteredBudgetForDealerReportUsed, requestBody)
      .then(result => {

        setBudgetThisMonth(result.data.budgetThisMonth[0]);
        setDealerBudgetLastYearSameMonth(result.data.budgetLastYearSameMonth[0]);
        setbudgetYTD(result.data.budgetYTD[0]);
        setBudgetLastYearYtd(result.data.lastYearBudgetYTD[0]);
        setTotalGrossProfitBudget(result.data.totalBudgetGrossProfit[0]);
        setTotalGrossProfitLastYearBudget(result.data.totalBudgetGrossProfitLastYear[0]);
      }).catch(err => {
        console.log(err);
      });
  };

  const handleYearChange = (event) => {
    setYear(event.target.value);
  };

  const handleStoreNameChange = (event) => {
    let data = (event.target.value).split("-")
    setStoreName(data[1]);
    setDivision(data[0]);
  };

  const handleMonthChange = (event) => {
    setMonth(event.target.value);
  };

  const showResults = () => {
    setLoading(true);
    getDailyData(year, month, storeName);
    getDealerBudgetData(year, month, storeName);
    setNumberOfMonthsTillToday(getNumberOfMonthsTillToday());
    setNumberOfDaysTillToday(getNumberOfDaysTillToday());
  };

  const pieChartOptions = {
    elements: {
      arc: {
        borderWidth: 2
      }
    },
    plugins: {
      datalabels: {
        color: 'white',
        formatter: (v) => {
          return "$" + v
        }
      },
    }
  };

  const normalChartOptions = {
    plugins: {
      datalabels: {
        formatter: (v) => {
          return "$" + v
        },
        position: '',
        padding: '10px',
        display: function (context) {
          console.log(context, 'context')
        }
      }
    },

    scales: {
      y: {
        ticks: {
          callback: function (label) {
            return "$" + label
          }
        },
        title: {
          display: true,
          text: 'Total Gross Profit'
        }
      },
      x: {
        title: {
          display: true,
          text: 'Month'
        }
      }
    },
  };

  const normalChartOptionsMTD = {
    plugins: {
      datalabels: {
        formatter: (v) => {
          return "$" + v
        }
      }
    },
    scales: {
      y: {
        ticks: {
          callback: function (label) {
            return "$" + label
          }
        },
        title: {
          display: true,
          text: 'Total Gross Profit'
        }
      },
      x: {
        title: {
          display: true,
          text: 'Day'
        }
      }
    },
  };

  const renderTotalGrossProfitChart = () => {
    const grossProfit = []
    const grossProfitLastYear = []
    if (mtdCharts) {
      mtdCharts.map(value => {
        grossProfit.push(value.total_new_front_GP + value.total_new_back_GP + value.total_used_front_GP + value.total_used_back_GP + value.total_service_total_GP + value.total_parts_GP)
      })
      ytdChartsLastYear.map(value => {
        grossProfitLastYear.push(value.total_new_front_GP + value.total_new_back_GP + value.total_used_front_GP + value.total_used_back_GP + value.total_service_total_GP + value.total_parts_GP)
      })
      return (
        <><h3>Month To Date: Total Gross Profit</h3><Line
          datasetIdKey='id'
          options={normalChartOptionsMTD}
          data={{
            labels: month === currentMonth ? numberOfDaysTillToday : Array.from({ length: moment(`${year}-${moment().month(month).format("M")}`).daysInMonth() }, (_, i) => i + 1),
            datasets: [
              {
                id: 1,
                label: 'Current Year',
                data: grossProfit,
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
                fill: false
              },
              {
                id: 2,
                label: 'Last Year',
                data: grossProfitLastYear,
                borderColor: 'rgb(53, 162, 235)',
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
                fill: false
              },
            ],
          }} /></>
      )
    } else {
      return (
        <>
        </>
      )
    }
  };

  const renderTotalGrossProfitBudgetChart = () => {
    const grossProfit = []
    const grossProfitLastYear = []
    if (ytdCharts) {
      ytdCharts.map(value => {
        grossProfit.push(value.total_new_front_GP + value.total_new_back_GP + value.total_used_front_GP + value.total_used_back_GP + value.total_service_total_GP + value.total_parts_GP)
      })
      ytdChartsLastYear.map(value => {
        grossProfitLastYear.push(value.total_new_front_GP + value.total_new_back_GP + value.total_used_front_GP + value.total_used_back_GP + value.total_service_total_GP + value.total_parts_GP)
      })
      return (
        <><h3>Year To Date: Total Gross Profit</h3><Line
          datasetIdKey='id'
          options={normalChartOptions}
          data={{
            labels: Object.values(MONTHS).slice(0, Object.values(MONTHS).indexOf(month) + 1),
            datasets: [
              {
                id: 1,
                label: 'Current Year',
                data: grossProfit,
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
                fill: false
              },
              {
                id: 2,
                label: 'Last Year',
                data: grossProfitLastYear,
                borderColor: 'rgb(53, 162, 235)',
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
                fill: false
              },
            ],
          }} /></>
      )
    } else {
      return (
        <>
        </>
      )
    }
  };

  const renderMTDChartGP = () => {
    const data = {
      labels: ["New front GP", "New back GP", "Used front GP", "Used back GP"],
      datasets: [
        {
          data: [dailyDataThisMonth.total_new_front_GP, dailyDataThisMonth.total_new_back_GP, dailyDataThisMonth.total_used_front_GP, dailyDataThisMonth.total_used_back_GP],
          backgroundColor: ["orange", "rgb(44, 160, 44)", "rgb(31, 119, 180)", "rgb(148, 103, 189)"],
        }
      ]
    };
    if (dailyDataThisMonth) {
      return (
        <>
          <h3>Month To Date: Gross Profit Breakdown</h3>
          <Pie data={data} options={pieChartOptions} />
        </>
      )
    } else {
      return (
        <>
        </>
      )
    }
  };

  const renderYTDChartGP = () => {
    const data = {
      maintainAspectRatio: false,
      responsive: false,
      labels: ["New front GP", "New back GP", "Used front GP", "Used back GP"],
      datasets: [
        {
          data: [ytd.total_new_front_GP, ytd.total_new_back_GP, ytd.total_used_front_GP, ytd.total_used_back_GP],
          backgroundColor: ["orange", "rgb(44, 160, 44)", "rgb(31, 119, 180)", "rgb(148, 103, 189)"],
        }
      ]
    };
    if (ytd) {
      return (
        <>
          <h3>Year To Date: Gross Profit Breakdown</h3>
          <Pie data={data} options={pieChartOptions} />
        </>
      )
    } else {
      return (
        <>
        </>
      )
    }
  };

  const changeToggle = (e) => {
    setShowNumbers(!showNumbers)
    setShowCharts(!showCharts)
  };

  return (
    <div style={{ marginTop: "3rem",marginLeft:"2rem", width: "100%" }}>
      <Grid container spacing={2} >
        <Grid item container spacing={1}>
          <Grid item  xs={10} sm={6} md={3} spacing={1} >
            <FormControl fullWidth variant="standard" className="mb-0">
              <InputLabel id="demo-simple-select-label">Dealer</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={division + "-" + storeName}
                label="Dealer"
                onChange={handleStoreNameChange}
              >
                {stores && stores.map((store, index) => (
                  ((store.division) === "DriveNation" || (store.division) === "FFUN Cars") && <MenuItem key={index} value={store.division + "-" + store.name}>{store.division + " " + store.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item  xs={10} sm={6} md={2} xl={1} spacing={1}>
            <FormControl fullWidth variant="standard" sx={{ minWidth: 120 }}>
              <InputLabel id="demo-simple-select-label">Year</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={year}
                label="Year"
                onChange={handleYearChange}
              >
                {years.map((year, index) => (
                  <MenuItem key={index} value={year}>{year}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item  xs={10} sm={6} md={2} xl={1} spacing={1}>
            <FormControl fullWidth variant="standard" sx={{ minWidth: 120 }}>
              <InputLabel id="demo-simple-select-label">Month</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={month}
                label="Age"
                onChange={handleMonthChange}
              >
                {Object.values(MONTHS).map((month, index) => (
                  <MenuItem key={index} value={month}>{month}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item   className="" style={{ marginTop: '11px'}}>
            <button className=" btn btn-secondary" type="submit"
              onClick={() => {
                showResults()
              }}
            >Show Details</button>
          </Grid>
          <Grid item  style={{ marginTop: '1px' }}>
            <button className={`p-2 mt-2 btn toggle ${showNumbers ? 'active' : ''}`} onClick={(e) => changeToggle(e)} value="Numbers">Numbers</button>
            <button className={`p-2 mt-2 btn toggle ${showCharts ? 'active' : ''}`} onClick={(e) => changeToggle(e)} value="Charts">Charts</button>
          </Grid>
        </Grid>
        {showCharts && <>
          <Grid item xs={5} style={{ marginTop: '100px' }}>
            <LineChart title={'Month To Date: Sold Units Trend'} labels={month === currentMonth ? numberOfDaysTillToday : Array.from({ length: moment(`${year}-${moment().month(month).format("M")}`).daysInMonth() }, (_, i) => i + 1)} month={month} year={year} mtdCharts={mtdCharts} />
          </Grid>
          <Grid item xs={5} style={{ marginTop: '100px' }}>
            <LineChart title={'Year To Date: Sold Units Trend'} labels={Object.values(MONTHS)} month={month} year={year} ytdCharts={ytdCharts} />
          </Grid>
          <Grid item xs={5} style={{ marginTop: '100px' }}>
            {renderTotalGrossProfitChart()}
          </Grid>
          <Grid item xs={5} style={{ marginTop: '100px' }}>
            {renderTotalGrossProfitBudgetChart()}
          </Grid>
          <Grid item xs={2} style={{ marginTop: '100px' }}>
          </Grid>
          <Grid item xs={4} style={{ marginTop: '100px' }}>
            {renderMTDChartGP()}
          </Grid>
          <Grid item xs={1} style={{ marginTop: '100px' }}>
          </Grid>
          <Grid item xs={4} style={{ marginTop: '100px' }}>
            {renderYTDChartGP()}
          </Grid>
        </>
        }
        {loading && <>
          <div style={{ marginLeft: "22rem", marginTop: "6rem", right: '10%', top: '10%' }} className="text-center position">
            <img src={CarLoading} />
          </div>
        </>

        }
        {!loading && showNumbers && <>
          <Grid style={{ borderRadius: 4, padding: 20, marginTop: 50, border: '0px solid #adadad', boxShadow: '0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%)' }}>
            <h4>Dealer Summary:</h4>
            <SummaryDefaultTable item xs={8} division={division} dailyDataThisMonth={dailyDataThisMonth} budgetThisMonth={budgetThisMonth} storeName={storeName} />
          </Grid>
          <div className="w-100"></div>
          <div >
            <Grid marginTop={4} style={{ borderRadius: 4, padding: 20, marginTop: 100, border: '0px solid #adadad', boxShadow: '0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%)' }}>
              <h4>MTD Dealer Comparison:</h4>
              <MTDDealerComparisonTable division="Used" usedStores={usedStores} stores={autoStoresNames} dailyDataThisMonth={dailyDataThisMonthDealerComparison} />
            </Grid>

          </div>
          {/*<Grid item xs={12} marginTop={4} style={{ borderRadius: 4,padding: 20, marginTop: 85, border: '0px solid #adadad', boxShadow: '0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%)'}}>
              <h4>Breakdown:</h4>
              <table className="table table-bordered border-black table-fit" style={{ marginTop: 30 }}>
                <thead>
                  <tr className="text-center">
                    <th colSpan="1" className="dn-header" ></th>
                    <th colSpan="3" className="dn-header" style={{ "backgroundColor": "orange" }} >New Vehicles</th>
                    <th colSpan="3" className="dn-header" style={{ "backgroundColor": "#a2c1a2" }}>Used Vehicles</th>
                    <th colSpan="4" className="dn-header" style={{ "backgroundColor": "#b1d5dd" }}>Service</th>
                    <th colSpan="3" className="dn-header" style={{ "backgroundColor": "#8b8bb5", "color": "#ffffff" }}>Parts</th>
                    <th colSpan="2" className="dn-header" style={{ "backgroundColor": "#8b8bb5", "color": "#ffffff" }}>Collision</th>
                    <th colSpan="1" className="dn-header" style={{ "backgroundColor": "#8b8bb5", "color": "#ffffff" }}></th>
                  </tr>
                  <tr>
                    <th colSpan="1" className="dn-header" ></th>
                    <th className="dn-header" scope="col" style={{ "backgroundColor": "orange" }}>Deliv`d</th>
                    <th className="dn-header" scope="col" style={{ "backgroundColor": "orange" }}>Front GP</th>
                    <th className="dn-header" scope="col" style={{ "backgroundColor": "orange" }}>Back GP</th>
                    <th className="dn-header" scope="col" style={{ "backgroundColor": "#a2c1a2" }}>Deliv`d</th>
                    <th className="dn-header" scope="col" style={{ "backgroundColor": "#a2c1a2" }}>Front GP</th>
                    <th className="dn-header" scope="col" style={{ "backgroundColor": "#a2c1a2" }}>Back GP</th>
                    <th className="dn-header" scope="col" style={{ "backgroundColor": "#b1d5dd" }}>Total Hours</th>
                    <th className="dn-header" scope="col" style={{ "backgroundColor": "#b1d5dd" }}>CP Hours</th>
                    <th className="dn-header" scope="col" style={{ "backgroundColor": "#b1d5dd" }}>CP RO's</th>
                    <th className="dn-header" scope="col" style={{ "backgroundColor": "#b1d5dd" }}>GP</th>
                    <th className="dn-header" scope="col" style={{ "backgroundColor": "#8b8bb5", "color": "#ffffff" }}>Sales</th>
                    <th className="dn-header" scope="col" style={{ "backgroundColor": "#8b8bb5", "color": "#ffffff" }}>Retail GP</th>
                    <th className="dn-header" scope="col" style={{ "backgroundColor": "#8b8bb5", "color": "#ffffff" }}>W/S GP</th>
                    <th className="dn-header" scope="col" style={{ "backgroundColor": "#8b8bb5", "color": "#ffffff" }}>Total Hours</th>
                    <th className="dn-header" scope="col" style={{ "backgroundColor": "#8b8bb5", "color": "#ffffff" }}>GP</th>
                    <th className="dn-header" scope="col" style={{ "backgroundColor": "#8b8bb5", "color": "#ffffff" }}>Total GP</th>
                  </tr>
                </thead>
                <tbody>
                  <MTDRow data={dailyDataThisMonth} title={"MTD"} />
                  <PercentageRow dailyData={dailyDataThisMonth} budget={budgetThisMonth} title={'KPI'} />
                  <PercentageRow dailyData={dailyDataLastYearSameMonth} budget={dealerBudgetLastYearSameMonth} title={'KPI: prior year'} />
                  <tr>
                    <td colSpan="1"></td>
                  </tr>
                  <MTDRow data={ytd} title={"YTD"}/>
                  <PercentageRow dailyData={ytd} budget={budgetYTD} title={'KPI'} />
                  <PercentageRow dailyData={lastYearYtd} budget={budgetLastYearYtd} title={'KPI: prior year'} />
                </tbody> 
              </table>
            </Grid>*/}
        </>
        }
      </Grid>
    </div>
  );
};