import React, { useEffect, useState } from "react";
import moment from "moment";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import {
  MONTHS,
  YEARS,
  EXECUTIVE_REPORTS_CATEGORIES,
} from "../../../src/constants.js";
import axios from "axios";
import Links from "../../../src/Data/Links.js";
import SummaryReportsTable from "../../component/Reports/SummaryReportsTable.js";
import SummaryReportsTableAllDivisions from "../../component/Reports/SummaryReportsTableAllDivisions.js";
import CarLoading from "../../images/487.gif";
import { PIE_COLORS } from "../../../src/constants.js"
import PieChart from "../../component/Charts/PieChart.js"
import BarChart from "../../component/Charts/BarChart.js"
import getFiscals from '../../utils/getFiscals';

export default function SummaryReportGross() {
  // const divisions = ['Auto', 'Rec', 'Used', 'FFUN Financial'];
  const divisions = ['Auto', 'Rec', 'Used'];
  const currentYear = moment().format("YYYY");
  const currentMonth = moment().format("MMM");
  const [month, setMonth] = useState(currentMonth);
  const [year, setYear] = useState(currentYear);
  const [category, setCategory] = useState("All");
  const [loading, setLoading] = useState(true);
  const [showNumbers, setShowNumbers] = useState(true);
  const [showCharts, setShowCharts] = useState(false);

  const [dailyDataThisMonth, setDailyDataThisMonth] = useState([]);
  const [budgetThisMonth, setBudgetThisMonth] = useState([]);
  const [dailyDataLastYearSameMonth, setDailyDataLastYearSameMonth] = useState([]);
  const [dealerBudgetLastYearSameMonth, setDealerBudgetLastYearSameMonth] = useState([]);
  const [totalGrossProfitYTD, setTotalGrossProfitYTD] = useState([]);

  const [dailyDataThisMonthForRec, setDailyDataThisMonthForRec] = useState({});
  const [budgetThisMonthForRec, setBudgetThisMonthForRec] = useState([]);
  const [dailyDataLastYearSameMonthForRec, setDailyDataLastYearSameMonthForRec] = useState([]);
  const [dealerBudgetLastYearSameMonthForRec, setDealerBudgetLastYearSameMonthForRec] = useState([]);
  const [totalGrossProfitYTDForRec, setTotalGrossProfitYTDForRec] = useState([]);

  const [dailyDataThisMonthForUsed, setDailyDataThisMonthForUsed] = useState([]);
  const [budgetThisMonthForUsed, setBudgetThisMonthForUsed] = useState([]);
  const [dailyDataLastYearSameMonthForUsed, setDailyDataLastYearSameMonthForUsed] = useState([]);
  const [dealerBudgetLastYearSameMonthForUsed, setDealerBudgetLastYearSameMonthForUsed] = useState([]);
  const [totalGrossProfitYTDForUsed, setTotalGrossProfitYTDForUsed] = useState([]);

  // const [dailyDataThisMonthForFF, setDailyDataThisMonthForFF] = useState([]);
  // const [budgetThisMonthForFF, setBudgetThisMonthForFF] = useState([]);
  // const [dailyDataLastYearSameMonthForFF, setDailyDataLastYearSameMonthForFF] = useState([]);
  // const [dealerBudgetLastYearSameMonthForFF, setDealerBudgetLastYearSameMonthForFF] = useState([]);
  // const [totalGrossProfitYTDForFF, setTotalGrossProfitYTDForFF] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getDailyData(year, month);
    getDealerBudgetData(year, month);
  }, []);

  useEffect(() => {
    getDailyDataForRec(year, month);
    getDealerBudgetDataForRec(year, month);
  }, []);

  useEffect(() => {
    getDailyDataForUsed(year, month);
    getDealerBudgetDataForUsed(year, month);
  }, []);

  // useEffect(() => {
  //   getDailyDataForFF(year, month);
  //   getDealerBudgetDataForFF(year, month);
  // }, []);

  const getDailyData = (year, month) => {
    const requestBody = {
      year: year,
      month: month,
      category: category,
      divisions: divisions,
    };
    axios
      .post(Links.getExecutiveReport, requestBody)
      .then((result) => {
        setDailyDataThisMonth(result.data.dailyDataThisMonth);
        setDailyDataLastYearSameMonth(result.data.dailyDataLastYearSameMonth);
        setTotalGrossProfitYTD(result.data.totalGrossProfitYTD);
      })
      .then(() => {
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getDailyDataForRec = (year, month) => {
    if (divisions.length > 0) {
      const requestBody = {
        year: year,
        month: month,
        divisions: divisions,
        category: category
      }
      axios.post(Links.getExecutiveReportForRec, requestBody)
        .then(res => {
          setDailyDataThisMonthForRec(res.data.dailyDataThisMonth);
          setDailyDataLastYearSameMonthForRec(res.data.dailyDataLastYearSameMonth);
          setTotalGrossProfitYTDForRec(res.data.totalGrossProfitYTD)
        })
        .catch(err => {
          console.log(err);
        });
    }
  };

  const getDailyDataForUsed = (year, month) => {
    if (divisions.length > 0) {
      const requestBody = {
        year: year,
        month: month,
        divisions: divisions,
        category: category
      }
      axios.post(Links.getExecutiveReportForUsed, requestBody)
        .then(res => {
          setDailyDataThisMonthForUsed(res.data.dailyDataThisMonth);
          setDailyDataLastYearSameMonthForUsed(res.data.dailyDataLastYearSameMonth);
          setTotalGrossProfitYTDForUsed(res.data.totalGrossProfitYTD);
        })
        .catch(err => {
          console.log(err);
        });
    }
  };

  const getDealerBudgetData = (year, month) => {
    const stringifiedYear = getFiscals(month, parseInt(year));
    const requestBody = {
      fiscal_year: "FY" + stringifiedYear.toString().slice(-2),
      month: month,
      divisions: divisions,
      category: category,
    };
    axios
      .post(Links.getFilteredBudgetForExecutiveReport, requestBody)
      .then((result) => {
        setBudgetThisMonth(result.data.budgetThisMonth);
        setDealerBudgetLastYearSameMonth(result.data.dealerBudgetLastYearSameMonth);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getDealerBudgetDataForRec = (year, month) => {
    const stringifiedYear = getFiscals(month, parseInt(year));
    const requestBody = {
      fiscal_year: "FY" + stringifiedYear.toString().slice(-2),
      month: month,
      divisions: divisions,
      category: category,
    };
    axios
      .post(Links.getFilteredBudgetForExecutiveReportForRec, requestBody)
      .then((result) => {
        setBudgetThisMonthForRec(result.data.budgetThisMonth);
        setDealerBudgetLastYearSameMonthForRec(result.data.dealerBudgetLastYearSameMonth);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getDealerBudgetDataForUsed = (year, month) => {
    const stringifiedYear = getFiscals(month, parseInt(year));
    const requestBody = {
      fiscal_year: "FY" + stringifiedYear.toString().slice(-2),
      month: month,
      divisions: divisions,
      category: category,
    };
    axios
      .post(Links.getFilteredBudgetForExecutiveReportForUsed, requestBody)
      .then((result) => {
        setBudgetThisMonthForUsed(result.data.budgetThisMonth);
        setDealerBudgetLastYearSameMonthForUsed(result.data.dealerBudgetLastYearSameMonth);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const getDailyDataForFF = (year, month) => {
  //   if (divisions.length > 0) {
  //     const requestBody = {
  //       year: year,
  //       month: month,
  //       category: category
  //     }
  //     axios.post(Links.getExecutiveReportForFF, requestBody)
  //       .then(res => {
  //         setDailyDataThisMonthForFF(res.data.dailyDataThisMonth);
  //         setDailyDataLastYearSameMonthForFF(res.data.dailyDataLastYearSameMonth);
  //         setTotalGrossProfitYTDForFF(res.data.totalGrossProfitYTD);
  //       })
  //       .catch(err => {
  //         console.log(err);
  //       });
  //   }
  // };

  // const getDealerBudgetDataForFF = (year, month) => {
  //   const stringifiedYear = getFiscals(month, parseInt(year));
  //   const requestBody = {
  //     fiscal_year: "FY" + stringifiedYear.toString().slice(-2),
  //     month: month,
  //     category: category,
  //   };
  //   axios
  //     .post(Links.getFilteredBudgetForExecutiveReportForFF, requestBody)
  //     .then((result) => {
  //       setBudgetThisMonthForFF(result.data.budgetThisMonth);
  //       setDealerBudgetLastYearSameMonthForFF(result.data.dealerBudgetLastYearSameMonth);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  const handleYearChange = (event) => {
    setYear(event.target.value);
  };

  const handleMonthChange = (event) => {
    setMonth(event.target.value);
  };

  const handleCateogryChange = (event) => {
    setCategory(event.target.value);
  };

  const showResults = () => {
    setLoading(true);
    getDailyData(year, month);
    getDealerBudgetData(year, month);
    getDailyDataForRec(year, month);
    getDealerBudgetDataForRec(year, month);
    getDailyDataForUsed(year, month);
    getDealerBudgetDataForUsed(year, month);
    // getDailyDataForFF(year, month);
    // getDealerBudgetDataForFF(year, month);
  };

  const changeToggle = (e) => {
    if (e.target.value === 'Numbers') {
      setShowNumbers(true);
      setShowCharts(false);
    } else {
      setShowNumbers(false);
      setShowCharts(true);
    }
  };

  const calculateTotalGP = (divisionGrossProfit) => {
    let totalGrossProfit = 0;
    if (divisionGrossProfit) {
      divisionGrossProfit.forEach((division) => {
        totalGrossProfit += division.total_gross_profit;
      });
    }
    return totalGrossProfit
  };

  const calculateAchieved = (dailyData, budget) => {
    let total = 0;
    if (dailyData.length > 0 && budget.length > 0) {
      total = ((calculateTotalGP(dailyData) / calculateTotalGP(budget)) * 100).toFixed();
    };
    return total;
  };


  const chartData = {
    labels: divisions,
    datasets: [
      {
        label: 'current year',
        backgroundColor: '#161853',
        borderColor: '#161853',
        borderWidth: 1,
        hoverBackgroundColor: '#160040',
        hoverBorderColor: '#160040',
        data: [
          calculateAchieved(dailyDataThisMonth, budgetThisMonth),
          calculateAchieved(dailyDataThisMonthForRec, budgetThisMonthForRec),
          calculateAchieved(dailyDataThisMonthForUsed, budgetThisMonthForUsed),
          // calculateAchieved(dailyDataThisMonthForFF, budgetThisMonthForFF),
        ]
      },
      {
        label: 'last year',
        backgroundColor: '#EC255A',
        borderColor: '#EC255A',
        borderWidth: 2,
        hoverBackgroundColor: '#EC255A',
        hoverBorderColor: '#EC255A',
        data: [
          calculateAchieved(dailyDataLastYearSameMonth, dealerBudgetLastYearSameMonth),
          calculateAchieved(dailyDataLastYearSameMonthForRec, dealerBudgetLastYearSameMonthForRec),
          calculateAchieved(dailyDataLastYearSameMonthForUsed, dealerBudgetLastYearSameMonthForUsed),
          // calculateAchieved(dailyDataLastYearSameMonthForFF, dealerBudgetLastYearSameMonthForFF),
        ]
      }
    ],
    scales: {
      x: {
        title: {
          display: true,
          text: 'Number'
        }
      }
    }
  };

  const chartDataGP = {
    labels: divisions,
    datasets: [
      {
        label: 'current year',
        backgroundColor: '#161853',
        borderColor: '#161853',
        borderWidth: 1,
        hoverBackgroundColor: '#160040',
        hoverBorderColor: '#160040',
        data: [
          dailyDataThisMonth[0] ? calculateTotalGP(dailyDataThisMonth) : 0,
          dailyDataThisMonthForRec[0] ? calculateTotalGP(dailyDataThisMonthForRec) : 0,
          dailyDataThisMonthForUsed[0] ? calculateTotalGP(dailyDataThisMonthForUsed) : 0,
          // dailyDataThisMonthForFF[0] ? calculateTotalGP(dailyDataThisMonthForFF) : 0
        ]
      },
      {
        label: 'last year',
        backgroundColor: '#EC255A',
        borderColor: '#EC255A',
        borderWidth: 2,
        hoverBackgroundColor: '#EC255A',
        hoverBorderColor: '#EC255A',
        data: [
          dailyDataLastYearSameMonth[0] ? calculateTotalGP(dailyDataLastYearSameMonth) : 0,
          dailyDataLastYearSameMonthForRec[0] ? calculateTotalGP(dailyDataLastYearSameMonthForRec) : 0,
          dailyDataLastYearSameMonthForUsed[0] ? calculateTotalGP(dailyDataLastYearSameMonthForUsed) : 0,
          // dailyDataLastYearSameMonthForFF[0] ? calculateTotalGP(dailyDataLastYearSameMonthForFF) : 0
        ]
      }
    ],
    scales: {
      x: {
        title: {
          display: true,
          text: 'Number'
        }
      }
    }
  };

  const percentageChartOptions = {
    plugins: {
      datalabels: {
        formatter: (v) => {
          if (Number.isNaN(v)) {
            return 0 + "%"
          } else {
            return v + "%"
          }
        },
        align: 'end',
        anchor: 'end'
      }
    },
    scales: {
      y: {
        suggestedMax: 100,
        ticks: {
          callback: function (label) {
            return label + "%"
          },
        },
        stepSize: 10,
        beginAtZero: true,
        max: 100,
        min: 0,
      }
    },
  };

  const percentageChartOptionsGP = {
    plugins: {
      datalabels: {
        formatter: (v) => {
          return "$" + v.toLocaleString()
        },
        align: 'end',
        anchor: 'end'
      }
    },
    scales: {
      y: {
        suggestedMax: 100,
        ticks: {
          callback: function (label) {
            return "$" + label.toLocaleString()
          },
        },
      }
    },
  };

  const pieDataGP = {
    labels: divisions,
    datasets: [
      {
        label: 'Gross Profit',
        backgroundColor: Object.values(PIE_COLORS),
        borderColor: Object.values(PIE_COLORS),
        borderWidth: 2,
        borderColor: 'white',
        hoverBackgroundColor: Object.values(PIE_COLORS),
        hoverBorderColor: Object.values(PIE_COLORS),
        data: [
          dailyDataThisMonth[0] ? calculateTotalGP(dailyDataThisMonth) : 0,
          dailyDataThisMonthForRec[0] ? calculateTotalGP(dailyDataThisMonthForRec) : 0,
          dailyDataThisMonthForUsed[0] ? calculateTotalGP(dailyDataThisMonthForUsed) : 0,
          // dailyDataThisMonthForFF[0] ? calculateTotalGP(dailyDataThisMonthForFF) : 0,
        ]
      },
    ],
  };

  const pieDataGPYTD = {
    labels: divisions,
    datasets: [
      {
        label: 'Gross Profit',
        backgroundColor: Object.values(PIE_COLORS),
        borderColor: Object.values(PIE_COLORS),
        borderWidth: 2,
        borderColor: 'white',
        hoverBackgroundColor: Object.values(PIE_COLORS),
        hoverBorderColor: Object.values(PIE_COLORS),
        data: [
          dailyDataThisMonth[0] ? calculateTotalGP(totalGrossProfitYTD) : 0,
          totalGrossProfitYTDForRec[0] ? calculateTotalGP(totalGrossProfitYTDForRec) : 0,
          totalGrossProfitYTDForUsed[0] ? calculateTotalGP(totalGrossProfitYTDForUsed) : 0,
          // totalGrossProfitYTDForFF[0] ? calculateTotalGP(totalGrossProfitYTDForFF) : 0,
        ]
      },
    ],
  };

  const mtdPieChartOptions = {
    elements: {
      arc: {
        borderWidth: 2
      }
    },
    showTooltips: true,
    interaction: {
      intersect: true
    },
    hover: {
      mode: false,
    },
    plugins: {
      datalabels: {
        color: 'white',
        formatter: (v) => {
          return v.toLocaleString('en-CA', {
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
            style: 'currency',
            currency: 'CAD',
          })
        }
      },
    },
  };

  const ytdPieChartOptions = {
    elements: {
      arc: {
        borderWidth: 2
      }
    },
    plugins: {
      datalabels: {
        color: 'white',
        formatter: (v) => {
          return v.toLocaleString('en-CA', {
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
            style: 'currency',
            currency: 'CAD',
          })
        }
      }
    },
    showAllTooltips: true
  };

  const passRightDivisonData = (division, type = 'dailydata') => {
    switch (division) {
      case 'Auto':
        if (type === 'dailydata' && dailyDataThisMonth) {
          return dailyDataThisMonth;
        } else if (type === 'budget' && budgetThisMonth) {
          return budgetThisMonth;
        }
        break;
      case 'Used':
        if (type === 'dailydata' && dailyDataThisMonthForUsed) {
          return dailyDataThisMonthForUsed;
        } else if (type === 'budget' && budgetThisMonthForUsed) {
          return budgetThisMonthForUsed;
        }
        break;
      case 'Rec':
        if (type === 'dailydata' && dailyDataThisMonthForRec) {
          return dailyDataThisMonthForRec;
        } else if (type === 'budget' && budgetThisMonthForRec) {
          return budgetThisMonthForRec;
        }
        break;
      // case 'FFUN Financial':
      //   if (type === 'dailydata' && dailyDataThisMonthForFF) {
      //     return dailyDataThisMonthForFF;
      //   } else if (type === 'budget' && budgetThisMonthForFF) {
      //     return budgetThisMonthForFF;
      //   }
      //   break;
      default:
        if (type === 'dailydata' && dailyDataThisMonth) {
          return dailyDataThisMonth;
        } else if (type === 'budget' && budgetThisMonth) {
          return budgetThisMonth;
        }
    }
  };

  return (
    <div style={{  marginTop: "3rem", width: "100%" }}>
      <Grid container spacing={2}>
        <Grid item container spacing={1}>
          <Grid item  xs={10} sm={6} md={2} xl={1}  spacing={1}>
            <FormControl variant="standard" sx={{ m: 1, width: "100%" }}>
              <InputLabel id="demo-simple-select-label">Year</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={year}
                label="Year"
                onChange={handleYearChange}
              >
                {YEARS.map((year, index) => (
                  <MenuItem key={index} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item  xs={10} sm={6} md={2} xl={1}  spacing={1}>
            <FormControl variant="standard" sx={{ m: 1, width: "100%" }}>
              <InputLabel id="demo-simple-select-label">Month</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={month}
                label="Age"
                onChange={handleMonthChange}
              >
                {Object.values(MONTHS).map((month, index) => (
                  <MenuItem key={index} value={month}>
                    {month}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item  xs={10} sm={6} md={2} xl={1}  spacing={1}>
            <FormControl variant="standard" sx={{ m: 1 }} className="w-75">
              <InputLabel id="demo-simple-select-label">Category</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={category}
                label="Category"
                onChange={handleCateogryChange}
              >
                {Object.values(EXECUTIVE_REPORTS_CATEGORIES).map(
                  (month, index) => (
                    <MenuItem key={index} value={month}>
                      {month}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
          </Grid>
          <Grid item  className="mt-3">
            <button
              className="p-2 mt-1 btn btn-secondary mx-3"
              type="submit"
              onClick={() => { showResults() }}
            >
              Show Details
            </button>
          </Grid>
          <Grid item  className="mt-2">
            <button className={`p-2 mt-2 btn toggle ${showNumbers ? 'active' : ''}`} onClick={(e) => changeToggle(e)} value="Numbers">Numbers</button>
            <button className={`p-2 mt-2 btn toggle ${showCharts ? 'active' : ''}`} onClick={(e) => changeToggle(e)} value="Charts">Charts</button>
          </Grid>
          <Grid item xs={1}></Grid>
        </Grid>
        <Grid item container>
          <Grid item >
            {loading && (
              <>
                <div
                  style={{
                    
                    marginTop: "3rem",
                    right: "50%",
                    top: "30%",
                  }}
                  className="text-center position-absolute"
                >
                  <img src={CarLoading} />
                </div>
              </>
            )}
            {!loading && showNumbers && (
              <>
                <Grid
                  item
                
                  style={{
                    borderRadius: 4,
                    padding: 20,
                    marginTop: 50,
                    border: "0px solid #adadad",
                    boxShadow:
                      "0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%)",
                  }}
                >
                  <SummaryReportsTableAllDivisions
                    divisions={divisions}
                    dailyDataThisMonth={dailyDataThisMonth}
                    dailyDataThisMonthForRec={dailyDataThisMonthForRec}
                    dailyDataThisMonthForUsed={dailyDataThisMonthForUsed}
                    // dailyDataThisMonthForFF={dailyDataThisMonthForFF}
                    budgetThisMonth={budgetThisMonth}
                    budgetThisMonthForRec={budgetThisMonthForRec}
                    budgetThisMonthForUsed={budgetThisMonthForUsed}
                    // budgetThisMonthForFF={budgetThisMonthForFF}
                    type="gross"
                  />
                </Grid>
                {divisions &&
                  divisions.map((division) => {
                    return (
                      <>
                        <Grid
                          item
                          xs={12}
                          style={{
                            borderRadius: 4,
                            padding: 20,
                            marginTop: 50,
                            border: "0px solid #adadad",
                            boxShadow:
                              "0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%)",
                          }}
                        >
                          <SummaryReportsTable
                            key={division}
                            division={division}
                            budgetThisMonth={passRightDivisonData(division, 'budget')}
                            dailyDataThisMonth={passRightDivisonData(division, 'dailydata')}
                            type="gross"
                          />
                        </Grid>
                      </>
                    );
                  })}
              </>
            )}
          </Grid>
        </Grid>
        {!loading && showCharts && (
          <>
            <Grid item xs={4} style={{ marginTop: '50px' }}>
              <BarChart title={'% Achieved'} data={chartData} options={percentageChartOptions} />
            </Grid>
            <Grid item xs={1}>
            </Grid>
            <Grid item xs={4} style={{ marginTop: '50px' }}>
              <BarChart title={'Total Gross Profit'} data={chartDataGP} options={percentageChartOptionsGP} />
            </Grid>
            <Grid item xs={4} style={{ marginTop: '50px' }}>
              <PieChart title={'Month To Date: Total Gross Profit'} data={pieDataGP} options={mtdPieChartOptions} />
            </Grid>
            <Grid item xs={1}>
            </Grid>
            <Grid item xs={4} style={{ marginTop: '50px' }}>
              <PieChart title={'Year To Date: Total Gross Profit'} data={pieDataGPYTD} options={ytdPieChartOptions} />
            </Grid>
          </>
        )}
      </Grid>
    </div>
  );
}
