import React, { useEffect, useState } from "react";
import axios from 'axios';
import './style.css';
import Links from "../../Data/Links";
import { store, updateBudgetRow, clearBudgetRow } from "../../redux";

import Cookies from 'js-cookie';
import { useSelector } from "react-redux";
const CreateRow = ({ row, month, filterData, loadData }) => {
    const budgetEntry = useSelector(state => state.dataEntryBudget.budgetEntry);
    const [edit, setEdit] = useState({});
    const onChangeValue = (event, field) => {
        store.dispatch(updateBudgetRow({ [field]: event.target.value ? event.target.value : 0 }));
    }
    let userData;
    if (Cookies.get('user_data')) {
        userData = JSON.parse(Cookies.get('user_data'));
    }
    const getTotalGross = (rowData = null) => {

        if (rowData != null) {
            if (filterData.store_name === 'FMS-Sask' || filterData.store_name === 'FMS-Yorkton' || filterData.store_name === 'FFUN Motorsports Winnipeg East' || filterData.store_name === 'FFUN Motorsports Winnipeg West'|| filterData.store_name === 'FFUN Motorsports Portage' || filterData.store_name === 'FFUN Motorsports Kenora') {
                let new_retail_front_GP = rowData.new_retail_front_GP ? parseInt(rowData.new_retail_front_GP) : 0;
                let new_retail_back_GP = rowData.new_retail_back_GP ? parseInt(rowData.new_retail_back_GP) : 0;
                let used_retail_front_GP = rowData.used_retail_front_GP ? parseInt(rowData.used_retail_front_GP) : 0;
                let used_retail_back_GP = rowData.used_retail_back_GP ? parseInt(rowData.used_retail_back_GP) : 0;
                let service_total_GP = rowData.service_total_GP ? parseInt(rowData.service_total_GP) : 0;
                let parts_total_GP = rowData.parts_total_GP ? parseInt(rowData.parts_total_GP) : 0;
                let boats_back_GP = rowData.boats_back_GP ? parseInt(rowData.boats_back_GP) : 0;
                let boats_front_GP = rowData.boats_front_GP ? parseInt(rowData.boats_front_GP) : 0;
                let trailers_back_GP = rowData.trailers_back_GP ? parseInt(rowData.trailers_back_GP) : 0;
                let trailers_front_GP = rowData.trailers_front_GP ? parseInt(rowData.trailers_front_GP) : 0;
                let sum = new_retail_front_GP + new_retail_back_GP + used_retail_front_GP + used_retail_back_GP + service_total_GP + parts_total_GP
                    + boats_back_GP + boats_front_GP + trailers_back_GP + trailers_front_GP;
                return "$" + sum.toLocaleString();
            } else {
                let new_retail_front_GP = rowData.new_retail_front_GP ? parseInt(rowData.new_retail_front_GP) : 0;
                let new_retail_back_GP = rowData.new_retail_back_GP ? parseInt(rowData.new_retail_back_GP) : 0;
                let used_retail_front_GP = rowData.used_retail_front_GP ? parseInt(rowData.used_retail_front_GP) : 0;
                let used_retail_back_GP = rowData.used_retail_back_GP ? parseInt(rowData.used_retail_back_GP) : 0;
                let service_total_GP = rowData.service_total_GP ? parseInt(rowData.service_total_GP) : 0;
                let parts_total_GP = rowData.parts_total_GP ? parseInt(rowData.parts_total_GP) : 0;
                let sum = new_retail_front_GP + new_retail_back_GP + used_retail_front_GP + used_retail_back_GP + service_total_GP + parts_total_GP;
                return "$" + sum.toLocaleString();

            }
        } else {
            return "$0";
        }
    }

    const getServiceCPhrsDivideRO = (rowData = null) => {
        if (rowData != null) {
            let service_CP_hours = rowData.service_CP_hours ? parseFloat(rowData.service_CP_hours) : 0;
            let service_CP_ROs = rowData.service_CP_ROs ? parseFloat(rowData.service_CP_ROs) : 0;
            let serviceCPhrsDivideRO = (service_CP_hours / service_CP_ROs).toFixed(2)
            if (serviceCPhrsDivideRO === "NaN" || serviceCPhrsDivideRO === 'Infinity') {
                return '0'
            } else {
                return serviceCPhrsDivideRO
            }
        } else {
            return "0";
        }
    }
    const renderBoatsAndTrailersValue = () => {
        if (filterData.store_name === 'FMS-Sask' || filterData.store_name === 'FMS-Yorkton' || filterData.store_name === 'FFUN Motorsports Winnipeg East' || filterData.store_name === 'FFUN Motorsports Winnipeg West'|| filterData.store_name === 'FFUN Motorsports Portage' || filterData.store_name === 'FFUN Motorsports Kenora') {
            return (<>
                {edit[month] ?
                    <td className="dn-column">
                        <input onChange={(e) => onChangeValue(e, "boats_delivered")} type="text" className="dn-input" value={budgetEntry.boats_delivered}></input>
                    </td>
                    : <td className="dn-column">{row?.[month]?.boats_delivered ? parseInt(row?.[month]?.boats_delivered).toLocaleString() : 0}</td>}

                {edit[month] ?
                    <td className="dn-column">
                        <input onChange={(e) => onChangeValue(e, "boats_front_GP")} type="text" className="dn-input" value={budgetEntry.boats_front_GP}></input>
                    </td>
                    : <td className="dn-column">{row?.[month]?.boats_front_GP ? '$' + parseInt(row?.[month]?.boats_front_GP).toLocaleString() : '$' + 0}</td>}
                {edit[month] ?
                    <td className="dn-column">
                        <input onChange={(e) => onChangeValue(e, "boats_back_GP")} type="text" className="dn-input" value={budgetEntry.boats_back_GP}></input>
                    </td>
                    : <td className="dn-column">{row?.[month]?.boats_back_GP ? '$' + parseInt(row?.[month]?.boats_back_GP).toLocaleString() : '$' + 0}</td>}
                {edit[month] ?
                    <td className="dn-column">
                        <input onChange={(e) => onChangeValue(e, "trailers_delivered")} type="text" className="dn-input" value={budgetEntry.trailers_delivered}></input>
                    </td>
                    : <td className="dn-column">{row?.[month]?.trailers_delivered ? row?.[month]?.trailers_delivered : 0}</td>}
                {edit[month] ?
                    <td className="dn-column">
                        <input onChange={(e) => onChangeValue(e, "trailers_front_GP")} type="text" className="dn-input" value={budgetEntry.trailers_front_GP}></input>
                    </td>
                    : <td className="dn-column">{row?.[month]?.trailers_front_GP ? '$' + parseInt(row?.[month]?.trailers_front_GP).toLocaleString() : '$' + 0}</td>}
                {edit[month] ?
                    <td className="dn-column">
                        <input onChange={(e) => onChangeValue(e, "trailers_back_GP")} type="text" className="dn-input" value={budgetEntry.trailers_back_GP}></input>
                    </td>
                    : <td className="dn-column">{row?.[month]?.trailers_back_GP ? '$' + parseInt(row?.[month]?.trailers_back_GP).toLocaleString() : '$' + 0}</td>}
            </>)
        } else {
            return <></>
        }
    }
    let totalLeads = parseInt(row?.[month]?.phone_up || 0) + parseInt(row?.[month]?.fresh_up || 0) + parseInt(row?.[month]?.internet_lead || 0);

    return (
        <tr>
            {userData.roles !== "normal" && userData.roles !== "Radmin" && <>   {
                edit[month] ?

                    <td><button className="button"
                        onClick={() => {
                         
                            if (budgetEntry.id) {
                                axios.patch(Links.updateBudgetById + '/' + budgetEntry.id, { row: budgetEntry })
                                    .then(res => {
                                        alert("Budget has been updated.");
                                        setEdit({ [month]: false });
                                        store.dispatch(clearBudgetRow());
                                        loadData(filterData);
                                    }).catch(err => {
                                        console.log(err);
                                    });
                            } else {
                                axios.post(Links.addBudgetData, budgetEntry)
                                    .then(res => {
                                        alert("Budget has been added.");
                                        setEdit({ [month]: false });
                                        store.dispatch(clearBudgetRow());
                                        loadData(filterData);
                                    }).catch(err => {
                                        console.log(err);
                                    });
                            }

                        }}
                    >Save</button></td>
                    :
                    <td><button className="button"
                        onClick={() => {
                            setEdit({ [month]: true });
                            store.dispatch(updateBudgetRow({ ...filterData, ...row[month], 'month': month }));
                        }}
                    >
                        {row?.[month] ? 'Edit' : 'Add'}
                    </button></td>
            }
                <td><button className="button" onClick={() => {
                    if (window.confirm("Are you sure want to delete?")) {
                        axios.post(Links.deleteBudget, { id: row?.[month]?.id })
                            .then(result => {
                                alert("Deleted succussfully!");
                                loadData(filterData);
                            }).catch(err => {
                                console.log(err);
                            });
                    }
                }}>Delete</button></td>
            </>}
            <td className="auto-column">{month}</td>
            {edit[month] ?
                <td className="auto-column">
                    <input onChange={(e) => onChangeValue(e, "new_retail_delivered")} type="text" className="auto-input" value={budgetEntry.new_retail_delivered}></input>
                </td>
                : <td className="auto-column">{row?.[month]?.new_retail_delivered>0 ? row?.[month]?.new_retail_delivered : 0}</td>}

            {edit[month] ?
                <td className="auto-column">
                    <input onChange={(e) => onChangeValue(e, "new_retail_front_GP")} type="text" className="auto-input" value={budgetEntry.new_retail_front_GP}></input>
                </td>
                : <td className="auto-column">{row?.[month]?.new_retail_front_GP>0 ? '$' + parseInt(row?.[month]?.new_retail_front_GP).toLocaleString() : '$' + 0}</td>}

            {edit[month] ?
                <td className="auto-column">
                    <input onChange={(e) => onChangeValue(e, "new_retail_back_GP")} type="text" className="auto-input" value={budgetEntry.new_retail_back_GP}></input>
                </td>
                : <td className="auto-column">{row?.[month]?.new_retail_back_GP>0 ? '$' + parseInt(row?.[month]?.new_retail_back_GP).toLocaleString() : '$' + 0}</td>}
            {edit[month] ?
                <td className="auto-column">
                    <input onChange={(e) => onChangeValue(e, "new_CSI")} type="text" className="auto-input" value={budgetEntry.new_CSI}></input>
                </td>
                : <td className="auto-column">{row?.[month]?.new_CSI>0 ? + parseInt(row?.[month]?.new_CSI).toLocaleString() : + 0}</td>}

            {edit[month] ?
                <td className="auto-column">
                    <input onChange={(e) => onChangeValue(e, "used_retail_delivered")} type="text" className="auto-input" value={budgetEntry.used_retail_delivered}></input>
                </td>
                : <td className="auto-column">{row?.[month]?.used_retail_delivered>0 ? row?.[month]?.used_retail_delivered : 0}</td>}
            {edit[month] ?
                <td className="auto-column">
                    <input onChange={(e) => onChangeValue(e, "used_retail_front_GP")} type="text" className="auto-input" value={budgetEntry.used_retail_front_GP}></input>
                </td>
                : <td className="auto-column">{row?.[month]?.used_retail_front_GP>0 ? '$' + parseInt(row?.[month]?.used_retail_front_GP).toLocaleString() : '$' + 0}</td>}
            {edit[month] ?
                <td className="auto-column">
                    <input onChange={(e) => onChangeValue(e, "used_retail_back_GP")} type="text" className="auto-input" value={budgetEntry.used_retail_back_GP}></input>
                </td>
                : <td className="auto-column">{row?.[month]?.used_retail_back_GP>0 ? '$' + parseInt(row?.[month]?.used_retail_back_GP).toLocaleString() : '$' + 0}</td>}
            {renderBoatsAndTrailersValue()}
         
            {edit[month] ?
                <td className="auto-column">
                    <input onChange={(e) => onChangeValue(e, "service_sales")} type="text" className="auto-input" value={budgetEntry.service_sales}></input>
                </td>
                : <td className="auto-column">{row?.[month]?.service_sales>0 ? '$' + parseInt(row?.[month]?.service_sales).toLocaleString() : '$' + 0}</td>}


{edit[month] ?
                <td className="auto-column">
                    <input onChange={(e) => onChangeValue(e, "service_total_GP")} type="text" className="auto-input" value={budgetEntry.service_total_GP}></input>
                </td>
                : <td className="auto-column">{row?.[month]?.service_total_GP>0 ? '$' + parseInt(row?.[month]?.service_total_GP).toLocaleString() : '$' + 0}</td>}

{edit[month] ?
                <td className="auto-column">
                    <input onChange={(e) => onChangeValue(e, "service_total_hours")} type="text" className="auto-input" value={budgetEntry.service_total_hours}></input>
                </td>
                : <td className="auto-column">{row?.[month]?.service_total_hours>0 ? row?.[month]?.service_total_hours : 0}</td>}

            {edit[month] ?
                <td className="auto-column">
                    <input onChange={(e) => onChangeValue(e, "service_CSI")} type="text" className="auto-input" value={budgetEntry.service_CSI}></input>
                </td>
                : <td className="auto-column">{row?.[month]?.service_CSI>0 ? + parseInt(row?.[month]?.service_CSI).toLocaleString() : + 0}</td>}



            {edit[month] ?
                <td className="auto-column">
                    <input onChange={(e) => onChangeValue(e, "service_CP_hours")} type="text" className="auto-input" value={budgetEntry.service_CP_hours}></input>
                </td>
                : <td className="auto-column">{row?.[month]?.service_CP_hours>0 ? row?.[month]?.service_CP_hours : 0}</td>}

            {edit[month] ?
                <td className="auto-column">
                    <input onChange={(e) => onChangeValue(e, "service_CP_ROs")} type="text" className="auto-input" value={budgetEntry.service_CP_ROs}></input>
                </td>
                : <td className="auto-column">{row?.[month]?.service_CP_ROs>0 ? row?.[month]?.service_CP_ROs : 0}</td>}
            {edit[month] ?
                <td className="auto-column">
                    <input onChange={(e) => onChangeValue(e, "service_CP_hrs_RO")} type="text" className="auto-input" value={budgetEntry.service_CP_hrs_RO}></input>
                </td>
                : <td className="auto-column"> {getServiceCPhrsDivideRO(row?.[month])}</td>}
   
   {/* {edit[month] ?
                <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} className="auto-column">
                    <input onChange={(e) => onChangeValue(e, "service_hours_available")} type="text" className="auto-input" value={budgetEntry.service_hours_available}></input>
                </td>
                : <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} className="auto-column">{row?.[month]?.service_hours_available>0 ? parseInt(row?.[month]?.service_hours_available).toLocaleString() : 0}</td>}
            {edit[month] ?
                <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} className="auto-column">
                    <input onChange={(e) => onChangeValue(e, "service_hours_sold")} type="text" className="auto-input" value={budgetEntry.service_hours_sold}></input>
                </td>
                : <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} className="auto-column">{row?.[month]?.service_hours_sold>0 ? parseInt(row?.[month]?.service_hours_sold).toLocaleString() : 0}</td>}
           
            {edit[month] ?
                <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} className="auto-column">
                    <input onChange={(e) => onChangeValue(e, "service_no_of_tech")} type="text" className="auto-input" value={budgetEntry.service_no_of_tech}></input>
                </td>
                : <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} className="auto-column">{row?.[month]?.service_no_of_tech>0 ? parseInt(row?.[month]?.service_no_of_tech).toLocaleString() : 0}</td>}
            {edit[month] ?
                <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} className="auto-column">
                    <input onChange={(e) => onChangeValue(e, "service_journeyman")} type="text" className="auto-input" value={budgetEntry.service_journeyman}></input>
                </td>
                : <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} className="auto-column">{row?.[month]?.service_journeyman>0 ? parseInt(row?.[month]?.service_journeyman).toLocaleString() : 0}</td>}
            {edit[month] ?
                <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} className="auto-column">
                    <input onChange={(e) => onChangeValue(e, "service_apprentice")} type="text" className="auto-input" value={budgetEntry.service_apprentice}></input>
                </td>
                : <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} className="auto-column">{row?.[month]?.service_apprentice>0 ? parseInt(row?.[month]?.service_apprentice).toLocaleString() : 0}</td>}


{edit[month] ?
                <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} className="auto-column">
                    <input onChange={(e) => onChangeValue(e, "service_advisors_needed")} type="text" className="auto-input" value={budgetEntry.service_advisors_needed}></input>
                </td>
                : <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} className="auto-column">{row?.[month]?.service_advisors_needed>0 ? parseInt(row?.[month]?.service_advisors_needed).toLocaleString() : 0}</td>}
 */}


            {edit[month] ?
                <td className="auto-column">
                    <input onChange={(e) => onChangeValue(e, "parts_sales")} type="text" className="auto-input" value={budgetEntry.parts_sales}></input>
                </td>
                : <td className="auto-column">{row?.[month]?.parts_sales>0 ? '$' + parseInt(row?.[month]?.parts_sales).toLocaleString() : 0}</td>}

            {edit[month] ?
                <td className="auto-column">
                    <input onChange={(e) => onChangeValue(e, "parts_total_GP")} type="text" className="auto-input" value={budgetEntry.parts_total_GP}></input>
                </td>
                : <td className="auto-column">{row?.[month]?.parts_total_GP>0 ? '$' + parseInt(row?.[month]?.parts_total_GP).toLocaleString() : '$' + 0}</td>}

            {(edit[month] && userData.roles !== "normal") ?
                <td className="auto-column">
                    <input onChange={(e) => onChangeValue(e, "appt_made")} type="text" className="auto-input" value={budgetEntry.appt_made}></input>
                </td>
                : <td className="auto-column">{row?.[month]?.appt_made>0 ? parseInt(row?.[month]?.appt_made).toLocaleString() : 0}</td>}



            {(edit[month] && userData.roles !== "normal") ?
                <td className="auto-column">
                    <input onChange={(e) => onChangeValue(e, "booked")} type="text" className="auto-input" value={budgetEntry.booked}></input>
                </td>
                : <td className="auto-column">{row?.[month]?.booked>0 ? parseInt(row?.[month]?.booked).toLocaleString() : 0}</td>}

            {(edit[month] && userData.roles !== "normal") ?
                <td className="auto-column">
                    <input onChange={(e) => onChangeValue(e, "appt_show")} type="text" className="auto-input" value={budgetEntry.appt_show}></input>
                </td>
                : <td className="auto-column">{row?.[month]?.appt_show>0 ? parseInt(row?.[month]?.appt_show).toLocaleString() : 0}</td>}

            {(edit[month] && userData.roles !== "normal") ?
                <td className="auto-column">
                    <input onChange={(e) => onChangeValue(e, "store_visit")} type="text" className="auto-input" value={budgetEntry.store_visit}></input>
                </td>
                : <td className="auto-column">{row?.[month]?.store_visit>0 ? parseInt(row?.[month]?.store_visit).toLocaleString() : 0}</td>}




            {(edit[month] && userData.roles !== "normal") ?
                <td className="auto-column">
                    <input onChange={(e) => onChangeValue(e, "internet_lead")} type="text" className="auto-input" value={budgetEntry.internet_lead}></input>
                </td>
                : <td className="auto-column">{row?.[month]?.internet_lead>0 ? parseInt(row?.[month]?.internet_lead).toLocaleString() : 0}</td>}


            {(edit[month] && userData.roles !== "normal") ?
                <td className="auto-column">
                    <input onChange={(e) => onChangeValue(e, "fresh_up")} type="text" className="auto-input" value={budgetEntry.fresh_up}></input>
                </td>
                : <td className="auto-column">{row?.[month]?.fresh_up>0 ? parseInt(row?.[month]?.fresh_up).toLocaleString() : 0}</td>}
            {(edit[month] && userData.roles !== "normal") ?
                <td className="auto-column">
                    <input onChange={(e) => onChangeValue(e, "phone_up")} type="text" className="auto-input" value={budgetEntry.phone_up}></input>
                </td>
                : <td className="auto-column">{row?.[month]?.phone_up>0 ? parseInt(row?.[month]?.phone_up).toLocaleString() : 0}</td>}



            {/* {(edit[month] && userData.roles !== "normal") ?
                <td className="auto-column">
                    <input onChange={(e) => onChangeValue(e, "lead")} type="text" className="auto-input" value={budgetEntry.lead}></input>
                </td>
                : <td className="auto-column">{row?.[month]?.lead>0 ? parseInt(row?.[month]?.lead).toLocaleString() : 0}</td>} */}
 <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} className="auto-column">{parseInt(totalLeads).toLocaleString()}</td>

            {(edit[month] && userData.roles !== "normal") ?
                <td className="auto-column">
                    <input onChange={(e) => onChangeValue(e, "sales_staff")} type="text" className="auto-input" value={budgetEntry.sales_staff}></input>
                </td>
                : <td className="auto-column">{row?.[month]?.sales_staff>0 ? parseInt(row?.[month]?.sales_staff).toLocaleString() : 0}</td>}

            {/* {(edit[month] && userData.roles !== "normal") ?
                <td className="auto-column">
                    <input onChange={(e) => onChangeValue(e, "no_show")} type="text" className="auto-input" value={budgetEntry.no_show}></input>
                </td>
                : <td className="auto-column">{row?.[month]?.no_show ? parseInt(row?.[month]?.no_show).toLocaleString() : 0}</td>} */}

            <td className="auto-column">
                {getTotalGross(row?.[month])}
            </td>
        </tr>
    )
}

export default CreateRow;