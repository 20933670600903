import React from "react";
import UserEdit from "../../component/Users/UserEdit";

const NewUser = () => {
    
    const user = {
      email: "",
      role: "",
      stores: "",
      password:""
    }

    return (
      <>
        <UserEdit title='Add a User' user={user} />
      </>
    );
    
}

export default NewUser;