import React from "react";
import { calculatePercentageOfBudget, calculateTotalGP } from "../../utils";
import { DEFAULT_TABLE_TITLES } from "../../constants";

const SummaryDefaultTable = (props) => {
  const { dailyDataThisMonth, budgetThisMonth, division, storeName,reportType} = props;

  if(budgetThisMonth) {
    budgetThisMonth.total_service_hours = budgetThisMonth.total_service_total_hours ? budgetThisMonth.total_service_total_hours : 0;
  }
 
  return (
    <table
      className="table table-bordered border-black table-fit"
      style={{ marginTop: 20 }}
    >
      <thead>
        <tr className="text-center">
          <th
            colSpan="1"
            className="dn-header-top"
            style={{ backgroundColor: "orange" }}
          >
            Category
          </th>
          <th
            colSpan="1"
            className="dn-header-top"
            style={{ backgroundColor: "orange", textAlign: "left" }}
          >
            Gross
          </th>
          <th
            colSpan="1"
            className="dn-header-top"
            style={{ backgroundColor: "orange", fontSize: "1rem" }}
          >
            Total
          </th>
          <th
            colSpan="1"
            className="dn-header-top"
            style={{ backgroundColor: "orange", fontSize: "1rem" }}
          >
            Budget
          </th>
          <th
            colSpan="1"
            className="dn-header-top"
            style={{ backgroundColor: "orange", fontSize: "1rem" }}
          >
            % Achieved
          </th>
        </tr>
      </thead>
      <tbody>
        {division!=="DriveNation" && division!=="FFUN Cars"  && division !== 'Used' && renderVehiclesRow(
          dailyDataThisMonth,
          budgetThisMonth,
          DEFAULT_TABLE_TITLES.NEW_VEHICLES
        )}
        {renderVehiclesRow(
          dailyDataThisMonth,
          budgetThisMonth,
          DEFAULT_TABLE_TITLES.USED_VEHICLES
        )}
        {renderVehiclesRow(
          dailyDataThisMonth,
          budgetThisMonth,
          DEFAULT_TABLE_TITLES.SERVICES
        )}
        {division!=="DriveNation" && division!=="FFUN Cars" && division !== 'Used'  && renderVehiclesRow(
          dailyDataThisMonth,
          budgetThisMonth,
          DEFAULT_TABLE_TITLES.PARTS
        )}


        {division === "Rec" && (storeName==="FMS-Sask" ||  storeName==="FMS-Yorkton" || reportType==="divisional")  && renderVehiclesRow(
          dailyDataThisMonth,
          budgetThisMonth,
          DEFAULT_TABLE_TITLES.BOATS
        )}
        {division === "Rec" && (storeName==="FMS-Sask" ||  storeName==="FMS-Yorkton" || reportType==="divisional") && renderVehiclesRow(
          dailyDataThisMonth,
          budgetThisMonth,
          DEFAULT_TABLE_TITLES.TRAILERS
        )}

        {division === "Rec" && renderVehiclesRow(
          dailyDataThisMonth,
          budgetThisMonth,
          DEFAULT_TABLE_TITLES.APPAREL
        )}


        {renderVehiclesRow(
          dailyDataThisMonth,
          budgetThisMonth,
          DEFAULT_TABLE_TITLES.TOTAL
        )}





      </tbody>
    </table>
  );
};

const isValueArray = (data, type) => {
  let value;


  if (Array.isArray(type.DATA_VALUE_TWO)) {
    value = parseInt(data[`${type.DATA_VALUE_TWO[0]}`] || 0) + parseInt(data[`${type.DATA_VALUE_TWO[1]}`] || 0);
  } else {
    value = data[`${type.DATA_VALUE_TWO}`] || 0;
  }

  return parseFloat(value?.toFixed());
};

const renderVehiclesRow = (dailyDataThisMonth, budgetThisMonth, type) => {

  if (dailyDataThisMonth && budgetThisMonth) {
    return (
      <tr>
        <td
          style={{
            paddingTop: "10px",
            textAlign: "center",
            fontSize: "1.25rem",
            fontWeight: "bold",
            backgroundColor: type.LABEL === "New Vehicles" ? "Orange" : type.LABEL === "Used Vehicles" ? "#a2c1a2" : type.LABEL === "Services" ? "rgb(177, 213, 221)" : type.LABEL === "Parts" ? "rgb(139, 139, 181)" : type.LABEL === "Apparel" ? "rgb(116, 187, 154)" : type.LABEL === "Boats" ? "rgb(111, 78, 55)":type.LABEL === "Trailers" ? "rgb(184, 115, 51)":"",
          }}
        >
          {type.LABEL}
        </td>
        <td>
          <tr>
            <td style={{ fontWeight: "normal", fontSize: "1rem" ,minWidth:130}}>
           {type.TITLE_ONE}
            </td>
          </tr>
          <tr>
            <td style={{ fontWeight: "normal", fontSize: "1rem" }}>
             {type.TITLE_TWO}
            </td>
          </tr>
        </td>
        <td>
          {type.LABEL === "Total" ? (
            <>
              <table style={{ width: "100%" }}>
                <tr style={{ textAlign: "center", fontSize: "1rem"  }}>
                  ${calculateTotalGP(dailyDataThisMonth).toLocaleString()}
                </tr>
              </table>
            </>
          ) : (
            <>
              <table style={{ width: "100%", textAlign: "center" }}>
                <tr>
                  <td
                    style={{
                      fontWeight: "normal",
                      fontSize: "1rem",
                      textAlign: "center",
                    }}
                  >
                    {type.DATA_VALUE_ONE === 'total_new_delivered' || type.DATA_VALUE_ONE === 'total_used_delivered' || type.DATA_VALUE_ONE === 'total_boats_delivered' || type.DATA_VALUE_ONE === 'total_trailers_delivered' ? parseInt(dailyDataThisMonth[`${type.DATA_VALUE_ONE}`] || 0).toLocaleString() : '$' + parseInt(dailyDataThisMonth[`${type.DATA_VALUE_ONE}`] || 0).toLocaleString()}
                  </td>
                </tr>
                {type.LABEL !== "Services" && <tr>
                  <td style={{ fontWeight: "normal", fontSize: "1rem" }}>
                $
                    {/* {type.DATA_VALUE_ONE} */}
                    {isValueArray(dailyDataThisMonth, type) === "NaN" || isValueArray(dailyDataThisMonth, type) === null
                      ? "0"
                      : isValueArray(dailyDataThisMonth, type).toLocaleString()}
                  </td>
                </tr>}
              </table>
            </>
          )}
        </td>
        <td>
          {type.LABEL === "Total" ? (
            <>
              <table style={{ width: "100%" }}>
                <tr style={{ textAlign: "center", fontSize: "1rem"  }}>
                  ${calculateTotalGP(budgetThisMonth).toLocaleString()}
                </tr>
              </table>
            </>
          ) : (
            <>
          
              <table style={{ width: "100%" }}>
                <tr style={{ textAlign: "center" }}>
                  <td style={{ fontWeight: "normal", fontSize: "1rem" }}>

                    {type.LABEL === "Services"
                      ? '$' + parseInt(budgetThisMonth[`${type.BUDGET_VALUE_ONE}`] || 0).toLocaleString()
                      : type.DATA_VALUE_ONE === 'total_new_delivered' || type.DATA_VALUE_ONE === 'total_used_delivered'|| type.DATA_VALUE_ONE === 'total_boats_delivered' || type.DATA_VALUE_ONE === 'total_trailers_delivered'  ? parseInt(budgetThisMonth[`${type.DATA_VALUE_ONE}`] || 0).toLocaleString() : '$' + parseInt(budgetThisMonth[`${type.DATA_VALUE_ONE}`] || 0).toLocaleString()}
                  </td>
                </tr>
                {type.LABEL !== "Services" && <tr style={{ textAlign: "center" }}>
                  <td style={{ fontWeight: "normal", fontSize: "1rem" }}>
                    $
                    {isValueArray(budgetThisMonth, type) === "NaN" || isValueArray(budgetThisMonth, type).toLocaleString() === null
                      ? "0"
                      : isValueArray(budgetThisMonth, type).toLocaleString()}
                  </td>
                </tr>}
              </table>
            </>
          )}
        </td>
        <td style={{ padding: "0", textAlign: "center" }}>
          {type.LABEL === "Total" ? (
            <>
              <table style={{ width: "100%", marginTop: 10 }}>
                <tr style={{ textAlign: "center", position: "relative" }}>
                  <div
                    class="progress"
                    style={{
                      height: "100%",
                      width: "100%",
                      position: "absolute",
                      right: "0%",
                      top: "0%",
                      zIndex: "-1",
                      borderRadius: "0px",
                    }}
                  >
                    <div
                      class="progress-bar"
                      role="progressbar"
                      style={{
                        backgroundColor: "#7CEF7C ",
                        width:
                          dailyDataThisMonth && budgetThisMonth
                            ?  calculatePercentageOfBudget(
                              calculateTotalGP(dailyDataThisMonth),
                              calculateTotalGP(budgetThisMonth)
                            )
                            : "0",
                      }}
                      aria-valuenow="25"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                  
                  {calculatePercentageOfBudget(
                    calculateTotalGP(dailyDataThisMonth),
                    calculateTotalGP(budgetThisMonth)
                  )}
                </tr>
              </table>
            </>
          ) : (
            <>
              <table style={{ width: "100%", marginBottom: "6px" }}>
                <tr style={{ position: "relative" }}>
                  <td
                    style={{
                      fontWeight: "bold",
                      fontSize: "1rem",
                      position: "relative",
                      textAlign: "center",
                    }}
                  >
                    <div
                      class="progress"
                      style={{
                        height: "100%",
                        width: "100%",
                        position: "absolute",
                        right: "0%",
                        top: "0%",
                        zIndex: "-1",
                        borderRadius: "0px",
                      }}
                    >
                      <div
                        class="progress-bar"
                        role="progressbar"
                        style={{
                          backgroundColor: "#7CEF7C ",
                          width:
                            dailyDataThisMonth && budgetThisMonth
                              ? calculatePercentageOfBudget(
                                dailyDataThisMonth[`${type.DATA_VALUE_ONE}`],
                                type.LABEL === "Services"
                                  ? budgetThisMonth[
                                  `${type.BUDGET_VALUE_ONE}`
                                  ] || 0
                                  : budgetThisMonth[`${type.DATA_VALUE_ONE}`] || 0
                              )
                              : "0",
                        }}
                        aria-valuenow="25"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                    {calculatePercentageOfBudget(
                      dailyDataThisMonth[`${type.DATA_VALUE_ONE}`] || 0,
                      type.LABEL === "Services"
                        ? budgetThisMonth[`${type.BUDGET_VALUE_ONE}`] || 0
                        : budgetThisMonth[`${type.DATA_VALUE_ONE}`] || 0
                    )}
                  
                  </td>
                </tr>
              </table>
              {type.LABEL !== "Services" &&
              <table style={{ width: "100%", marginBottom: "6px" }}>
                <tr>
                  <td
                    style={{
                      fontWeight: "bold",
                      fontSize: "1rem",
                      position: "relative",
                      textAlign: "center",
                    }}
                  >
                    <div
                      class="progress"
                      style={{
                        height: "100%",
                        width: "100%",
                        position: "absolute",
                        right: "0%",
                        top: "0%",
                        zIndex: "-1",
                        borderRadius: "0px",
                      }}
                    >
                      <div
                        class="progress-bar"
                        role="progressbar"
                        style={{
                          backgroundColor: "#7CEF7C ",
                          width:
                            dailyDataThisMonth && budgetThisMonth
                              ? calculatePercentageOfBudget(
                                isValueArray(dailyDataThisMonth, type),
                                isValueArray(budgetThisMonth, type)
                              )
                              : "0",
                        }}
                        aria-valuenow="25"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                    {calculatePercentageOfBudget(
                      isValueArray(dailyDataThisMonth, type),
                      isValueArray(budgetThisMonth, type)
                    )}
                  </td>
                </tr>
              </table>}
            </>
          )}
        </td>
      </tr>
    );
  } else {
    return (
      <>
        <tr>
          <td></td>
        </tr>
      </>
    );
  }
};

export default SummaryDefaultTable;
