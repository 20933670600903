import React, { useEffect, useState } from "react";
import axios from 'axios';
import './style.css';
import Links from "../../Data/Links";
import { store, updateStaffRow, clearStaffRow } from "../../redux";

import Cookies from 'js-cookie';
import { useSelector } from "react-redux";
const CreateRowSales = ({ row, month, filterData, loadData }) => {
    const staffEntry = useSelector(state => state.dataEntryStaff);
    console.log(row)
    const [edit, setEdit] = useState({});
    const onChangeValue = (event, field) => {
        store.dispatch(updateStaffRow({ [field]: event.target.value ? event.target.value : 0 }));
    }
    let userData;
    if (Cookies.get('user_data')) {
        userData = JSON.parse(Cookies.get('user_data'));
    }



    return (
        <tr >
            {
                edit[month] ?

                    <td className="auto-column" style={{minWidth:50,maxWidth:50}} ><button className="button mx-auto"
                        onClick={() => {

                            if (staffEntry.id) {
                                axios.post(Links.updateStaffData,  staffEntry )
                                    .then(res => {
                                        alert("Staff data has been updated.");
                                        setEdit({ [month]: false });
                                        store.dispatch(clearStaffRow());
                                        loadData(filterData);
                                    }).catch(err => {
                                        console.log(err);
                                    });
                            } else {
                                axios.post(Links.addStaffData, staffEntry)
                                    .then(res => {
                                        alert("Staff data has been added.");
                                        setEdit({ [month]: false });
                                        store.dispatch(clearStaffRow());
                                        loadData(filterData);
                                    }).catch(err => {
                                        console.log(err);
                                    });
                            }

                        }}
                    >Save</button></td>
                    :
                    <td className="auto-column"><button className="button max-auto"
                        onClick={() => {
                            setEdit({ [month]: true });
                            store.dispatch(updateStaffRow({ ...filterData, ...row[month], 'month': month }));
                        }}
                    >
                        {row?.[month] ? 'Edit' : 'Add'}
                    </button></td>}
            
                <td className="auto-column"><button className="button max-auto " onClick={() => {
                    if (window.confirm("Are you sure want to delete?")) {
                        axios.post(Links.deleteStaff, { id: row?.[month]?.id })
                            .then(result => {
                                alert("Deleted succussfully!");
                                loadData(filterData);
                            }).catch(err => {
                                console.log(err);
                            });
                    }
                }}>Delete</button></td>
            
            <td className="auto-column">{month}</td>
            
            {edit[month] ?
                <td className="auto-column">
                    <input onChange={(e) => onChangeValue(e, "sales_people")} type="text" className="auto-input" defaultValue={row?.[month]?.sales_people || 0}></input>
                </td>
                : <td className="auto-column">{row?.[month]?.sales_people > 0 ? row?.[month]?.sales_people : 0}</td>}

            {edit[month] ?
                <td className="auto-column">
                    <input onChange={(e) => onChangeValue(e, "sales_people_needed")} type="text" className="auto-input" defaultValue={row?.[month]?.sales_people_needed || 0}></input>
                </td>
                : <td className="auto-column">{row?.[month]?.sales_people_needed > 0 ? + parseInt(row?.[month]?.sales_people_needed).toLocaleString() : + 0}</td>}




            {edit[month] ?
                <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} className="auto-column">
                    <input onChange={(e) => onChangeValue(e, "service_no_of_tech")} type="text" className="auto-input" defaultValue={row?.[month]?.service_no_of_tech || 0}></input>
                </td>
                : <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} className="auto-column">{row?.[month]?.service_no_of_tech > 0 ? parseInt(row?.[month]?.service_no_of_tech).toLocaleString() : 0}</td>}
            {edit[month] ?
                <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} className="auto-column">
                    <input onChange={(e) => onChangeValue(e, "service_journeyman_needed")} type="text" className="auto-input" defaultValue={row?.[month]?.service_journeyman_needed || 0}></input>
                </td>
                : <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} className="auto-column">{row?.[month]?.service_journeyman_needed > 0 ? parseInt(row?.[month]?.service_journeyman_needed).toLocaleString() : 0}</td>}
            {edit[month] ?
                <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} className="auto-column">
                    <input onChange={(e) => onChangeValue(e, "service_apprentice_needed")} type="text" className="auto-input" defaultValue={row?.[month]?.service_apprentice_needed || 0}></input>
                </td>
                : <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} className="auto-column">{row?.[month]?.service_apprentice_needed > 0 ? parseInt(row?.[month]?.service_apprentice_needed).toLocaleString() : 0}</td>}


            {edit[month] ?
                <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} className="auto-column">
                    <input onChange={(e) => onChangeValue(e, "service_advisors_needed")} type="text" className="auto-input" defaultValue={row?.[month]?.service_advisors_needed || 0}></input>
                </td>
                : <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} className="auto-column">{row?.[month]?.service_advisors_needed > 0 ? parseInt(row?.[month]?.service_advisors_needed).toLocaleString() : 0}</td>}



        </tr>
    )
}

export default CreateRowSales;