import { STATUTORY_IN_MONTH, MONTH_BY_NUMBER, DAYS_IN_MONTH } from "../../src/constants";

export default (month, year) => {
    let currentDay = DAYS_IN_MONTH[month];
    let stautoryHolidays = STATUTORY_IN_MONTH[month];
    let startDate = year + "-" + MONTH_BY_NUMBER[month] + "-" + "01";
    let endDate = year + "-" + MONTH_BY_NUMBER[month] + "-" + currentDay;
    let start = new Date(startDate);
    let finish = new Date(endDate);
    let dayMilliseconds = 1000 * 60 * 60 * 24;
    let weekendDays = 0;
    while (start <= finish) {
        var day = start.getDay()
        if (day == 0) {
            weekendDays++;
        }
        start = new Date(+start + dayMilliseconds);
    }

    if (stautoryHolidays !== "None") {
        stautoryHolidays = stautoryHolidays.split(",");
        for (let i = 0; i <= stautoryHolidays.length; i++) {
            if (currentDay <= stautoryHolidays[i]) {
                weekendDays++;
            }
        }
    }




    return weekendDays;
}


