import React, { useState,useEffect } from "react";
import { useLocation } from "react-router-dom";
import generator from "generate-password";
import { useHistory } from "react-router-dom";
import {
  Paper,
  Grid,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  FormGroup,
  Typography
} from "@material-ui/core";
import {
  AccessibilityNew,
  ApartmentOutlined,
  AccountCircle,
  
} from "@material-ui/icons";
import LockOpenIcon from '@mui/icons-material/LockOpen';
import Links from "../../Data/Links";
import axios from "axios";

const UserEdit = () => {
  const location = useLocation();
  const history = useHistory();
  let formFields = {
    id: "",
    userEmail: "",
    roles: "",
    password:"",
    active: "",
  };

  const user = location.state ? location.state.user : undefined;
  if (user) {
    formFields = {
      id: user.id,
      userEmail: user.userEmail,
      password:user.password,
      roles: user.roles,
      active: user.active,
    };
  }

  const errors = {
    userEmail: "",
    roles: "",
    password:""
  };

  const [checkAll, setCheckAll] = useState(false);
 

  // useEffect(() => {
  
  // }, []);

  const [stores, _] = useState(
    localStorage.getItem("stores")
      ? JSON.parse(localStorage.getItem("stores"))
      : ""
  );
  var defaultPassword = generator.generate({
    length: 10,
    numbers: true
  });
  formFields.password=defaultPassword;
  //sort the stores based on division
  stores.sort((a, b) => (a.division > b.division) ? 1: -1);
  const [saveInfo, setSaveInfo] = useState(formFields);
  const [error, setError] = useState(errors);

  const storesMap = {};
  const userStores = user ? user.stores : undefined;
  const userStoreArray = userStores ? userStores.split(",") : [];

  for (const [k, v] of Object.entries(stores)) {
    storesMap[v.id] =
      userStoreArray.indexOf(v.id.toString()) < 0 ? false : true;
  }

  const [storeCheckBoxes, setStoreCheckBoxes] = useState(storesMap);

  const onSelectHandler = (e) => {
    setStoreCheckBoxes((prevState) => ({
      ...prevState,
      [e.target.value]: e.target.checked,
    }));
  };

  const toggleAllCheckboxesHandler = (e) => {
    setCheckAll(e.target.checked);
    setAllCheckBoxes(e.target.checked);
  }

  const setAllCheckBoxes = (checked) => {
    setStoreCheckBoxes((prevState) => {
      const newValues = {};
      for (const [k, v] of Object.entries(prevState)) {
        newValues[k] = checked;
      }
      return newValues;
    });
  };

  let storesDisplayArray = [];
  const halfwayPoint = Math.ceil(stores.length / 2);
  const storesColumn1 = stores.slice(0, halfwayPoint);
  const storesColumn2 = stores.slice(halfwayPoint);

  for (let i = 0; i < halfwayPoint; i++) {
    if (i < storesColumn2.length) {
      storesDisplayArray[i] = [storesColumn1[i], storesColumn2[i]];
    } else {
      storesDisplayArray[i] = [storesColumn1[i]];
    }
  }

  let row = 0;
  const storesDisplay = storesDisplayArray.map((e) => {
    row += 1;
    return (
      <Grid
        key={"Row" + row}
        container
        spacing={4}
        alignItems="center"
        justifyContent="center"
      >
        {e.map((s) => {
          return (
            <Grid key={"item" + s.id} item md={true} sm={true} xs={true}>
              <FormControlLabel
                key={s.id}
                control={
                  <Checkbox
                    value={s.id}
                    checked={storeCheckBoxes[s.id]}
                    onChange={onSelectHandler}
                  />
                }
                label={s.division + " - " + s.name}
              />
            </Grid>
          );
        })}
      </Grid>
    );
  });

  const formControlChangeHandler = (e) => {
    const { name, value } = e.target;
    setSaveInfo((fields) => ({ ...fields, [name]: value }));

    let emailPattern = new RegExp("^[A-Za-z._0-9]+@[A-Za-z0-9]+.[A-Za-z]+$");
    if (name === "userEmail") {
      if (emailPattern.test(value) === false) {
        setError((error) => ({
          ...error,
          [name]: "Please enter a valid email!",
        }));
      } else {
        setError((error) => ({ ...error, [name]: "" }));
      }
    } else if (name === "roles") {
      if (value === "Select Role *") {
        setError((error) => ({
          ...error,
          [name]: "Please select a valid role",
        }));
      } else {
        setError((error) => ({ ...error, [name]: "" }));
      }
    } else {
      setError((error) => ({ ...error, [name]: "" }));
    }
  };

  const saveUser = (e) => {
    //validate form
    if (saveInfo.userEmail === "" || saveInfo.roles === "") {
      if (saveInfo.userEmail === "") {
        setError((error) => ({
          ...error,
          ["userEmail"]: "Please enter a valid email!",
        }));
      }
      if (saveInfo.roles === "") {
        setError((error) => ({
          ...error,
          ["roles"]: "Please enter valid role!",
        }));
      }
    } else {
      //send save user request
      const storesDataArray = [];
      for (const [k, v] of Object.entries(storeCheckBoxes)) {
        if (v) storesDataArray.push(k);
      }

      //sort stores array and turn into string of comma separated values
      const collator = new Intl.Collator(undefined, {
        numeric: true,
        sensitivity: "base",
      });
      const storesString = storesDataArray.sort(collator.compare).join();
      const submitData = { ...saveInfo, stores: storesString };
      
      axios
        .post(Links.saveUser, submitData)
        .then((res) => {
          if (res.data.error) {
            setError((error) => ({ ...error, ["userEmail"]: res.data.error }));
            //alert(res.data.error);
          } else {
            setSaveInfo((fields) => ({
              ...fields,
              ["userEmail"]: "",
              ["roles"]: "",
              ["stores"]: "",
            }));
            setError((error) => ({
              ...error,
              ["userEmail"]: "",
              ["roles"]: "",
              ["stores"]: "",
            }));
            setAllCheckBoxes(false);
            alert("User saved successfully");
            history.push("/user-manage");
          }
        })
        .catch((err) => {
          alert("Something went wrong!");
        });
    }
  };

  return (
    <>
      <Grid container justifyContent="center">
        <Paper
          style={{
            padding: "20px 40px",            
            border: "2px solid #dbdbdb",
            marginTop: "50px",
            borderRadius: "10px",
            width: "470px",
          }}
          variant="elevation"
          elevation={24}
        >
          <h4 className="mt-auto text-center" style={{ color: "#F79020" }}>
            {user ? "Edit User" : "Add a User"}
          </h4>
          <div>
            <Grid container spacing={4} alignItems="flex-end">
              <Grid item xs={1}>
                <AccountCircle />
              </Grid>
              <Grid item md={true} sm={true} xs={true}>
                <TextField
                  onChange={(e) => formControlChangeHandler(e)}
                  value={saveInfo.userEmail}
                  id="userEmail"
                  label="User email"
                  name="userEmail"
                  type="email"
                  fullWidth
                  autoFocus
                  required
                  disabled={user ? true : false}
                />
                {error.userEmail !== "" && (
                  <div className="text-danger">{error.userEmail}</div>
                )}
              </Grid>
            </Grid>
            <Grid container spacing={4} alignItems="flex-end">
              <Grid item xs={1}>
                <LockOpenIcon />
              </Grid>
              <Grid item md={true} sm={true} xs={true}>
                <TextField
                  onChange={(e) => formControlChangeHandler(e)}
                  value={saveInfo.password}
                  id="password"
                  label="Password"
                  name="password"
                  type="text"
                  fullWidth
                  required
                  disabled={user ? true : false}
                />
                {error.userEmail !== "" && (
                  <div className="text-danger">{error.password}</div>
                )}
              </Grid>
            </Grid>

            <Grid container spacing={4} alignItems="flex-end">
              <Grid item xs={1}>
                <AccessibilityNew />
              </Grid>
              <Grid item md={true} sm={true} xs={true}>
                <Select
                  labelId="rolesLabel"
                  name="roles"
                  id="roles"
                  label="Roles"
                  onChange={(e) => formControlChangeHandler(e)}
                  value={
                    saveInfo.roles == "" ? "Select Role *" : saveInfo.roles
                  }
                  fullWidth
                  required
                >
                  <MenuItem value={"Select Role *"} disabled>
                    Select Role *
                  </MenuItem>

                  <MenuItem value="normal">Normal</MenuItem>
                  
                  <MenuItem value="Radmin">Radmin</MenuItem>
                  <MenuItem value="admin">Admin</MenuItem>
                  <MenuItem value="Sadmin">Sadmin</MenuItem>
                </Select>
                {/* <TextField id="roles" onChange= label="Roles" type="text" name="roles"  /> */}
                {error.roles !== "" && (
                  <div className="text-danger">{error.roles}</div>
                )}
              </Grid>
            </Grid>
            <Grid className="mt-2 mb-2" container spacing={4} alignItems="flex-end">
              <Grid item xs={1}>
                <ApartmentOutlined />
              </Grid>
              <Grid item md={true} sm={true} xs={true}>
                <Typography variant='subtitle1'>Stores</Typography>
              </Grid>
              
            </Grid>
            <FormGroup>
            <Grid item md={true} sm={true} xs={true}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checkAll}
                    onChange={toggleAllCheckboxesHandler}
                  />
                }
                label="Select All"
              />
            </Grid>
            </FormGroup>
            <FormGroup>{storesDisplay}</FormGroup>

            <Grid
              container
              justifyContent="center"
              style={{ marginTop: "10px" }}
            >
              <Grid item xs={true}>
                <Button
                  className="w-100 text-white"
                  variant="outlined"
                  disabled={error.userEmail !== "" && "disabled"}
                  onClick={saveUser}
                  style={{
                    textTransform: "none",
                    borderColor: "white",
                    backgroundColor: "#F79020",
                  }}
                >
                  Save
                </Button>
              </Grid>
              {user ? <Grid item xs={true}>
                <Button
                  className="w-100 text-white"
                  variant="outlined"
                  disabled={error.userEmail !== "" && "disabled"}
                  onClick={() => history.push('/user-manage')}
                  style={{
                    textTransform: "none",
                    borderColor: "white",
                    backgroundColor: "#388E3C",
                  }}
                >
                  Cancel
                </Button>
              </Grid> : <></>}
            </Grid>
          </div>
        </Paper>
      </Grid>
    </>
  );
};

export default UserEdit;
