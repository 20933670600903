import React, { useEffect } from "react";
import { store, updateTotalStoreGross } from "../../redux";

const TotalRowForUsed = ({ total }) => {

    if (!total) {
        total = {}
    }

    let totalStoreGross = parseInt(total.used_retail_front_GP ? total.used_retail_front_GP : 0) +
        parseInt(total.used_retail_back_GP ? total.used_retail_back_GP : 0) +
        parseInt(total.service_total_GP ? total.service_total_GP : 0);

    if (isNaN(totalStoreGross) || totalStoreGross === 'NaN' || totalStoreGross === 'Infinity' || totalStoreGross === null) {
        totalStoreGross = '0'
    }

    useEffect(() => {
        store.dispatch(updateTotalStoreGross({ totalStoreGross }));
    }, [totalStoreGross])

    return (
        <tr>
            <td className="dn-column" style={{ "background-color": "#e9ecef" }}></td>
            <td className="dn-column" style={{ "background-color": "#e9ecef" }}></td>
            <td className="dn-column" style={{ "background-color": "#e9ecef" }}>Total</td>
            <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{total.used_retail_delivered ? total.used_retail_delivered : 0}</td>
            <td className="dn-column" style={{ "background-color": "#e9ecef" }}>${total.used_retail_front_GP ? total.used_retail_front_GP?.toLocaleString() : 0}</td>
            <td className="dn-column" style={{ "background-color": "#e9ecef" }}>${total.used_retail_back_GP ? total.used_retail_back_GP?.toLocaleString() : 0}</td>

            <td className="dn-column" style={{ "background-color": "#e9ecef" }}>${total.service_total_GP ? total.service_total_GP?.toLocaleString() : 0}</td>
            <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{total.service_total_hours ? total.service_total_hours : 0}</td>
            <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{total.service_CP_hours ? total.service_CP_hours : 0}</td>
            <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{total.service_CP_ROs ? total.service_CP_ROs : 0}</td>
            {/* <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{total.service_hours_available ? total.service_hours_available.toLocaleString() : 0}</td>
          
            <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{total.service_hours_sold ? total.service_hours_sold.toLocaleString() : 0}</td>
          
            <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{total.service_total_hours && total.service_hours_sold ? (total.service_hours_sold / total.service_total_hours).toFixed(2) : 0}</td>

          
         
            <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{total.service_no_of_tech ? total.service_no_of_tech.toLocaleString() : 0}</td>
            <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{total.service_journeyman ? total.service_journeyman.toLocaleString() : 0}</td>
            <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{total.service_apprentice ? total.service_apprentice.toLocaleString() : 0}</td>
            <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{total.service_advisors_needed ? total.service_advisors_needed.toLocaleString() : 0}</td> */}


            <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{total.service_units_shipped ? total.service_units_shipped.toLocaleString() : 0}</td>


            <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{total.appt_made ? total.appt_made : 0}</td>
            <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{total.booked ? total.booked : 0}</td>
            <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{total.appt_show ? total.appt_show : 0}</td>
            <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{total.store_visit ? total.store_visit : 0}</td>
         
            <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{total.internet_lead ? total.internet_lead : 0}</td>

            <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{total.fresh_up ? total.fresh_up : 0}</td>
            <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{total.phone_up ? total.phone_up : 0}</td>
            <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{total.lead ? total.lead : 0}</td>
            <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{total.sales_staff ? total.sales_staff : 0}</td>
        
            {/* <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{total.no_show ? total.no_show : 0}</td> */}

            <td className="dn-column" style={{ "background-color": "#e9ecef" }}>${totalStoreGross?.toLocaleString()}</td>
        </tr>
    )
}

export default TotalRowForUsed