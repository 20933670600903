import React, { useEffect, useState } from "react";
import { MONTHS, YEARS, PIE_COLORS, DEALER_REPORT_DAILY_DATA_ROW } from "../../constants"
import moment from 'moment';
import Links from "../../../src/Data/Links.js";
import axios from 'axios';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MTDDealerComparisonTable from "../../component/Reports/MTDDealerComparisonTable.js"

const DailyDealerComparison = (props) => {
  const { stores, division, usedStores } = props;
  const currentYear = moment().format('YYYY');
  const currentMonth = moment().format('MMM');
  const today = moment().date()
  const [year, setYear] = useState(currentYear);
  const [month, setMonth] = useState(currentMonth);
  const [day, setDay] = useState(today);
  const [dailyDataThisMonth, setDailyDataThisMonth] = useState([]);

  const passRightUrl = (division) => {
    switch (division) {
      case 'Used':
        return Links.getDivisionalReportDailyComparisonForUsed
      case 'Rec':
        return Links.getDivisionalReportDailyComparisonForRec
      case 'Auto':
        return Links.getDivisionalReportDailyComparison
      default:
        return Links.getDivisionalReportDailyComparison
    }
  };

  const getDailyData = (year, month) => {
    if(stores.length > 0) {
      const requestBody = {
        year: year,
        month: month,
        day: day,
        store_names: stores
      }
      axios.post(passRightUrl(division), requestBody)
        .then(result => {
          setDailyDataThisMonth(result.data.dailyDataThisMonth);
        })
        .catch(err => {
          console.log(err);
        });
    }
  };

  const showResults = () => {
    getDailyData(year, month);
  };

  const handleYearChange = (event) => {
    setYear(event.target.value);
  };

  const handleMonthChange = (event) => {
    setMonth(event.target.value);
  };

  const handleDayChange = (event) => {
    setDay(event.target.value);
  };

  const arrayOfDays = () => {
    let numberOfDays = [];
    for(let day = 1; day <= 31; day++) {
      numberOfDays.push(day.toString());
    }
    return numberOfDays;
  };

  return (
    <>
      <Grid container>
        <Grid item xs={1} style={{marginRight: '20px'}}>
          <FormControl variant="standard" sx={{ m: 1, width: "100%" }}>
            <InputLabel id="demo-simple-select-label">Year</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={year}
                label="Year"
                onChange={handleYearChange}
              >
              {YEARS.map((year, index) => (
                <MenuItem key={index} value={year}>{year}</MenuItem>
              ))}
              </Select>
          </FormControl>
        </Grid>
        <Grid item xs={1} style={{margin: '0px 10px'}}>
          <FormControl variant="standard" sx={{ m: 1, width: "100%" }}>
            <InputLabel id="demo-simple-select-label">Month</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={month}
                label="Age"
                onChange={handleMonthChange}
              >
              {Object.values(MONTHS).map((month, index) => (
                <MenuItem key={index} value={month}>{month}</MenuItem>
              ))}
              </Select>
          </FormControl>
        </Grid>
        <Grid item xs={1} style={{margin: '0px 20px'}}>
          <FormControl variant="standard" sx={{ m: 1, width: "100%" }}>
            <InputLabel id="demo-simple-select-label">Day</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={day}
                label="Day"
                onChange={handleDayChange}
              >
              {arrayOfDays().map((day, index) => (
                <MenuItem key={index} value={day}>{day}</MenuItem>
              ))}
              </Select>
          </FormControl>
        </Grid>
        <Grid item xs={3} className="mt-3">
          <button className="p-2 mt-1 btn btn-secondary mx-3" type="submit"
            onClick={() => {
              showResults()
            }}
          >
            Show Details
          </button>
      </Grid>
      <Grid item xs={12}>
        <MTDDealerComparisonTable stores={stores} dailyDataThisMonth={dailyDataThisMonth} usedStores={usedStores} division={division}/>
      </Grid>
    </Grid>
    </>
  )
};

export default DailyDealerComparison;