import { Switch, Route, BrowserRouter } from "react-router-dom";
import React, { useState } from "react";
import { Component } from "react";
import { store } from "../redux";
import { Provider } from 'react-redux';
import ProtectedRoute from "./ProtectedRoute";
import FileDrop from "./Budget/FileDrop";
import Home from "./Home";
import Signin from "./Signin";
//Data Entry
import DataEntryTs from "./DataEntry/DataEntryTs";
import DataEntryDn from "./DataEntry/DataEntryDn";
import DataEntryVillage from "./DataEntry/DataEntryVillage";
import DataEntryFfunFinancial from "./DataEntry/DataEntryFfunFinancial";
import DataEntryStaff from "./DataEntry/DataEntryStaff";
import DataEntryRec from "./DataEntry/DataEntryRec";
import Leftnav from "./Leftnav";
import MtdDealer from "./MtdDealer";
import MtdDealer2 from "./MtdDealer2";
import MtdDealer3 from "./MtdDealer3";
import DataEntryCarooga from './DataEntry/DataEntryCarooga';
//Budget
import BudgetAuto from "./Budget/BudgetAuto";
import BudgetDn from "./Budget/BudgetDn";
import BudgetVillage from "./Budget/BudgetVillage";
import BudgetCarooga from "./Budget/BudgetCarooga";
import BudgetRec from "./Budget/BudgetRec";
import BudgetFfunFinancial from "./Budget/BudgetFfunFinancial";
//Dealers Report
import DealerReports from "./DealerReports/DealerReports";

import DealerReportsRec from "./DealerReportsRec/DealerReportsRec";
import DealerReportsUsed from "./DealerReportsUsed/DealerReportsUsed";
//Divisional Reports
import AutomativeReports from "./DivisionalReports/AutomativeReports";
import DriveNation from "./DivisionalReports/DriveNationReports";
import RecreationalReports from "./DivisionalReports/RecreationalReports";
import VillageReports from "./DivisionalReports/VillageReports";
import CaroogaReports from "./DivisionalReports/CaroogaReports";
import FFUNFinancialReports from "./DivisionalReports/FFUNFinancialReports";
//Executive Reports
import ExecutiveReports from "./ExecutiveReports/ExecutiveReports";
import SummaryReportGross from "./ExecutiveReports/SummaryReportGross";
import SummaryReportSoldUnits from "./ExecutiveReports/SummaryReportSoldUnits";
import SummaryReportBudget from "./ExecutiveReports/SummaryReportBudget";
import FullReport from "./ExecutiveReports/FullReport";
import MonthlyReport from "./ExecutiveReports/MonthlyReport";
import SummaryReport from "./ExecutiveReports/SummaryReport";
import StaffSummaryReport from "./ExecutiveReports/StaffSummaryReport";

import CeoDashboard from "./CeoDashboard/CeoDashboard";
// Amplify.configure(awsconfig);
import FFUN_Wheel_opacity from '../images/output-onlinepngtools.png'
//User Management
import NewUser from './Users/NewUser';
import ManageUsers from './Users/ManageUsers';
import UserEdit from '../component/Users/UserEdit';
import DataEntryFc from "./DataEntry/DataEntryFc";
import BudgetFc from "./Budget/BudgetFc";
import DriveNationReports from "./DivisionalReports/DriveNationReports";
import UsedReports from "./DivisionalReports/UsedReports";
import Sop from "./Sop/Sop";
import Cookies from "js-cookie";

const App = ({ }) => {

  const initialValue=window.innerWidth<800?true:false;
  const isLeftNavSHownInitial=initialValue?false:true;

  let [isLeftNavSHown, setIsLeftNavShown] = useState(isLeftNavSHownInitial);

  let marginLeft = isLeftNavSHown ? "275px" : "105px";
  if(!Cookies.get("user_data")){
    marginLeft=0;
  }
  return (
    <Provider store={store}>
      <BrowserRouter>
        <div className="flex">
          {Cookies.get("user_data") ? <Leftnav setIsLeftNavShown={setIsLeftNavShown} isLeftNavSHown={isLeftNavSHown} initialValue={initialValue}/> : ''}
          <div className="content" style={{ width: '100%',marginLeft:marginLeft,paddingRIght:10, backgroundImage: !Cookies.get("user_data") ? `url(${FFUN_Wheel_opacity})` : '' }}>
            <Switch>
              <Route exact path="/signin" component={Signin} />
              <ProtectedRoute exact path="/" component={Home} />
              <ProtectedRoute exact path="/home" component={Home} />
              <ProtectedRoute path="/dataentryrec" component={DataEntryRec} />
              <ProtectedRoute path="/dataentryts" component={DataEntryTs} />
              <ProtectedRoute path="/dataentrydrivenation" component={DataEntryDn} />
              <ProtectedRoute path="/dataentryffuncars" component={DataEntryFc} />
              <ProtectedRoute path="/dataentryvillage" component={DataEntryVillage} />
              <ProtectedRoute path="/dataentryffunfinancial" component={DataEntryFfunFinancial} />
              <ProtectedRoute path="/dataentrystaff" component={DataEntryStaff} />

              <ProtectedRoute path="/budgetauto" component={BudgetAuto} />
              <ProtectedRoute path="/budgetdrivenation" component={BudgetDn} />
              <ProtectedRoute path="/budgetvillage" component={BudgetVillage} />
              <ProtectedRoute path="/budgetcarooga" component={BudgetCarooga} />
              <ProtectedRoute path="/budgetffuncars" component={BudgetFc} />
              <ProtectedRoute path="/budgetrec" component={BudgetRec} />
              <ProtectedRoute path="/budgetffunfinancial" component={BudgetFfunFinancial} />
              <ProtectedRoute path="/dealer-reports-auto" component={DealerReports} />
              <ProtectedRoute path="/dealer-reports-rec" component={DealerReportsRec} />
              <ProtectedRoute path="/dealer-reports-used" component={DealerReportsUsed} />
              <ProtectedRoute path="/divisional-automative-reports" component={AutomativeReports} />
              <ProtectedRoute path="/divisional-recreational-reports" component={RecreationalReports} />
              <ProtectedRoute path="/divisional-used-reports" component={UsedReports} />
              <ProtectedRoute path="/divisional-ffun-financial-reports" component={FFUNFinancialReports} />
              <ProtectedRoute path="/drive-nation-reports" component={DriveNation} />
              <ProtectedRoute path="/recreational-reports" component={RecreationalReports} />
              <ProtectedRoute path="/carooga-reports" component={CaroogaReports} />
              <ProtectedRoute path="/village-reports" component={VillageReports} />
              <ProtectedRoute path="/summary-report-gross" component={SummaryReportGross} />
              <ProtectedRoute path="/summary-report-sold-units" component={SummaryReportSoldUnits} />
              <ProtectedRoute path="/full-report" component={FullReport} />
              <ProtectedRoute path="/monthly-report" component={MonthlyReport} />
              <ProtectedRoute path="/summary-report" component={SummaryReport} />
              <ProtectedRoute path="/staff-summary-report" component={StaffSummaryReport} />
              <ProtectedRoute path="/summary-report-budget" component={SummaryReportBudget} />
              <ProtectedRoute exact path="/mtd" component={MtdDealer} />
              <ProtectedRoute exact path="/mtd2" component={MtdDealer2} />
              <ProtectedRoute exact path="/mtd3" component={MtdDealer3} />
              <ProtectedRoute exact path="/dataentrycarooga" component={DataEntryCarooga} />
              <ProtectedRoute exact path="/filedrop" component={FileDrop} />
              <ProtectedRoute exact path="/user-add" component={NewUser} />
              <ProtectedRoute exact path="/user-manage" component={ManageUsers} />
              <ProtectedRoute exact path="/user-edit" component={UserEdit} />
              <ProtectedRoute exact path="/ffun-one-dashboard" component={CeoDashboard} />
              <ProtectedRoute exact path="/ffun-sop" component={Sop} />
            </Switch>
          </div>
        </div>
        <footer>

        </footer>
      </BrowserRouter>
    </Provider>
  );

}
export default App;
