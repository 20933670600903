import React, { useEffect, useState } from "react";
import '../Budget/Demo.css';
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import axios from "axios";
import Links from "../../Data/Links";
import CreateRowSales from "../../component/DataEntry/CreateRowSales";
import Cookies from 'js-cookie';
import monthData from "../../constants/monthData";
import yearData from "../../constants/yearData";
import dayData from "../../constants/dayData";

const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
]

// Code Splitting for future

const RowTotal = ({ total }) => {

    let userData;
    if (Cookies.get('user_data')) {
        userData = JSON.parse(Cookies.get('user_data'));
    }

    if (!total) {
        total = {}
    }

    return (
        <tr>
            <td className="auto-column"></td>
            <td className="auto-column"></td>
            <td className="auto-column">Total</td>

            <td className="auto-column" >{total.sales_people ? total.sales_people.toLocaleString() : 0}</td>
            <td className="auto-column" >{total.sales_people_needed ? total.sales_people_needed.toLocaleString() : 0}</td>

            <td className="auto-column" >{total.service_no_of_tech ? total.service_no_of_tech.toLocaleString() : 0}</td>
            <td className="auto-column" >{total.service_journeyman_needed ? total.service_journeyman_needed.toLocaleString() : 0}</td>
            <td className="auto-column" >{total.service_apprentice_needed ? total.service_apprentice_needed.toLocaleString() : 0}</td>
            <td className="auto-column" >{total.service_advisors_needed ? total.service_advisors_needed.toLocaleString() : 0}</td>



        </tr>
    )

}
const DealerYearMonthSelector = ({ stores, filterVal, setFilterVal, loadData,allStores,idToDivision }) => {
    const handleChange = (field, event) => {
        if (field === 'store_name') {
            setFilterVal({ ...filterVal, [field]: event.target.value, 'store_id': Object.keys(stores).find(key => stores[key] === event.target.value), 'division': idToDivision[Object.keys(stores).find(key => stores[key] === event.target.value)] });
        } else {
            setFilterVal({ ...filterVal, [field]: event.target.value });
        }
        localStorage.setItem('filterVal', JSON.stringify(filterVal));
    };

    return (
        <div className="row" >
            <div className="col-12 col-md-4  col-xl-2">
                <Box
                    sx={{ minWidth: 120 }}
                    style={{ width: "100%", marginTop: ".7em" }}
                >
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label" className="pt-2">Dealer</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={filterVal?.store_name}
                            label="Dealer"
                            onChange={(e) => handleChange('store_name', e)}
                        >
                             {allStores?.map(i => {
                                // console.log(i.name)
                                return <MenuItem value={i.name}>{i.name==="Regina"||i.name==="Saskatoon"||i.name==="Prince Albert"?i.division+" "+i.name:i.name}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                </Box>
            </div>
            <div className="col-12 col-md-2">
                <Box
                    style={{ display: "inline-block", width: "100%", marginTop: ".7em" }}
                >
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label" className="pt-2">Year</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={filterVal?.year}
                            label="Year"
                            onChange={(e) => handleChange('year', e)}
                        >
                            {yearData.map(child => (
                                <MenuItem value={child}>{child}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
            </div>

            <div className="col">
                <button className="p-2 mt-1 btn btn-secondary mt-2" type="submit"
                    onClick={() => {
                        loadData()
                    }}
                >Show Details</button>
            </div>
        </div>
    )
}
const Staff = ({ props }) => {
    const [stores, setStores] = useState(localStorage.getItem('stores') ? JSON.parse(localStorage.getItem('stores')) : "");
    const [allStores,setAllStores]=useState(localStorage.getItem('stores') ? JSON.parse(localStorage.getItem('stores')) : "");
    const [staffData, setStaffData] = useState(localStorage.getItem('stores') ? JSON.parse(localStorage.getItem('stores')) : "");
    const [filterVal, setFilterVal] = useState({
   
    });
    const [idToDivision,setIdToDivision]=useState({})

    let userData;
    if (Cookies.get('user_data')) {
        userData = JSON.parse(Cookies.get('user_data'));
    }
    useEffect(() => {
        let storeObj = {};
        let storeObjDivision = {};
        stores.length > 0 && stores?.map(i => {

            storeObj[i.id] = i.name;
            storeObjDivision[i.id]=i.division
        });
        setStores(storeObj);
        setIdToDivision(storeObjDivision);
        setFilterVal({
            store_id: Object.keys(storeObj)[0],
            store_name: Object.values(storeObj)[0],
            division: storeObjDivision[Object.keys(storeObj)[0]],
            company: 'FFUN',
            year: new Date().getFullYear(),
        })
    }, []);

    useEffect(() => {
        loadData();
    }, [filterVal])

    const loadData = (filterData = null) => {
        if (filterData) {
            setFilterVal(filterData);
        }
        axios.post(Links.getStaffData, filterVal)
            .then(res => {

                setStaffData(res.data.staff)
            }).catch(err => {
                console.log(err);
            });
    }

    let total = {
        sales_people: 0,
        sales_people_needed: 0,
        service_no_of_tech: 0,
        service_journeyman_needed: 0,
        service_apprentice_needed: 0,
        service_advisors_needed: 0,

    };

    return (
        <div style={{ margin: "2rem 2rem 2rem 0rem" }}>
           {filterVal?.store_id>0 && <DealerYearMonthSelector idToDivision={idToDivision} stores={stores} allStores={allStores} filterVal={filterVal} setFilterVal={setFilterVal} loadData={() => loadData()} />}


            <table class="table table-bordered border-black" style={{ marginTop: 30 }}>
                <thead>
                    <tr className="text-center">
                        
                         
                      <th className="auto-header-top" colSpan="2" style={{ "background-color": "#e9ecef" }} ></th>
                           
                        
                        <th className="auto-header-top" style={{ "background-color": "#e9ecef" }} ></th>
                        <th className="auto-header-top" colSpan="2" style={{ "background-color": "#b38bb5" }}>Sales</th>
                        <th className="auto-header-top" colSpan="4" style={{ "background-color": "#b1d5dd" }}>Service</th>


                    </tr>
                    <tr>
                       
                       
                                <th className="auto-header" scope="col" style={{ "background-color": "#e9ecef" }} ></th>
                                <th className="auto-header" scope="col" style={{ "background-color": "#e9ecef" }} ></th>
                       
                        <th scope="col" className="auto-header" style={{ "background-color": "#e9ecef" }} >Month</th>

                        <th className="auto-header" scope="col" style={{ "background-color": "#b38bb5" }}>No of Staff</th>
                        <th className="auto-header" scope="col" style={{ "background-color": "#b38bb5" }}>Staff Needed</th>
                        <th className="auto-header" scope="col" style={{ "background-color": "#b1d5dd" }}>No of Technicians</th>
                        <th className="auto-header" scope="col" style={{ "background-color": "#b1d5dd" }}>Journeyman Needed</th>
                        <th className="auto-header" scope="col" style={{ "background-color": "#b1d5dd" }}>Apprentice Needed</th>
                        <th className="auto-header" scope="col" style={{ "background-color": "#b1d5dd" }}>Advisors Needed</th>


                    </tr>
                </thead>
                <tbody>
                    {
                        months.map((i, n) => {
                            total.sales_people = (staffData[i] && staffData[i].sales_people !== '') ? total.sales_people + parseInt(staffData[i].sales_people) : total.sales_people;
                            total.sales_people_needed = (staffData[i] && staffData[i].sales_people_needed !== '') ? total.sales_people_needed + parseInt(staffData[i].sales_people_needed) : total.sales_people_needed;
                            total.service_no_of_tech = (staffData[i] && staffData[i].service_no_of_tech !== '') ? total.service_no_of_tech + parseInt(staffData[i].service_no_of_tech) : total.service_no_of_tech;
                            total.service_journeyman_needed = (staffData[i] && staffData[i].service_journeyman_needed !== '') ? total.service_journeyman_needed + parseInt(staffData[i].service_journeyman_needed) : total.service_journeyman_needed;
                            total.service_apprentice_needed = (staffData[i] && staffData[i].service_apprentice_needed !== '') ? total.service_apprentice_needed + parseInt(staffData[i].service_apprentice_needed) : total.service_apprentice_needed;
                            total.service_advisors_needed = (staffData[i] && staffData[i].service_advisors_needed !== '') ? total.service_advisors_needed + parseInt(staffData[i].service_advisors_needed) : total.service_advisors_needed;
                            return <CreateRowSales idToDivision={idToDivision} row={staffData} userData={userData} month={i} filterData={filterVal} loadData={() => loadData()} />
                        })
                    }
                    <RowTotal total={total} />
                </tbody>
            </table>
        </div>
    );
};

export default Staff