import moment from 'moment';

export default () => {
  const currentMonth = moment().format('MMM');
  const MonthNumber = moment().month(currentMonth).format("M");
  const numberOfMonths = []
  for (let month = 1; month <= MonthNumber; month++) {
    numberOfMonths.push(month)
  };
  return numberOfMonths;
};