import React from "react";

const ForecastRowForUsed = ({ budget }) => {
    if (!budget) {
        budget = {}
    }

    let totalStoreGross = parseInt(budget.used_retail_front_GP ? budget.used_retail_front_GP : 0) +
        parseInt(budget.used_retail_back_GP ? budget.used_retail_back_GP : 0) +
        parseInt(budget.service_total_GP ? budget.service_total_GP : 0);

    if (isNaN(totalStoreGross) || totalStoreGross === 'NaN' || totalStoreGross === 'Infinity' || totalStoreGross === null) {
        totalStoreGross = '0'
    }

    return (
        <tr>
            <td className="dn-column" style={{ "background-color": "#e9ecef" }}></td>
            <td className="dn-column" style={{ "background-color": "#e9ecef" }}></td>
            <td className="dn-column" style={{ "background-color": "#e9ecef" }}>Forecast</td>
            <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{budget.used_retail_delivered ? budget.used_retail_delivered : 0}</td>
            <td className="dn-column" style={{ "background-color": "#e9ecef" }}>${budget.used_retail_front_GP ? parseInt(budget.used_retail_front_GP)?.toLocaleString() : 0}</td>
            <td className="dn-column" style={{ "background-color": "#e9ecef" }}>${budget.used_retail_back_GP ? parseInt(budget.used_retail_back_GP)?.toLocaleString() : 0}</td>

            <td className="dn-column" style={{ "background-color": "#e9ecef" }}>${budget.service_total_GP ? parseInt(budget.service_total_GP)?.toLocaleString() : 0}</td>
            <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{budget.service_total_hours ? budget.service_total_hours : 0}</td>
            <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{budget.service_CP_hours ? budget.service_CP_hours : 0}</td>
            <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{budget.service_CP_ROs ? budget.service_CP_ROs : 0}</td>
         
            {/* <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{budget.service_hours_available ? parseInt(budget.service_hours_available).toLocaleString() : 0}</td>
            <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{budget.service_hours_sold ? parseInt(budget.service_hours_sold).toLocaleString() : 0}</td>

            
            <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{budget.service_total_hours && budget.service_hours_sold ? (budget.service_hours_sold / budget.service_total_hours).toFixed(2) : 0}</td>



            <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{budget.service_no_of_tech ? parseInt(budget.service_no_of_tech).toLocaleString() : 0}</td>
            <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{budget.service_journeyman ? parseInt(budget.service_journeyman).toLocaleString() : 0}</td>
            <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{budget.service_apprentice ? parseInt(budget.service_apprentice).toLocaleString() : 0}</td>
            <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{budget.service_advisors_needed ? parseInt(budget.service_advisors_needed).toLocaleString() : 0}</td> */}

            <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{budget.service_units_shipped ? parseInt(budget.service_units_shipped).toLocaleString() : 0}</td>

            <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{budget.appt_made ? budget.appt_made : 0}</td>
            <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{budget.booked ? budget.booked : 0}</td>
            <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{budget.appt_show ? budget.appt_show : 0}</td>
            <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{budget.store_visit ? budget.store_visit : 0}</td>
    
            <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{budget.internet_lead ? budget.internet_lead : 0}</td>


            <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{budget.fresh_up ? budget.fresh_up : 0}</td>
            <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{budget.phone_up ? budget.phone_up : 0}</td>
            <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{budget.lead ? budget.lead : 0}</td>
            <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{budget.sales_staff ? budget.sales_staff : 0}</td>
           
            {/* <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{budget.no_show ? budget.no_show : 0}</td> */}


            <td className="dn-column" style={{ "background-color": "#e9ecef" }}>${totalStoreGross?.toLocaleString()}</td>
        </tr>
    )
}

export default ForecastRowForUsed