import React, { useEffect, useState } from "react";
import moment from "moment";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { MONTHS, YEARS, DAYS_IN_MONTH } from "../../../src/constants.js";
import axios from "axios";
import Links from "../../../src/Data/Links.js";
import CarLoading from "../../images/487.gif";
import calculateKPIPercentage from "../../utils/calculateKPIPercentage.js";
import getFiscals from "../../utils/getFiscals.js";
import calculateTrend from "../../utils/calculateTrend.js";
import calculateTotalHolidaysbyDates from "../../utils/calculateTotalHolidaysbyDates.js";
import calculateTotalHolidaysbyMonth from "../../utils/calculateTotalHolidaysbyMonth.js";

const TableHead = ({ type }) => (
    <thead>
        <tr className="text-center align-middle">
            <th colSpan="1" className="dn-header-top" style={{ whiteSpace: "nowrap", backgroundColor: "orange" }}></th>
            <th colSpan="5" className="dn-header-top" style={{ whiteSpace: "nowrap", backgroundColor: "#A2C1A2" }}>Leads</th>
            <th colSpan="5" className="dn-header-top" style={{ whiteSpace: "nowrap", backgroundColor: "#b1d5dd" }}>Appts Show</th>
            <th colSpan="5" className="dn-header-top" style={{ whiteSpace: "nowrap", backgroundColor: "#A2C1A2" }}>Sold</th>
            <th colSpan="5" className="dn-header-top" style={{ whiteSpace: "nowrap", backgroundColor: "#b1d5dd" }}>Total GP</th>
            <th colSpan="2" className="dn-header-top" style={{ whiteSpace: "nowrap", backgroundColor: "orange" }}></th>
        </tr>
        <tr className="text-center align-middle">

            {type === 'dealership' ? (
                <th colSpan="1" className="dn-header" style={{ whiteSpace: "nowrap", backgroundColor: "orange" }}>
                    Store Name
                </th>
            ) : (
                <th colSpan="1" className="dn-header" style={{ whiteSpace: "nowrap", backgroundColor: "orange" }} >
                    Division
                </th>
            )}
            <th className="dn-header" style={{ whiteSpace: "nowrap", backgroundColor: "#A2C1A2" }}>
                Current
            </th>
            <th className="dn-header" style={{ whiteSpace: "nowrap", backgroundColor: "#A2C1A2" }} >
                Month End Tracking
            </th>
            <th className="dn-header" style={{ whiteSpace: "nowrap", backgroundColor: "#A2C1A2" }} >
                Goal
            </th>
            <th className="dn-header" style={{ whiteSpace: "nowrap", backgroundColor: "#A2C1A2" }} >
                KPI MTD
            </th>
            <th className="dn-header" style={{ whiteSpace: "nowrap", backgroundColor: "#A2C1A2" }} >
                Trend&#x2191;&#x2193;
            </th>

            <th className="dn-header" style={{ whiteSpace: "nowrap", backgroundColor: "#b1d5dd" }} >
                Current
            </th>
            <th className="dn-header" style={{ whiteSpace: "nowrap", backgroundColor: "#b1d5dd" }} >
                Month End Tracking
            </th>
            <th className="dn-header" style={{ whiteSpace: "nowrap", backgroundColor: "#b1d5dd" }} >
                Goal
            </th>
            <th className="dn-header" style={{ whiteSpace: "nowrap", backgroundColor: "#b1d5dd" }} >
                KPI MTD
            </th>
            <th className="dn-header" style={{ whiteSpace: "nowrap", backgroundColor: "#b1d5dd" }} >
                Trend&#x2191;&#x2193;
            </th>

            <th className="dn-header" style={{ whiteSpace: "nowrap", backgroundColor: "#a2c1a2" }} >
                Current
            </th>
            <th className="dn-header" style={{ whiteSpace: "nowrap", backgroundColor: "#a2c1a2" }} >
                Month End Tracking
            </th>
            <th className="dn-header" style={{ whiteSpace: "nowrap", backgroundColor: "#a2c1a2" }} >
                Goal
            </th>
            <th className="dn-header" style={{ whiteSpace: "nowrap", backgroundColor: "#a2c1a2" }} >
                KPI MTD
            </th>
            <th className="dn-header" style={{ whiteSpace: "nowrap", backgroundColor: "#a2c1a2" }} >
                Trend&#x2191;&#x2193;
            </th>

            <th className="dn-header" style={{ whiteSpace: "nowrap", backgroundColor: "#b1d5dd" }} >
                Current
            </th>
            <th className="dn-header" style={{ whiteSpace: "nowrap", backgroundColor: "#b1d5dd" }} >
                Month End Tracking
            </th>
            <th className="dn-header" style={{ whiteSpace: "nowrap", backgroundColor: "#b1d5dd" }} >
                Goal
            </th>
            <th className="dn-header" style={{ whiteSpace: "nowrap", backgroundColor: "#b1d5dd" }} >
                KPI MTD
            </th>
            <th className="dn-header" style={{ whiteSpace: "nowrap", backgroundColor: "#b1d5dd" }} >
                Trend&#x2191;&#x2193;
            </th>

            <th className="dn-header" style={{ whiteSpace: "nowrap", backgroundColor: "orange" }} >
                Leads to Sale
            </th>
            <th className="dn-header" style={{ whiteSpace: "nowrap", backgroundColor: "orange" }} >
                Closing %
            </th>
        </tr>
    </thead>
)


const TableHeadStaff = ({ type }) => (
    <thead>
        <tr className="text-center align-middle">
            <th colSpan="1" className="dn-header-top" style={{ whiteSpace: "nowrap", backgroundColor: "orange" }}></th>
            <th colSpan="3" className="dn-header-top" style={{ whiteSpace: "nowrap", backgroundColor: "#A2C1A2" }}>Sales</th>
            <th colSpan="5" className="dn-header-top" style={{ whiteSpace: "nowrap", backgroundColor: "#b1d5dd" }}>Service</th>
            {/* <th colSpan="2" className="dn-header-top" style={{ whiteSpace: "nowrap", backgroundColor: "orange" }}></th> */}
        </tr>
        <tr className="text-center align-middle">

            {type === 'dealership' ? (
                <th colSpan="1" className="dn-header" style={{ whiteSpace: "nowrap", backgroundColor: "orange" }}>
                    Store Name
                </th>
            ) : (
                <th colSpan="1" className="dn-header" style={{ whiteSpace: "nowrap", backgroundColor: "orange" }} >
                    Division
                </th>
            )}
            <th className="dn-header" style={{ whiteSpace: "nowrap", backgroundColor: "#A2C1A2" }}>
                No of Staff
            </th>
            <th className="dn-header" style={{ whiteSpace: "nowrap", backgroundColor: "#A2C1A2" }} >
                Staff Needed
            </th>
            <th className="dn-header" style={{ whiteSpace: "nowrap", backgroundColor: "#A2C1A2" }} >
                Sales Per Staff
            </th>
           
            <th className="dn-header" style={{ whiteSpace: "nowrap", backgroundColor: "#b1d5dd" }} >
                No of Technicians
            </th>
            <th className="dn-header" style={{ whiteSpace: "nowrap", backgroundColor: "#b1d5dd" }} >
                Journeyman Needed
            </th>

            <th className="dn-header" style={{ whiteSpace: "nowrap", backgroundColor: "#b1d5dd" }} >
                Apprentice Needed
            </th>
            <th className="dn-header" style={{ whiteSpace: "nowrap", backgroundColor: "#b1d5dd" }} >
                Advisors Needed
            </th>
            <th className="dn-header" style={{ whiteSpace: "nowrap", backgroundColor: "#b1d5dd" }} >
                Tech Proficiency
            </th>

        </tr>
    </thead>
)


{/* removed dailydata param  */ }
const DealershipTable = ({ division, apptData, apptBudgetData, soldData, soldBudgetData, gpData, gpBudgetData, days, getnoOfHolidaysbyDate, getnoOfHolidaysbyMonth, day }) => {
    const [stores, setStores] = useState([]);

    useEffect(() => {
        getStores();
    }, []);

    const getStores = () => {
        axios
            .get(Links.getAllStores)
            .then((res) => {
                let all_stores_in_current_division;
                if (division === 'Used') {
                    all_stores_in_current_division = res.data.stores.filter(store => store.division === 'DriveNation' || store.division === 'FFUN Cars')
                } else {
                    all_stores_in_current_division = res.data.stores.filter(store => store.division === division)
                };
                setStores(all_stores_in_current_division);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const calculateTotal = (data, field) => {
        let total = 0;
        if (data) {
            data.forEach((item) => {
                total += item[field] ? parseFloat(item[field]) : 0;
            });
        }
        return total;
    };
    const trendLeadsTotal = calculateTrend(calculateTotal(apptData, 'total_lead'), calculateTotal(apptBudgetData, 'lead'), days, getnoOfHolidaysbyDate, getnoOfHolidaysbyMonth, day);
    const trendApptTotal = calculateTrend(calculateTotal(apptData, 'total_appt_show'), calculateTotal(apptBudgetData, 'appt_show'), days, getnoOfHolidaysbyDate, getnoOfHolidaysbyMonth, day);
    const trendSoldTotal = calculateTrend(calculateTotal(soldData, 'total_sold'), calculateTotal(soldBudgetData, 'total_sold'), days, getnoOfHolidaysbyDate, getnoOfHolidaysbyMonth, day);
    const trendTotalTotal = calculateTrend(calculateTotal(gpData, 'total_gross_profit'), calculateTotal(gpBudgetData, 'total_gross_profit'), days, getnoOfHolidaysbyDate, getnoOfHolidaysbyMonth, day);
    return (
        <>
            <h4>{division === "Auto" ? 'Automotive' : division === "Rec" ? 'Recreational' : "Used "} Dearlerships:</h4>
            {(!stores || stores.length === 0) && (
                (<div className="p-4 d-flex justify-content-center" >
                    <img src={CarLoading} />
                </div>)
            )}

            {stores && stores.length > 0 && (
                <table className="mb-5 mt-3 table table-bordered border-black table-fit table-striped">
                    <TableHead type='dealership' />
                    <tbody>
                        {stores.map((store) => {
                            const appt_current_store = apptData.find(item => item.store_id === store.id);
                            const appt_budget_current_store = apptBudgetData.find(item => item.store_id === store.id);
                            const sold_current_store = soldData.find(item => item.store_id === store.id);
                            const sold_budget_current_store = soldBudgetData.find(item => item.store_id === store.id);
                            // const daily_data_current_store = dailyData.find(item => item.store_id === store.id);
                            const gp_current_store = gpData.find(item => item.store_id === store.id);
                            const gp_budget_current_store = gpBudgetData.find(item => item.store_id === store.id);
                            const trendLeads = calculateTrend(appt_current_store?.total_lead || 0, appt_budget_current_store?.lead || 0, days, getnoOfHolidaysbyDate, getnoOfHolidaysbyMonth, day);
                            const trendAppt = calculateTrend(appt_current_store?.total_appt_show || 0, appt_budget_current_store?.appt_show || 0, days, getnoOfHolidaysbyDate, getnoOfHolidaysbyMonth, day);
                            const trendSold = calculateTrend(sold_current_store?.total_sold || 0, sold_budget_current_store?.total_sold || 0, days, getnoOfHolidaysbyDate, getnoOfHolidaysbyMonth, day);
                            const trendTotal = calculateTrend(gp_current_store?.total_gross_profit || 0, gp_budget_current_store?.total_gross_profit || 0, days, getnoOfHolidaysbyDate, getnoOfHolidaysbyMonth, day);
                            return (
                                <tr>
                                    <td className="text-capitalize"> {store.name} </td>
                                    <td className="text-center align-middle"> {appt_current_store?.total_lead || 0} </td>
                                    <td className="text-center align-middle">
                                        {Math.ceil((appt_budget_current_store?.lead || 0) * (1 + trendLeads / 100))}
                                    </td>
                                    <td className="text-center align-middle"> {appt_budget_current_store?.lead || 0} </td>
                                    {/* <td className="text-center align-middle">
                                        {calculateKPIPercentage((appt_current_store?.total_lead || 0), (appt_budget_current_store?.lead || 0))}
                                    </td> */}
                                    <td className="align-middle" style={{ fontWeight: 500, textAlign: "center", position: "relative" }}>
                                        <div className="progress" style={{ height: "100%", width: "100%", position: "absolute", right: "0%", top: "0%", zIndex: "-1", borderRadius: 0 }}>
                                            <div
                                                className="progress-bar"
                                                role="progressbar"
                                                style={{
                                                    backgroundColor: "#7CEF7C ",
                                                    width: calculateKPIPercentage((appt_current_store?.total_lead || 0), (appt_budget_current_store?.lead || 0))
                                                }}
                                            ></div>
                                        </div>
                                        {calculateKPIPercentage((appt_current_store?.total_lead || 0), (appt_budget_current_store?.lead || 0))}
                                    </td>
                                    <td className="text-center align-middle">{trendLeads}%{trendLeads !== 0 ? Math.sign(trendLeads) === -1 ? <span style={{ color: "#dc3545", fontSize: 18 }}>&#x2193;</span> : <span style={{ color: "#35dc66", fontSize: 18 }}>&#x2191;</span> : ""} </td>
                                    {/* <td className="text-center align-middle">
                                        {calculateKPIPercentage(daily_data_current_store?.lead || 0, ((appt_budget_current_store?.lead || 0) / days))}
                                    </td> */}
                                    <td className="text-center align-middle"> {appt_current_store?.total_appt_show || 0} </td>
                                    <td className="text-center align-middle">
                                        {Math.ceil((appt_budget_current_store?.appt_show || 0) * (1 + trendAppt / 100))}
                                    </td>
                                    <td className="text-center align-middle"> {appt_budget_current_store?.appt_show || 0} </td>
                                    {/* <td className="text-center align-middle">
                                        {calculateKPIPercentage((appt_current_store?.total_appt_show || 0), (appt_budget_current_store?.appt_show || 0))}
                                    </td> */}
                                    <td className="align-middle" style={{ fontWeight: 500, textAlign: "center", position: "relative" }}>
                                        <div className="progress" style={{ height: "100%", width: "100%", position: "absolute", right: "0%", top: "0%", zIndex: "-1", borderRadius: 0 }}>
                                            <div
                                                className="progress-bar"
                                                role="progressbar"
                                                style={{
                                                    backgroundColor: "#7CEF7C ",
                                                    width: calculateKPIPercentage((appt_current_store?.total_appt_show || 0), (appt_budget_current_store?.appt_show || 0))
                                                }}
                                            ></div>
                                        </div>
                                        {calculateKPIPercentage((appt_current_store?.total_appt_show || 0), (appt_budget_current_store?.appt_show || 0))}
                                    </td>
                                    <td className="text-center align-middle">{trendAppt}%{trendAppt !== 0 ? Math.sign(trendAppt) === -1 ? <span style={{ color: "#dc3545", fontSize: 18 }}>&#x2193;</span> : <span style={{ color: "#35dc66", fontSize: 18 }}>&#x2191;</span> : ""} </td>
                                    {/* <td className="text-center align-middle">
                                        {calculateKPIPercentage((daily_data_current_store?.appt_show || 0), ((appt_budget_current_store?.appt_show / days) || 0))}
                                    </td> */}
                                    <td className="text-center align-middle"> {sold_current_store?.total_sold || 0} </td>
                                    <td className="text-center align-middle">
                                        {Math.ceil((sold_budget_current_store?.total_sold || 0) * (1 + trendSold / 100))}
                                    </td>
                                    <td className="text-center align-middle"> {sold_budget_current_store?.total_sold || 0} </td>
                                    {/* <td className="text-center align-middle">
                                        {calculateKPIPercentage((sold_current_store?.total_sold || 0), (sold_budget_current_store?.total_sold || 0))}
                                    </td> */}
                                    <td className="align-middle" style={{ fontWeight: 500, textAlign: "center", position: "relative" }}>
                                        <div className="progress" style={{ height: "100%", width: "100%", position: "absolute", right: "0%", top: "0%", zIndex: "-1", borderRadius: 0 }}>
                                            <div
                                                className="progress-bar"
                                                role="progressbar"
                                                style={{
                                                    backgroundColor: "#7CEF7C ",
                                                    width: calculateKPIPercentage((sold_current_store?.total_sold || 0), (sold_budget_current_store?.total_sold || 0))
                                                }}
                                            ></div>
                                        </div>
                                        {calculateKPIPercentage((sold_current_store?.total_sold || 0), (sold_budget_current_store?.total_sold || 0))}
                                    </td>
                                    <td className="text-center align-middle">{trendSold}%{trendSold !== 0 ? Math.sign(trendSold) === -1 ? <span style={{ color: "#dc3545", fontSize: 18 }}>&#x2193;</span> : <span style={{ color: "#35dc66", fontSize: 18 }}>&#x2191;</span> : ""} </td>
                                    {/* <td className="text-center align-middle">
                                        {calculateKPIPercentage((daily_data_current_store?.total_sold || 0), ((sold_budget_current_store?.total_sold / days) || 0))}
                                    </td> */}

                                    <td className="text-center align-middle">
                                        {(gp_current_store?.total_gross_profit || 0).toLocaleString("en-CA", {
                                            minimumFractionDigits: 0,
                                            maximumFractionDigits: 0,
                                            style: "currency",
                                            currency: "CAD"
                                        })} </td>
                                    <td className="text-center align-middle">
                                        {Math.ceil((gp_budget_current_store?.total_gross_profit || 0) * (1 + trendTotal / 100))
                                            .toLocaleString("en-CA", {
                                                minimumFractionDigits: 0,
                                                maximumFractionDigits: 0,
                                                style: "currency",
                                                currency: "CAD"
                                            })}
                                    </td>
                                    <td className="text-center align-middle">
                                        {(gp_budget_current_store?.total_gross_profit || 0).toLocaleString("en-CA", {
                                            minimumFractionDigits: 0,
                                            maximumFractionDigits: 0,
                                            style: "currency",
                                            currency: "CAD"
                                        })} </td>
                                    {/* <td className="text-center align-middle">
                                        {calculateKPIPercentage((gp_current_store?.total_gross_profit || 0), (gp_budget_current_store?.total_gross_profit || 0))}
                                    </td> */}
                                    <td className="align-middle" style={{ fontWeight: 500, textAlign: "center", position: "relative" }}>
                                        <div className="progress" style={{ height: "100%", width: "100%", position: "absolute", right: "0%", top: "0%", zIndex: "-1", borderRadius: 0 }}>
                                            <div
                                                className="progress-bar"
                                                role="progressbar"
                                                style={{
                                                    backgroundColor: "#7CEF7C ",
                                                    width: calculateKPIPercentage((gp_current_store?.total_gross_profit || 0), (gp_budget_current_store?.total_gross_profit || 0))
                                                }}
                                            ></div>
                                        </div>
                                        {calculateKPIPercentage((gp_current_store?.total_gross_profit || 0), (gp_budget_current_store?.total_gross_profit || 0))}
                                    </td>
                                    <td className="text-center align-middle">{trendTotal}%{trendTotal !== 0 ? Math.sign(trendTotal) === -1 ? <span style={{ color: "#dc3545", fontSize: 18 }}>&#x2193;</span> : <span style={{ color: "#35dc66", fontSize: 18 }}>&#x2191;</span> : ""} </td>
                                    {/* <td className="text-center align-middle">
                                        {calculateKPIPercentage(daily_data_current_store?.total_gross_profit || 0, ((gp_budget_current_store?.total_gross_profit || 0) / days))}
                                    </td> */}

                                    <td className="text-center align-middle">
                                        {sold_current_store?.total_sold ? Math.round((appt_current_store?.total_lead || 0) / (sold_current_store?.total_sold)) : 0}
                                    </td>
                                    <td className="text-center align-middle">
                                        {calculateKPIPercentage((sold_current_store?.total_sold || 0), (appt_current_store?.total_store_visit || 0))}
                                    </td>
                                </tr>
                            )
                        })}

                        <tr>
                            <td className="text-capitalize"> Total </td>
                            <td className="text-center align-middle"> {calculateTotal(apptData, 'total_lead').toLocaleString()} </td>
                            <td className="text-center align-middle">
                                {Math.ceil(calculateTotal(apptBudgetData, 'lead') * (1 + trendLeadsTotal / 100))}
                            </td>
                            <td className="text-center align-middle"> {calculateTotal(apptBudgetData, 'lead').toLocaleString()} </td>
                            {/* <td className="text-center align-middle"> {calculateKPIPercentage(calculateTotal(apptData, 'total_lead'), calculateTotal(apptBudgetData, 'lead'))} </td> */}
                            <td className="align-middle" style={{ fontWeight: 500, textAlign: "center", position: "relative" }}>
                                <div className="progress" style={{ height: "100%", width: "100%", position: "absolute", right: "0%", top: "0%", zIndex: "-1", borderRadius: 0 }}>
                                    <div
                                        className="progress-bar"
                                        role="progressbar"
                                        style={{
                                            backgroundColor: "#7CEF7C ",
                                            width: calculateKPIPercentage(calculateTotal(apptData, 'total_lead'), calculateTotal(apptBudgetData, 'lead'))
                                        }}
                                    ></div>
                                </div>
                                {calculateKPIPercentage(calculateTotal(apptData, 'total_lead'), calculateTotal(apptBudgetData, 'lead'))}
                            </td>
                            <td className="text-center align-middle">{trendLeadsTotal}%{trendLeadsTotal !== 0 ? Math.sign(trendLeadsTotal) === -1 ? <span style={{ color: "#dc3545", fontSize: 18 }}>&#x2193;</span> : <span style={{ color: "#35dc66", fontSize: 18 }}>&#x2191;</span> : ""} </td>
                            {/* <td className="text-center align-middle"> {calculateKPIPercentage(calculateTotal(dailyData, 'lead'), calculateTotal(apptBudgetData, 'lead') / days)} </td> */}

                            <td className="text-center align-middle"> {calculateTotal(apptData, 'total_appt_show').toLocaleString()} </td>
                            <td className="text-center align-middle">
                                {Math.ceil(calculateTotal(apptBudgetData, 'appt_show') * (1 + trendApptTotal / 100))}
                            </td>
                            <td className="text-center align-middle"> {calculateTotal(apptBudgetData, 'appt_show').toLocaleString()} </td>
                            {/* <td className="text-center align-middle"> {calculateKPIPercentage(calculateTotal(apptData, 'total_appt_show'), calculateTotal(apptBudgetData, 'appt_show'))} </td> */}
                            <td className="align-middle" style={{ fontWeight: 500, textAlign: "center", position: "relative" }}>
                                <div className="progress" style={{ height: "100%", width: "100%", position: "absolute", right: "0%", top: "0%", zIndex: "-1", borderRadius: 0 }}>
                                    <div
                                        className="progress-bar"
                                        role="progressbar"
                                        style={{
                                            backgroundColor: "#7CEF7C ",
                                            width: calculateKPIPercentage(calculateTotal(apptData, 'total_appt_show'), calculateTotal(apptBudgetData, 'appt_show'))
                                        }}
                                    ></div>
                                </div>
                                {calculateKPIPercentage(calculateTotal(apptData, 'total_appt_show'), calculateTotal(apptBudgetData, 'appt_show'))}
                            </td>
                            <td className="text-center align-middle">{trendApptTotal}%{trendApptTotal !== 0 ? Math.sign(trendApptTotal) === -1 ? <span style={{ color: "#dc3545", fontSize: 18 }}>&#x2193;</span> : <span style={{ color: "#35dc66", fontSize: 18 }}>&#x2191;</span> : ""} </td>
                            {/* <td className="text-center align-middle"> {calculateKPIPercentage(calculateTotal(dailyData, 'appt_show'), calculateTotal(apptBudgetData, 'appt_show') / days)} </td> */}

                            <td className="text-center align-middle"> {calculateTotal(soldData, 'total_sold').toLocaleString()} </td>
                            <td className="text-center align-middle">
                                {Math.ceil(calculateTotal(soldBudgetData, 'total_sold') * (1 + trendSoldTotal / 100))}
                            </td>
                            <td className="text-center align-middle"> {calculateTotal(soldBudgetData, 'total_sold').toLocaleString()} </td>
                            {/* <td className="text-center align-middle"> {calculateKPIPercentage(calculateTotal(soldData, 'total_sold'), calculateTotal(soldBudgetData, 'total_sold'))} </td> */}
                            <td className="align-middle" style={{ fontWeight: 500, textAlign: "center", position: "relative" }}>
                                <div className="progress" style={{ height: "100%", width: "100%", position: "absolute", right: "0%", top: "0%", zIndex: "-1", borderRadius: 0 }}>
                                    <div
                                        className="progress-bar"
                                        role="progressbar"
                                        style={{
                                            backgroundColor: "#7CEF7C ",
                                            width: calculateKPIPercentage(calculateTotal(soldData, 'total_sold'), calculateTotal(soldBudgetData, 'total_sold'))
                                        }}
                                    ></div>
                                </div>
                                {calculateKPIPercentage(calculateTotal(soldData, 'total_sold'), calculateTotal(soldBudgetData, 'total_sold'))}
                            </td>
                            <td className="text-center align-middle">{trendSoldTotal}%{trendSoldTotal !== 0 ? Math.sign(trendSoldTotal) === -1 ? <span style={{ color: "#dc3545", fontSize: 18 }}>&#x2193;</span> : <span style={{ color: "#35dc66", fontSize: 18 }}>&#x2191;</span> : ""} </td>
                            {/* <td className="text-center align-middle">
                                {calculateKPIPercentage(
                                    calculateTotal(dailyData, 'total_sold'),
                                    calculateTotal(soldBudgetData, 'total_sold') / days)}
                            </td> */}

                            <td className="text-center align-middle"> {calculateTotal(gpData, 'total_gross_profit').toLocaleString("en-CA", {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                                style: "currency",
                                currency: "CAD"
                            })} </td>
                            <td className="text-center align-middle">
                                {Math.ceil(calculateTotal(gpBudgetData, 'total_gross_profit') * (1 + trendTotalTotal / 100))
                                    .toLocaleString("en-CA", {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                        style: "currency",
                                        currency: "CAD"
                                    })}
                            </td>
                            <td className="text-center align-middle"> {calculateTotal(gpBudgetData, 'total_gross_profit').toLocaleString("en-CA", {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                                style: "currency",
                                currency: "CAD"
                            })} </td>
                            {/* <td className="text-center align-middle"> {calculateKPIPercentage(calculateTotal(gpData, 'total_gross_profit'), calculateTotal(gpBudgetData, 'total_gross_profit'))} </td> */}
                            <td className="align-middle" style={{ fontWeight: 500, textAlign: "center", position: "relative" }}>
                                <div className="progress" style={{ height: "100%", width: "100%", position: "absolute", right: "0%", top: "0%", zIndex: "-1", borderRadius: 0 }}>
                                    <div
                                        className="progress-bar"
                                        role="progressbar"
                                        style={{
                                            backgroundColor: "#7CEF7C ",
                                            width: calculateKPIPercentage(calculateTotal(gpData, 'total_gross_profit'), calculateTotal(gpBudgetData, 'total_gross_profit'))
                                        }}
                                    ></div>
                                </div>
                                {calculateKPIPercentage(calculateTotal(gpData, 'total_gross_profit'), calculateTotal(gpBudgetData, 'total_gross_profit'))}
                            </td>
                            <td className="text-center align-middle">{trendTotalTotal}%{trendTotalTotal !== 0 ? Math.sign(trendTotalTotal) === -1 ? <span style={{ color: "#dc3545", fontSize: 18 }}>&#x2193;</span> : <span style={{ color: "#35dc66", fontSize: 18 }}>&#x2191;</span> : ""} </td>
                            {/* <td className="text-center align-middle">
                                {calculateKPIPercentage(
                                    calculateTotal(dailyData, 'total_gross_profit'),
                                    calculateTotal(gpBudgetData, 'total_gross_profit') / days)}
                            </td> */}

                            <td className="text-center align-middle">
                                {Math.round(calculateTotal(apptData, 'total_lead') / calculateTotal(soldData, 'total_sold'))}
                            </td>
                            <td className="text-center align-middle">
                                {calculateKPIPercentage(calculateTotal(soldData, 'total_sold'), calculateTotal(apptData, 'total_store_visit'),)}
                            </td>
                        </tr>
                    </tbody>
                </table>
            )}
        </>

    )
}


const StaffTable = ({  apptData, days,  day,staffKpiData }) => {
    const [stores, setStores] = useState([]);
    const [allDataKpi, setAllDataKpi] = useState([]);
    console.log(apptData)
    useEffect(() => {
        getStores();
        let storeObj = {};
        staffKpiData.length > 0 && staffKpiData?.map(i => {

            storeObj[i.store_id] = i;

        });
        setAllDataKpi(storeObj)
    }, []);

    const getStores = () => {
        axios
            .get(Links.getAllStores)
            .then((res) => {
                let all_stores_in_current_division;

                all_stores_in_current_division = res.data.stores

                setStores(all_stores_in_current_division);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const calculateTotal = (data, field) => {
        let total = 0;
        if (data) {
            data.forEach((item) => {
                total += item[field] ? parseFloat(item[field]) : 0;
            });
        }
        return total;
    };

    return (
        <>
            <h4>Staff Report:</h4>
            {(!stores || stores.length === 0) && (
                (<div className="p-4 d-flex justify-content-center" >
                    <img src={CarLoading} />
                </div>)
            )}

            {stores && stores.length > 0 && (
                <table className="mb-5 mt-3 table table-bordered border-black table-fit table-striped">
                    <TableHeadStaff type='dealership' />
                    <tbody>
                        {stores.map((store) => {
                             
                            const appt_current_store = apptData.find(item => item.store_id === store.id);
                    //  console.log(appt_current_store)
                       return (
                                <tr>
                                    <td className="text-capitalize"> {store.name} </td>
                                    <td className="text-center align-middle"> {appt_current_store?.sales_people || 0} </td>
                                    <td className="text-center align-middle">
                                    {appt_current_store?.sales_people_needed || 0}
                                    </td>
                                    <td className="text-center align-middle">
                                    {(allDataKpi[store.id]?.total_sold/appt_current_store?.sales_people || 0).toFixed(2)}
                                    </td>
                          
                                    <td className="text-center align-middle"> {appt_current_store?.service_no_of_tech || 0} </td>
                                    <td className="text-center align-middle"> {appt_current_store?.service_journeyman_needed || 0} </td>
                                    <td className="text-center align-middle"> {appt_current_store?.service_apprentice_needed || 0} </td>
                                    <td className="text-center align-middle"> {appt_current_store?.service_advisors_needed || 0} </td>
                                    <td className="text-center align-middle" >
                                    {(allDataKpi[store.id]?.service_hours_sold/allDataKpi[store.id]?.service_total_hours|| 0).toFixed(2)}
                                    </td>

                                </tr>
                            )
                        })}

                        <tr>
                            <td className="text-capitalize"> Total </td>
                            <td className="text-center align-middle"> {calculateTotal(apptData, 'sales_people').toLocaleString()} </td>
                        
                            <td className="text-center align-middle" >
                            {calculateTotal(apptData, 'sales_people_needed').toLocaleString()} 
                            </td>  
                            <td className="text-center align-middle">
                            {Math.ceil(calculateTotal(staffKpiData, 'total_sold')/calculateTotal(apptData, 'sales_people')||0)} 
                            </td>
                            <td className="text-center align-middle"> {calculateTotal(apptData, 'service_no_of_tech').toLocaleString()} </td>
                            <td className="text-center align-middle"> {calculateTotal(apptData, 'service_journeyman_needed').toLocaleString()} </td>
                            <td className="text-center align-middle"> {calculateTotal(apptData, 'service_apprentice_needed').toLocaleString()} </td>
                            <td className="text-center align-middle"> {calculateTotal(apptData, 'service_advisors_needed').toLocaleString()} </td>
                            <td className="text-center align-middle">
                            {Math.ceil(calculateTotal(staffKpiData, 'service_hours_sold')/calculateTotal(staffKpiData, 'service_total_hours')||0)} 
                            </td>
                        </tr>
                    </tbody>
                </table>
            )}
        </>

    )
}

export default function SummaryReport() {
    const divisions = ['Auto', 'Rec', 'Used'];
    const currentYear = moment().format("YYYY");
    const currentMonth = moment().format("MMM");

    const d = new Date();
    const [day, setDay] = useState(d.getDate());
    const [month, setMonth] = useState(currentMonth);
    const [year, setYear] = useState(currentYear);
    const [days, setDays] = useState(DAYS_IN_MONTH[currentMonth]);
    const [loading, setLoading] = useState(true);

    const [getnoOfHolidaysbyDate, setGetnoOfHolidaysbyDate] = useState(calculateTotalHolidaysbyDates(currentMonth, currentYear));
    const [getnoOfHolidaysbyMonth, setGetnoOfHolidaysbyMonth] = useState(calculateTotalHolidaysbyMonth(currentMonth, currentYear));

    const [totalSold, setTotalSold] = useState([]);
    const [totalSoldBudget, setTotalSoldBudget] = useState([]);
    const [totalAppt, setTotalAppt] = useState([]);
    const [totalApptBudget, setTotalApptBudget] = useState([]);
    const [grossProfit, setGrossProfit] = useState([]);
    const [grossProfitBudget, setGrossProfitBudget] = useState([]);
    const[allStaffData,setAllStaffData]=useState([]);
    const[staffKpiData,setStaffKpiData]=useState([]);

    const [totalSoldForRec, setTotalSoldForRec] = useState([]);
    const [totalSoldBudgetForRec, setTotalSoldBudgetForRec] = useState([]);
    const [totalApptForRec, setTotalApptForRec] = useState([]);
    const [totalApptBudgetForRec, setTotalApptBudgetForRec] = useState([]);
    const [grossProfitForRec, setGrossProfitForRec] = useState([]);
    const [grossProfitBudgetForRec, setGrossProfitBudgetForRec] = useState([]);

    const [totalSoldForUsed, setTotalSoldForUsed] = useState([]);
    const [totalSoldBudgetForUsed, setTotalSoldBudgetForUsed] = useState([]);
    const [totalApptForUsed, setTotalApptForUsed] = useState([]);
    const [totalApptBudgetForUsed, setTotalApptBudgetForUsed] = useState([]);
    const [grossProfitForUsed, setGrossProfitForUsed] = useState([]);
    const [grossProfitBudgetForUsed, setGrossProfitBudgetForUsed] = useState([]);

    // const [dailyDataForAuto, setDailyDataForAuto] = useState([]);
    // const [dailyDataForRec, setDailyDataForRec] = useState([]);
    // const [dailyDataForUsed, setDailyDataForUsed] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        getAutoData(year, month);
        getRecData(year, month);
        getUsedData(year, month);
        getStaffData(year, month);
        getVehicleDataforStaff(year, month);
        // getCurrentDayDailyData(year, month, day);
    }, []);

    {/* removed && dailyDataForAuto && dailyDataForRec && dailyDataForUsed from if condition and dependency */ }
    useEffect(() => {
        if (totalSold && totalSoldForRec && totalSoldForUsed) {
            setLoading(false)
        }
    }, [totalSold, totalSoldForRec, totalSoldForUsed])

    const getAutoData = (year, month) => {
        const stringifiedYear = getFiscals(month, year);
        const requestBody = {
            year: year,
            month: month,
            category: "All",
            divisions: ['Auto', 'Rec', 'Used'],
        };

        const requestBodyBudget = {
            fiscal_year: "FY" + stringifiedYear.toString().slice(-2),
            month: month,
            category: "All",
            divisions: ['Auto', 'Rec', 'Used'],
        };
        axios.all([
            axios.post(Links.getExecutiveReport, requestBody),
            axios.post(Links.getFilteredBudgetForExecutiveReport, requestBodyBudget)
        ])
            .then(axios.spread((data1, data2) => {
                setTotalSold(data1.data.totalSold);
                setTotalAppt(data1.data.totalAppt);
                setGrossProfit(data1.data.dailyDataThisMonth);
                setTotalSoldBudget(data2.data.totalBudgetSold);
                setTotalApptBudget(data2.data.totalBudgetAppt);
                setGrossProfitBudget(data2.data.budgetThisMonth);
            }))
            .catch((err) => {
                console.log(err);
            });
    };

    const getRecData = (year, month) => {
        const stringifiedYear = getFiscals(month, year);
        const requestBody = {
            year: year,
            month: month,
            category: "All",
            divisions: ['Auto', 'Rec', 'Used'],
        };

        const requestBodyBudget = {
            fiscal_year: "FY" + stringifiedYear.toString().slice(-2),
            month: month,
            category: "All",
            divisions: ['Auto', 'Rec', 'Used'],
        };
        axios.all([
            axios.post(Links.getExecutiveReportForRec, requestBody),
            axios.post(Links.getFilteredBudgetForExecutiveReportForRec, requestBodyBudget)
        ])
            .then(axios.spread((data1, data2) => {
                setTotalSoldForRec(data1.data.totalSold);
                setTotalApptForRec(data1.data.totalAppt);
                setGrossProfitForRec(data1.data.dailyDataThisMonth);
                setTotalSoldBudgetForRec(data2.data.totalBudgetSold);
                setTotalApptBudgetForRec(data2.data.totalBudgetAppt);
                setGrossProfitBudgetForRec(data2.data.budgetThisMonth);
            }))
            .catch((err) => {
                console.log(err);
            });
    };

    const getUsedData = (year, month) => {
        const stringifiedYear = getFiscals(month, year);
        const requestBody = {
            year: year,
            month: month,
            category: "All",
            divisions: ['Auto', 'Rec', 'Used'],
        };

        const requestBodyBudget = {
            fiscal_year: "FY" + stringifiedYear.toString().slice(-2),
            month: month,
            category: "All",
            divisions: ['Auto', 'Rec', 'Used'],
        };
        axios.all([
            axios.post(Links.getExecutiveReportForUsed, requestBody),
            axios.post(Links.getFilteredBudgetForExecutiveReportForUsed, requestBodyBudget)
        ])
            .then(axios.spread((data1, data2) => {
                setTotalSoldForUsed(data1.data.totalSold);
                setTotalApptForUsed(data1.data.totalAppt);
                setGrossProfitForUsed(data1.data.dailyDataThisMonth);
                setTotalSoldBudgetForUsed(data2.data.totalBudgetSold);
                setTotalApptBudgetForUsed(data2.data.totalBudgetAppt);
                setGrossProfitBudgetForUsed(data2.data.budgetThisMonth);
            }))
            .catch((err) => {
                console.log(err);
            });
    };


    const getStaffData = (year, month) => {
        const requestBody = {
            year: year,
            month: month,
            category: "All",
            divisions: ['Auto', 'Rec', 'Used'],
        };

        axios.post(Links.getStaffSummary, requestBody)
            .then(res => {
                setAllStaffData(res.data.staffSummary)
            }).catch(err => {
                console.log(err);
            });
    };


    const getVehicleDataforStaff = (year, month) => {
        const requestBody = {
            year: year,
            month: month,
            category: "All",
            divisions: ['Auto', 'Rec', 'Used'],
        };

        axios.post(Links.getVehicleDataforStaff, requestBody)
            .then(res => {
                console.log("vehicle_total")
                console.log(res)
                console.log(res.data.totals)
                setStaffKpiData(res.data.totals)
            }).catch(err => {
                console.log(err);
            });
    };
    // const getCurrentDayDailyData = (year, month, day) => {
    //     const requestBody = {
    //         year: year,
    //         month: month,
    //         day: day
    //     };
    //     axios.all([
    //         axios.post(Links.getAllAutoDailyDataByDate, requestBody),
    //         axios.post(Links.getAllRecDailyDataByData, requestBody),
    //         axios.post(Links.getAllUsedDailyDataByDate, requestBody),
    //     ])
    //         .then(axios.spread((data1, data2, data3) => {
    //             setDailyDataForAuto(data1.data.dailydata);
    //             setDailyDataForRec(data2.data.dailydata);
    //             setDailyDataForUsed(data3.data.dailydata);
    //         }))
    //         .catch((err) => {
    //             console.log(err);
    //         });
    // }

    const handleYearChange = (event) => {
        setYear(event.target.value);
    };

    const handleMonthChange = (event) => {
        setMonth(event.target.value);
    };

    const showResults = () => {
        setLoading(true);
        setDays(DAYS_IN_MONTH[month]);

        if (month == currentMonth && year == currentYear) {
            setDay(d.getDate());
        } else {
            const new_date = new Date(year, Object.values(MONTHS).indexOf(month) + 1, 0);
            setDay(new_date.getDate());
        }

        setGetnoOfHolidaysbyDate(calculateTotalHolidaysbyDates(month, year));
        setGetnoOfHolidaysbyMonth(calculateTotalHolidaysbyMonth(month, year));

        getAutoData(year, month);
        getRecData(year, month);
        getUsedData(year, month);
        getStaffData(year, month);
        getVehicleDataforStaff(year, month);
        // getCurrentDayDailyData(year, month, day);
    };

    const calculateTotal = (data, field) => {
        let total = 0;
        if (data) {
            data.forEach((item) => {
                total += item[field] ? parseFloat(item[field]) : 0;
            });
        }
        return total;
    };

    const EnterpriseTable = ({ days, getnoOfHolidaysbyDate, getnoOfHolidaysbyMonth, day }) => {
        const total_apptData = [...totalAppt, ...totalApptForRec, ...totalApptForUsed];
        const total_apptBudgetData = [...totalApptBudget, ...totalApptBudgetForRec, ...totalApptBudgetForUsed];
        const total_soldData = [...totalSold, ...totalSoldForRec, ...totalSoldForUsed];
        const total_soldBudgetData = [...totalSoldBudget, ...totalSoldBudgetForRec, ...totalSoldBudgetForUsed];
        // const total_dailyData = [...dailyDataForAuto, ...dailyDataForRec, ...dailyDataForUsed];
        const total_gpData = [...grossProfit, ...grossProfitForRec, ...grossProfitForUsed];
        const total_gpBudgetData = [...grossProfitBudget, ...grossProfitBudgetForRec, ...grossProfitBudgetForUsed];
        const trendLeadsTotal = calculateTrend(calculateTotal(total_apptData, 'total_lead'), calculateTotal(total_apptBudgetData, 'lead'), days, getnoOfHolidaysbyDate, getnoOfHolidaysbyMonth, day);
        const trendApptTotal = calculateTrend(calculateTotal(total_apptData, 'total_appt_show'), calculateTotal(total_apptBudgetData, 'appt_show'), days, getnoOfHolidaysbyDate, getnoOfHolidaysbyMonth, day);
        const trendSoldTotal = calculateTrend(calculateTotal(total_soldData, 'total_sold'), calculateTotal(total_soldBudgetData, 'total_sold'), days, getnoOfHolidaysbyDate, getnoOfHolidaysbyMonth, day);
        const trendTotalTotal = calculateTrend(calculateTotal(total_gpData, 'total_gross_profit'), calculateTotal(total_gpBudgetData, 'total_gross_profit'), days, getnoOfHolidaysbyDate, getnoOfHolidaysbyMonth, day);
        return (
            <>
                <h4>Enterprise Summary:</h4>
                <table className="mb-5 mt-3 table table-bordered border-black table-fit table-striped">
                    <TableHead type='enterprise' />
                    <tbody>
                        {divisions.map((division) => {
                            let apptData, apptBudgetData, soldData, soldBudgetData, gpData, gpBudgetData;
                            switch (division) {
                                case 'Auto':
                                    apptData = totalAppt;
                                    apptBudgetData = totalApptBudget;
                                    soldData = totalSold;
                                    soldBudgetData = totalSoldBudget;
                                    gpData = grossProfit;
                                    gpBudgetData = grossProfitBudget;
                                    // dailyData = dailyDataForAuto;
                                    break;
                                case 'Rec':
                                    apptData = totalApptForRec;
                                    apptBudgetData = totalApptBudgetForRec;
                                    soldData = totalSoldForRec;
                                    soldBudgetData = totalSoldBudgetForRec;
                                    gpData = grossProfitForRec;
                                    gpBudgetData = grossProfitBudgetForRec;
                                    // dailyData = dailyDataForRec;
                                    break;
                                case 'Used':
                                    apptData = totalApptForUsed;
                                    apptBudgetData = totalApptBudgetForUsed;
                                    soldData = totalSoldForUsed;
                                    soldBudgetData = totalSoldBudgetForUsed;
                                    gpData = grossProfitForUsed;
                                    gpBudgetData = grossProfitBudgetForUsed;
                                    // dailyData = dailyDataForUsed;
                                    break;
                                default:
                            }
                            const trendLeads = calculateTrend(calculateTotal(apptData, 'total_lead'), calculateTotal(apptBudgetData, 'lead'), days, getnoOfHolidaysbyDate, getnoOfHolidaysbyMonth, day);
                            const trendAppt = calculateTrend(calculateTotal(apptData, 'total_appt_show'), calculateTotal(apptBudgetData, 'appt_show'), days, getnoOfHolidaysbyDate, getnoOfHolidaysbyMonth, day);
                            const trendSold = calculateTrend(calculateTotal(soldData, 'total_sold'), calculateTotal(soldBudgetData, 'total_sold'), days, getnoOfHolidaysbyDate, getnoOfHolidaysbyMonth, day);
                            const trendTotal = calculateTrend(calculateTotal(gpData, 'total_gross_profit'), calculateTotal(gpBudgetData, 'total_gross_profit'), days, getnoOfHolidaysbyDate, getnoOfHolidaysbyMonth, day);
                            return (
                                <tr>
                                    <td className="text-capitalize"> {division} </td>
                                    <td className="text-center align-middle"> {calculateTotal(apptData, 'total_lead')} </td>
                                    <td className="text-center align-middle">
                                        {Math.ceil(calculateTotal(apptBudgetData, 'lead') * (1 + trendLeads / 100))}
                                    </td>
                                    <td className="text-center align-middle"> {calculateTotal(apptBudgetData, 'lead')} </td>
                                    {/* <td className="text-center align-middle">
                                        {calculateKPIPercentage(calculateTotal(apptData, 'total_lead'),
                                            calculateTotal(apptBudgetData, 'lead'))}
                                    </td> */}
                                    <td className="align-middle" style={{ fontWeight: 500, textAlign: "center", position: "relative" }}>
                                        <div className="progress" style={{ height: "100%", width: "100%", position: "absolute", right: "0%", top: "0%", zIndex: "-1", borderRadius: 0 }}>
                                            <div
                                                className="progress-bar"
                                                role="progressbar"
                                                style={{
                                                    backgroundColor: "#7CEF7C ",
                                                    width: calculateKPIPercentage(calculateTotal(apptData, 'total_lead'), calculateTotal(apptBudgetData, 'lead'))
                                                }}
                                            ></div>
                                        </div>
                                        {calculateKPIPercentage(calculateTotal(apptData, 'total_lead'), calculateTotal(apptBudgetData, 'lead'))}
                                    </td>
                                    <td className="text-center align-middle">
                                        {trendLeads}%{trendLeads !== 0 ?
                                            Math.sign(trendLeads) === -1 ? <span style={{ color: "#dc3545", fontSize: 18 }}>&#x2193;</span>
                                                : <span style={{ color: "#35dc66", fontSize: 18 }}>&#x2191;</span>
                                            : ""}
                                    </td>

                                    {/* <td className="text-center align-middle">
                                        {calculateKPIPercentage(
                                            calculateTotal(dailyData, 'lead'),
                                            calculateTotal(apptBudgetData, 'lead') / days)}
                                    </td> */}

                                    <td className="text-center align-middle"> {calculateTotal(apptData, 'total_appt_show')} </td>
                                    <td className="text-center align-middle">
                                        {Math.ceil(calculateTotal(apptBudgetData, 'appt_show') * (1 + trendAppt / 100))}
                                    </td>
                                    <td className="text-center align-middle"> {calculateTotal(apptBudgetData, 'appt_show')} </td>
                                    {/* <td className="text-center align-middle">
                                        {calculateKPIPercentage(calculateTotal(apptData, 'total_appt_show'),
                                            calculateTotal(apptBudgetData, 'appt_show'))}
                                    </td> */}
                                    <td className="align-middle" style={{ fontWeight: 500, textAlign: "center", position: "relative" }}>
                                        <div className="progress" style={{ height: "100%", width: "100%", position: "absolute", right: "0%", top: "0%", zIndex: "-1", borderRadius: 0 }}>
                                            <div
                                                className="progress-bar"
                                                role="progressbar"
                                                style={{
                                                    backgroundColor: "#7CEF7C ",
                                                    width: calculateKPIPercentage(calculateTotal(apptData, 'total_appt_show'), calculateTotal(apptBudgetData, 'appt_show'))
                                                }}
                                            ></div>
                                        </div>
                                        {calculateKPIPercentage(calculateTotal(apptData, 'total_appt_show'), calculateTotal(apptBudgetData, 'appt_show'))}
                                    </td>
                                    <td className="text-center align-middle">
                                        {trendAppt}%{trendAppt !== 0 ?
                                            Math.sign(trendAppt) === -1 ? <span style={{ color: "#dc3545", fontSize: 18 }}>&#x2193;</span>
                                                : <span style={{ color: "#35dc66", fontSize: 18 }}>&#x2191;</span>
                                            : ""}
                                    </td>

                                    {/* <td className="text-center align-middle">
                                        {calculateKPIPercentage(
                                            calculateTotal(dailyData, 'appt_show'),
                                            calculateTotal(apptBudgetData, 'appt_show') / days)}
                                    </td> */}

                                    <td className="text-center align-middle"> {calculateTotal(soldData, 'total_sold')} </td>
                                    <td className="text-center align-middle">
                                        {Math.ceil(calculateTotal(soldBudgetData, 'total_sold') * (1 + trendSold / 100))}
                                    </td>
                                    <td className="text-center align-middle"> {calculateTotal(soldBudgetData, 'total_sold')} </td>
                                    {/* <td className="text-center align-middle">
                                        {calculateKPIPercentage(calculateTotal(soldData, 'total_sold'),
                                            calculateTotal(soldBudgetData, 'total_sold'))}
                                    </td> */}
                                    <td className="align-middle" style={{ fontWeight: 500, textAlign: "center", position: "relative" }}>
                                        <div className="progress" style={{ height: "100%", width: "100%", position: "absolute", right: "0%", top: "0%", zIndex: "-1", borderRadius: 0 }}>
                                            <div
                                                className="progress-bar"
                                                role="progressbar"
                                                style={{
                                                    backgroundColor: "#7CEF7C ",
                                                    width: calculateKPIPercentage(calculateTotal(soldData, 'total_sold'), calculateTotal(soldBudgetData, 'total_sold'))
                                                }}
                                            ></div>
                                        </div>
                                        {calculateKPIPercentage(calculateTotal(soldData, 'total_sold'), calculateTotal(soldBudgetData, 'total_sold'))}
                                    </td>

                                    <td className="text-center align-middle">
                                        {trendSold}%{trendSold !== 0 ?
                                            Math.sign(trendSold) === -1 ? <span style={{ color: "#dc3545", fontSize: 18 }}>&#x2193;</span>
                                                : <span style={{ color: "#35dc66", fontSize: 18 }}>&#x2191;</span>
                                            : ""}
                                    </td>

                                    {/* <td className="text-center align-middle">
                                        {calculateKPIPercentage(
                                            calculateTotal(dailyData, 'total_sold'),
                                            calculateTotal(soldBudgetData, 'total_sold') / days)}
                                    </td> */}

                                    <td className="text-center align-middle">
                                        {calculateTotal(gpData, 'total_gross_profit').toLocaleString("en-CA", {
                                            minimumFractionDigits: 0,
                                            maximumFractionDigits: 0,
                                            style: "currency",
                                            currency: "CAD"
                                        })} </td>
                                    <td className="text-center align-middle">
                                        {Math.ceil(calculateTotal(gpBudgetData, 'total_gross_profit') * (1 + trendTotal / 100))
                                            .toLocaleString("en-CA", {
                                                minimumFractionDigits: 0,
                                                maximumFractionDigits: 0,
                                                style: "currency",
                                                currency: "CAD"
                                            })}
                                    </td>
                                    <td className="text-center align-middle">
                                        {calculateTotal(gpBudgetData, 'total_gross_profit').toLocaleString("en-CA", {
                                            minimumFractionDigits: 0,
                                            maximumFractionDigits: 0,
                                            style: "currency",
                                            currency: "CAD"
                                        })} </td>
                                    {/* <td className="text-center align-middle">
                                        {calculateKPIPercentage(calculateTotal(gpData, 'total_gross_profit'),
                                            calculateTotal(gpBudgetData, 'total_gross_profit'))}
                                    </td> */}
                                    <td className="align-middle" style={{ fontWeight: 500, textAlign: "center", position: "relative" }}>
                                        <div className="progress" style={{ height: "100%", width: "100%", position: "absolute", right: "0%", top: "0%", zIndex: "-1", borderRadius: 0 }}>
                                            <div
                                                className="progress-bar"
                                                role="progressbar"
                                                style={{
                                                    backgroundColor: "#7CEF7C ",
                                                    width: calculateKPIPercentage(calculateTotal(gpData, 'total_gross_profit'), calculateTotal(gpBudgetData, 'total_gross_profit'))
                                                }}
                                            ></div>
                                        </div>
                                        {calculateKPIPercentage(calculateTotal(gpData, 'total_gross_profit'), calculateTotal(gpBudgetData, 'total_gross_profit'))}
                                    </td>
                                    <td className="text-center align-middle">
                                        {trendTotal}%{trendTotal !== 0 ?
                                            Math.sign(trendTotal) === -1 ? <span style={{ color: "#dc3545", fontSize: 18 }}>&#x2193;</span>
                                                : <span style={{ color: "#35dc66", fontSize: 18 }}>&#x2191;</span>
                                            : ""}
                                    </td>

                                    {/* <td className="text-center align-middle">
                                        {calculateKPIPercentage(
                                            calculateTotal(dailyData, 'total_gross_profit'),
                                            calculateTotal(gpBudgetData, 'total_gross_profit') / days)}
                                    </td> */}

                                    <td className="text-center align-middle">
                                        {calculateTotal(soldData, 'total_sold') ?
                                            Math.round(calculateTotal(apptData, 'total_lead') / calculateTotal(soldData, 'total_sold'))
                                            : 0}
                                    </td>
                                    <td className="text-center align-middle">
                                        {calculateKPIPercentage(
                                            calculateTotal(soldData, 'total_sold'),
                                            calculateTotal(apptData, 'total_store_visit'))}
                                    </td>
                                </tr>
                            )
                        })}

                        <tr>
                            <td className="text-capitalize"> Total </td>
                            <td className="text-center align-middle"> {calculateTotal(total_apptData, 'total_lead').toLocaleString()} </td>
                            <td className="text-center align-middle">
                                {Math.ceil(calculateTotal(total_apptBudgetData, 'lead') * (1 + trendLeadsTotal / 100))}
                            </td>
                            <td className="text-center align-middle"> {calculateTotal(total_apptBudgetData, 'lead').toLocaleString()} </td>
                            {/* <td className="text-center align-middle">
                                {calculateKPIPercentage(
                                    calculateTotal(total_apptData, 'total_lead'),
                                    calculateTotal(total_apptBudgetData, 'lead'))}
                            </td> */}
                            <td className="align-middle" style={{ fontWeight: 500, textAlign: "center", position: "relative" }}>
                                <div className="progress" style={{ height: "100%", width: "100%", position: "absolute", right: "0%", top: "0%", zIndex: "-1", borderRadius: 0 }}>
                                    <div
                                        className="progress-bar"
                                        role="progressbar"
                                        style={{
                                            backgroundColor: "#7CEF7C ",
                                            width: calculateKPIPercentage(calculateTotal(total_apptData, 'total_lead'), calculateTotal(total_apptBudgetData, 'lead'))
                                        }}
                                    ></div>
                                </div>
                                {calculateKPIPercentage(
                                    calculateTotal(total_apptData, 'total_lead'),
                                    calculateTotal(total_apptBudgetData, 'lead'))}
                            </td>
                            <td className="text-center align-middle">
                                {trendLeadsTotal}%{trendLeadsTotal !== 0 ?
                                    Math.sign(trendLeadsTotal) === -1 ? <span style={{ color: "#dc3545", fontSize: 18 }}>&#x2193;</span>
                                        : <span style={{ color: "#35dc66", fontSize: 18 }}>&#x2191;</span>
                                    : ""}
                            </td>

                            {/* <td className="text-center align-middle">
                                {calculateKPIPercentage(
                                    calculateTotal(total_dailyData, 'lead'),
                                    calculateTotal(total_apptBudgetData, 'lead') / days)}
                            </td> */}

                            <td className="text-center align-middle"> {calculateTotal(total_apptData, 'total_appt_show').toLocaleString()} </td>
                            <td className="text-center align-middle">
                                {Math.ceil(calculateTotal(total_apptBudgetData, 'appt_show') * (1 + trendApptTotal / 100))}
                            </td>
                            <td className="text-center align-middle"> {calculateTotal(total_apptBudgetData, 'appt_show').toLocaleString()} </td>
                            {/* <td className="text-center align-middle">
                                {calculateKPIPercentage(calculateTotal(total_apptData, 'total_appt_show'),
                                    calculateTotal(total_apptBudgetData, 'appt_show'))}
                            </td> */}
                            <td className="align-middle" style={{ fontWeight: 500, textAlign: "center", position: "relative" }}>
                                <div className="progress" style={{ height: "100%", width: "100%", position: "absolute", right: "0%", top: "0%", zIndex: "-1", borderRadius: 0 }}>
                                    <div
                                        className="progress-bar"
                                        role="progressbar"
                                        style={{
                                            backgroundColor: "#7CEF7C ",
                                            width: calculateKPIPercentage(calculateTotal(total_apptData, 'total_appt_show'), calculateTotal(total_apptBudgetData, 'appt_show'))
                                        }}
                                    ></div>
                                </div>
                                {calculateKPIPercentage(calculateTotal(total_apptData, 'total_appt_show'),
                                    calculateTotal(total_apptBudgetData, 'appt_show'))}
                            </td>
                            <td className="text-center align-middle">
                                {trendApptTotal}%{trendApptTotal !== 0 ? Math.sign(trendApptTotal) === -1 ?
                                    <span style={{ color: "#dc3545", fontSize: 18 }}>&#x2193;</span>
                                    : <span style={{ color: "#35dc66", fontSize: 18 }}>&#x2191;</span>
                                    : ""}
                            </td>

                            {/* <td className="text-center align-middle">
                                {calculateKPIPercentage(
                                    calculateTotal(total_dailyData, 'appt_show'),
                                    calculateTotal(total_apptBudgetData, 'appt_show') / days)}
                            </td> */}

                            <td className="text-center align-middle"> {calculateTotal(total_soldData, 'total_sold').toLocaleString()} </td>
                            <td className="text-center align-middle">
                                {Math.ceil(calculateTotal(total_soldBudgetData, 'total_sold') * (1 + trendSoldTotal / 100))}
                            </td>
                            <td className="text-center align-middle"> {calculateTotal(total_soldBudgetData, 'total_sold').toLocaleString()} </td>
                            {/* <td className="text-center align-middle">
                                {calculateKPIPercentage(
                                    calculateTotal(total_soldData, 'total_sold'),
                                    calculateTotal(total_soldBudgetData, 'total_sold'))}
                            </td> */}
                            <td className="align-middle" style={{ fontWeight: 500, textAlign: "center", position: "relative" }}>
                                <div className="progress" style={{ height: "100%", width: "100%", position: "absolute", right: "0%", top: "0%", zIndex: "-1", borderRadius: 0 }}>
                                    <div
                                        className="progress-bar"
                                        role="progressbar"
                                        style={{
                                            backgroundColor: "#7CEF7C ",
                                            width: calculateKPIPercentage(calculateTotal(total_soldData, 'total_sold'), calculateTotal(total_soldBudgetData, 'total_sold'))
                                        }}
                                    ></div>
                                </div>
                                {calculateKPIPercentage(
                                    calculateTotal(total_soldData, 'total_sold'),
                                    calculateTotal(total_soldBudgetData, 'total_sold'))}
                            </td>
                            <td className="text-center align-middle">
                                {trendSoldTotal}%{trendSoldTotal !== 0 ?
                                    Math.sign(trendSoldTotal) === -1 ? <span style={{ color: "#dc3545", fontSize: 18 }}>&#x2193;</span>
                                        : <span style={{ color: "#35dc66", fontSize: 18 }}>&#x2191;</span>
                                    : ""}
                            </td>

                            {/* <td className="text-center align-middle">
                                {calculateKPIPercentage(
                                    calculateTotal(total_dailyData, 'total_sold'),
                                    calculateTotal(total_soldBudgetData, 'total_sold') / days)}
                            </td> */}

                            <td className="text-center align-middle">
                                {calculateTotal(total_gpData, 'total_gross_profit').toLocaleString("en-CA", {
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 0,
                                    style: "currency",
                                    currency: "CAD"
                                })} </td>
                            <td className="text-center align-middle">
                                {Math.ceil(calculateTotal(total_gpBudgetData, 'total_gross_profit') * (1 + trendTotalTotal / 100))
                                    .toLocaleString("en-CA", {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                        style: "currency",
                                        currency: "CAD"
                                    })}
                            </td>
                            <td className="text-center align-middle">
                                {calculateTotal(total_gpBudgetData, 'total_gross_profit').toLocaleString("en-CA", {
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 0,
                                    style: "currency",
                                    currency: "CAD"
                                })} </td>
                            {/* <td className="text-center align-middle">
                                {calculateKPIPercentage(calculateTotal(total_gpData, 'total_gross_profit'),
                                    calculateTotal(total_gpBudgetData, 'total_gross_profit'))}
                            </td> */}
                            <td className="align-middle" style={{ fontWeight: 500, textAlign: "center", position: "relative" }}>
                                <div className="progress" style={{ height: "100%", width: "100%", position: "absolute", right: "0%", top: "0%", zIndex: "-1", borderRadius: 0 }}>
                                    <div
                                        className="progress-bar"
                                        role="progressbar"
                                        style={{
                                            backgroundColor: "#7CEF7C ",
                                            width: calculateKPIPercentage(calculateTotal(total_gpData, 'total_gross_profit'), calculateTotal(total_gpBudgetData, 'total_gross_profit'))
                                        }}
                                    ></div>
                                </div>
                                {calculateKPIPercentage(
                                    calculateTotal(total_gpData, 'total_gross_profit'),
                                    calculateTotal(total_gpBudgetData, 'total_gross_profit'))}
                            </td>
                            <td className="text-center align-middle">
                                {trendTotalTotal}%{trendTotalTotal !== 0 ?
                                    Math.sign(trendTotalTotal) === -1 ? <span style={{ color: "#dc3545", fontSize: 18 }}>&#x2193;</span>
                                        : <span style={{ color: "#35dc66", fontSize: 18 }}>&#x2191;</span>
                                    : ""}
                            </td>

                            {/* <td className="text-center align-middle">
                                {calculateKPIPercentage(
                                    calculateTotal(total_dailyData, 'total_gross_profit'),
                                    calculateTotal(total_gpBudgetData, 'total_gross_profit') / days)}
                            </td> */}

                            <td className="text-center align-middle">
                                {calculateTotal(total_soldData, 'total_sold') ?
                                    Math.round(calculateTotal(total_apptData, 'total_lead') / calculateTotal(total_soldData, 'total_sold'))
                                    : 0}
                            </td>
                            <td className="text-center align-middle">
                                {calculateKPIPercentage(
                                    calculateTotal(total_soldData, 'total_sold'),
                                    calculateTotal(total_apptData, 'total_store_visit'))}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </>
        )
    }


    return (
        <div style={{ marginTop: "3rem", width: "100%" }}>
            <Grid container spacing={2}>
                <Grid item container spacing={1}>
                    <Grid item xs={10} sm={6} md={2} xl={1} spacing={1}>
                        <FormControl variant="standard" sx={{ m: 1, width: "100%" }}>
                            <InputLabel id="demo-simple-select-label">Year</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={year}
                                label="Year"
                                onChange={handleYearChange}
                            >
                                {YEARS.map((year, index) => (
                                    <MenuItem key={index} value={year}>
                                        {year}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={10} sm={6} md={2} xl={1} spacing={1}>
                        <FormControl variant="standard" sx={{ m: 1, width: "100%" }}>
                            <InputLabel id="demo-simple-select-label">Month</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={month}
                                label="Age"
                                onChange={handleMonthChange}
                            >
                                {Object.values(MONTHS).map((month, index) => (
                                    <MenuItem key={index} value={month}>
                                        {month}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item className="mt-3">
                        <button
                            className="p-2 mt-1 btn btn-secondary mx-3"
                            type="submit"
                            onClick={() => {
                                showResults();
                            }}
                        >
                            Show Details
                        </button>
                    </Grid>
                </Grid>

                <Grid item container xs={12}>
                    <Grid item xs={10}></Grid>
                    <Grid item xs={6}>
                        {loading ? (
                            <div style={{ marginTop: "3rem", right: "50%", top: "30%" }} className="text-center align-middle position-absolute" >
                                <img src={CarLoading} />
                            </div>

                        ) : (
                            <>
                                {/* removed dailydata param  */}
                                <EnterpriseTable days={days} day={day} getnoOfHolidaysbyDate={getnoOfHolidaysbyDate} getnoOfHolidaysbyMonth={getnoOfHolidaysbyMonth} />
                                <DealershipTable division='Auto' apptData={totalAppt} apptBudgetData={totalApptBudget} soldData={totalSold} soldBudgetData={totalSoldBudget} gpData={grossProfit} gpBudgetData={grossProfitBudget} days={days} day={day} getnoOfHolidaysbyDate={getnoOfHolidaysbyDate} getnoOfHolidaysbyMonth={getnoOfHolidaysbyMonth} />
                                <DealershipTable division='Rec' apptData={totalApptForRec} apptBudgetData={totalApptBudgetForRec} soldData={totalSoldForRec} soldBudgetData={totalSoldBudgetForRec} gpData={grossProfitForRec} gpBudgetData={grossProfitBudgetForRec} days={days} day={day} getnoOfHolidaysbyDate={getnoOfHolidaysbyDate} getnoOfHolidaysbyMonth={getnoOfHolidaysbyMonth} />
                                <DealershipTable division='Used' apptData={totalApptForUsed} apptBudgetData={totalApptBudgetForUsed} soldData={totalSoldForUsed} soldBudgetData={totalSoldBudgetForUsed} gpData={grossProfitForUsed} gpBudgetData={grossProfitBudgetForUsed} days={days} day={day} getnoOfHolidaysbyDate={getnoOfHolidaysbyDate} getnoOfHolidaysbyMonth={getnoOfHolidaysbyMonth} />

                                <StaffTable division='Auto' staffKpiData={staffKpiData} apptData={allStaffData} soldData={totalSold}  days={days} day={day}  />


                            </>
                        )}
                    </Grid>
                </Grid>

            </Grid>
        </div>
    );
}
