import React, { useState } from "react";
import { FileDrop } from 'react-file-drop';
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import './Demo.css';
import axios from "axios";
import Links from "../../Data/Links";

const divisionOption = [
    'Auto',
    'Drive Nation',
    'FFUN Cars',
    'Carooga',
    'Village',
    'Rec',
    'FFUN Financial'
];

const BudgetFileDrop = (props) => {
    const [status, setStatus] = useState('');
    const [division, setDivision] = useState('Auto');

    const onFileDrop = (files, event, division) => {
        setStatus('Hang tight, parsing the file...')
        var data = new FormData();
        data.append('datafile', files[0]);
        data.append('division', division);
        axios.post(Links.uploadBudgetFile, data)
            .then(res => {
                setStatus(res.data)
            }).catch(err => {
                setStatus(err)
                console.log(err);
            });
    }

    const handleChange = (event) => {
        setDivision(event.target.value);
    }

    return (
        <div style={{ margin: "2rem 2rem 2rem 0rem" }}>
            <div className="row">
                <div className="col-3">
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label" className="pt-2">Select Division</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={division}
                            label="Division"
                            onChange={(e) => handleChange(e)}
                        >
                            {divisionOption.map(child => (
                                <MenuItem value={child}>{child}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
                <div className="mt-3">
                    <h6>
                        <a href={"csv/" + division.replace(/\s/g, '').toLowerCase() + "Sample.csv"} download>Click here</a> to download the Budget Data-template for {division}.
                    </h6>
                </div>
            </div>
            <div className="row mt-5">
                <h4> Please <span style={{ "color": 'orange' }}> DRAG & DROP </span>the budget data in CSV file format. </h4>
                <div className="file-drop-border">
                    <FileDrop onDrop={(files, event) => onFileDrop(files, event, division)}>
                    </FileDrop>
                </div>
                <h5 className="mt-2">Status : {status}</h5>
            </div>
        </div>
    )
};

export default BudgetFileDrop;