import React, { useState, useEffect } from "react";
import axios from 'axios';
import Cookies from 'js-cookie';
import Links from "../../Data/Links";
import CreateRowForUsed from "../../component/DataEntry/CreateRowForUsed";
import TotalRowForUsed from "../../component/DataEntry/TotalRowForUsed";
import monthData from "../../constants/monthData";
import dayData from "../../constants/dayData";
import KPIRowForUsed from "../../component/DataEntry/KPIRowForUsed";
import THForUsed from "../../component/DataEntry/THForUsed";
import DealerYearMonthSelector from "../../component/DataEntry/DealerYearMonthSelector";
import ForecastRowForUsed from "../../component/DataEntry/ForecastRowForUsed";
import { connect } from 'react-redux';
import { store, updateDataEntryRow } from "../../redux";
import getFiscals from '../../utils/getFiscals';

const DataEntryFc = ({ props, editDay }) => {
  const [stores, setStores] = useState(localStorage.getItem('stores') ? JSON.parse(localStorage.getItem('stores')) : "");
  const [fcData, setFcData] = useState({});
  const [budget, setBudget] = useState({});
  const [filterVal, setFilterVal] = useState({
    store_id: 46,
    store_name: 'Saskatoon',
    division: "FFUN Cars",
    company: 'FFUN Cars',
    month: monthData[new Date().getMonth()],
    year: new Date().getFullYear(),
    fiscal_year: 'FY' + getFiscals(monthData[new Date().getMonth()], new Date().getFullYear()).toString().substring(2),
  });

  let userData;
  if (Cookies.get('user_data')) {
    userData = JSON.parse(Cookies.get('user_data'));
  }

  useEffect(() => {
    let storeObj = {};
    stores.length > 0 && stores.map(i => {
      if (i.division === "FFUN Cars") {
        storeObj[i.id] = i.name;
      }
    });
    setFilterVal({
      store_id: Object.keys(storeObj)[0],
      store_name: Object.values(storeObj)[0],
      division: "FFUN Cars",
      company: 'FFUN Cars',
      month: monthData[new Date().getMonth()],
      year: new Date().getFullYear(),
      fiscal_year: 'FY' + getFiscals(monthData[new Date().getMonth()], new Date().getFullYear()).toString().substring(2),
    })
    setStores(storeObj);
    window.scrollTo(0, 0)
  }, []);

  useEffect(() => {
    loadData();
  }, [filterVal])

  const loadData = (filterData = null, day) => {
    filterVal.fiscal_year = 'FY' + getFiscals(filterVal.month, filterVal.year).toString().substring(2)
    axios.post(Links.getFilteredUsedData, filterVal)
      .then(res => {
        // store.dispatch(updateData(res.data.dailydata));
        setFcData(res.data.dailydata);
        if (parseInt(day) > 0) {
          store.dispatch(updateDataEntryRow({ ...filterVal, ...res.data.dailydata[day], 'day': day }));
        }
        if (parseInt(day) > 0) {
          store.dispatch(updateDataEntryRow({ ...filterVal, ...res.data.dailydata[day], 'day': day }));
        }
        axios.post(Links.getBudgetForUsedDailydata, filterVal)
          .then(result => {
            setBudget(result.data.budget);
          }).catch(err => {
            console.log(err);
          });
      }).catch(err => {
        console.log(err);
      });
  }

  let total = {
    used_retail_delivered: 0,
    used_retail_front_GP: 0,
    used_retail_back_GP: 0,
    service_total_hours: 0,
    service_CP_hours: 0,
    service_CP_ROs: 0,
    service_total_GP: 0,
    service_hours_sold: 0,
    service_hours_available: 0,
    service_no_of_tech: 0,
    service_booked_hours: 0,
    service_units_shipped: 0,
    appt_made: 0,
    appt_show: 0,
    store_visit: 0,
    lead: 0,
    sales_staff: 0,
    booked: 0,
    no_show: 0,
    phone_up: 0,
    fresh_up: 0,
    internet_lead:0,
    service_journeyman:0,
    service_apprentice:0,
    service_advisors_needed:0
  };

  return (
    <div style={{ margin: "2rem 2rem 2rem 0rem" }}>

      <DealerYearMonthSelector stores={stores} filterVal={filterVal} setFilterVal={setFilterVal} loadData={() => loadData()} />

      <table class="table table-bordered border-black" style={{ marginTop: 30 }}>
        <THForUsed />
        <tbody>
          {
            fcData && dayData.map((i, n) => {
              total.used_retail_delivered = (fcData[i] && fcData[i].used_retail_delivered != '') ? total.used_retail_delivered + parseInt(fcData[i].used_retail_delivered) : total.used_retail_delivered;
              total.used_retail_front_GP = (fcData[i] && fcData[i].used_retail_front_GP != '') ? total.used_retail_front_GP + parseInt(fcData[i].used_retail_front_GP) : total.used_retail_front_GP;
              total.used_retail_back_GP = (fcData[i] && fcData[i].used_retail_back_GP != '') ? total.used_retail_back_GP + parseInt(fcData[i].used_retail_back_GP) : total.used_retail_back_GP;
              total.service_total_hours = (fcData[i] && fcData[i].service_total_hours != '') ? total.service_total_hours + parseInt(fcData[i].service_total_hours) : total.service_total_hours;
              total.service_CP_hours = (fcData[i] && fcData[i].service_CP_hours != '') ? total.service_CP_hours + parseInt(fcData[i].service_CP_hours) : total.service_CP_hours;
              total.service_CP_ROs = (fcData[i] && fcData[i].service_CP_ROs != '') ? total.service_CP_ROs + parseInt(fcData[i].service_CP_ROs) : total.service_CP_ROs;
              total.service_total_GP = (fcData[i] && fcData[i].service_total_GP != '') ? total.service_total_GP + parseInt(fcData[i].service_total_GP) : total.service_total_GP;
           
              total.service_hours_sold = (fcData[i] && fcData[i].service_hours_sold >0) ? total.service_hours_sold + parseInt(fcData[i].service_hours_sold) : total.service_hours_sold;
              total.service_hours_available = (fcData[i] && fcData[i].service_hours_available >0) ? total.service_hours_available + parseInt(fcData[i].service_hours_available) : total.service_hours_available;
              total.service_no_of_tech = (fcData[i] && fcData[i].service_no_of_tech >0) ? total.service_no_of_tech + parseInt(fcData[i].service_no_of_tech) : total.service_no_of_tech;
              total.service_booked_hours = (fcData[i] && fcData[i].service_booked_hours >0) ? total.service_booked_hours + parseInt(fcData[i].service_booked_hours) : total.service_booked_hours;
              total.service_units_shipped = (fcData[i] && fcData[i].service_units_shipped >0) ? total.service_units_shipped + parseInt(fcData[i].service_units_shipped) : total.service_units_shipped; 

              total.service_journeyman = (fcData[i] && fcData[i].service_journeyman > 0) ? total.service_journeyman + parseInt(fcData[i].service_journeyman) : total.service_journeyman;
              total.service_apprentice = (fcData[i] && fcData[i].service_apprentice > 0) ? total.service_apprentice + parseInt(fcData[i].service_apprentice) : total.service_apprentice;
              total.service_advisors_needed = (fcData[i] && fcData[i].service_advisors_needed > 0) ? total.service_advisors_needed + parseInt(fcData[i].service_advisors_needed) : total.service_advisors_needed;

           
           
              total.appt_made = (fcData[i] && fcData[i].appt_made != '') ? total.appt_made + parseInt(fcData[i].appt_made) : total.appt_made;
              total.appt_show = (fcData[i] && fcData[i].appt_show != '') ? total.appt_show + parseInt(fcData[i].appt_show) : total.appt_show;
              total.store_visit = (fcData[i] && fcData[i].store_visit != '') ? total.store_visit + parseInt(fcData[i].store_visit) : total.store_visit;

              total.internet_lead = (fcData[i] && fcData[i].internet_lead != '') ? total.internet_lead + parseInt(fcData[i].internet_lead) : total.internet_lead;


              total.sales_staff= (fcData[i] && fcData[i].sales_staff >0) ? total.sales_staff + parseInt(fcData[i].sales_staff) : total.sales_staff;
              total.booked= (fcData[i] && fcData[i].booked >0) ? total.booked + parseInt(fcData[i].booked) : total.booked;
              total.no_show= (fcData[i] && fcData[i].no_show >0) ? total.no_show + parseInt(fcData[i].no_show) : total.no_show;
              total.phone_up= (fcData[i] && fcData[i].phone_up>0) ? total.phone_up + parseInt(fcData[i].phone_up) : total.phone_up;
              total.fresh_up= (fcData[i] && fcData[i].fresh_up >0) ? total.fresh_up + parseInt(fcData[i].fresh_up) : total.fresh_up;
              total.lead = total.internet_lead + total.phone_up + total.fresh_up;

              return <CreateRowForUsed row={fcData} userData={userData} day={i} filterData={filterVal} loadData={loadData} editDay={editDay} />
            })
          }
          <TotalRowForUsed total={total} />
          <ForecastRowForUsed budget={budget} />
          <KPIRowForUsed total={total} budget={budget} />
        </tbody>
      </table>
    </div>
  );
};

const mapStateToProps = state => ({
  dataEntry: state.dataEntryBudget.dataEntry,
  editDay: state.currentEditDayUsed.currentEditDayUsed
  // totalOfRecdata: state.dataEntryBudget.total
});
export default connect(mapStateToProps)(DataEntryFc);

// const mapStateToProps = state => ({
//   fcdataEntry: state.dataEntryBudget.dataEntry,
// });

// export default connect(mapStateToProps)(DataEntryFc);
// export default DataEntryFc