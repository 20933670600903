import React, { useEffect, useState } from "react";
import moment from "moment";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { MONTHS, YEARS, DAYS_IN_MONTH } from "../../../src/constants.js";
import axios from "axios";
import Links from "../../../src/Data/Links.js";
import CarLoading from "../../images/487.gif";
import calculateKPIPercentage from "../../utils/calculateKPIPercentage.js";
import getFiscals from "../../utils/getFiscals.js";
import calculateTrend from "../../utils/calculateTrend.js";
import calculateTotalHolidaysbyDates from "../../utils/calculateTotalHolidaysbyDates.js";
import calculateTotalHolidaysbyMonth from "../../utils/calculateTotalHolidaysbyMonth.js";


const TableHeadStaff = ({ type }) => (
    <thead>
        <tr className="text-center align-middle">
            <th colSpan="1" className="dn-header-top" style={{ whiteSpace: "nowrap", backgroundColor: "orange" }}></th>
            <th colSpan="3" className="dn-header-top" style={{ whiteSpace: "nowrap", backgroundColor: "#A2C1A2" }}>Sales</th>
            <th colSpan="4" className="dn-header-top" style={{ whiteSpace: "nowrap", backgroundColor: "#b1d5dd" }}>Service</th>
            {/* <th colSpan="2" className="dn-header-top" style={{ whiteSpace: "nowrap", backgroundColor: "orange" }}></th> */}
        </tr>
        <tr className="text-center align-middle">

            {type === 'dealership' ? (
                <th colSpan="1" className="dn-header" style={{ whiteSpace: "nowrap", backgroundColor: "orange" }}>
                    Store Name
                </th>
            ) : (
                <th colSpan="1" className="dn-header" style={{ whiteSpace: "nowrap", backgroundColor: "orange" }} >
                    Division
                </th>
            )}
            <th className="dn-header" style={{ whiteSpace: "nowrap", backgroundColor: "#A2C1A2" }}>
                No of Staff
            </th>
            <th className="dn-header" style={{ whiteSpace: "nowrap", backgroundColor: "#A2C1A2" }} >
                Staff Needed
            </th>
            <th className="dn-header" style={{ whiteSpace: "nowrap", backgroundColor: "#A2C1A2" }} >
                Sales Per Staff
            </th>

            <th className="dn-header" style={{ whiteSpace: "nowrap", backgroundColor: "#b1d5dd" }} >
                No of Technicians
            </th>
            <th className="dn-header" style={{ whiteSpace: "nowrap", backgroundColor: "#b1d5dd" }} >
                Journeyman Needed
            </th>

            <th className="dn-header" style={{ whiteSpace: "nowrap", backgroundColor: "#b1d5dd" }} >
                Apprentice Needed
            </th>
            <th className="dn-header" style={{ whiteSpace: "nowrap", backgroundColor: "#b1d5dd" }} >
                Advisors Needed
            </th>
            {/* <th className="dn-header" style={{ whiteSpace: "nowrap", backgroundColor: "#b1d5dd" }} >
                Tech Proficiency
            </th> */}

        </tr>
    </thead>
)


const ReturnFinite = (data) => {
    if (isFinite(data)) {

        return data;
    }
    else { return 0; }
}



const EnterpriseStaffTable = ({ division, apptData, days, day, staffKpiData }) => {
    const divisions = ['Auto', 'Rec', 'Used'];
    const [stores, setStores] = useState([]);
    const [allDataKpi, setAllDataKpi] = useState([]);

    useEffect(() => {
        getStores();
        let storeObj = {};
        staffKpiData.length > 0 && staffKpiData?.map(i => {

            storeObj[i.store_id] = i;

        });
        setAllDataKpi(storeObj)
    }, []);

    const getStores = () => {
        axios
            .get(Links.getAllStores)
            .then((res) => {
                let all_stores_in_current_division;


                all_stores_in_current_division = res.data.stores.filter(store => store.division === division)

                setStores(all_stores_in_current_division);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const calculateTotal = (data, field, division) => {

        let total = 0;
        if (data) {
            data.forEach((item) => {

                if ((item.division)?.toString() === division?.toString()) {

                    total += item[field] ? parseFloat(item[field]) : 0;
                }

            });
        }
        return total;
    };


    const calculateTotalAll = (data, field, division) => {

        let total = 0;
        if (data) {
            data.forEach((item) => {



                total += item[field] ? parseFloat(item[field]) : 0;


            });
        }
        return total;
    };

    return (
        <>
            <h4>Enterprise Staff Report:</h4>
            {/* {(!stores || stores.length === 0) && (
                (<div className="p-4 d-flex justify-content-center" >
                    <img src={CarLoading} />
                </div>)
            )} */}


            <table className="mb-5 mt-3 table table-bordered border-black table-fit table-striped">
                <TableHeadStaff type='dealership' />
                <tbody>
                    {divisions.map((division) => {

                        // const appt_current_store = apptData.find(item => item.store_id === store.id);

                        return (
                            <tr>
                                <td className="text-capitalize"> {division} </td>
                                <td className="text-center align-middle"> {calculateTotal(apptData, 'sales_people', division).toLocaleString()} </td>
                                <td className="text-center align-middle">
                                    {calculateTotal(apptData, 'sales_people_needed', division).toLocaleString()}
                                </td>
                                <td className="text-center align-middle">
                                    {ReturnFinite(Math.ceil(calculateTotal(staffKpiData, 'total_sold', division) / calculateTotal(apptData, 'sales_people', division) || 0))}


                                </td>

                                <td className="text-center align-middle">{calculateTotal(apptData, 'service_no_of_tech', division).toLocaleString()}</td>
                                <td className="text-center align-middle"> {calculateTotal(apptData, 'service_journeyman_needed', division).toLocaleString()} </td>
                                <td className="text-center align-middle"> {calculateTotal(apptData, 'service_apprentice_needed', division).toLocaleString()} </td>
                                <td className="text-center align-middle"> {calculateTotal(apptData, 'service_advisors_needed', division).toLocaleString()}  </td>
                                {/* <td className="text-center align-middle" >
                                    {Math.ceil(calculateTotal(staffKpiData, 'service_hours_sold',division)/calculateTotal(staffKpiData, 'service_total_hours',division)||0)}
                                    </td> */}

                            </tr>
                        )
                    })}

                    <tr>
                        <td className="text-capitalize"> Total </td>
                        <td className="text-center align-middle"> {calculateTotalAll(apptData, 'sales_people').toLocaleString()} </td>

                        <td className="text-center align-middle" >
                            {calculateTotalAll(apptData, 'sales_people_needed').toLocaleString()}
                        </td>
                        <td className="text-center align-middle">
                            {ReturnFinite(Math.ceil(calculateTotalAll(staffKpiData, 'total_sold', division) / calculateTotalAll(apptData, 'sales_people') || 0))}
                        </td>
                        <td className="text-center align-middle"> {calculateTotalAll(apptData, 'service_no_of_tech').toLocaleString()} </td>
                        <td className="text-center align-middle"> {calculateTotalAll(apptData, 'service_journeyman_needed').toLocaleString()} </td>
                        <td className="text-center align-middle"> {calculateTotalAll(apptData, 'service_apprentice_needed').toLocaleString()} </td>
                        <td className="text-center align-middle"> {calculateTotalAll(apptData, 'service_advisors_needed').toLocaleString()} </td>
                        {/* <td className="text-center align-middle">
                            {Math.ceil(calculateTotalAll(staffKpiData, 'service_hours_sold')/calculateTotalAll(staffKpiData, 'service_total_hours')||0)} 
                            </td> */}
                    </tr>
                </tbody>
            </table>

        </>

    )
}

const StaffTable = ({ division, apptData, days, day, staffKpiData }) => {
    const [stores, setStores] = useState([]);
    const [allDataKpi, setAllDataKpi] = useState([]);

    useEffect(() => {
        getStores();
        let storeObj = {};
        staffKpiData.length > 0 && staffKpiData?.map(i => {

            storeObj[i.store_id] = i;

        });
        setAllDataKpi(storeObj)
    }, []);

    const getStores = () => {
        axios
            .get(Links.getAllStores)
            .then((res) => {
                let all_stores_in_current_division;

                if (division === 'Used') {
                    all_stores_in_current_division = res.data.stores.filter(store => store.division === 'DriveNation' || store.division === 'FFUN Cars')
                } else {
                    all_stores_in_current_division = res.data.stores.filter(store => store.division === division)
                };
                setStores(all_stores_in_current_division);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const calculateTotal = (data, field, division) => {

        let total = 0;
        if (data) {
            data.forEach((item) => {

                if ((item.division)?.toString() === division?.toString()) {

                    total += item[field] ? parseFloat(item[field]) : 0;
                }

            });
        }
        return total;
    };


    return (
        <>
            <h4>{division === "Auto" ? 'Automotive' : division === "Rec" ? 'Recreational' : "Used "}  Staff Report:</h4>
            {(!stores || stores.length === 0) && (
                (<div className="p-4 d-flex justify-content-center" >
                    <img src={CarLoading} />
                </div>)
            )}

            {stores && stores.length > 0 && (
                <table className="mb-5 mt-3 table table-bordered border-black table-fit table-striped">
                    <TableHeadStaff type='dealership' />
                    <tbody>
                        {stores.map((store) => {

                            const appt_current_store = apptData.find(item => item.store_id === store.id);
                            //  console.log(appt_current_store)
                            return (
                                <tr>
                                    <td className="text-capitalize"> {store.name} </td>
                                    <td className="text-center align-middle"> {appt_current_store?.sales_people || 0} </td>
                                    <td className="text-center align-middle">
                                        {appt_current_store?.sales_people_needed || 0}
                                    </td>
                                    <td className="text-center align-middle">
                                        {ReturnFinite(Math.ceil((allDataKpi[store.id]?.total_sold / appt_current_store?.sales_people || 0) || 0))}
                                    </td>

                                    <td className="text-center align-middle"> {appt_current_store?.service_no_of_tech || 0} </td>
                                    <td className="text-center align-middle"> {appt_current_store?.service_journeyman_needed || 0} </td>
                                    <td className="text-center align-middle"> {appt_current_store?.service_apprentice_needed || 0} </td>
                                    <td className="text-center align-middle"> {appt_current_store?.service_advisors_needed || 0} </td>
                                    {/* <td className="text-center align-middle" >
                                    {(allDataKpi[store.id]?.service_hours_sold/allDataKpi[store.id]?.service_total_hours|| 0).toFixed(2)}
                                    </td> */}

                                </tr>
                            )
                        })}

                        <tr>
                            <td className="text-capitalize"> Total </td>
                            <td className="text-center align-middle"> {calculateTotal(apptData, 'sales_people', division).toLocaleString()} </td>

                            <td className="text-center align-middle" >
                                {calculateTotal(apptData, 'sales_people_needed', division).toLocaleString()}
                            </td>
                            <td className="text-center align-middle">
                                {ReturnFinite(Math.ceil((calculateTotal(staffKpiData, 'total_sold', division) / calculateTotal(apptData, 'sales_people', division)) || 0))}
                            </td>
                            <td className="text-center align-middle"> {calculateTotal(apptData, 'service_no_of_tech', division).toLocaleString()} </td>
                            <td className="text-center align-middle"> {calculateTotal(apptData, 'service_journeyman_needed', division).toLocaleString()} </td>
                            <td className="text-center align-middle"> {calculateTotal(apptData, 'service_apprentice_needed', division).toLocaleString()} </td>
                            <td className="text-center align-middle"> {calculateTotal(apptData, 'service_advisors_needed', division).toLocaleString()} </td>
                            {/* <td className="text-center align-middle">
                            {Math.ceil(calculateTotal(staffKpiData, 'service_hours_sold',division)/calculateTotal(staffKpiData, 'service_total_hours',division)||0)} 
                            </td> */}
                        </tr>
                    </tbody>
                </table>
            )}
        </>

    )
}

export default function SummaryReport() {
    const divisions = ['Auto', 'Rec', 'Used'];
    const currentYear = moment().format("YYYY");
    const currentMonth = moment().format("MMM");

    const d = new Date();
    const [day, setDay] = useState(d.getDate());
    const [month, setMonth] = useState(currentMonth);
    const [year, setYear] = useState(currentYear);
    const [days, setDays] = useState(DAYS_IN_MONTH[currentMonth]);
    const [loading, setLoading] = useState(true);

    const [getnoOfHolidaysbyDate, setGetnoOfHolidaysbyDate] = useState(calculateTotalHolidaysbyDates(currentMonth, currentYear));
    const [getnoOfHolidaysbyMonth, setGetnoOfHolidaysbyMonth] = useState(calculateTotalHolidaysbyMonth(currentMonth, currentYear));

    const [totalSold, setTotalSold] = useState([]);

    const [allStaffData, setAllStaffData] = useState([]);
    const [staffKpiData, setStaffKpiData] = useState([]);



    // const [dailyDataForAuto, setDailyDataForAuto] = useState([]);
    // const [dailyDataForRec, setDailyDataForRec] = useState([]);
    // const [dailyDataForUsed, setDailyDataForUsed] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {

        getStaffData(year, month);
        getVehicleDataforStaff(year, month);

        // getCurrentDayDailyData(year, month, day);
    }, []);






    const getStaffData = (year, month) => {
        const requestBody = {
            year: year,
            month: month,
            category: "All",
            divisions: ['Auto', 'Rec', 'Used'],
        };

        axios.post(Links.getStaffSummary, requestBody)
            .then(res => {
                setAllStaffData(res.data.staffSummary)
            }).catch(err => {
                console.log(err);
            });
    };


    const getVehicleDataforStaff = (year, month) => {
        const requestBody = {
            year: year,
            month: month,
            category: "All",
            divisions: ['Auto', 'Rec', 'Used'],
        };

        axios.post(Links.getVehicleDataforStaff, requestBody)
            .then(res => {

                setStaffKpiData(res.data.totals)
                setLoading(false);
            }).catch(err => {
                setLoading(false);
                console.log(err);
            });
    };
    // const getCurrentDayDailyData = (year, month, day) => {
    //     const requestBody = {
    //         year: year,
    //         month: month,
    //         day: day
    //     };
    //     axios.all([
    //         axios.post(Links.getAllAutoDailyDataByDate, requestBody),
    //         axios.post(Links.getAllRecDailyDataByData, requestBody),
    //         axios.post(Links.getAllUsedDailyDataByDate, requestBody),
    //     ])
    //         .then(axios.spread((data1, data2, data3) => {
    //             setDailyDataForAuto(data1.data.dailydata);
    //             setDailyDataForRec(data2.data.dailydata);
    //             setDailyDataForUsed(data3.data.dailydata);
    //         }))
    //         .catch((err) => {
    //             console.log(err);
    //         });
    // }

    const handleYearChange = (event) => {
        setYear(event.target.value);
    };

    const handleMonthChange = (event) => {
        setMonth(event.target.value);
    };

    const showResults = () => {

        setDays(DAYS_IN_MONTH[month]);

        if (month == currentMonth && year == currentYear) {
            setDay(d.getDate());
        } else {
            const new_date = new Date(year, Object.values(MONTHS).indexOf(month) + 1, 0);
            setDay(new_date.getDate());
        }

        setGetnoOfHolidaysbyDate(calculateTotalHolidaysbyDates(month, year));
        setGetnoOfHolidaysbyMonth(calculateTotalHolidaysbyMonth(month, year));

        getStaffData(year, month);
        getVehicleDataforStaff(year, month);

        // getCurrentDayDailyData(year, month, day);
    };

    const calculateTotal = (data, field) => {
        let total = 0;
        if (data) {
            data.forEach((item) => {
                total += item[field] ? parseFloat(item[field]) : 0;
            });
        }
        return total;
    };



    return (
        <div style={{ marginTop: "3rem", width: "100%" }}>
            <Grid container spacing={2}>
                <Grid item container spacing={1}>
                    <Grid item xs={10} sm={6} md={2} xl={1} spacing={1}>
                        <FormControl variant="standard" sx={{ m: 1, width: "100%" }}>
                            <InputLabel id="demo-simple-select-label">Year</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={year}
                                label="Year"
                                onChange={handleYearChange}
                            >
                                {YEARS.map((year, index) => (
                                    <MenuItem key={index} value={year}>
                                        {year}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={10} sm={6} md={2} xl={1} spacing={1}>
                        <FormControl variant="standard" sx={{ m: 1, width: "100%" }}>
                            <InputLabel id="demo-simple-select-label">Month</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={month}
                                label="Age"
                                onChange={handleMonthChange}
                            >
                                {Object.values(MONTHS).map((month, index) => (
                                    <MenuItem key={index} value={month}>
                                        {month}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item className="mt-3">
                        <button
                            className="p-2 mt-1 btn btn-secondary mx-3"
                            type="submit"
                            onClick={() => {
                                showResults();
                            }}
                        >
                            Show Details
                        </button>
                    </Grid>
                </Grid>

                <Grid item container xs={12}>
                    <Grid item xs={10}></Grid>
                    <Grid item xs={6}>
                        {loading ? (
                            <div style={{ marginTop: "3rem", right: "50%", top: "30%" }} className="text-center align-middle position-absolute" >
                                <img src={CarLoading} />
                            </div>

                        ) : (
                            <>
                                <EnterpriseStaffTable division='Used' staffKpiData={staffKpiData} apptData={allStaffData} soldData={totalSold} days={days} day={day} />
                                <StaffTable division='Auto' staffKpiData={staffKpiData} apptData={allStaffData} soldData={totalSold} days={days} day={day} />
                                <StaffTable division='Rec' staffKpiData={staffKpiData} apptData={allStaffData} soldData={totalSold} days={days} day={day} />
                                <StaffTable division='Used' staffKpiData={staffKpiData} apptData={allStaffData} soldData={totalSold} days={days} day={day} />


                            </>
                        )}
                    </Grid>
                </Grid>

            </Grid>
        </div>
    );
}
