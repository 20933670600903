import React from "react";
import { calculateKPIPercentage } from "../../utils";
import { useSelector } from "react-redux";

const KPIRowForUsed = ({ total, budget }) => {
  if (!total) {
    total = {}
  }
  if (!budget) {
    budget = {}
  }
  let budgetOfDailydata = parseInt(budget.used_retail_front_GP ? budget.used_retail_front_GP : 0) +
    parseInt(budget.used_retail_back_GP ? budget.used_retail_back_GP : 0) +
    parseInt(budget.service_total_GP ? budget.service_total_GP : 0)

  const totalStoreGross = useSelector(state => state.dataEntryBudget.total);

  return (
    <tr>
      <td className="dn-column" style={{ "background-color": "#e9ecef" }}></td>
      <td className="dn-column" style={{ "background-color": "#e9ecef" }}></td>
      <td className="dn-column" style={{ "background-color": "#e9ecef" }}>KPI</td>
      <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.used_retail_delivered, budget.used_retail_delivered)}</td>
      <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.used_retail_front_GP, budget.used_retail_front_GP)}</td>
      <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.used_retail_back_GP, budget.used_retail_back_GP)}</td>
      <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.service_total_GP, budget.service_total_GP)}</td>
      <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.service_total_hours, budget.service_total_hours)}</td>
      <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.service_CP_hours, budget.service_CP_hours)}</td>
      <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.service_CP_ROs, budget.service_CP_ROs)}</td>

      {/* <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.service_hours_available, budget.service_hours_available)}</td>
      <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.service_hours_sold, budget.service_hours_sold)}</td>

      <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage((total.service_hours_sold / total.service_total_hours), (budget.service_hours_sold / budget.service_total_hours))}</td>

      <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.service_no_of_tech, budget.service_no_of_tech)}</td>
      <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.service_journeyman, budget.service_journeyman)}</td>
      <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.service_apprentice, budget.service_apprentice)}</td>
      <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.service_advisors_needed, budget.service_advisors_needed)}</td> */}

      <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.service_units_shipped, budget.service_units_shipped)}</td>


      <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.appt_made, budget.appt_made)}</td>
      <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.booked, budget.booked)}</td>
      <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.appt_show, budget.appt_show)}</td>
      <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.store_visit, budget.store_visit)}</td>

      <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.internet_lead, budget.internet_lead)}</td>

      <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.fresh_up, budget.fresh_up)}</td>
      <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.phone_up, budget.phone_up)}</td>
      <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.lead, budget.lead)}</td>
      <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.sales_staff, budget.sales_staff)}</td>

      {/* <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.no_show, budget.no_show)}</td> */}


      <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(totalStoreGross, budgetOfDailydata)}</td>
    </tr>
  )
}

export default KPIRowForUsed