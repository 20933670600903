import React from "react";

const THForUsed = () =>{
    return(
        <thead>
          <tr className="text-center">
            <th colSpan="3" className="dn-header-top" style={{ "background-color": "#e9ecef" }}></th>
            <th colSpan="3" className="dn-header-top" style={{ "background-color": "#a2c1a2" }}>Used Vehicles</th>
            <th colSpan="5" className="dn-header-top" style={{ "background-color": "#b1d5dd" }}>Service</th>
            <th colSpan="9" className="auto-header-top" style={{ "background-color": "#b38bb5" }}>Sales</th>
            <th className="dn-header-top" style={{ "background-color": "orange" }}></th>
          </tr>
          <tr>
            <th className="dn-header" scope="col" style={{ "background-color": "#e9ecef" }}></th>
            <th className="dn-header" scope="col" style={{ "background-color": "#e9ecef" }}></th>
            <th className="dn-header" scope="col" style={{ "background-color": "#e9ecef" }}>Date</th>
            <th className="dn-header" scope="col" style={{ "background-color": "#a2c1a2" }}>Deliv`d</th>
            <th className="dn-header" scope="col" style={{ "background-color": "#a2c1a2" }}>Front GP</th>
            <th className="dn-header" scope="col" style={{ "background-color": "#a2c1a2" }}>Back GP</th>

            <th className="dn-header" scope="col" style={{ "background-color": "#b1d5dd" }}>GP</th>
            <th className="dn-header" scope="col" style={{ "background-color": "#b1d5dd" }}>Total Hours</th>
            <th className="dn-header" scope="col" style={{ "background-color": "#b1d5dd" }}>CP Hours</th>
            <th className="dn-header" scope="col" style={{ "background-color": "#b1d5dd" }}>CP RO's</th>
         
            {/* <th className="auto-header" scope="col" style={{ "background-color": "#b1d5dd" }}>Hours Avail</th>
            <th className="auto-header" scope="col" style={{ "background-color": "#b1d5dd" }}>Total Flag Hours</th> */}
            {/* <th className="auto-header" scope="col" style={{ "background-color": "#b1d5dd" }}>Tech Proficiency</th> */}
            {/* <th className="auto-header" scope="col" style={{ "background-color": "#b1d5dd" }}>No of Technicians</th>
            <th className="auto-header" scope="col" style={{ "background-color": "#b1d5dd" }}>Journeyman Needed</th>
            <th className="auto-header" scope="col" style={{ "background-color": "#b1d5dd" }}>Apprentice Needed</th>
            <th className="auto-header" scope="col" style={{ "background-color": "#b1d5dd" }}>Advisors Needed</th> */}
            {/* <th className="auto-header" scope="col" style={{ "background-color": "#b1d5dd" }}>Booked Hours</th> */}
            <th className="auto-header" scope="col" style={{ "background-color": "#b1d5dd" }}>Units Shipped</th>

            <th className="dn-header" scope="col" style={{ "background-color": "#b38bb5" }}>Appts Made</th>
            <th className="auto-header" scope="col" style={{ "background-color": "#b38bb5" }}>Appts Confirmed</th>
            <th className="dn-header" scope="col" style={{ "background-color": "#b38bb5" }}>Appts Shown</th>
            <th className="dn-header" scope="col" style={{ "background-color": "#b38bb5" }}>Store Visits</th>
         
            <th className="auto-header" scope="col" style={{ "background-color": "#b38bb5" }}>Int Leads</th>

            <th className="auto-header" scope="col" style={{ "background-color": "#b38bb5" }}>F/Up</th>
            <th className="auto-header" scope="col" style={{ "background-color": "#b38bb5" }}>Phone Up</th>
            <th className="dn-header" scope="col" style={{ "background-color": "#b38bb5" }}>Total Leads</th>
            <th className="auto-header" scope="col" style={{ "background-color": "#b38bb5" }}>Sales Staff</th>
         
            {/* <th className="auto-header" scope="col" style={{ "background-color": "#b38bb5" }}>No Show</th> */}


            <th className="dn-header" scope="col" style={{ "background-color": "orange" }}>Total Store Gross</th>
          </tr>
        </thead>
    )
}

export default THForUsed