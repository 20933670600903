export default (dailyData, budget) => {
    if (dailyData && budget) {
      const percentage = ((parseFloat(dailyData) / parseFloat(budget)) * 100)
      if (percentage === "NaN%" || percentage === 'Infinity%') {
        return 0
      } else {
        return percentage
      }
    } else {
      return 0;
    }
  };