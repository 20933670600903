import React, { useState } from "react";
import styled from "styled-components";
import { useTable } from "react-table";
import makeData from "./makeData";
import MonthYearPicker from "react-month-year-picker";
import Paper from "@material-ui/core/Paper";

const Styles = styled.div`
  padding: 2rem;

  table {
    border-spacing: 1;
    border: 1px solid black;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }
    }
  }
`;
function Table({ columns, data }) {
  // Use the state and functions returned from useTable to build your UI
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });

  // Render the UI for your table
  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()}>{column.render("Header")}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
const MtdDealer2 = (props) => {
  const columns = React.useMemo(
    () => [
      {
        Header: "Dealers",
        columns: [
          {
            Header: "Dealers",
            accessor: "dealers",
          },
        ],
      },
      {
        Header: "New Vehicles",
        columns: [
          {
            Header: "Retail Gross",
            accessor: "retailGross",
          },
          {
            Header: "Retail Delivered",
            accessor: "retailDelivered",
          },
        ],
      },
      {
        Header: "Used Vehicles",
        columns: [
          {
            Header: "Retail Gross",
            accessor: "retailGrossU",
          },
          {
            Header: "Retail Delivered",
            accessor: "retailDeliveredU",
          },
        ],
      },
      {
        Header: "Service",
        columns: [
          {
            Header: "Retail Gross",
            accessor: "retailGrossS",
          },
          {
            Header: "Retail Sales",
            accessor: "retailSalesS",
          },
        ],
      },
      {
        Header: "Parts",
        columns: [
          {
            Header: " Gross",
            accessor: "GrossP",
          },
          {
            Header: "Retail Sales",
            accessor: "retailSalesP",
          },
        ],
      },
      {
        Header: "Total",
        columns: [
          {
            Header: "Total",
            accessor: "total",
          },
        ],
      },
    ],
    []
  );

  const data2 = [
    {
      dealers: "Accura",
      retailGross: 1,
      retailDelivered: 2,
      retailGrossU: 2,
      retailDeliveredU: 3,
      retailGrossS: 2,
      retailSalesS: 3,
      GrossP: 2,
      retailSalesP: 2,
      total: 100,
    },
    {
      dealers: "Country Ford",
      retailGross: 1,
      retailDelivered: 2,
      retailGrossU: 2,
      retailDeliveredU: 3,
      retailGrossS: 2,
      retailSalesS: 3,
      GrossP: 2,
      retailSalesP: 2,
      total: 100,
    },
    {
      dealers: "Kia PA",
      retailGross: 1,
      retailDelivered: 2,
      retailGrossU: 2,
      retailDeliveredU: 3,
      retailGrossS: 2,
      retailSalesS: 3,
      GrossP: 2,
      retailSalesP: 2,
      total: 100,
    },
    {
      dealers: "Hyundai",
      retailGross: 1,
      retailDelivered: 2,
      retailGrossU: 2,
      retailDeliveredU: 3,
      retailGrossS: 2,
      retailSalesS: 3,
      GrossP: 2,
      retailSalesP: 2,
      total: 100,
    },
    {
      dealers: "Kia",
      retailGross: 1,
      retailDelivered: 2,
      retailGrossU: 2,
      retailDeliveredU: 3,
      retailGrossS: 2,
      retailSalesS: 3,
      GrossP: 2,
      retailSalesP: 2,
      total: 100,
    },
    {
      dealers: "West Edmonton Hyundai",
      retailGross: 1,
      retailDelivered: 2,
      retailGrossU: 2,
      retailDeliveredU: 3,
      retailGrossS: 2,
      retailSalesS: 3,
      GrossP: 2,
      retailSalesP: 2,
      total: 100,
    },
    {
      dealers: "MVL Ford Lincoln",
      retailGross: 1,
      retailDelivered: 2,
      retailGrossU: 2,
      retailDeliveredU: 3,
      retailGrossS: 2,
      retailSalesS: 3,
      GrossP: 2,
      retailSalesP: 2,
      total: 100,
    },
    {
      dealers: "South 20 Dodge",
      retailGross: 1,
      retailDelivered: 2,
      retailGrossU: 2,
      retailDeliveredU: 3,
      retailGrossS: 2,
      retailSalesS: 3,
      GrossP: 2,
      retailSalesP: 2,
      total: 100,
    },
    {
      dealers: "Hyundai South",
      retailGross: 1,
      retailDelivered: 2,
      retailGrossU: 2,
      retailDeliveredU: 3,
      retailGrossS: 2,
      retailSalesS: 3,
      GrossP: 2,
      retailSalesP: 2,
      total: 100,
    },
    {
      dealers: "Nipawin_AD",
      retailGross: 1,
      retailDelivered: 2,
      retailGrossU: 2,
      retailDeliveredU: 3,
      retailGrossS: 2,
      retailSalesS: 3,
      GrossP: 2,
      retailSalesP: 2,
      total: 100,
    },
  ];

  const [month, setMonth] = useState(10);
  const [year, setYear] = useState(2018);
  return (
    <div style={{ marginLeft: "20rem" }}>
      <Paper elevation={3} style={{ marginLeft: "2rem",width:"45rem" }}>
        <MonthYearPicker
          selectedMonth={month}
          selectedYear={year}
          minYear={2000}
          maxYear={2030}
          onChangeYear={(year) => setYear(year)}
          onChangeMonth={(month) => setMonth(month)}
        />
      </Paper>{" "}
      <Styles>
        <Table columns={columns} data={data2} />
      </Styles>
    </div>
  );
};

export default MtdDealer2;
