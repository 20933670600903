export default {
    "dataEntry": {
        new_retail_appt: '',
        new_retail_sold: '',
        new_retail_delivered: '',
        new_retail_front_GP: '',
        new_retail_back_GP: '',
        new_CSI: '',
        used_appt: '',
        used_retail_sold: '',
        used_retail_delivered: '',
        used_retail_front_GP: '',
        used_retail_back_GP: '',
        service_total_hours: '',
        service_CP_hours: '',
        service_CP_ROs: '',
        service_CP_hrs_RO: '',
        service_total_GP: '',
        service_sales: '',
        service_CSI: '',
        service_hours_sold: '',
        service_hours_available: '',
        service_no_of_tech: '',
        service_journeyman: '',
        service_apprentice: '',
        service_advisors_needed: '',
        service_booked_hours: '',
        service_units_shipped: '',
        parts_sales: '',
        parts_total_GP: '',
        parts_whole_sale_GP: '',
        appt_made: '',
        appt_show: '',
        store_visit: '',
        lead: '',
        sales_staff: '',
        booked: '',
        no_show: '',
        phone_up: '',
        fresh_up: '',
        internet_lead: '',
        transaction_lock: 0,
        locked_user: '',
        transaction_locked_at: ''
    },
    "dataEntryRec": {
        new_retail_appt: '',
        new_retail_sold: '',
        new_retail_delivered: '',
        new_retail_front_GP: '',
        new_retail_back_GP: '',
        new_CSI: '',
        used_appt: '',
        used_retail_sold: '',
        used_retail_delivered: '',
        used_retail_front_GP: '',
        used_retail_back_GP: '',
        boats_delivered: '',
        boats_front_GP: '',
        boats_back_GP: '',
        trailers_delivered: '',
        trailers_front_GP: '',
        trailers_back_GP: '',
        service_total_hours: '',
        service_CP_hours: '',
        service_CP_ROs: '',
        service_CP_hrs_RO: '',
        service_total_GP: '',
        service_sales: '',
        service_CSI: '',
        service_hours_sold: '',
        service_hours_available: '',
        service_no_of_tech: '',
        service_journeyman: '',
        service_apprentice: '',
        service_advisors_needed: '',
        service_booked_hours: '',
        service_units_shipped: '',
        parts_sales: '',
        parts_total_GP: '',
        parts_whole_sale_GP: '',
        apparel_sales: '',
        apparel_gross: '',
        apparel_inventory_value: '',
        appt_made: '',
        appt_show: '',
        store_visit: '',
        lead: '',
        sales_staff: '',
        booked: '',
        no_show: '',
        phone_up: '',
        fresh_up: '',
        internet_lead: '',
        transaction_lock: 0,
        locked_user: '',
        transaction_locked_at: ''
    },
    "dataEntryFF": {
        apps: '',
        approvals: '',
        tier_1: '',
        tier_2: '',
        tier_3: '',
        tier_4: '',
        deals: '',
        deal_gp: '',
        new_auto_ffun_adv: '',
        new_auto_gap: '',
        new_auto_creditor: '',
        used_auto_ffun_adv: '',
        used_auto_warranty: '',
        used_auto_gap: '',
        used_auto_creditor: '',
        rec_ffun_adv: '',
        rec_warranty: '',
        rec_gap: '',
        rec_creditor: ''
    },
    "budgetEntry": {
        new_retail_delivered: '',
        new_retail_front_GP: '',
        new_retail_back_GP: '',
        new_CSI: '',
        used_retail_delivered: '',
        used_retail_front_GP: '',
        used_retail_back_GP: '',
        service_total_hours: '',
        service_CP_hours: '',
        service_CP_ROs: '',
        service_total_GP: '',
        service_sales: '',
        service_CSI: '',
        service_hours_sold: '',
        service_hours_available: '',
        service_no_of_tech: '',
        service_journeyman: '',
        service_apprentice: '',
        service_advisors_needed: '',
        service_booked_hours: '',
        service_units_shipped: '',
        parts_sales: '',
        parts_total_GP: '',
        appt_made: '',
        appt_show: '',
        store_visit: '',
        lead: '',
        sales_staff: '',
        booked: '',
        no_show: '',
        phone_up: '',
        fresh_up: '',
        internet_lead: '',
    },
    "budgetEntryRec": {
        new_retail_delivered: '',
        new_retail_front_GP: '',
        new_retail_back_GP: '',
        new_CSI: '',
        used_retail_delivered: '',
        used_retail_front_GP: '',
        used_retail_back_GP: '',
        service_total_hours: '',
        service_CP_hours: '',
        service_CP_ROs: '',
        service_total_GP: '',
        service_sales: '',
        service_CSI: '',
        service_hours_sold: '',
        service_hours_available: '',
        service_no_of_tech: '',
        service_journeyman: '',
        service_apprentice: '',
        service_advisors_needed: '',
        service_booked_hours: '',
        service_units_shipped: '',
        parts_sales: '',
        parts_total_GP: '',
        parts_whole_sale_GP: '',
        boats_delivered: '',
        boats_front_GP: '',
        boats_back_GP: '',
        trailers_delivered: '',
        trailers_front_GP: '',
        trailers_back_GP: '',
        apparel_gross: '',
        apparel_sales: '',
        apparel_inventory_value: '',
        appt_made: '',
        appt_show: '',
        store_visit: '',
        lead: '',
        sales_staff: '',
        booked: '',
        no_show: '',
        phone_up: '',
        fresh_up: '',
        internet_lead: '',
    },
    "budgetEntryFF": {
        apps: '',
        approvals: '',
        tier_1: '',
        tier_2: '',
        tier_3: '',
        tier_4: '',
        deals: '',
        deal_gp: '',
        new_auto_ffun_adv: '',
        new_auto_gap: '',
        new_auto_creditor: '',
        used_auto_ffun_adv: '',
        used_auto_warranty: '',
        used_auto_gap: '',
        used_auto_creditor: '',
        rec_ffun_adv: '',
        rec_warranty: '',
        rec_gap: '',
        rec_creditor: ''
    },
    "currentEditDayRec": {
        currentEditDayRec: 0
    },
    "currentEditDayAuto": {
        currentEditDayAuto: 0
    },
    "currentEditDayUsed": {
        currentEditDayUsed: 0
    },
    "currentEditDayFF": {
        currentEditDayFF: 0
    },
    "staffEntry": {
        sales_people: 0,
        sales_people_needed: 0,
        service_no_of_tech: 0,
        service_journeyman_needed: 0,
        service_apprentice_needed: 0,
        service_advisors_needed: 0,
    }
}