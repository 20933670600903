import * as actions from "./actionTypes";
import {
    combineReducers
} from 'redux';
import reduxState from './state';

const merge = (prev, next) => Object.assign({}, prev, next);

const initialDataEntryBudgetState = {
    dataEntry: reduxState.dataEntry,
    budgetEntry: reduxState.budgetEntry,
    dataEntryRec: reduxState.dataEntryRec,
    budgetEntryRec: reduxState.budgetEntryRec,
    dataEntryFF: reduxState.dataEntryFF,
    budgetEntryFF: reduxState.budgetEntryFF,
    total: 0,
    totalBudget: 0,
    totalCPHrsRo: 0
}

const dataEntryBudgetReducer = (state = initialDataEntryBudgetState, action) => {
    switch (action.type) {

        case actions.UPDATE_DATA_ENTRY_ROW: {
            return {
                ...state,
                dataEntry: merge(state.dataEntry, action.payload)
            }
        }

        case actions.CLEAR_DATA_ENTRY_ROW: {
            return {
                ...state,
                dataEntry: reduxState.dataEntry
            };
        }

        case actions.UPDATE_DATA_ENTRY_REC_ROW: {
            return {
                ...state,
                dataEntryRec: merge(state.dataEntryRec, action.payload)
            }
        }

        case actions.CLEAR_DATA_ENTRY_REC_ROW: {
            return {
                ...state,
                dataEntryRec: reduxState.dataEntryRec
            };
        }


        case actions.UPDATE_DATA_ENTRY_FF_ROW: {
            return {
                ...state,
                dataEntryFF: merge(state.dataEntryFF, action.payload)
            }
        }

        case actions.CLEAR_DATA_ENTRY_FF_ROW: {
            return {
                ...state,
                dataEntryFF: reduxState.dataEntryFF
            };
        }

        case actions.UPDATE_BUDGET_ROW: {
            return {
                ...state,
                budgetEntry: merge(state.budgetEntry, action.payload)
            }
        }

        case actions.CLEAR_BUDGET_ROW: {
            return {
                ...state,
                budgetEntry: reduxState.budgetEntry
            };
        }



        case actions.UPDATE_BUDGET_REC_ROW: {
            return {
                ...state,
                budgetEntryRec: merge(state.budgetEntryRec, action.payload)
            }
        }

        case actions.CLEAR_BUDGET_REC_ROW: {
            return {
                ...state,
                budgetEntryRec: reduxState.budgetEntryRec
            };
        }

        case actions.UPDATE_BUDGET_FF_ROW: {
            return {
                ...state,
                budgetEntryFF: merge(state.budgetEntryFF, action.payload)
            }
        }

        case actions.CLEAR_BUDGET_FF_ROW: {
            return {
                ...state,
                budgetEntryFF: reduxState.budgetEntryFF
            };
        }

        case actions.UPDATE_TOTAL_STORE_GROSS: {
            return {
                ...state,
                total: action.payload.totalStoreGross
            };
        }

        case actions.UPDATE_TOTAL_BUDGET: {
            return {
                ...state,
                totalBudget: action.payload.totalBudget
            };
        }

        case actions.UPDATE_TOTAL_OF_CP_HRS_RO: {
            return {
                ...state,
                totalCPHrsRo: action.payload.serviceCPhrsDivideRO
            };
        }

        case actions.UPDATE_TOTAL_OF_CP_HRS_RO: {
            return {
                ...state,
                totalCPHrsRo: action.payload.serviceCPhrsDivideRO
            };
        }

        default:
            return state
    }
}

const defaultReducer = (type, stateKey) => (state = reduxState[stateKey], action) => {
    if (action.type === type) {
        if (Object.keys(action.payload).length === 0) {
            return reduxState[stateKey];
        } else {
            return merge(state, action.payload);
        };
    };
    return state;
};

const reducer = combineReducers({
    dataEntryBudget: dataEntryBudgetReducer,
    currentEditDayRec: defaultReducer(actions.UPDATE_CURRENT_EDIT_DAY_REC, "currentEditDayRec"),
    currentEditDayAuto: defaultReducer(actions.UPDATE_CURRENT_EDIT_DAY_AUTO, "currentEditDayAuto"),
    currentEditDayUsed: defaultReducer(actions.UPDATE_CURRENT_EDIT_DAY_USED, "currentEditDayUsed"),
    currentEditDayFF: defaultReducer(actions.UPDATE_CURRENT_EDIT_DAY_FF, "currentEditDayFF"),
    dataEntryStaff: defaultReducer(actions.UPDATE_STAFF_ROW, "staffEntry"),
});

export default reducer;