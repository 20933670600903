import React, { useState, useEffect } from "react";
import UserList from "../../component/Users/UserList";
import Links from "../../Data/Links";
import axios from "axios";

const ManageUsers = () => {
  const [userList, setUserList] = useState([]);

  const fetchUserList = () => {
    axios
      .get(Links.getAllUsers)
      .then((res) => {
        setUserList(res.data.data);
      })
      .catch((err) => {
        alert("Something went wrong!");
      });
  };

  useEffect(fetchUserList, []);

  return (
    <>
      <UserList userList={userList} update={fetchUserList} />
    </>
  );
};

export default ManageUsers;
