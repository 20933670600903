import React from "react";
import { calculatePercentageOfBudget } from "../../utils";

const SummaryReportsTableAllDivisions = (props) => {
  // let divisions = ["Auto", "Rec", "Used"];
  const {
    divisions,
    dailyDataThisMonth,
    dailyDataThisMonthForRec,
    dailyDataThisMonthForUsed,
    dailyDataThisMonthForFF,
    budgetThisMonth,
    budgetThisMonthForRec,
    budgetThisMonthForUsed,
    budgetThisMonthForFF,
    type
  } = props;

  const caluclateTotal = (data, division) => {
    let total = 0;
    if (data && data.length > 0) {
      data.map((data) => {
        if (data.total_gross_profit) {
          total += data.total_gross_profit;
        } else if (data.total_sold) {
          total += data.total_sold;
        } else {
          total += 0;
        }
      });
    }
    return total;
  };

  const totalDailyData = caluclateTotal(dailyDataThisMonth, "");
  const totalBudget = caluclateTotal(budgetThisMonth, "");
  const totalDailyDataForRec = caluclateTotal(dailyDataThisMonthForRec, "");
  const totalBudgetForRec = caluclateTotal(budgetThisMonthForRec, "");
  const totalDailyDataForUsed = caluclateTotal(dailyDataThisMonthForUsed, "");
  const totalBudgetForUsed = caluclateTotal(budgetThisMonthForUsed, "");
  const totalDailyDataForFF = caluclateTotal(dailyDataThisMonthForFF, "");
  const totalBudgetForFF = caluclateTotal(budgetThisMonthForFF, "");

  const totalKpiIndicator = calculatePercentageOfBudget(
    (totalDailyData + totalDailyDataForRec + totalDailyDataForUsed),
    (totalBudget + totalBudgetForRec + totalBudgetForUsed)
  );

  const kpiIndicatorsForAll = {
    "Rec": calculatePercentageOfBudget(totalDailyDataForRec, totalBudgetForRec),
    "Auto": calculatePercentageOfBudget(totalDailyData, totalBudget),
    "Used": calculatePercentageOfBudget(totalDailyDataForUsed, totalBudgetForUsed),
    "FFUN Financial": calculatePercentageOfBudget(totalDailyDataForFF, totalBudgetForFF)
  }

  const sorted_divisions = divisions.sort((a, b) => {
    return (parseInt(kpiIndicatorsForAll[a]) < parseInt(kpiIndicatorsForAll[b])) ? 1
      : (parseInt(kpiIndicatorsForAll[b]) < parseInt(kpiIndicatorsForAll[a]) ? -1 : 0)
  });

  return (
    <>
      <h4>Enterprise Summary:</h4>
      <table className="mb-5 mt-3 table table-bordered border-black table-fit table-striped">
        <thead>
          <tr className="text-center">
            <th
              colSpan="1"
              className="dn-header-top"
              style={{ whiteSpace: "nowrap", backgroundColor: "orange" }}
            >
              Division
            </th>
            <th
              colSpan="1"
              className="dn-header-top"
              style={{
                whiteSpace: "nowrap",
                backgroundColor: "rgb(139, 139, 181)",
              }}
            >
              {type === "gross" ? "Actual Gross" : "Sold Units"}
            </th>
            <th
              colSpan="1"
              className="dn-header-top"
              style={{ whiteSpace: "nowrap", backgroundColor: "#a2c1a2" }}
            >
              Monthly Budget
            </th>
            <th
              colSpan="1"
              className="dn-header-top"
              style={{ whiteSpace: "nowrap", backgroundColor: "#b1d5dd" }}
            >
              KPI Indicator
            </th>
          </tr>
        </thead>
        <tbody>
          {sorted_divisions &&
            sorted_divisions.map((division) => {
              let totalDailyData, totalBudget, kpiIndicator;

              switch (division) {
                case 'Auto':
                  totalBudget = caluclateTotal(budgetThisMonth, division);
                  totalDailyData = caluclateTotal(dailyDataThisMonth, division);
                  kpiIndicator = calculatePercentageOfBudget(totalDailyData, totalBudget);
                  break;
                case 'Used':
                  totalBudget = caluclateTotal(budgetThisMonthForUsed, division);
                  totalDailyData = caluclateTotal(dailyDataThisMonthForUsed, division);
                  kpiIndicator = calculatePercentageOfBudget(totalDailyDataForUsed, totalBudgetForUsed);
                  break;
                case 'Rec':
                  totalBudget = caluclateTotal(budgetThisMonthForRec, division);
                  totalDailyData = caluclateTotal(dailyDataThisMonthForRec, division);
                  kpiIndicator = calculatePercentageOfBudget(totalDailyDataForRec, totalBudgetForRec);
                  break;
                case 'FFUN Financial':
                  totalBudget = caluclateTotal(budgetThisMonthForFF, division);
                  totalDailyData = caluclateTotal(dailyDataThisMonthForFF, division);
                  kpiIndicator = calculatePercentageOfBudget(totalDailyDataForFF, totalBudgetForFF);
                  break;
                default:
                  totalBudget = caluclateTotal(budgetThisMonth, division);
                  totalDailyData = caluclateTotal(dailyDataThisMonth, division);
                  kpiIndicator = calculatePercentageOfBudget(totalDailyData, totalBudget);
              }

              return (
                <>
                  <tr>
                    <td style={{ textAlign: "center" }} className="text-capitalize">
                      {division}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {type === "gross"
                        ? totalDailyData.toLocaleString("en-CA", {
                          maximumFractionDigits: 0,
                          minimumFractionDigits: 0,
                          style: "currency",
                          currency: "CAD",
                        })
                        : totalDailyData}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {type === "gross"
                        ? totalBudget.toLocaleString("en-CA", {
                          maximumFractionDigits: 0,
                          minimumFractionDigits: 0,
                          style: "currency",
                          currency: "CAD",
                        })
                        : totalBudget}
                    </td>
                    <td
                      style={{
                        fontWeight: 500,
                        textAlign: "center",
                        position: "relative",
                      }}
                    >
                      <div
                        class="progress"
                        style={{
                          height: "100%",
                          width: "100%",
                          position: "absolute",
                          right: "0%",
                          top: "0%",
                          zIndex: "-1",
                          borderRadius: 0,
                        }}
                      >
                        <div
                          class="progress-bar"
                          role="progressbar"
                          style={{
                            backgroundColor: "#7CEF7C ",
                            width: kpiIndicator ? kpiIndicator : "0",
                          }}
                          aria-valuenow="25"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                      {kpiIndicator}
                    </td>
                  </tr>
                </>
              );
            })}
          <tr>
            <td style={{ textAlign: "center" }}>Total</td>
            <td style={{ textAlign: "center" }}>
              {type === "gross"
                ? (totalDailyData + totalDailyDataForRec + totalDailyDataForUsed).toLocaleString("en-CA", {
                  maximumFractionDigits: 0,
                  minimumFractionDigits: 0,
                  style: "currency",
                  currency: "CAD",
                })
                : (totalDailyData + totalDailyDataForRec + totalDailyDataForUsed)}
            </td>
            <td style={{ textAlign: "center" }}>
              {type === "gross"
                ? (totalBudget + totalBudgetForRec + totalBudgetForUsed).toLocaleString("en-CA", {
                  maximumFractionDigits: 0,
                  minimumFractionDigits: 0,
                  style: "currency",
                  currency: "CAD",
                })
                : (totalBudget + totalBudgetForRec + totalBudgetForUsed)}
            </td>
            <td
              style={{
                fontWeight: 500,
                textAlign: "center",
                position: "relative",
              }}
            >
              <div
                class="progress"
                style={{
                  height: "100%",
                  width: "100%",
                  position: "absolute",
                  right: "0%",
                  top: "0%",
                  zIndex: "-1",
                }}
              >
                <div
                  class="progress-bar"
                  role="progressbar"
                  style={{
                    backgroundColor: "#7CEF7C ",
                    width: totalKpiIndicator ? totalKpiIndicator : "0",
                  }}
                  aria-valuenow="25"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
              {totalKpiIndicator}
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default SummaryReportsTableAllDivisions;
