import React, { useState } from "react";
import axios from 'axios';
import './style.css';
import Links from "../../Data/Links";
import { store, updateDataEntryRow, clearDataEntryRow, updateCurrentEditDayAuto } from "../../redux";
import { useSelector } from "react-redux";
import Cookies from 'js-cookie';
import moment from 'moment';

const CreateRow = ({ row, day, filterData, loadData, userData, editDay, stores }) => {


    const dataEntry = useSelector(state => state.dataEntryBudget.dataEntry)
    const [edit, setEdit] = useState({});
    const [isBeingEdited, setIsBeingEdited] = useState(false);
    const onChangeValue = (event, field) => {
        store.dispatch(updateDataEntryRow({ [field]: event.target.value ? event.target.value : 0 }));
    }

    const checkDatabaseTransactions = (day) => {
        const currentUser = JSON.parse(Cookies.get('user_data')).userName
        const dataEntryObject = { ...dataEntry, transaction_lock: 1, locked_user: currentUser, store_id: filterData.store_id, store_name: filterData.store_name, company: filterData.company, month: filterData.month, year: filterData.year, day: day.toString() }
        axios.post(Links.addDailyData, dataEntryObject)
            .then(res => {
                if (res.data.recordExist && res.data.recordExist === true) {
                    if (res.data.lockedUser !== currentUser) {
                        alert(res.data.message)
                        setEdit({ [day]: false });
                        loadData(null, day)
                        store.dispatch(updateCurrentEditDayAuto({ "currentEditDayAuto": 0 }))
                    } else {
                        setEdit({ [day]: true });
                        loadData(null, day)
                    }
                } else {
                    loadData(null, day)
                }
            }).catch(err => {
                console.log(err);
            });
    };

    const checkDatabaseTransactionsForEdit = (day, id) => {
        const currentUser = JSON.parse(Cookies.get('user_data')).userName

        const dataEntryObject = { ...row[day], transaction_lock: 1, locked_user: currentUser, store_id: filterData.store_id, store_name: filterData.store_name, company: filterData.company, month: filterData.month, year: filterData.year, day: day.toString(), saveOrEdit: 'edit' }
        axios.patch(Links.updateDailyDataById + '/' + id, { row: dataEntryObject })
            .then(res => {

                if (res.data.recordExist && res.data.recordExist === true) {
                    if (res.data.lockedUser !== currentUser) {
                        alert(res.data.message)
                        setEdit({ [day]: false });
                        store.dispatch(updateCurrentEditDayAuto({ "currentEditDayAuto": 0 }))
                        loadData(null, day)
                    } else {
                        setEdit({ [day]: true });
                        loadData(null, day)
                    }
                } else {
                    loadData(null, day)
                }


            }).catch(err => {
                console.log(err);
            });
    };

    const getTotalGross = (rowData = null) => {
        if (rowData != null) {
            let new_retail_front_GP = rowData.new_retail_front_GP ? parseInt(rowData.new_retail_front_GP) : 0;
            let new_retail_back_GP = rowData.new_retail_back_GP ? parseInt(rowData.new_retail_back_GP) : 0;
            let used_retail_front_GP = rowData.used_retail_front_GP ? parseInt(rowData.used_retail_front_GP) : 0;
            let used_retail_back_GP = rowData.used_retail_back_GP ? parseInt(rowData.used_retail_back_GP) : 0;
            let service_total_GP = rowData.service_total_GP ? parseInt(rowData.service_total_GP) : 0;
            let parts_total_GP = rowData.parts_total_GP ? parseInt(rowData.parts_total_GP) : 0;
            let sum = new_retail_front_GP + new_retail_back_GP + used_retail_front_GP + used_retail_back_GP + service_total_GP + parts_total_GP;
            return "$" + parseInt(sum).toLocaleString();
        } else {
            return "$0";
        }
    }

    const getServiceCPhrsDivideRO = (rowData = null) => {
        if (rowData != null) {
            let service_CP_hours = rowData.service_CP_hours ? parseFloat(rowData.service_CP_hours) : 0;
            let service_CP_ROs = rowData.service_CP_ROs ? parseFloat(rowData.service_CP_ROs) : 0;
            let serviceCPhrsDivideRO = (service_CP_hours / service_CP_ROs).toFixed(2)
            if (serviceCPhrsDivideRO === "NaN" || serviceCPhrsDivideRO === 'Infinity') {
                return '0'
            } else {
                return serviceCPhrsDivideRO
            }
        } else {
            return "0";
        }
    }
    const getTimeDifferenceInMinutes = (startTime, endTime) => {
        if (typeof startTime === 'undefined') {
            return false
        }
        const startDate = moment(startTime).unix()
        const timeEnd = moment(endTime).unix()
        const endTimeduration = moment.duration(timeEnd);
        const startTimeduration = moment.duration(startDate);
        const endTimeMinutes = endTimeduration.asMinutes();
        const startTimeMinutes = startTimeduration.asMinutes()
        const diff = endTimeMinutes - startTimeMinutes
        if (diff < 3) {
            return true
        } else {
            return false
        }
    }

    let totalLeads = parseInt(row?.[day]?.phone_up || 0) + parseInt(row?.[day]?.fresh_up || 0) + parseInt(row?.[day]?.internet_lead || 0);

    return (
        <tr style={{ border: row[day]?.diff <= 3 && row[day]?.locked_user !== "Undefined" && row[day]?.locked_user !== "undefined" && row[day]?.locked_user && row[day]?.locked_user !== 'null' && row[day]?.locked_user !== JSON.parse(Cookies.get('user_data')).userName ? '2px solid red' : '', position: 'relative' }}>
            {edit[day] ?
                <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }}><button className="button"
                    onClick={() => {
                        if (Object.keys(stores).length > 0 && filterData.store_name === stores[filterData.store_id]) {
                            if (row[day]?.id) {
                                axios.patch(Links.updateDailyDataById + '/' + row[day]?.id, { row: { ...dataEntry, transaction_lock: 0, locked_user: JSON.parse(Cookies.get('user_data')).userName, transaction_locked_at: null, saveOrEdit: 'save' } })
                                    .then(res => {

                                        if (res.data.recordExist && res.data.recordExist === true) {
                                            alert("Timeout! Please try again.");
                                            window.location.reload();
                                        } else {
                                            alert("Data Entry has been updated.");
                                            setEdit({ [day]: false });
                                            loadData(filterData, 0);
                                            store.dispatch(clearDataEntryRow());

                                        }
                                        store.dispatch(updateCurrentEditDayAuto({ "currentEditDayAuto": 0 }))
                                    }).catch(err => {
                                        console.log(err);
                                    });
                            }




                        }

                        else {
                            alert("something went wrong! Please try again");
                            window.location.reload();
                        }
                    }}
                >Save</button></td>
                :
                <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }}><button className="button"
                    onClick={() => {
                        if (Object.keys(stores).length > 0 && filterData.store_name === stores[filterData.store_id]) {


                            // store.dispatch(updateCurrentEditDayAuto({ "currentEditDayAuto": day }))
                            if (editDay === 0) {
                                setEdit({ [day]: true });
                                row?.[day] ? checkDatabaseTransactionsForEdit(day, row[day].id) : checkDatabaseTransactions(day)
                                store.dispatch(updateCurrentEditDayAuto({ "currentEditDayAuto": day }))
                            }
                            else {
                                alert("Please save the data you are editing and try again.")
                            }
                        }
                        else {
                            alert("something went wrong! Please try again");
                            window.location.reload();
                        }
                        // store.dispatch(updateDataEntryRow({ ...filterData, ...row[day], 'day': day }));
                    }}
                >
                    {row?.[day] ? 'Edit' : 'Add'}
                </button></td>
            }
            <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }}><button className="button"
                onClick={() => {
                    if (Object.keys(stores).length > 0 && filterData.store_name === stores[filterData.store_id]) {



                        if (editDay === 0 && window.confirm("Are you sure want to delete?")) {
                            const currentUser = JSON.parse(Cookies.get('user_data')).userName
                            const dataEntryObject = { ...row[day], transaction_lock: 1, locked_user: currentUser, store_id: filterData.store_id, store_name: filterData.store_name, company: filterData.company, month: filterData.month, year: filterData.year, day: day.toString(), saveOrEdit: 'edit' }
                            axios.post(Links.deleteDailyData, { row: dataEntryObject })
                                .then(result => {
                                    if (result.data.recordExist && result.data.recordExist === true) {
                                        alert(result.data.message);
                                        loadData(filterData)
                                    } else {
                                        alert("Deleted succussfully!");
                                        store.dispatch(clearDataEntryRow());
                                        store.dispatch(updateCurrentEditDayAuto({ "currentEditDayAuto": 0 }))
                                        loadData(filterData);
                                    }

                                }).catch(err => {
                                    console.log(err);
                                });
                        } else {
                            alert("Please save the data you are editing and try again.");
                        }
                    }
                    else {
                        alert("something went wrong! Please try again");
                        window.location.reload();
                    }

                }
                }
            >Delete</button></td>
            <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} className="auto-column">{day}</td>

            {edit[day] ?
                <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} className="auto-column">
                    <input onChange={(e) => onChangeValue(e, "new_retail_delivered")} type="text" className="auto-input" value={dataEntry.new_retail_delivered}></input>
                </td>
                : <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} className="auto-column">{row?.[day]?.new_retail_delivered > 0 ? row?.[day]?.new_retail_delivered : 0}</td>}

            {edit[day] ?
                <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} className="auto-column">
                    <input onChange={(e) => onChangeValue(e, "new_retail_front_GP")} type="text" className="auto-input" value={dataEntry.new_retail_front_GP}></input>
                </td>
                : <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} className="auto-column">{row?.[day]?.new_retail_front_GP > 0 ? '$' + parseInt(row?.[day]?.new_retail_front_GP).toLocaleString() : '$' + 0}</td>}

            {edit[day] ?
                <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} className="auto-column">
                    <input onChange={(e) => onChangeValue(e, "new_retail_back_GP")} type="text" className="auto-input" value={dataEntry.new_retail_back_GP}></input>
                </td>
                : <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} className="auto-column">{row?.[day]?.new_retail_back_GP > 0 ? '$' + parseInt(row?.[day]?.new_retail_back_GP).toLocaleString() : '$' + 0}</td>}

            {edit[day] ?
                <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} className="auto-column">
                    <input onChange={(e) => onChangeValue(e, "new_CSI")} type="text" className="auto-input" value={dataEntry.new_CSI}></input>
                </td>
                : <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} className="auto-column">{row?.[day]?.new_CSI > 0 ? parseInt(row?.[day]?.new_CSI).toLocaleString() : 0}</td>}

            {edit[day] ?
                <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} className="auto-column">
                    <input onChange={(e) => onChangeValue(e, "used_retail_delivered")} type="text" className="auto-input" value={dataEntry.used_retail_delivered}></input>
                </td>
                : <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} className="auto-column">{row?.[day]?.used_retail_delivered > 0 ? row?.[day]?.used_retail_delivered : 0}</td>}
            {edit[day] ?
                <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} className="auto-column">
                    <input onChange={(e) => onChangeValue(e, "used_retail_front_GP")} type="text" className="auto-input" value={dataEntry.used_retail_front_GP}></input>
                </td>
                : <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} className="auto-column">{row?.[day]?.used_retail_front_GP > 0 ? '$' + parseInt(row?.[day]?.used_retail_front_GP).toLocaleString() : '$' + 0}</td>}
            {edit[day] ?
                <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} className="auto-column">
                    <input onChange={(e) => onChangeValue(e, "used_retail_back_GP")} type="text" className="auto-input" value={dataEntry.used_retail_back_GP}></input>
                </td>
                : <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} className="auto-column">{row?.[day]?.used_retail_back_GP > 0 ? '$' + parseInt(row?.[day]?.used_retail_back_GP).toLocaleString() : '$' + 0}</td>}

            {edit[day] ?
                <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} className="auto-column">
                    <input onChange={(e) => onChangeValue(e, "service_sales")} type="text" className="auto-input" value={dataEntry.service_sales}></input>
                </td>
                : <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} className="auto-column">{row?.[day]?.service_sales > 0 ? '$' + parseInt(row?.[day]?.service_sales).toLocaleString() : 0}</td>}

            {edit[day] ?
                <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} className="auto-column">
                    <input onChange={(e) => onChangeValue(e, "service_total_GP")} type="text" className="auto-input" value={dataEntry.service_total_GP}></input>
                </td>
                : <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} className="auto-column">{row?.[day]?.service_total_GP > 0 ? '$' + parseInt(row?.[day]?.service_total_GP).toLocaleString() : '$' + 0}</td>}


            {edit[day] ?
                <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} className="auto-column">
                    <input onChange={(e) => onChangeValue(e, "service_total_hours")} type="text" className="auto-input" value={dataEntry.service_total_hours}></input>
                </td>
                : <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} className="auto-column">{row?.[day]?.service_total_hours > 0 ? row?.[day]?.service_total_hours : 0}</td>}

            {edit[day] ?
                <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} className="auto-column">
                    <input onChange={(e) => onChangeValue(e, "service_CSI")} type="text" className="auto-input" value={dataEntry.service_CSI}></input>
                </td>
                : <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} className="auto-column">{row?.[day]?.service_CSI > 0 ? parseInt(row?.[day]?.service_CSI).toLocaleString() : 0}</td>}


            {edit[day] ?
                <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} className="auto-column">
                    <input onChange={(e) => onChangeValue(e, "service_CP_hours")} type="text" className="auto-input" value={dataEntry.service_CP_hours}></input>
                </td>
                : <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} className="auto-column">{row?.[day]?.service_CP_hours > 0 ? row?.[day]?.service_CP_hours : 0}</td>}

            {edit[day] ?
                <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} className="auto-column">
                    <input onChange={(e) => onChangeValue(e, "service_CP_ROs")} type="text" className="auto-input" value={dataEntry.service_CP_ROs}></input>
                </td>
                : <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} className="auto-column">{row?.[day]?.service_CP_ROs > 0 ? row?.[day]?.service_CP_ROs : 0}</td>}
            {edit[day] ?
                <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} className="auto-column">
                    <input onChange={(e) => onChangeValue(e, "service_CP_hrs_RO")} type="text" className="auto-input" value={dataEntry.service_CP_hrs_RO}></input>
                </td>
                : <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} className="auto-column"> {getServiceCPhrsDivideRO(row?.[day])}</td>}



            {/* {edit[day] ?
                <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} className="auto-column">
                    <input onChange={(e) => onChangeValue(e, "service_hours_available")} type="text" className="auto-input" value={dataEntry.service_hours_available}></input>
                </td>
                : <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} className="auto-column">{row?.[day]?.service_hours_available > 0 ? parseInt(row?.[day]?.service_hours_available).toLocaleString() : 0}</td>}



            {edit[day] ?
                <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} className="auto-column">
                    <input onChange={(e) => onChangeValue(e, "service_hours_sold")} type="text" className="auto-input" value={dataEntry.service_hours_sold}></input>
                </td>
                : <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} className="auto-column">{row?.[day]?.service_hours_sold > 0 ? parseInt(row?.[day]?.service_hours_sold).toLocaleString() : 0}</td>}


            <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} className="auto-column">{row?.[day]?.service_total_hours > 0 && row?.[day]?.service_hours_sold > 0 ? (parseInt(row?.[day]?.service_hours_sold) / parseInt(row?.[day]?.service_total_hours)).toFixed(2) : 0}</td>

            {edit[day] ?
                <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} className="auto-column">
                    <input onChange={(e) => onChangeValue(e, "service_no_of_tech")} type="text" className="auto-input" value={dataEntry.service_no_of_tech}></input>
                </td>
                : <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} className="auto-column">{row?.[day]?.service_no_of_tech > 0 ? parseInt(row?.[day]?.service_no_of_tech).toLocaleString() : 0}</td>}


            {edit[day] ?
                <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} className="auto-column">
                    <input onChange={(e) => onChangeValue(e, "service_journeyman")} type="text" className="auto-input" value={dataEntry.service_journeyman}></input>
                </td>
                : <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} className="auto-column">{row?.[day]?.service_journeyman > 0 ? parseInt(row?.[day]?.service_journeyman).toLocaleString() : 0}</td>}


            {edit[day] ?
                <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} className="auto-column">
                    <input onChange={(e) => onChangeValue(e, "service_apprentice")} type="text" className="auto-input" value={dataEntry.service_apprentice}></input>
                </td>
                : <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} className="auto-column">{row?.[day]?.service_apprentice > 0 ? parseInt(row?.[day]?.service_apprentice).toLocaleString() : 0}</td>}

{edit[day] ?
                <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} className="auto-column">
                    <input onChange={(e) => onChangeValue(e, "service_advisors_needed")} type="text" className="auto-input" value={dataEntry.service_advisors_needed}></input>
                </td>
                : <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} className="auto-column">{row?.[day]?.service_advisors_needed > 0 ? parseInt(row?.[day]?.service_advisors_needed).toLocaleString() : 0}</td>} */}

            {/* {edit[day] ?
                <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} className="auto-column">
                    <input onChange={(e) => onChangeValue(e, "service_booked_hours")} type="text" className="auto-input" value={dataEntry.service_booked_hours}></input>
                </td>
                : <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} className="auto-column">{row?.[day]?.service_booked_hours ? parseInt(row?.[day]?.service_booked_hours).toLocaleString() : 0}</td>} */}
            {/* {edit[day] ?
                <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} className="auto-column">
                    <input onChange={(e) => onChangeValue(e, "service_units_shipped")} type="text" className="auto-input" value={dataEntry.service_units_shipped}></input>
                </td>
                : <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} className="auto-column">{row?.[day]?.service_units_shipped ? parseInt(row?.[day]?.service_units_shipped).toLocaleString() : 0}</td>}
 */}


            {edit[day] ?
                <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} className="auto-column">
                    <input onChange={(e) => onChangeValue(e, "parts_sales")} type="text" className="auto-input" value={dataEntry.parts_sales}></input>
                </td>
                : <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} className="auto-column">{row?.[day]?.parts_sales > 0 ? '$' + parseInt(row?.[day]?.parts_sales).toLocaleString() : 0}</td>}

            {edit[day] ?
                <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} className="auto-column">
                    <input onChange={(e) => onChangeValue(e, "parts_total_GP")} type="text" className="auto-input" value={dataEntry.parts_total_GP}></input>
                </td>
                : <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} className="auto-column">{row?.[day]?.parts_total_GP > 0 ? '$' + parseInt(row?.[day]?.parts_total_GP).toLocaleString() : '$' + 0}</td>}

            {(edit[day] && userData.roles !== "normal") ?
                <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} className="auto-column">
                    <input onChange={(e) => onChangeValue(e, "appt_made")} type="text" className="auto-input" value={dataEntry.appt_made}></input>
                </td>
                : <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} className="auto-column">{row?.[day]?.appt_made > 0 ? parseInt(row?.[day]?.appt_made).toLocaleString() : 0}</td>}


            {(edit[day] && userData.roles !== "normal") ?
                <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} className="auto-column">
                    <input onChange={(e) => onChangeValue(e, "booked")} type="text" className="auto-input" value={dataEntry.booked}></input>
                </td>
                : <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} className="auto-column">{row?.[day]?.booked > 0 ? parseInt(row?.[day]?.booked).toLocaleString() : 0}</td>}

            {(edit[day] && userData.roles !== "normal") ?
                <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} className="auto-column">
                    <input onChange={(e) => onChangeValue(e, "appt_show")} type="text" className="auto-input" value={dataEntry.appt_show}></input>
                </td>
                : <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} className="auto-column">{row?.[day]?.appt_show > 0 ? parseInt(row?.[day]?.appt_show).toLocaleString() : 0}</td>}

            {(edit[day] && userData.roles !== "normal") ?
                <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} className="auto-column">
                    <input onChange={(e) => onChangeValue(e, "store_visit")} type="text" className="auto-input" value={dataEntry.store_visit}></input>
                </td>
                : <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} className="auto-column">{row?.[day]?.store_visit > 0 ? parseInt(row?.[day]?.store_visit).toLocaleString() : 0}</td>}




            {(edit[day] && userData.roles !== "normal") ?
                <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} className="auto-column">
                    <input onChange={(e) => onChangeValue(e, "internet_lead")} type="text" className="auto-input" value={dataEntry.internet_lead}></input>
                </td>
                : <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} className="auto-column">{row?.[day]?.internet_lead > 0 ? parseInt(row?.[day]?.internet_lead).toLocaleString() : 0}</td>}


            {(edit[day] && userData.roles !== "normal") ?
                <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} className="auto-column">
                    <input onChange={(e) => onChangeValue(e, "fresh_up")} type="text" className="auto-input" value={dataEntry.fresh_up}></input>
                </td>
                : <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} className="auto-column">{row?.[day]?.fresh_up > 0 ? parseInt(row?.[day]?.fresh_up).toLocaleString() : 0}</td>}
            {(edit[day] && userData.roles !== "normal") ?
                <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} className="auto-column">
                    <input onChange={(e) => onChangeValue(e, "phone_up")} type="text" className="auto-input" value={dataEntry.phone_up}></input>
                </td>
                : <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} className="auto-column">{row?.[day]?.phone_up > 0 ? parseInt(row?.[day]?.phone_up).toLocaleString() : 0}</td>}


            <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} className="auto-column">{parseInt(totalLeads).toLocaleString()}</td>

            {(edit[day] && userData.roles !== "normal") ?
                <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} className="auto-column">
                    <input onChange={(e) => onChangeValue(e, "sales_staff")} type="text" className="auto-input" value={dataEntry.sales_staff}></input>
                </td>
                : <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} className="auto-column">{row?.[day]?.sales_staff > 0 ? parseInt(row?.[day]?.sales_staff).toLocaleString() : 0}</td>}

            {/* {(edit[day] && userData.roles !== "normal") ?
                <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} className="auto-column">
                    <input onChange={(e) => onChangeValue(e, "no_show")} type="text" className="auto-input" value={dataEntry.no_show}></input>
                </td>
                : <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} className="auto-column">{row?.[day]?.no_show ? parseInt(row?.[day]?.no_show).toLocaleString() : 0}</td>} */}


            <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} className="auto-column">
                {getTotalGross(row?.[day])}
            </td>
            {row[day]?.diff <= 3 && row[day]?.locked_user && row[day]?.locked_user !== "Undefined" && row[day]?.locked_user !== "undefined" && row[day]?.locked_user !== 'null' && row[day]?.locked_user !== JSON.parse(Cookies.get('user_data')).userName &&
                <>
                    <div className="position-absolute text-capitalize text-center" style={{ backgroundColor: '#ff6961', color: 'white', left: '-1px', fontWeight: 'bold', fontSize: '17px', minWidth: '171px' }}>
                        {row[day]?.locked_user.split('.')[0]}
                    </div>
                </>
            }
        </tr>
    )
}

export default CreateRow;