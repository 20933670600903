import React from "react";
import { useHistory } from "react-router-dom";
import {
  Paper,
  Grid,
  Button,
  Typography,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableContainer,
  TableRow,
} from "@material-ui/core";
import { Edit, Delete } from "@material-ui/icons";
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { styled } from "@mui/material/styles";
import Links from "../../Data/Links";
import axios from "axios";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const UserList = (props) => {
  const history = useHistory();

  const editUserHandler = (user) => {
    //props.navigation.navigate('/user-edit', { title: "Edit User", user: user });
    history.push("/user-edit", { user: user });
  };

  const deleteUserHandler = (user) => {
    if (window.confirm(`Do you want to delete user ${user.userEmail}?`)) {
      axios
        .post(Links.deleteUser, user)
        .then((res) => {
          if (res.data.error) {
            alert("Error deleting user: " + res.data.error);
          } else {
            alert("User deleted successfully");
            props.update();
          }
        })
        .catch((err) => {
          alert("Something went wrong!");
        });
    }
  };


  const PasswordResetHandler = (user) => {
    if (window.confirm(`Do you want to send reset password link to the  user ${user.userEmail}?`)) {
      axios
        .post(Links.resetUserPassword, user)
        .then((res) => {
          if (res.data.error) {
            alert("Error Sending password reset link: " + res.data.error);
          } else {
            alert("Password reset link sent successfully");
            props.update();
          }
        })
        .catch((err) => {
          alert("Something went wrong!");
        });
    }
  };
  return (
    <>
      <Grid container justifyContent="center">
        <Paper
          style={{
            padding: "20px 40px",
            border: "2px solid #dbdbdb",
            marginTop: "50px",
            marginLeft: "120px",
            // marginRight: "auto",
            borderRadius: "10px",
            minWidth: "640px",
          }}
          variant="elevation"
          elevation={24}
        >
          <Grid item xs={12}>
            <h4
              className="mt-auto mb-3 text-center"
              style={{ color: "#F79020" }}
            >
              Users List
            </h4>
          </Grid>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="user-list-table">
              <TableHead>
                <TableRow>
                  <TableCell>User Email</TableCell>
                  <TableCell align="right">Status</TableCell>
                  <TableCell align="right">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.userList.map((u) => {
                  return (
                    <TableRow
                      key={u.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {u.userName}
                      </TableCell>
                      {u.active === 1 ?
                        <TableCell align="right">
                          <Typography variant="body1" color="primary">Active</Typography>
                        </TableCell> :
                        <TableCell align="right">
                          <Typography variant="body1" color="secondary">Inactive</Typography>
                        </TableCell>
                      }

                      <TableCell align="right">
                        <Grid container spacing={2} alignContent="center" justifyContent="flex-end">
                          <Grid item>
                            <Button
                              name="Reset Password"
                              className="text-white mr-2"
                              variant="outlined"
                              style={{
                                textTransform: "none",
                                borderColor: "white",
                                backgroundColor: "#000000",
                              }}
                              onClick={() => {
                                const user = {
                                  id: u.id,
                                  userName: u.userName,
                                  userEmail: u.userEmail,
                                  roles: u.roles,
                                  stores: u.stores,
                                  active: u.active,
                                };
                                PasswordResetHandler(user);
                              }}
                            >
                              <LockOpenIcon/>
                            <span className="ms-2">Reset Password</span>
                            </Button>
                          </Grid>


                          <Grid item>
                            <Button
                              className="text-white mr-2"
                              variant="outlined"
                              style={{
                                textTransform: "none",
                                borderColor: "white",
                                backgroundColor: "#F79020",
                              }}
                              onClick={() => {
                                const user = {
                                  id: u.id,
                                  userName: u.userName,
                                  userEmail: u.userEmail,
                                  roles: u.roles,
                                  stores: u.stores,
                                  active: u.active,
                                };
                                deleteUserHandler(user);
                              }}
                            >
                              <Delete />
                            </Button>
                          </Grid>

                          <Grid item>
                            <Button
                              className="text-white"
                              variant="outlined"
                              style={{
                                textTransform: "none",
                                borderColor: "white",
                                backgroundColor: "#388E3C",
                              }}
                              onClick={() => {
                                const user = {
                                  id: u.id,
                                  userName: u.userName,
                                  userEmail: u.userEmail,
                                  roles: u.roles,
                                  stores: u.stores,
                                  active: u.active,
                                };
                                editUserHandler(user);
                              }}
                            >
                              <Edit />
                            </Button>
                          </Grid>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Grid>
    </>
  );
};

export default UserList;
