import React, { useState, useEffect } from "react";
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import QuizIcon from '@mui/icons-material/Quiz';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import BusinessIcon from '@mui/icons-material/Business';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import BuildIcon from '@mui/icons-material/Build';
import axios from 'axios';
import Links from "../../../src/Data/Links.js";
import moment from 'moment';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { MONTHS, YEARS, WEEKS } from "../../../src/constants.js"
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Cookies from 'js-cookie';
const styles = {
    inputField: {
        width: 120
    }
}








const Sop = () => {
    const userName = JSON.parse(Cookies.get('user_data')).userName;
    const [stores, setStores] = useState(localStorage.getItem('stores') ? JSON.parse(localStorage.getItem('stores')) : "");
    const currentYear = moment().format('YYYY');
    const currentMonth = moment().format('MMM');
    const currentDate = moment().format('DD');
    const currentWeek = Math.ceil(currentDate / 7) - 1 > 1 ? Math.ceil(currentDate / 7) - 1 : 1;
    const [month, setMonth] = useState(currentMonth);
    const [years, setYears] = useState([]);
    const [year, setYear] = useState(currentYear);
    const [week, setWeek] = useState(currentWeek);
    let [buttonFlash, setButtonFlash] = useState(false);
    let [currentStore, setCurrentStore] = useState(2)


    const handleYearChange = (event) => {
        setYear(event.target.value);
        // setButtonFlash(true);
    };
    const handleMonthChange = (event) => {
        setMonth(event.target.value);
        // setButtonFlash(true);
    };

    const handleWeekChange = (event) => {
        setCurrentStore(event.target.value);
        // setButtonFlash(true);
    };

    const handleStoreChange = (event) => {
        setCurrentStore(event.target.value);
        // setButtonFlash(true);
    };



    useEffect(() => {

        setYears(YEARS);

    }, []);


    return (
        <div style={{ margin: "1rem 1rem 1rem 20rem" }}>

            <Grid item xs={12} container spacing={1}>

                <Grid item xs={2}>
                    <FormControl variant="standard" sx={{ m: 1, width: "100%" }}>
                        <InputLabel id="demo-simple-select-label">Store</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={currentStore}
                            label="Store"
                            onChange={handleStoreChange}
                        >
                            {stores.map((store, index) => (
                                <MenuItem key={index} value={store.id}>{store.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={2}>
                    <FormControl variant="standard" sx={{ m: 1, width: "100%" }}>
                        <InputLabel id="demo-simple-select-label">Year</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={year}
                            label="Year"
                            onChange={handleYearChange}
                        >
                            {years.map((year, index) => (
                                <MenuItem key={index} value={year}>{year}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={2}>
                    <FormControl variant="standard" sx={{ m: 1, width: "100%" }}>
                        <InputLabel id="demo-simple-select-label">Month</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={month}
                            label="Age"
                            onChange={handleMonthChange}
                        >
                            {Object.values(MONTHS).map((month, index) => (
                                <MenuItem key={index} value={month}>{month}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>

                {/* <Grid item xs={2}>
                    <FormControl variant="standard" sx={{ m: 1, width: "100%" }}>
                        <InputLabel id="demo-simple-select-label">Week</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={week}
                            label="Age"
                            onChange={handleWeekChange}
                        >
                            {Object.values(WEEKS).map((week, index) => (
                                <MenuItem key={index} value={week}>Week {week}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid> */}
                {/* <Grid item xs={3} className="mt-3">
                    <button className={`p-2 mt-1 btn btn-secondary mx-3 ${buttonFlash === true && "button-glow"}`} type="submit"
                        onClick={() => {
                            setButtonFlash(false);
                            showResults()
                        }}
                    >Show Details</button>
                </Grid> */}


            </Grid>

            <div className="mt-5">

                <h3 style={{ color: "#198754" }}><BusinessIcon fontSize="large" />{" "}Entrance</h3>
                <div className="m-4">
                    <FormGroup >
                        <FormControlLabel control={<Checkbox color="warning" />} label="Warm welcome or acknowledgment" />
                        <FormControlLabel control={<Checkbox color="warning" />} label="Dress code" />
                        <FormControlLabel control={<Checkbox color="warning" />} label="Clean floors" />
                        <FormControlLabel control={<Checkbox color="warning" />} label="Bathrooms are clean and well supplied" />
                        <FormControlLabel control={<Checkbox color="warning" />} label="Windows are clean" />
                    </FormGroup>
                </div>


                <h3 style={{ color: "#198754" }}><DirectionsCarIcon fontSize="large" />{" "}Sales Lot</h3>
                <div className="m-4">
                    <FormGroup >
                        <FormControlLabel control={<Checkbox color="warning" />} label="Cars lined and proper stickers and merchandising displayed" />
                        <FormControlLabel control={<Checkbox color="warning" />} label="Outside of cars are clean" />
                        <FormControlLabel control={<Checkbox color="warning" />} label="License plate Frames" />
                        <FormControlLabel control={<Checkbox color="warning" />} label="FFUN flags up and in good shape" />
                    </FormGroup>
                </div>


                <h3 style={{ color: "#198754" }}><WarehouseIcon fontSize="large" />{" "}Showroom</h3>
                <div className="m-4">
                    <FormGroup >
                        <FormControlLabel control={<Checkbox color="warning" />} label="Clean desks" />
                        <FormControlLabel control={<Checkbox color="warning" />} label=" FFUN promotional posters" />
                        <FormControlLabel control={<Checkbox color="warning" />} label="FFUN Core values displayed" />
                    </FormGroup>
                </div>


                <h3 style={{ color: "#198754" }}><BuildIcon fontSize="large" />{" "}Parts & Service</h3>
                <div className="m-4">
                    <FormGroup >
                        <FormControlLabel control={<Checkbox color="warning" />} label="Service lane clean and organized" />
                        <FormControlLabel control={<Checkbox color="warning" />} label="Employee uniforms" />
                        <FormControlLabel control={<Checkbox color="warning" />} label="License plate frames swapped over" />
                        <FormControlLabel control={<Checkbox color="warning" />} label="FFUN Posters displayed" />
                        <FormControlLabel control={<Checkbox color="warning" />} label="Shuttle clean, inside and out" />
                    </FormGroup>
                </div>

                <h3 style={{ color: "#198754" }}><HealthAndSafetyIcon fontSize="large" />{" "}Safety</h3>
                <div className="m-4">
                    <FormGroup >
                        <FormControlLabel control={<Checkbox color="warning" />} label="Up to date safety minutes" />
                    </FormGroup>
                </div>

                <button className={`p-2 mb-5 mt-1 btn btn-success mx-3`} type="button"
                    onClick={() => {
                        alert("Your review is submitted successfully!")
                        window.location.reload();
                    }}
                >Submit Review</button>

            </div>


            <table className="table table-bordered border-black table-fit table-striped mb-5" style={{ marginTop: 20 }}>
                <thead>
                    <tr className="text-center">
                        <th colSpan="1" className="dn-header" style={{ whiteSpace: 'nowrap', backgroundColor: 'white' }}>Rank</th>
                        <th colSpan="1" className="dn-header" style={{ whiteSpace: 'nowrap', backgroundColor: 'white' }}>Store</th>
                        <th colSpan="1" className="dn-header" style={{ whiteSpace: 'nowrap', backgroundColor: 'white' }}>Overall Rank Score</th>
                        <th colSpan="1" className="dn-header" style={{ whiteSpace: 'nowrap', backgroundColor: 'white' }}>Entrance</th>
                        <th colSpan="1" className="dn-header" style={{ whiteSpace: 'nowrap', backgroundColor: 'white' }}>Sales Lot</th>
                        <th colSpan="1" className="dn-header" style={{ whiteSpace: 'nowrap', backgroundColor: 'white' }}>Show Room</th>
                        <th colSpan="1" className="dn-header" style={{ whiteSpace: 'nowrap', backgroundColor: 'white' }}>Parts & Service</th>
                        <th colSpan="1" className="dn-header" style={{ whiteSpace: 'nowrap', backgroundColor: 'white' }}>Safety</th>

                    </tr>
                </thead>
                <tbody>
                    <tr >
                        <td style={{ backgroundColor: 'white', textAlign: 'center' }}>
                            1
                        </td>
                        <td style={{ textAlign: 'center' }}>
                            Acura
                        </td>
                        <td style={{ textAlign: 'center' }}>
                            18
                        </td>
                        <td style={{ textAlign: 'center' }}>
                            5
                        </td>
                        <td style={{ textAlign: 'center' }}>
                            4
                        </td>
                        <td style={{ textAlign: 'center' }}>
                            3
                        </td>
                        <td style={{ textAlign: 'center' }}>
                            5
                        </td>
                        <td style={{ textAlign: 'center' }}>
                            1
                        </td>
                    </tr>

                    <tr >
                        <td style={{ backgroundColor: 'white', textAlign: 'center' }}>
                            2
                        </td>
                        <td style={{ textAlign: 'center' }}>
                            VW
                        </td>
                        <td style={{ textAlign: 'center' }}>
                            17
                        </td>
                        <td style={{ textAlign: 'center' }}>
                            4
                        </td>
                        <td style={{ textAlign: 'center' }}>
                            4
                        </td>
                        <td style={{ textAlign: 'center' }}>
                            3
                        </td>
                        <td style={{ textAlign: 'center' }}>
                            5
                        </td>
                        <td style={{ textAlign: 'center' }}>
                            1
                        </td>
                    </tr>

                    <tr >
                        <td style={{ backgroundColor: 'white', textAlign: 'center' }}>
                            3
                        </td>
                        <td style={{ textAlign: 'center' }}>
                            Hyundai South
                        </td>
                        <td style={{ textAlign: 'center' }}>
                            16
                        </td>
                        <td style={{ textAlign: 'center' }}>
                            4
                        </td>
                        <td style={{ textAlign: 'center' }}>
                            3
                        </td>
                        <td style={{ textAlign: 'center' }}>
                            3
                        </td>
                        <td style={{ textAlign: 'center' }}>
                            5
                        </td>
                        <td style={{ textAlign: 'center' }}>
                            1
                        </td>
                    </tr>
                    <tr >
                        <td style={{ backgroundColor: 'white', textAlign: 'center' }}>
                            4
                        </td>
                        <td style={{ textAlign: 'center' }}>
                            FFUN Cars
                        </td>
                        <td style={{ textAlign: 'center' }}>
                            15
                        </td>
                        <td style={{ textAlign: 'center' }}>
                            4
                        </td>
                        <td style={{ textAlign: 'center' }}>
                            3
                        </td>
                        <td style={{ textAlign: 'center' }}>
                            2
                        </td>
                        <td style={{ textAlign: 'center' }}>
                            5
                        </td>
                        <td style={{ textAlign: 'center' }}>
                            1
                        </td>
                    </tr>

                    <tr >
                        <td style={{ backgroundColor: 'white', textAlign: 'center' }}>
                            5
                        </td>
                        <td style={{ textAlign: 'center' }}>
                            Hyundai North
                        </td>
                        <td style={{ textAlign: 'center' }}>
                            15
                        </td>
                        <td style={{ textAlign: 'center' }}>
                            4
                        </td>
                        <td style={{ textAlign: 'center' }}>
                            3
                        </td>
                        <td style={{ textAlign: 'center' }}>
                            2
                        </td>
                        <td style={{ textAlign: 'center' }}>
                            5
                        </td>
                        <td style={{ textAlign: 'center' }}>
                            1
                        </td>
                    </tr>

                    <tr >
                        <td style={{ backgroundColor: 'white', textAlign: 'center' }}>
                            6
                        </td>
                        <td style={{ textAlign: 'center' }}>
                            KIA
                        </td>
                        <td style={{ textAlign: 'center' }}>
                            14
                        </td>
                        <td style={{ textAlign: 'center' }}>
                            4
                        </td>
                        <td style={{ textAlign: 'center' }}>
                            2
                        </td>
                        <td style={{ textAlign: 'center' }}>
                            2
                        </td>
                        <td style={{ textAlign: 'center' }}>
                            5
                        </td>
                        <td style={{ textAlign: 'center' }}>
                            1
                        </td>
                    </tr>

                </tbody>
            </table>

            <div className="py-5"></div>

        </div>)
};

export default Sop;
