import * as actions from "./actionTypes";

export const updateTotalStoreGross = update => ({
  type: actions.UPDATE_TOTAL_STORE_GROSS,
  payload: {
    ...update
  },
});

export const updateTotalBudget = update => ({
  type: actions.UPDATE_TOTAL_BUDGET,
  payload: {
    ...update
  },
});

export const updateDataEntryRow = update => ({
  type: actions.UPDATE_DATA_ENTRY_ROW,
  payload: {
    ...update
  },
});

export const clearDataEntryRow = () => ({
  type: actions.CLEAR_DATA_ENTRY_ROW,
  payload: {},
});

export const updateDataEntryRecRow = update => ({
  type: actions.UPDATE_DATA_ENTRY_REC_ROW,
  payload: {
    ...update
  },
});

export const clearDataEntryRecRow = () => ({
  type: actions.CLEAR_DATA_ENTRY_REC_ROW,
  payload: {},
});

export const updateDataEntryFFRow = update => ({
  type: actions.UPDATE_DATA_ENTRY_FF_ROW,
  payload: {
    ...update
  },
});

export const clearDataEntryFFRow = () => ({
  type: actions.CLEAR_DATA_ENTRY_FF_ROW,
  payload: {},
});


export const updateBudgetRow = update => ({
  type: actions.UPDATE_BUDGET_ROW,
  payload: {
    ...update
  },
});

export const clearBudgetRow = () => ({
  type: actions.CLEAR_BUDGET_ROW,
  payload: {},
});


export const updateStaffRow = update => ({
  type: actions.UPDATE_STAFF_ROW,
  payload: {
    ...update
  },
});

export const clearStaffRow = () => ({
  type: actions.UPDATE_STAFF_ROW,
  payload: {},
});


export const updateBudgetRecRow = update => ({
  type: actions.UPDATE_BUDGET_REC_ROW,
  payload: {
    ...update
  },
});

export const clearBudgetRecRow = () => ({
  type: actions.CLEAR_BUDGET_REC_ROW,
  payload: {},
});

export const updateBudgetFFRow = update => ({
  type: actions.UPDATE_BUDGET_FF_ROW,
  payload: {
    ...update
  },
});

export const clearBudgetFFRow = () => ({
  type: actions.CLEAR_BUDGET_FF_ROW,
  payload: {},
});


export const updateTotalOfCPHrsRo = update => ({
  type: actions.UPDATE_TOTAL_OF_CP_HRS_RO,
  payload: {
    ...update
  },
});

export const updateCurrentEditDayRec = update => ({
  type: actions.UPDATE_CURRENT_EDIT_DAY_REC,
  payload: {
    ...update
  },
});


export const updateCurrentEditDayFF = update => ({
  type: actions.UPDATE_CURRENT_EDIT_DAY_FF,
  payload: {
    ...update
  },
});

export const updateCurrentEditDayAuto = update => ({
  type: actions.UPDATE_CURRENT_EDIT_DAY_AUTO,
  payload: {
    ...update
  },
});

export const updateCurrentEditDayUsed = update => ({
  type: actions.UPDATE_CURRENT_EDIT_DAY_USED,
  payload: {
    ...update
  },
});