import React from "react";
import { Redirect, Route } from "react-router-dom";
import Cookies from 'js-cookie';

const ProtectedRoute = ({ component: Component, ...restOfProps}) => {
  const isAuthenticated = Cookies.get('user_data')||"";
 return (
    <Route
      {...restOfProps}
      render={(props) =>
        isAuthenticated!=="" && isAuthenticated!==undefined  ? <Component {...props} /> : <Redirect to="/signin" />
      }
    />
  );
}




export default ProtectedRoute;