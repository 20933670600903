import React, { useEffect } from "react";
import Cookies from 'js-cookie';
import { store, updateTotalBudget } from "../../redux";

const TotalRowForUsed = ({ total }) => {
  let userData;
  if (Cookies.get('user_data')) {
    userData = JSON.parse(Cookies.get('user_data'));
  }

  if (!total) {
    total = {}
  }

  let totalStoreGross = parseInt(total.used_retail_front_GP ? total.used_retail_front_GP : 0) +
    parseInt(total.used_retail_back_GP ? total.used_retail_back_GP : 0) +
    parseInt(total.service_total_GP ? total.service_total_GP : 0) +
    parseInt(total.parts_total_GP ? total.parts_total_GP : 0);
  if (isNaN(totalStoreGross) || totalStoreGross === 'NaN' || totalStoreGross === 'Infinity' || totalStoreGross === null) {
    totalStoreGross = '0'
  }

  useEffect(() => {
    store.dispatch(updateTotalBudget({ totalBudget: totalStoreGross }));
  }, [totalStoreGross])

  return (
    <tr>
      {userData.roles !== "normal" && userData.roles !== "Radmin" &&
        <>
          <td className="dn-column"></td>
          <td className="auto-column"></td>
        </>}
      <td className="auto-column">Total</td>
      <td className="auto-column">{total.used_retail_delivered ? total.used_retail_delivered : 0}</td>
      <td className="auto-column">${total.used_retail_front_GP ? total.used_retail_front_GP?.toLocaleString() : 0}</td>
      <td className="auto-column">${total.used_retail_back_GP ? total.used_retail_back_GP?.toLocaleString() : 0}</td>
      <td className="auto-column">${total.service_total_GP ? total.service_total_GP?.toLocaleString() : 0}</td>
      <td className="auto-column">{total.service_total_hours ? total.service_total_hours : 0}</td>
      <td className="auto-column">{total.service_CP_hours ? total.service_CP_hours : 0}</td>
      <td className="auto-column">{total.service_CP_ROs ? total.service_CP_ROs : 0}</td>
{/*      
      <td className="auto-column" >{total.service_hours_available ? total.service_hours_available.toLocaleString() : 0}</td>
      <td className="auto-column" >{total.service_hours_sold ? total.service_hours_sold.toLocaleString() : 0}</td>
    
      <td className="auto-column" >{total.service_no_of_tech ? total.service_no_of_tech.toLocaleString() : 0}</td>
      <td className="auto-column" >{total.service_journeyman ? total.service_journeyman.toLocaleString() : 0}</td>
     
      <td className="auto-column" >{total.service_apprentice ? total.service_apprentice.toLocaleString() : 0}</td>
      <td className="auto-column" >{total.service_advisors_needed ? total.service_advisors_needed.toLocaleString() : 0}</td> */}
      <td className="auto-column" >{total.service_units_shipped ? total.service_units_shipped.toLocaleString() : 0}</td>

      <td className="auto-column">{total.appt_made ? total.appt_made : 0}</td>
      <td className="auto-column" >{total.booked ? total.booked : 0}</td>
      <td className="auto-column">{total.appt_show ? total.appt_show : 0}</td>
      <td className="auto-column">{total.store_visit ? total.store_visit : 0}</td>
 
      <td className="auto-column">{total.internet_lead ? total.internet_lead : 0}</td>

      <td className="auto-column" >{total.fresh_up ? total.fresh_up : 0}</td>
      <td className="auto-column" >{total.phone_up ? total.phone_up : 0}</td>
      <td className="auto-column">{total.lead ? total.lead : 0}</td>
      <td className="auto-column" >{total.sales_staff ? total.sales_staff : 0}</td>
   
      {/* <td className="auto-column" >{total.no_show ? total.no_show : 0}</td> */}
      <td className="auto-column">${totalStoreGross?.toLocaleString()}</td>
    </tr>
  )
}

export default TotalRowForUsed