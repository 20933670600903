import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useSelector } from 'react-redux';
import axios from 'axios';
import Links from "../../Data/Links";
import { store, updateTotalOfCPHrsRo, updateTotalStoreGross, updateDataEntryRow } from "../../redux";
import CreateRow from "../../component/DataEntry/CreateRow";
import monthData from "../../constants/monthData";
import yearData from "../../constants/yearData";
import dayData from "../../constants/dayData";
import Cookies from 'js-cookie';
import { calculateKPIPercentage } from "../../utils";
import { connect } from 'react-redux';
import getFiscals from '../../utils/getFiscals';

// Code Splitting for furture 
const RowTotal = ({ total }) => {
  if (!total) {
    total = {}
  }
  let totalOfDailydata = parseInt(total.new_retail_front_GP ? total.new_retail_front_GP : 0) +
    parseInt(total.new_retail_back_GP ? total.new_retail_back_GP : 0) +
    parseInt(total.used_retail_front_GP ? total.used_retail_front_GP : 0) +
    parseInt(total.used_retail_back_GP ? total.used_retail_back_GP : 0) +
    parseInt(total.service_total_GP ? total.service_total_GP : 0) +
    parseInt(total.parts_total_GP ? total?.parts_total_GP : 0);

  if (isNaN(totalOfDailydata) || totalOfDailydata === 'NaN' || totalOfDailydata === 'Infinity' || totalOfDailydata === null) {
    totalOfDailydata = '0'
  }

  useEffect(() => {
    store.dispatch(updateTotalStoreGross({ totalStoreGross: totalOfDailydata }));
  }, [totalOfDailydata])

  let service_CP_hours = total.service_CP_hours ? parseFloat(total.service_CP_hours) : 0;
  let service_CP_ROs = total.service_CP_ROs ? parseFloat(total.service_CP_ROs) : 0;
  let totalCPHrsRo = (service_CP_hours / service_CP_ROs).toFixed(2);
  if (totalCPHrsRo === "NaN" || totalCPHrsRo === 'Infinity') {
    totalCPHrsRo = 0
  }
  totalCPHrsRo = parseFloat(totalCPHrsRo);

  useEffect(() => {
    store.dispatch(updateTotalOfCPHrsRo({ serviceCPhrsDivideRO: totalCPHrsRo }));
  }, [totalCPHrsRo])

  return (
    <tr>
      <td className="auto-column" style={{ "background-color": "#e9ecef" }}></td>
      <td className="auto-column" style={{ "background-color": "#e9ecef" }}></td>
      <td className="auto-column" style={{ "background-color": "#e9ecef" }}>Total</td>
      <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{total.new_retail_delivered ? total.new_retail_delivered : 0}</td>
      <td className="auto-column" style={{ "background-color": "#e9ecef" }}>${total.new_retail_front_GP ? parseInt(total.new_retail_front_GP)?.toLocaleString() : 0}</td>
      <td className="auto-column" style={{ "background-color": "#e9ecef" }}>${total.new_retail_back_GP ? parseInt(total.new_retail_back_GP)?.toLocaleString() : 0}</td>
      <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{total.new_CSI && total.new_CSI_count ? (total.new_CSI / total.new_CSI_count).toFixed(2).toLocaleString() : 0}</td>
      <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{total.used_retail_delivered ? total.used_retail_delivered : 0}</td>
      <td className="auto-column" style={{ "background-color": "#e9ecef" }}>${total.used_retail_front_GP ? total.used_retail_front_GP?.toLocaleString() : 0}</td>
      <td className="auto-column" style={{ "background-color": "#e9ecef" }}>${total.used_retail_back_GP ? total.used_retail_back_GP?.toLocaleString() : 0}</td>

      <td className="auto-column" style={{ "background-color": "#e9ecef" }}>${total.service_sales ? total.service_sales?.toLocaleString() : 0}</td>
      <td className="auto-column" style={{ "background-color": "#e9ecef" }}>${total.service_total_GP ? total.service_total_GP?.toLocaleString() : 0}</td>
      <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{total.service_total_hours ? total.service_total_hours : 0}</td>
      <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{total.service_CSI && total.service_CSI_count ? (total.service_CSI / total.service_CSI_count).toFixed(2).toLocaleString() : 0}</td>

      <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{total.service_CP_hours ? total.service_CP_hours : 0}</td>
      <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{total.service_CP_ROs ? total.service_CP_ROs : 0}</td>
      <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{totalCPHrsRo ? totalCPHrsRo : 0}</td>
      {/* <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{total.service_hours_available ? total.service_hours_available.toLocaleString() : 0}</td> */}


      {/* <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{total.service_hours_sold ? total.service_hours_sold.toLocaleString() : 0}</td> */}



      {/* <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{total.service_total_hours && total.service_hours_sold ? (total.service_hours_sold / total.service_total_hours).toFixed(2) : 0}</td>

      <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{total.service_no_of_tech ? total.service_no_of_tech.toLocaleString() : 0}</td>
      <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{total.service_journeyman ? total.service_journeyman.toLocaleString() : 0}</td>
      <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{total.service_apprentice ? total.service_apprentice.toLocaleString() : 0}</td>
      <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{total.service_advisors_needed ? total.service_advisors_needed.toLocaleString() : 0}</td> */}

      {/* <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{total.service_booked_hours ? total.service_booked_hours.toLocaleString() : 0}</td> */}
      {/* <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{total.service_units_shipped ? total.service_units_shipped.toLocaleString() : 0}</td> */}

      <td className="auto-column" style={{ "background-color": "#e9ecef" }}>${total.parts_sales ? total.parts_sales.toLocaleString() : 0}</td>
      <td className="auto-column" style={{ "background-color": "#e9ecef" }}>${total.parts_total_GP ? total.parts_total_GP?.toLocaleString() : 0}</td>
      <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{total.appt_made ? total.appt_made : 0}</td>
      <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{total.booked ? total.booked : 0}</td>
      <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{total.appt_show ? total.appt_show : 0}</td>
      <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{total.store_visit ? total.store_visit : 0}</td>

      <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{total.internet_lead ? total.internet_lead : 0}</td>
      <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{total.fresh_up ? total.fresh_up : 0}</td>
      <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{total.phone_up ? total.phone_up : 0}</td>
      <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{total.lead ? total.lead : 0}</td>
      <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{total.sales_staff ? total.sales_staff : 0}</td>

      {/* <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{total.no_show ? total.no_show : 0}</td> */}




      <td className="auto-column" style={{ "background-color": "#e9ecef" }}>${totalOfDailydata?.toLocaleString()}</td>
    </tr>
  )
}

const RowForecast = ({ budget }) => {
  if (!budget) {
    budget = {}
  }
  let totalOfDailydata = parseInt(budget?.new_retail_front_GP ? budget?.new_retail_front_GP : 0) +
    parseInt(budget?.new_retail_back_GP ? budget?.new_retail_back_GP : 0) +
    parseInt(budget?.used_retail_front_GP ? budget?.used_retail_front_GP : 0) +
    parseInt(budget?.used_retail_back_GP ? budget?.used_retail_back_GP : 0) +
    parseInt(budget?.service_total_GP ? budget?.service_total_GP : 0) +
    parseInt(budget?.parts_total_GP ? budget?.parts_total_GP : 0);


  if (isNaN(totalOfDailydata) || totalOfDailydata === 'NaN' || totalOfDailydata === 'Infinity' || totalOfDailydata === null) {
    totalOfDailydata = '0'
  }
  let totalCPHrsRo = (budget.service_CP_hours / budget.service_CP_ROs).toFixed(2);
  if (totalCPHrsRo === "NaN" || totalCPHrsRo === 'Infinity') {
    totalCPHrsRo = 0
  }

  return (
    <tr>
      <td className="auto-column" style={{ "background-color": "#e9ecef" }}></td>
      <td className="auto-column" style={{ "background-color": "#e9ecef" }}></td>
      <td className="auto-column" style={{ "background-color": "#e9ecef" }}>Forecast</td>
      <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{budget.new_retail_delivered ? budget.new_retail_delivered : 0}</td>
      <td className="auto-column" style={{ "background-color": "#e9ecef" }}>${budget.new_retail_front_GP ? parseInt(budget.new_retail_front_GP)?.toLocaleString() : 0}</td>
      <td className="auto-column" style={{ "background-color": "#e9ecef" }}>${budget.new_retail_back_GP ? parseInt(budget.new_retail_back_GP)?.toLocaleString() : 0}</td>
      <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{budget.new_CSI ? parseFloat(budget.new_CSI).toFixed(2) : 0}</td>
      <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{budget.used_retail_delivered ? budget.used_retail_delivered : 0}</td>
      <td className="auto-column" style={{ "background-color": "#e9ecef" }}>${budget.used_retail_front_GP ? parseInt(budget.used_retail_front_GP)?.toLocaleString() : 0}</td>
      <td className="auto-column" style={{ "background-color": "#e9ecef" }}>${budget.used_retail_back_GP ? parseInt(budget.used_retail_back_GP)?.toLocaleString() : 0}</td>

      <td className="auto-column" style={{ "background-color": "#e9ecef" }}>${budget.service_sales ? parseInt(budget.service_sales)?.toLocaleString() : 0}</td>
      <td className="auto-column" style={{ "background-color": "#e9ecef" }}>${budget.service_total_GP ? parseInt(budget.service_total_GP)?.toLocaleString() : 0}</td>
      <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{budget.service_total_hours ? budget.service_total_hours : 0}</td>
      <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{budget.service_CSI ? parseFloat(budget.service_CSI).toFixed(2) : 0}</td>
      <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{budget.service_CP_hours ? budget.service_CP_hours : 0}</td>
      <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{budget.service_CP_ROs ? budget.service_CP_ROs : 0}</td>
      <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{totalCPHrsRo ? totalCPHrsRo : 0}</td>


      {/* <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{budget.service_hours_available ? parseInt(budget.service_hours_available).toLocaleString() : 0}</td>
      <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{budget.service_hours_sold ? parseInt(budget.service_hours_sold).toLocaleString() : 0}</td>



      <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{budget.service_total_hours && budget.service_hours_sold ? (budget.service_hours_sold / budget.service_total_hours).toFixed(2) : 0}</td>

      <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{budget.service_no_of_tech ? parseInt(budget.service_no_of_tech).toLocaleString() : 0}</td>
      <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{budget.service_journeyman ? parseInt(budget.service_journeyman).toLocaleString() : 0}</td>
      <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{budget.service_apprentice ? parseInt(budget.service_apprentice).toLocaleString() : 0}</td> */}
      {/* <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{budget.service_advisors_needed ? parseInt(budget.service_advisors_needed).toLocaleString() : 0}</td> */}
      {/* <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{budget.service_booked_hours ? parseInt(budget.service_booked_hours).toLocaleString() : 0}</td> */}
      {/* <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{budget.service_units_shipped ? parseInt(budget.service_units_shipped).toLocaleString() : 0}</td> */}

      <td className="auto-column" style={{ "background-color": "#e9ecef" }}>${budget.parts_sales ? parseInt(budget.parts_sales).toLocaleString() : 0}</td>
      <td className="auto-column" style={{ "background-color": "#e9ecef" }}>${budget.parts_total_GP ? parseInt(budget.parts_total_GP)?.toLocaleString() : 0}</td>
      <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{budget.appt_made ? budget.appt_made : 0}</td>
      <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{budget.booked ? budget.booked : 0}</td>
      <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{budget.appt_show ? budget.appt_show : 0}</td>
      <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{budget.store_visit ? budget.store_visit : 0}</td>

      <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{budget.internet_lead ? budget.internet_lead : 0}</td>

      <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{budget.fresh_up ? budget.fresh_up : 0}</td>
      <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{budget.phone_up ? budget.phone_up : 0}</td>
      <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{parseInt(budget.internet_lead || 0) + parseInt(budget.fresh_up || 0) + parseInt(budget.phone_up || 0)}</td>
      <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{budget.sales_staff ? budget.sales_staff : 0}</td>

      {/* <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{budget.no_show ? budget.no_show : 0}</td> */}

      <td className="auto-column" style={{ "background-color": "#e9ecef" }}>${totalOfDailydata?.toLocaleString()}</td>
    </tr>
  )
}

const RowKPI = ({ total, budget }) => {
  if (!total) {
    total = {}
  }
  if (!budget) {
    budget = {}
  }
  let budgetOfDailydata = parseInt(budget.new_retail_front_GP ? budget.new_retail_front_GP : 0) +
    parseInt(budget.new_retail_back_GP ? budget.new_retail_back_GP : 0) +
    parseInt(budget.used_retail_front_GP ? budget.used_retail_front_GP : 0) +
    parseInt(budget.used_retail_back_GP ? budget.used_retail_back_GP : 0) +
    parseInt(budget.service_total_GP ? budget.service_total_GP : 0) +
    parseInt(budget.parts_total_GP ? budget.parts_total_GP : 0) +
    parseInt(budget.parts_whole_sale_GP ? budget.parts_whole_sale_GP : 0);

  if (isNaN(budgetOfDailydata) || budgetOfDailydata === 'NaN' || budgetOfDailydata === 'Infinity' || budgetOfDailydata === null) {
    budgetOfDailydata = '0'
  }

  const totalStoreGross = useSelector(state => state.dataEntryBudget.total);

  return (
    <tr>
      <td className="auto-column" style={{ "background-color": "#e9ecef" }}></td>
      <td className="auto-column" style={{ "background-color": "#e9ecef" }}></td>
      <td className="auto-column" style={{ "background-color": "#e9ecef" }}>KPI</td>
      <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.new_retail_delivered, budget.new_retail_delivered)}</td>
      <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.new_retail_front_GP, budget.new_retail_front_GP)}</td>
      <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.new_retail_back_GP, budget.new_retail_back_GP)}</td>
      <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.new_CSI, budget.new_CSI)}</td>
      <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.used_retail_delivered, budget.used_retail_delivered)}</td>
      <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.used_retail_front_GP, budget.used_retail_front_GP)}</td>
      <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.used_retail_back_GP, budget.used_retail_back_GP)}</td>

      <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.service_sales, budget.service_sales)}</td>
      <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.service_total_GP, budget.service_total_GP)}</td>
      <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.service_total_hours, budget.service_total_hours)}</td>
      <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.service_CSI, budget.service_CSI)}</td>
      <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.service_CP_hours, budget.service_CP_hours)}</td>
      <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.service_CP_ROs, budget.service_CP_ROs)}</td>
      <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.service_CP_hrs_RO, budget.service_CP_hrs_RO)}</td>


      {/* <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.service_hours_available, budget.service_hours_available)}</td>
      <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.service_hours_sold, budget.service_hours_sold)}</td>



      <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage((total.service_hours_sold / total.service_total_hours), (budget.service_hours_sold / budget.service_total_hours))}</td>

      <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.service_no_of_tech, budget.service_no_of_tech)}</td>
      <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.service_journeyman, budget.service_journeyman)}</td>
      <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.service_apprentice, budget.service_apprentice)}</td>
      <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.service_advisors_needed, budget.service_advisors_needed)}</td> */}

      {/* <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.service_booked_hours, budget.service_booked_hours)}</td> */}
      {/* <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.service_units_shipped, budget.service_units_shipped)}</td> */}

      <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.parts_sales, budget.parts_sales)}</td>
      <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.parts_total_GP, budget.parts_total_GP)}</td>
      <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.appt_made, budget.appt_made)}</td>
      <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.booked, budget.booked)}</td>
      <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.appt_show, budget.appt_show)}</td>
      <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.store_visit, budget.store_visit)}</td>

      <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.internet_lead, budget.internet_lead)}</td>

      <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.fresh_up, budget.fresh_up)}</td>
      <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.phone_up, budget.phone_up)}</td>
      <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.lead, budget.lead)}</td>
      <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.sales_staff, budget.sales_staff)}</td>

      {/* <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.no_show, budget.no_show)}</td> */}

      <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(totalStoreGross, budgetOfDailydata)}</td>
    </tr>
  )
}

const DealerYearMonthSelector = ({ stores, filterVal, setFilterVal, loadData }) => {
  const handleChange = (field, event) => {
    if (field === 'store_name') {
      setFilterVal({ ...filterVal, [field]: event.target.value, 'store_id': Object.keys(stores).find(key => stores[key] === event.target.value) });
    } else {
      setFilterVal({ ...filterVal, [field]: event.target.value });
    }
    localStorage.setItem('filterVal', JSON.stringify(filterVal));
  };

  return (
    <div className="row" >
      <div className="col-12 col-md-4  col-xl-2">
        <Box
          sx={{ minWidth: 120 }}
          style={{ width: "100%", marginTop: ".7em" }}
        >
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label" className="pt-2">Dealer</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={filterVal?.store_name}
              label="Dealer"
              onChange={(e) => handleChange('store_name', e)}
            >
              {Object.values(stores).map(child => (
                <MenuItem value={child}>{child}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </div>
      <div className="col-12 col-md-2">
        <Box
          style={{ display: "inline-block", width: "100%", marginTop: ".7em" }}
        >
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label" className="pt-2">Year</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={filterVal?.year}
              label="Year"
              onChange={(e) => handleChange('year', e)}
            >
              {yearData.map(child => (
                <MenuItem value={child}>{child}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </div>
      <div className="col-12 col-md-2">
        <Box
          style={{ display: "inline-block", width: "100%", marginTop: ".7em" }}
        >
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label" className="pt-2">Month</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Months"
              value={filterVal?.month}
              onChange={(e) => handleChange('month', e)}
            >
              {monthData.map(child => (
                <MenuItem value={child}>{child}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </div>
      <div className="col">
        <button className="p-2 mt-1 btn btn-secondary mt-2" type="submit"
          onClick={() => {
            loadData()
          }}
        >Show Details</button>
      </div>
    </div>
  )
}


const DataEntryTs = ({ dataEntry, props, editDay }) => {
  const [autoData, setAutoData] = useState({});
  let theStores = localStorage.getItem('stores') ? JSON.parse(localStorage.getItem('stores')) : {};
  const [stores, setStores] = useState({});
  const [budget, setBudget] = useState({});
  const [filterVal, setFilterVal] = useState(
    localStorage.getItem('filterVal') ? JSON.parse(localStorage.getItem('filterVal')):{}
  );
  // localStorage.getItem('filterVal') ? JSON.parse(localStorage.getItem('filterVal'))
  // :
  let userData;
  if (Cookies.get('user_data')) {
    userData = JSON.parse(Cookies.get('user_data'));
  }

  useEffect(() => {
    let storeObj = {};
    theStores.length > 0 && theStores?.map(i => {
      if (i.division === "Auto" && i.name !== 'Purple Car') {
        storeObj[i.id] = i.name;
      }
    });

    setStores(storeObj);
    window.scrollTo(0, 0)
    setFilterVal({
      store_id: Object.keys(storeObj)[0],
      store_name: Object.values(storeObj)[0],
      division: "Auto",
      company: 'FFUN',
      month: monthData[new Date().getMonth()],
      year: new Date().getFullYear(),
      fiscal_year: 'FY' + getFiscals(monthData[new Date().getMonth()], new Date().getFullYear()).toString().substring(2),
    });
    localStorage.setItem('filterVal', JSON.stringify(filterVal));
  }, []);

  useEffect(() => {
    loadData();
  }, [filterVal]);

  const loadData = (filterData = null, day) => {
    if (filterData) {
      setFilterVal(filterData);
    }

    axios.post(Links.getFilteredAutoData, filterVal)
      .then(res => {
        // store.dispatch(updateData(res.data.dailydata));
        if (parseInt(day) > 0) {
          store.dispatch(updateDataEntryRow({ ...filterVal, ...res.data.dailydata[day], 'day': day }));
        }
        setAutoData(res.data.dailydata);
        filterVal.fiscal_year = 'FY' + getFiscals(filterVal.month, filterVal.year).toString().substring(2)

        axios.post(Links.getBudgetForDailydata, filterVal)
          .then(result => {
            setBudget(result.data.budget);
          }).catch(err => {
            console.log(err);
          });
      }).catch(err => {
        console.log(err);
      });
  }

  let total = {
    new_retail_delivered: 0,
    new_retail_front_GP: 0,
    new_retail_back_GP: 0,
    new_CSI: 0,
    new_CSI_count: 0,
    used_retail_delivered: 0,
    used_retail_front_GP: 0,
    used_retail_back_GP: 0,
    service_total_hours: 0,
    service_CP_hours: 0,
    service_CP_ROs: 0,
    service_CP_hrs_RO: 0,
    service_total_GP: 0,
    service_sales: 0,
    service_CSI: 0,
    // service_hours_sold: 0,
    // service_hours_available: 0,
    // service_no_of_tech: 0,
    service_booked_hours: 0,
    service_units_shipped: 0,
    service_CSI_count: 0,
    parts_sales: 0,
    parts_total_GP: 0,
    appt_made: 0,
    appt_show: 0,
    store_visit: 0,
    lead: 0,
    sales_staff: 0,
    booked: 0,
    no_show: 0,
    phone_up: 0,
    fresh_up: 0,
    internet_lead: 0,
    // service_journeyman: 0,
    // service_apprentice: 0,
    // service_advisors_needed:0
  };

  return (
    <div style={{ margin: "2rem 2rem 2rem 0rem" }}>
      {filterVal?.store_name !== undefined && <>
        <DealerYearMonthSelector stores={stores} filterVal={filterVal} setFilterVal={setFilterVal} loadData={() => loadData()} />

        <table class="table table-bordered border-black" style={{ marginTop: 30 }}>
          <thead className="bg-white">
            <tr className="text-center">
              <th colSpan="3" className="auto-header-top" style={{ "background-color": "#e9ecef" }}></th>
              <th colSpan="4" className="auto-header-top" style={{ "background-color": "orange" }} >New Vehicles</th>
              <th colSpan="3" className="auto-header-top" style={{ "background-color": "#a2c1a2" }}>Used Vehicles</th>
              <th colSpan="7" className="auto-header-top" style={{ "background-color": "#b1d5dd" }}>Service</th>
              <th colSpan="2" className="auto-header-top" style={{ "background-color": "#8b8bb5" }}>Parts</th>
              <th colSpan="9" className="auto-header-top" style={{ "background-color": "#b38bb5" }}>Sales</th>
              <th className="auto-header-top" style={{ "background-color": "orange" }}></th>
            </tr>
            < tr >
              <th className="auto-header" scope="col" style={{ "background-color": "#e9ecef" }}></th>
              <th className="auto-header" scope="col" style={{ "background-color": "#e9ecef" }}></th>
              <th className="auto-header" scope="col" style={{ "background-color": "#e9ecef" }}>Date</th>
              <th className="auto-header" scope="col" style={{ "background-color": "orange" }}>Retail Deliv`d</th>
              <th className="auto-header" scope="col" style={{ "background-color": "orange" }}>Retail Front GP</th>
              <th className="auto-header" scope="col" style={{ "background-color": "orange" }}>Retail Back GP</th>
              <th className="auto-header" scope="col" style={{ "background-color": "orange" }}>CSI</th>
              <th className="auto-header" scope="col" style={{ "background-color": "#a2c1a2" }}>Retail Deliv`d</th>
              <th className="auto-header" scope="col" style={{ "background-color": "#a2c1a2" }}>Retail Front GP</th>
              <th className="auto-header" scope="col" style={{ "background-color": "#a2c1a2" }}>Retail Back GP</th>

              <th className="auto-header" scope="col" style={{ "background-color": "#b1d5dd" }}>Sales</th>
              <th className="auto-header" scope="col" style={{ "background-color": "#b1d5dd" }}>Total GP</th>
              <th className="auto-header" scope="col" style={{ "background-color": "#b1d5dd" }}>Total Hours</th>
              <th className="auto-header" scope="col" style={{ "background-color": "#b1d5dd" }}>CSI</th>
              <th className="auto-header" scope="col" style={{ "background-color": "#b1d5dd" }}>CP Hours</th>
              <th className="auto-header" scope="col" style={{ "background-color": "#b1d5dd" }}>CP RO's</th>
              <th className="auto-header" scope="col" style={{ "background-color": "#b1d5dd" }}>CP Hrs/RO</th>
              {/* <th className="auto-header" scope="col" style={{ "background-color": "#b1d5dd" }}>Hours Avail</th>

              <th className="auto-header" scope="col" style={{ "background-color": "#b1d5dd" }}>Total Flag Hours</th>


              <th className="auto-header" scope="col" style={{ "background-color": "#b1d5dd" }}>Tech Proficiency</th>
              <th className="auto-header" scope="col" style={{ "background-color": "#b1d5dd" }}>No of Technicians</th>
              <th className="auto-header" scope="col" style={{ "background-color": "#b1d5dd" }}>Journeyman Needed</th>
              <th className="auto-header" scope="col" style={{ "background-color": "#b1d5dd" }}>Apprentice Needed</th>
              <th className="auto-header" scope="col" style={{ "background-color": "#b1d5dd" }}>Advisors Needed</th> */}
              {/* <th className="auto-header" scope="col" style={{ "background-color": "#b1d5dd" }}>Booked Hours</th>
            <th className="auto-header" scope="col" style={{ "background-color": "#b1d5dd" }}>Units Shipped</th> */}
              <th className="auto-header" scope="col" style={{ "background-color": "#8b8bb5" }}>Sales</th>
              <th className="auto-header" scope="col" style={{ "background-color": "#8b8bb5" }}>Total GP</th>
              <th className="auto-header" scope="col" style={{ "background-color": "#b38bb5" }}>Appts Made</th>
              <th className="auto-header" scope="col" style={{ "background-color": "#b38bb5" }}>Appts Confirmed</th>
              <th className="auto-header" scope="col" style={{ "background-color": "#b38bb5" }}>Appts Shown</th>
              <th className="auto-header" scope="col" style={{ "background-color": "#b38bb5" }}>Store Visits</th>

              <th className="auto-header" scope="col" style={{ "background-color": "#b38bb5" }}>Int Leads</th>
              <th className="auto-header" scope="col" style={{ "background-color": "#b38bb5" }}>F/Up</th>
              <th className="auto-header" scope="col" style={{ "background-color": "#b38bb5" }}>Phone Up</th>
              <th className="auto-header" scope="col" style={{ "background-color": "#b38bb5" }}>Total Leads</th>
              <th className="auto-header" scope="col" style={{ "background-color": "#b38bb5" }}>Sales Staff</th>

              {/* <th className="auto-header" scope="col" style={{ "background-color": "#b38bb5" }}>No Show</th> */}

              <th className="auto-header" scope="col" style={{ "background-color": "orange" }}>Total Store Gross</th>
            </tr >
          </thead>

          <tbody>
            {
              autoData && dayData.map((i, n) => {
                total.new_retail_delivered = (autoData[i] && autoData[i].new_retail_delivered.trim() !== '') ? total.new_retail_delivered + parseInt(autoData[i].new_retail_delivered) : total.new_retail_delivered;
                total.new_retail_front_GP = (autoData[i] && autoData[i].new_retail_front_GP.trim() !== '') ? total.new_retail_front_GP + parseInt(autoData[i].new_retail_front_GP) : total.new_retail_front_GP;
                total.new_retail_back_GP = (autoData[i] && autoData[i].new_retail_back_GP.trim() !== '') ? total.new_retail_back_GP + parseInt(autoData[i].new_retail_back_GP) : total.new_retail_back_GP;
                total.new_CSI = (autoData[i] && autoData[i].new_CSI !== '') ? total.new_CSI + parseInt(autoData[i].new_CSI) : total.new_CSI;
                if (autoData[i] && autoData[i].new_CSI !== "" && autoData[i].new_CSI !== 0) { total.new_CSI_count++; }
                total.used_retail_delivered = (autoData[i] && autoData[i].used_retail_delivered.trim() !== '') ? total.used_retail_delivered + parseInt(autoData[i].used_retail_delivered) : total.used_retail_delivered;
                total.used_retail_front_GP = (autoData[i] && autoData[i].used_retail_front_GP.trim() !== '') ? total.used_retail_front_GP + parseInt(autoData[i].used_retail_front_GP) : total.used_retail_front_GP;
                total.used_retail_back_GP = (autoData[i] && autoData[i].used_retail_back_GP.trim() !== '') ? total.used_retail_back_GP + parseInt(autoData[i].used_retail_back_GP) : total.used_retail_back_GP;
                total.service_total_hours = (autoData[i] && autoData[i].service_total_hours !== '') ? total.service_total_hours + parseInt(autoData[i].service_total_hours) : total.service_total_hours;
                total.service_CP_hours = (autoData[i] && autoData[i].service_CP_hours !== '') ? total.service_CP_hours + parseInt(autoData[i].service_CP_hours) : total.service_CP_hours;
                total.service_CP_ROs = (autoData[i] && autoData[i].service_CP_ROs !== '') ? total.service_CP_ROs + parseInt(autoData[i].service_CP_ROs) : total.service_CP_ROs;
                total.service_CP_hrs_RO = (autoData[i] && autoData[i].service_CP_hrs_RO !== '') ? total.service_CP_hrs_RO + parseInt(autoData[i].service_CP_hrs_RO) : total.service_CP_hrs_RO;
                total.service_total_GP = (autoData[i] && autoData[i].service_total_GP !== '') ? total.service_total_GP + parseInt(autoData[i].service_total_GP) : total.service_total_GP;
                total.service_sales = (autoData[i] && autoData[i].service_sales !== '') ? total.service_sales + parseInt(autoData[i].service_sales) : total.service_sales;
                total.service_CSI = (autoData[i] && autoData[i].service_CSI !== '') ? total.service_CSI + parseInt(autoData[i].service_CSI) : total.service_CSI;
                if (autoData[i] && autoData[i].service_CSI !== "" && autoData[i].service_CSI !== 0) { total.service_CSI_count++; }

                // total.service_hours_sold = (autoData[i] && autoData[i].service_hours_sold > 0) ? total.service_hours_sold + parseInt(autoData[i].service_hours_sold) : total.service_hours_sold;
                // total.service_hours_available = (autoData[i] && autoData[i].service_apprentice > 0) ? total.service_apprentice + parseInt(autoData[i].service_apprentice) : total.service_apprentice;

                // total.service_journeyman = (autoData[i] && autoData[i].service_journeyman > 0) ? total.service_journeyman + parseInt(autoData[i].service_journeyman) : total.service_journeyman;

                // total.service_apprentice = (autoData[i] && autoData[i].service_apprentice > 0) ? total.service_apprentice + parseInt(autoData[i].service_apprentice) : total.service_apprentice;
                // total.service_advisors_needed = (autoData[i] && autoData[i].service_advisors_needed > 0) ? total.service_advisors_needed + parseInt(autoData[i].service_advisors_needed) : total.service_advisors_needed;

                // total.service_no_of_tech = (autoData[i] && autoData[i].service_no_of_tech > 0) ? total.service_no_of_tech + parseInt(autoData[i].service_no_of_tech) : total.service_no_of_tech;
                total.service_booked_hours = (autoData[i] && autoData[i].service_booked_hours > 0) ? total.service_booked_hours + parseInt(autoData[i].service_booked_hours) : total.service_booked_hours;
                total.service_units_shipped = (autoData[i] && autoData[i].service_units_shipped > 0) ? total.service_units_shipped + parseInt(autoData[i].service_units_shipped) : total.service_units_shipped;

                total.parts_sales = (autoData[i] && autoData[i].parts_sales !== '') ? total.parts_sales + parseInt(autoData[i].parts_sales) : total.parts_sales;
                total.parts_total_GP = (autoData[i] && autoData[i].parts_total_GP !== '') ? total.parts_total_GP + parseInt(autoData[i].parts_total_GP) : total.parts_total_GP;
                total.appt_made = (autoData[i] && autoData[i].appt_made !== '') ? total.appt_made + parseInt(autoData[i].appt_made) : total.appt_made;
                total.appt_show = (autoData[i] && autoData[i].appt_show !== '') ? total.appt_show + parseInt(autoData[i].appt_show) : total.appt_show;
                total.store_visit = (autoData[i] && autoData[i].store_visit !== '') ? total.store_visit + parseInt(autoData[i].store_visit) : total.store_visit;

                total.internet_lead = (autoData[i] && autoData[i].internet_lead > 0) ? total.internet_lead + parseInt(autoData[i].internet_lead) : total.internet_lead;

                total.sales_staff = (autoData[i] && autoData[i].sales_staff > 0) ? total.sales_staff + parseInt(autoData[i].sales_staff) : total.sales_staff;
                total.booked = (autoData[i] && autoData[i].booked > 0) ? total.booked + parseInt(autoData[i].booked) : total.booked;
                // total.no_show= (autoData[i] && autoData[i].no_show >0) ? total.no_show + parseInt(autoData[i].no_show) : total.no_show;
                total.phone_up = (autoData[i] && autoData[i].phone_up > 0) ? total.phone_up + parseInt(autoData[i].phone_up) : total.phone_up;
                total.fresh_up = (autoData[i] && autoData[i].fresh_up > 0) ? total.fresh_up + parseInt(autoData[i].fresh_up) : total.fresh_up;
                total.lead = total.internet_lead + total.phone_up + total.fresh_up;
                return <CreateRow row={autoData} userData={userData} day={i} filterData={filterVal} loadData={loadData} editDay={editDay} stores={stores} />
              })
            }
            <RowTotal total={total} />
            <RowForecast budget={budget} />
            <RowKPI total={total} budget={budget} />
          </tbody>
        </table>
      </>}
    </div>
  );
};

const mapStateToProps = state => ({
  dataEntry: state.dataEntryBudget.dataEntry,
  editDay: state.currentEditDayAuto.currentEditDayAuto
  // totalOfRecdata: state.dataEntryBudget.total
});
export default connect(mapStateToProps)(DataEntryTs);