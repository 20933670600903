import React from "react";
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';


  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );


const MultiLineChart = (props) => {
  const { title, data, options } = props;
  
  if(title && data && options) {
    return (
      <>
        <h3 className="text-center">{title}</h3>
        <Line
          options={options}
          data={data}
        />
      </>
    )
  } else {
    return (
      <>
      </>
    )
  }
};

export default MultiLineChart;