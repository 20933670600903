import React, { useEffect, useState } from "react";
import moment from "moment";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { MONTHS, YEARS, DAYS_IN_MONTH } from "../../constants.js";
import axios from "axios";
import Links from "../../Data/Links.js";
import CarLoading from "../../images/487.gif";
import calculateKPIPercentage from "../../utils/calculateKPIPercentage.js";
import getFiscals from "../../utils/getFiscals.js";

const TableHead = ({ type,columnName="" }) => (
    <thead>
        <tr className="text-center">
            <th colSpan="1" className="dn-header-top" style={{ whiteSpace: "nowrap", backgroundColor: "orange" }} >
                Division
            </th>
            {type === 'dealership' && (
                <th colSpan="1" className="dn-header-top" style={{ whiteSpace: "nowrap", backgroundColor: "orange" }}>
                    Store Name
                </th>
            )}
           
{columnName==="APPT" &&
<>
<th className="dn-header-top" style={{ whiteSpace: "nowrap", backgroundColor: "#b1d5dd" }} >
                Actual
            </th>
            <th className="dn-header-top" style={{ whiteSpace: "nowrap", backgroundColor: "#b1d5dd" }} >
                Budget
            </th>
            <th className="dn-header-top" style={{ whiteSpace: "nowrap", backgroundColor: "#b1d5dd" }} >
                KPI
            </th>
</>

}
         
           
            {columnName==="SAS" &&
         <>
            <th className="dn-header-top" style={{ whiteSpace: "nowrap", backgroundColor: "#a2c1a2" }} >
              Actual
            </th>
            <th className="dn-header-top" style={{ whiteSpace: "nowrap", backgroundColor: "#a2c1a2" }} >
                Budget
            </th>
            <th className="dn-header-top" style={{ whiteSpace: "nowrap", backgroundColor: "#a2c1a2" }} >
                KPI
            </th>
            </>
            }
           
            {columnName==="SV" &&
                <>
            <th className="dn-header-top" style={{ whiteSpace: "nowrap", backgroundColor: "#b1d5dd" }} >
               Actual
            </th>
            <th className="dn-header-top" style={{ whiteSpace: "nowrap", backgroundColor: "#b1d5dd" }} >
               Budget
            </th>
            <th className="dn-header-top" style={{ whiteSpace: "nowrap", backgroundColor: "#b1d5dd" }} >
                KPI
            </th>
            </>
            }
            {columnName==="CLS" &&
                <>
            <th className="dn-header-top" style={{ whiteSpace: "nowrap", backgroundColor: "orange" }} >
                Actual
            </th>
            <th className="dn-header-top" style={{ whiteSpace: "nowrap", backgroundColor: "orange" }} >
                Budget
            </th>
            <th className="dn-header-top" style={{ whiteSpace: "nowrap", backgroundColor: "orange" }} >
                KPI
            </th>
            </>
            }
        </tr>
    </thead>
)

const DealershipTable = ({ division, apptData, apptBudgetData, soldData, soldBudgetData, gpData, gpBudgetData, days, dailyData,columnName }) => {
    const [stores, setStores] = useState([]);
    useEffect(() => {
        getStores();
    }, []);

    const getStores = () => {
        axios
            .get(Links.getAllStores)
            .then((res) => {
                let all_stores_in_current_division;
                if (division === 'Used') {
                    all_stores_in_current_division = res.data.stores.filter(store => store.division === 'DriveNation' || store.division === 'FFUN Cars')
                } else {
                    all_stores_in_current_division = res.data.stores.filter(store => store.division === division)
                };
                setStores(all_stores_in_current_division);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const calculateTotal = (data, field) => {
        let total = 0;
        if (data) {
            data.forEach((item) => {
                total += item[field] ? parseFloat(item[field]) : 0;
            });
        }
        return total;
    };

    // const total_kpiIndicator = calculateKPIPercentage(calculateTotal(soldData, 'total_sold'), calculateTotal(apptData, 'total_lead'));

    return (
        <>
            <h4>{division === "Auto" ? 'Automotive' : division === "Rec" ? 'Recreational' : "Used "} Dearlerships:</h4>
            {(!stores || stores.length === 0) && (
                (<div className="p-4 d-flex justify-content-center" >
                    <img src={CarLoading} />
                </div>)
            )}

            {stores && stores.length > 0 && (
                <table className="mb-5 mt-3 table table-bordered border-black table-fit table-striped">
                    <TableHead type='dealership' columnName={columnName}/>
                    <tbody>
                        {stores.map((store) => {
                            const appt_current_store = apptData.find(item => item.store_id === store.id);
                            const appt_budget_current_store = apptBudgetData.find(item => item.store_id === store.id);
                            const sold_current_store = soldData.find(item => item.store_id === store.id);
                            const sold_budget_current_store = soldBudgetData.find(item => item.store_id === store.id);
                            const daily_data_current_store = dailyData.find(item => item.store_id === store.id);
                            const gp_current_store = gpData.find(item => item.store_id === store.id);
                            const gp_budget_current_store = gpBudgetData.find(item => item.store_id === store.id);
                            // const kpiIndicator = calculateKPIPercentage(sold_current_store?.total_sold || 0, appt_current_store?.total_lead || 0)
                            return (
                                <tr>
                                    <td className="text-capitalize"> {division} </td>
                                    <td className="text-capitalize"> {store.name} </td>
                                  {columnName==="APPT" &&
                                        <>
                                    <td className="text-center"> {appt_current_store?.total_appt_made || 0} </td>
                                    <td className="text-center"> {appt_budget_current_store?.appt_made || 0} </td>
                                    <td className="text-center">
                                        {calculateKPIPercentage((appt_current_store?.total_appt_made || 0), (appt_budget_current_store?.appt_made || 0))}
                                    </td>
                                    </>}
                                  
                                    {columnName==="SAS" &&
         <>
                                    <td className="text-center"> {appt_current_store?.total_appt_show || 0} </td>
                                    <td className="text-center"> {appt_budget_current_store?.appt_show || 0} </td>
                                    <td className="text-center">
                                        {calculateKPIPercentage((appt_current_store?.total_appt_show || 0), (appt_budget_current_store?.appt_show || 0))}
                                    </td>
                                    </>
                        }
                                 

                                    {columnName==="SV" &&
                <>
                                    <td className="text-center"> {appt_current_store?.total_store_visit || 0} </td>
                                    <td className="text-center"> {appt_budget_current_store?.store_visit || 0} </td>
                                    <td className="text-center">
                                        {calculateKPIPercentage((appt_current_store?.total_store_visit || 0), (appt_budget_current_store?.store_visit || 0))}
                                    </td>
                                    </>}
                                
                              
                                    {columnName==="CLS" &&
                <>
                                    <td className="text-center">
                                        {calculateKPIPercentage((sold_current_store?.total_sold || 0), (appt_current_store?.total_store_visit || 0))}
                                    </td>
                                    <td className="text-center">
                                   0 </td>
                                   <td className="text-center">
                                   0% </td>
                                    </>}
                                </tr>
                            )
                        })}

      
                    </tbody>
                </table>
            )}
        </>

    )
}

export default function CeoDashboardApptModal({division,columnName,Modalmonth,Modalyear}) {
    const divisions = ['Auto', 'Rec', 'Used'];
    const currentYear = moment().format("YYYY");
    const currentMonth = moment().format("MMM");
    const d = new Date();
    const [day, setDay] = useState(d.getDate());
    const [month, setMonth] = useState(Modalmonth);
    const [year, setYear] = useState(Modalyear);
    const [loading, setLoading] = useState(true);
    const [days, setDays] = useState(DAYS_IN_MONTH[currentMonth]);

    const [totalSold, setTotalSold] = useState([]);
    const [totalSoldBudget, setTotalSoldBudget] = useState([]);
    const [totalAppt, setTotalAppt] = useState([]);
    const [totalApptBudget, setTotalApptBudget] = useState([]);
    const [grossProfit, setGrossProfit] = useState([]);
    const [grossProfitBudget, setGrossProfitBudget] = useState([]);

    const [totalSoldForRec, setTotalSoldForRec] = useState([]);
    const [totalSoldBudgetForRec, setTotalSoldBudgetForRec] = useState([]);
    const [totalApptForRec, setTotalApptForRec] = useState([]);
    const [totalApptBudgetForRec, setTotalApptBudgetForRec] = useState([]);
    const [grossProfitForRec, setGrossProfitForRec] = useState([]);
    const [grossProfitBudgetForRec, setGrossProfitBudgetForRec] = useState([]);

    const [totalSoldForUsed, setTotalSoldForUsed] = useState([]);
    const [totalSoldBudgetForUsed, setTotalSoldBudgetForUsed] = useState([]);
    const [totalApptForUsed, setTotalApptForUsed] = useState([]);
    const [totalApptBudgetForUsed, setTotalApptBudgetForUsed] = useState([]);
    const [grossProfitForUsed, setGrossProfitForUsed] = useState([]);
    const [grossProfitBudgetForUsed, setGrossProfitBudgetForUsed] = useState([]);

    const [dailyDataForAuto, setDailyDataForAuto] = useState([]);
    const [dailyDataForRec, setDailyDataForRec] = useState([]);
    const [dailyDataForUsed, setDailyDataForUsed] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        getAutoData(year, month);
        getRecData(year, month);
        getUsedData(year, month);
        getCurrentDayDailyData(year, month, day);
    }, []);

    useEffect(() => {
        if (totalSold && totalSoldForRec && totalSoldForUsed && dailyDataForAuto && dailyDataForRec && dailyDataForUsed) {
            setLoading(false)
        }
    }, [totalSold, totalSoldForRec, totalSoldForUsed, dailyDataForAuto, dailyDataForRec, dailyDataForUsed])

    const getAutoData = (year, month) => {
        const stringifiedYear = getFiscals(month, year);
        const requestBody = {
            year: year,
            month: month,
            category: "All",
            divisions: ['Auto', 'Rec', 'Used'],
        };

        const requestBodyBudget = {
            fiscal_year: "FY" + stringifiedYear.toString().slice(-2),
            month: month,
            category: "All",
            divisions: ['Auto', 'Rec', 'Used'],
        };
        axios.all([
            axios.post(Links.getExecutiveReport, requestBody),
            axios.post(Links.getFilteredBudgetForExecutiveReport, requestBodyBudget)
        ])
            .then(axios.spread((data1, data2) => {
                setTotalSold(data1.data.totalSold);
                setTotalAppt(data1.data.totalAppt);
                setGrossProfit(data1.data.dailyDataThisMonth);
                setTotalSoldBudget(data2.data.totalBudgetSold);
                setTotalApptBudget(data2.data.totalBudgetAppt);
                setGrossProfitBudget(data2.data.budgetThisMonth);
            }))
            .catch((err) => {
                console.log(err);
            });
    };

    const getRecData = (year, month) => {
        const stringifiedYear = getFiscals(month, year);
        const requestBody = {
            year: year,
            month: month,
            category: "All",
            divisions: ['Auto', 'Rec', 'Used'],
        };

        const requestBodyBudget = {
            fiscal_year: "FY" + stringifiedYear.toString().slice(-2),
            month: month,
            category: "All",
            divisions: ['Auto', 'Rec', 'Used'],
        };
        axios.all([
            axios.post(Links.getExecutiveReportForRec, requestBody),
            axios.post(Links.getFilteredBudgetForExecutiveReportForRec, requestBodyBudget)
        ])
            .then(axios.spread((data1, data2) => {
                setTotalSoldForRec(data1.data.totalSold);
                setTotalApptForRec(data1.data.totalAppt);
                setGrossProfitForRec(data1.data.dailyDataThisMonth);
                setTotalSoldBudgetForRec(data2.data.totalBudgetSold);
                setTotalApptBudgetForRec(data2.data.totalBudgetAppt);
                setGrossProfitBudgetForRec(data2.data.budgetThisMonth);
            }))
            .catch((err) => {
                console.log(err);
            });
    };

    const getUsedData = (year, month) => {
        const stringifiedYear = getFiscals(month, year);
        const requestBody = {
            year: year,
            month: month,
            category: "All",
            divisions: ['Auto', 'Rec', 'Used'],
        };

        const requestBodyBudget = {
            fiscal_year: "FY" + stringifiedYear.toString().slice(-2),
            month: month,
            category: "All",
            divisions: ['Auto', 'Rec', 'Used'],
        };
        axios.all([
            axios.post(Links.getExecutiveReportForUsed, requestBody),
            axios.post(Links.getFilteredBudgetForExecutiveReportForUsed, requestBodyBudget)
        ])
            .then(axios.spread((data1, data2) => {
                setTotalSoldForUsed(data1.data.totalSold);
                setTotalApptForUsed(data1.data.totalAppt);
                setGrossProfitForUsed(data1.data.dailyDataThisMonth);
                setTotalSoldBudgetForUsed(data2.data.totalBudgetSold);
                setTotalApptBudgetForUsed(data2.data.totalBudgetAppt);
                setGrossProfitBudgetForUsed(data2.data.budgetThisMonth);
            }))
            .catch((err) => {
                console.log(err);
            });
    };

    const getCurrentDayDailyData = (year, month, day) => {
        const requestBody = {
            year: year,
            month: month,
            day: day
        };
        axios.all([
            axios.post(Links.getAllAutoDailyDataByDate, requestBody),
            axios.post(Links.getAllRecDailyDataByData, requestBody),
            axios.post(Links.getAllUsedDailyDataByDate, requestBody),
        ])
            .then(axios.spread((data1, data2, data3) => {
                setDailyDataForAuto(data1.data.dailydata);
                setDailyDataForRec(data2.data.dailydata);
                setDailyDataForUsed(data3.data.dailydata);
            }))
            .catch((err) => {
                console.log(err);
            });
    }

    const handleYearChange = (event) => {
        setYear(event.target.value);
    };

    const handleMonthChange = (event) => {
        setMonth(event.target.value);
    };

    const showResults = () => {
        setLoading(true);
        setDays(DAYS_IN_MONTH[month]);

        if (month == currentMonth && year == currentYear) {
            setDay(d.getDate());
        } else {
            const new_date = new Date(year, Object.values(MONTHS).indexOf(month) + 1, 0);
            setDay(new_date.getDate());
        }

        getAutoData(year, month);
        getRecData(year, month);
        getUsedData(year, month);
        getCurrentDayDailyData(year, month, day);
    };

    const calculateTotal = (data, field) => {
        let total = 0;
        if (data) {
            data.forEach((item) => {
                total += item[field] ? parseFloat(item[field]) : 0;
            });
        }
        return total;
    };

    const EnterpriseTable = () => {
        const total_apptData = [...totalAppt, ...totalApptForRec, ...totalApptForUsed];
        const total_apptBudgetData = [...totalApptBudget, ...totalApptBudgetForRec, ...totalApptBudgetForUsed];
        const total_soldData = [...totalSold, ...totalSoldForRec, ...totalSoldForUsed];
        const total_soldBudgetData = [...totalSoldBudget, ...totalSoldBudgetForRec, ...totalSoldBudgetForUsed];
        const total_dailyData = [...dailyDataForAuto, ...dailyDataForRec, ...dailyDataForUsed];
        const total_gpData = [...grossProfit, ...grossProfitForRec, ...grossProfitForUsed];
        const total_gpBudgetData = [...grossProfitBudget, ...grossProfitBudgetForRec, ...grossProfitBudgetForUsed];
        // const total_kpiIndicator = calculateKPIPercentage(calculateTotal(total_soldData, 'total_sold'), calculateTotal(total_apptData, 'total_lead'));
        return (
            <>
                <h4>Enterprise Summary:</h4>
                <table className="mb-5 mt-3 table table-bordered border-black table-fit table-striped">
                    <TableHead type='enterprise' />
                    <tbody>
                        {divisions.map((division) => {
                            let apptData, apptBudgetData, soldData, soldBudgetData, gpData, gpBudgetData, dailyData;
                            switch (division) {
                                case 'Auto':
                                    apptData = totalAppt;
                                    apptBudgetData = totalApptBudget;
                                    soldData = totalSold;
                                    soldBudgetData = totalSoldBudget;
                                    gpData = grossProfit;
                                    gpBudgetData = grossProfitBudget;
                                    dailyData = dailyDataForAuto;
                                    break;
                                case 'Rec':
                                    apptData = totalApptForRec;
                                    apptBudgetData = totalApptBudgetForRec;
                                    soldData = totalSoldForRec;
                                    soldBudgetData = totalSoldBudgetForRec;
                                    gpData = grossProfitForRec;
                                    gpBudgetData = grossProfitBudgetForRec;
                                    dailyData = dailyDataForRec;
                                    break;
                                case 'Used':
                                    apptData = totalApptForUsed;
                                    apptBudgetData = totalApptBudgetForUsed;
                                    soldData = totalSoldForUsed;
                                    soldBudgetData = totalSoldBudgetForUsed;
                                    gpData = grossProfitForUsed;
                                    gpBudgetData = grossProfitBudgetForUsed;
                                    dailyData = dailyDataForUsed;
                                    break;
                                default:
                            }
                            // let kpiIndicator = calculateKPIPercentage(calculateTotal(soldData, 'total_sold'), calculateTotal(apptData, 'total_lead'));
                            return (
                                <tr>
                                    <td className="text-capitalize"> {division} </td>
                                    <td className="text-center"> {calculateTotal(apptData, 'total_lead')} </td>
                                    <td className="text-center"> {calculateTotal(apptBudgetData, 'lead')} </td>
                                    <td className="text-center">{calculateKPIPercentage(calculateTotal(apptData, 'total_lead'), calculateTotal(apptBudgetData, 'lead'))} </td>
                                    <td className="text-center">
                                        {calculateKPIPercentage(
                                            calculateTotal(dailyData, 'lead'),
                                            calculateTotal(apptBudgetData, 'lead') / days)}
                                    </td>
                                    <td className="text-center"> {calculateTotal(apptData, 'total_appt_made')} </td>
                                    <td className="text-center"> {calculateTotal(apptBudgetData, 'appt_made')} </td>
                                    <td className="text-center">{calculateKPIPercentage(calculateTotal(apptData, 'total_appt_made'), calculateTotal(apptBudgetData, 'appt_made'))} </td>
                                    <td className="text-center">
                                        {calculateKPIPercentage(
                                            calculateTotal(dailyData, 'appt_made'),
                                            calculateTotal(apptBudgetData, 'appt_made') / days)}
                                    </td>
                                    <td className="text-center"> {calculateTotal(apptData, 'total_appt_show')} </td>
                                    <td className="text-center"> {calculateTotal(apptBudgetData, 'appt_show')} </td>
                                    <td className="text-center">{calculateKPIPercentage(calculateTotal(apptData, 'total_appt_show'), calculateTotal(apptBudgetData, 'appt_show'))} </td>
                                    <td className="text-center">
                                        {calculateKPIPercentage(
                                            calculateTotal(dailyData, 'appt_show'),
                                            calculateTotal(apptBudgetData, 'appt_show') / days)}
                                    </td>
                                    <td className="text-center"> {calculateTotal(apptData, 'total_store_visit')} </td>
                                    <td className="text-center"> {calculateTotal(apptBudgetData, 'store_visit')} </td>
                                    <td className="text-center">{calculateKPIPercentage(calculateTotal(apptData, 'total_store_visit'), calculateTotal(apptBudgetData, 'store_visit'))} </td>
                                    <td className="text-center">
                                        {calculateKPIPercentage(
                                            calculateTotal(dailyData, 'store_visit'),
                                            calculateTotal(apptBudgetData, 'store_visit') / days)}
                                    </td>
                                    <td className="text-center"> {calculateTotal(soldData, 'total_sold')} </td>
                                    <td className="text-center"> {calculateTotal(soldBudgetData, 'total_sold')} </td>
                                    <td className="text-center">{calculateKPIPercentage(calculateTotal(soldData, 'total_sold'), calculateTotal(soldBudgetData, 'total_sold'))} </td>
                                    <td className="text-center">
                                        {calculateKPIPercentage(
                                            calculateTotal(dailyData, 'total_sold'),
                                            calculateTotal(soldBudgetData, 'total_sold') / days)}
                                    </td>
                                    <td className="text-center"> {calculateTotal(gpData, 'total_gross_profit').toLocaleString("en-CA", {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                        style: "currency",
                                        currency: "CAD"
                                    })} </td>
                                    <td className="text-center"> {calculateTotal(gpBudgetData, 'total_gross_profit').toLocaleString("en-CA", {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                        style: "currency",
                                        currency: "CAD"
                                    })} </td>
                                    <td className="text-center">{calculateKPIPercentage(calculateTotal(gpData, 'total_gross_profit'), calculateTotal(gpBudgetData, 'total_gross_profit'))} </td>
                                    <td className="text-center">
                                        {calculateKPIPercentage(
                                            calculateTotal(dailyData, 'total_gross_profit'),
                                            calculateTotal(gpBudgetData, 'total_gross_profit') / days)}
                                    </td>
                                    <td className="text-center">
                                        {calculateTotal(soldData, 'total_sold') ? Math.round(calculateTotal(apptData, 'total_lead') / calculateTotal(soldData, 'total_sold')) : 0}
                                    </td>
                                    <td className="text-center">
                                        {calculateKPIPercentage(
                                            calculateTotal(soldData, 'total_sold'),
                                            calculateTotal(apptData, 'total_store_visit'))}
                                    </td>
                                </tr>
                            )
                        })}
                        <tr>
                            <td className="text-capitalize"> Total </td>
                            <td className="text-center"> {calculateTotal(total_apptData, 'total_lead').toLocaleString()} </td>
                            <td className="text-center"> {calculateTotal(total_apptBudgetData, 'lead').toLocaleString()} </td>
                            <td className="text-center"> {calculateKPIPercentage(calculateTotal(total_apptData, 'total_lead'), calculateTotal(total_apptBudgetData, 'lead'))} </td>
                            <td className="text-center">
                                {calculateKPIPercentage(
                                    calculateTotal(total_dailyData, 'lead'),
                                    calculateTotal(total_apptBudgetData, 'lead') / days)}
                            </td>
                            <td className="text-center"> {calculateTotal(total_apptData, 'total_appt_made').toLocaleString()} </td>
                            <td className="text-center"> {calculateTotal(total_apptBudgetData, 'appt_made').toLocaleString()} </td>
                            <td className="text-center"> {calculateKPIPercentage(calculateTotal(total_apptData, 'total_appt_made'), calculateTotal(total_apptBudgetData, 'appt_made'))} </td>
                            <td className="text-center">
                                {calculateKPIPercentage(
                                    calculateTotal(total_dailyData, 'appt_made'),
                                    calculateTotal(total_apptBudgetData, 'appt_made') / days)}
                            </td>
                            <td className="text-center"> {calculateTotal(total_apptData, 'total_appt_show').toLocaleString()} </td>
                            <td className="text-center"> {calculateTotal(total_apptBudgetData, 'appt_show').toLocaleString()} </td>
                            <td className="text-center"> {calculateKPIPercentage(calculateTotal(total_apptData, 'total_appt_show'), calculateTotal(total_apptBudgetData, 'appt_show'))} </td>
                            <td className="text-center">
                                {calculateKPIPercentage(
                                    calculateTotal(total_dailyData, 'appt_show'),
                                    calculateTotal(total_apptBudgetData, 'appt_show') / days)}
                            </td>
                            <td className="text-center"> {calculateTotal(total_apptData, 'total_store_visit').toLocaleString()} </td>
                            <td className="text-center"> {calculateTotal(total_apptBudgetData, 'store_visit').toLocaleString()} </td>
                            <td className="text-center"> {calculateKPIPercentage(calculateTotal(total_apptData, 'total_store_visit'), calculateTotal(total_apptBudgetData, 'store_visit'))} </td>
                            <td className="text-center">
                                {calculateKPIPercentage(
                                    calculateTotal(total_dailyData, 'store_visit'),
                                    calculateTotal(total_apptBudgetData, 'store_visit') / days)}
                            </td>
                            <td className="text-center"> {calculateTotal(total_soldData, 'total_sold').toLocaleString()} </td>
                            <td className="text-center"> {calculateTotal(total_soldBudgetData, 'total_sold').toLocaleString()} </td>
                            <td className="text-center"> {calculateKPIPercentage(calculateTotal(total_soldData, 'total_sold'), calculateTotal(total_soldBudgetData, 'total_sold'))} </td>
                            <td className="text-center">
                                {calculateKPIPercentage(
                                    calculateTotal(total_dailyData, 'total_sold'),
                                    calculateTotal(total_soldBudgetData, 'total_sold') / days)}
                            </td>
                            <td className="text-center"> {calculateTotal(total_gpData, 'total_gross_profit').toLocaleString("en-CA", {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                                style: "currency",
                                currency: "CAD"
                            })} </td>
                            <td className="text-center"> {calculateTotal(total_gpBudgetData, 'total_gross_profit').toLocaleString("en-CA", {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                                style: "currency",
                                currency: "CAD"
                            })} </td>
                            <td className="text-center"> {calculateKPIPercentage(calculateTotal(total_gpData, 'total_gross_profit'), calculateTotal(total_gpBudgetData, 'total_gross_profit'))} </td>
                            <td className="text-center">
                                {calculateKPIPercentage(
                                    calculateTotal(total_dailyData, 'total_gross_profit'),
                                    calculateTotal(total_gpBudgetData, 'total_gross_profit') / days)}
                            </td>
                            <td className="text-center">
                                {calculateTotal(total_soldData, 'total_sold') ? Math.round(calculateTotal(total_apptData, 'total_lead') / calculateTotal(total_soldData, 'total_sold')) : 0}
                            </td>
                            <td className="text-center">
                                {calculateKPIPercentage(
                                    calculateTotal(total_soldData, 'total_sold'),
                                    calculateTotal(total_apptData, 'total_store_visit'))}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </>
        )
    }


    return (
        <div style={{  marginTop: "3rem", width: "100%" }}>
             <Grid container spacing={2}>
           

           <Grid item container xs={12}>
               <Grid item xs={10}></Grid>
               <Grid className="mx-auto" item xs={6}>
                        {loading ? (
                            <div style={{  marginTop: "3rem", right: "50%", top: "30%" }} className="text-center position-absolute" >
                                <img src={CarLoading} />
                            </div>
                        ) : (
                            <>
                                    {
                                division=="Auto" &&   <DealershipTable division='Auto' apptData={totalAppt} apptBudgetData={totalApptBudget} soldData={totalSold} soldBudgetData={totalSoldBudget} gpData={grossProfit} gpBudgetData={grossProfitBudget} days={days} dailyData={dailyDataForAuto} columnName={columnName}/>
                                   
                            }

                            {
                                division=="Rec" && <DealershipTable division='Rec' apptData={totalApptForRec} apptBudgetData={totalApptBudgetForRec} soldData={totalSoldForRec} soldBudgetData={totalSoldBudgetForRec} gpData={grossProfitForRec} gpBudgetData={grossProfitBudgetForRec} days={days} dailyData={dailyDataForRec}  columnName={columnName}/>
                            }

                            {
                                division=="Used" &&       <DealershipTable division='Used' apptData={totalApptForUsed} apptBudgetData={totalApptBudgetForUsed} soldData={totalSoldForUsed} soldBudgetData={totalSoldBudgetForUsed} gpData={grossProfitForUsed} gpBudgetData={grossProfitBudgetForUsed} days={days} dailyData={dailyDataForUsed}  columnName={columnName}/>
                            }

                              
                           </>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}
