import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.css';
import App from './pages/App';
import { ProSidebarProvider } from 'react-pro-sidebar';
ReactDOM.render(
  <React.StrictMode>
    <ProSidebarProvider>
    <App />
    </ProSidebarProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
