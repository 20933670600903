export const MONTHS = {
  "JANUARY": "Jan",
  "FEBRUARY": "Feb",
  "MARCH": "Mar",
  "APRIL": "Apr",
  "MAY": "May",
  "JUNE": "Jun",
  "JULY": "Jul",
  "AUGUST": "Aug",
  "SEPTEMBER": "Sep",
  "OCTOBER": "Oct",
  "NOVEMBER": "Nov",
  "DECEMBER": "Dec"
}
export const MONTHSBYNUMBER = {
  1: "Jan",
  2: "Feb",
  3: "Mar",
  4: "Apr",
  5: "May",
  6: "Jun",
  7: "Jul",
  8: "Aug",
  9: "Sep",
  10: "Oct",
  11: "Nov",
  12: "Dec"
}

export const YEARS = [
  '2020',
  '2021',
  '2022',
  '2023',
  '2024',
  '2025',
]

export let WEEKS = [
  '1',
  '2',
  '3',
  '4',
  '5'
]

export const DAYS_IN_MONTH = {
  'Jan': 31,
  'Feb': 28,
  'Mar': 31,
  'Apr': 30,
  'May': 31,
  'Jun': 30,
  'Jul': 31,
  'Aug': 31,
  'Sep': 30,
  'Oct': 31,
  'Nov': 30,
  'Dec': 31
}

export const STATUTORY_IN_MONTH = {
  'Jan': "1",
  'Feb': "None",
  'Mar': "None",
  'Apr': "10,22",
  'May': "22",
  'Jun': "None",
  'Jul': "1",
  'Aug': "7",
  'Sep': "4,30",
  'Oct': "9",
  'Nov': "11",
  'Dec': "25,26"
}

export const MONTH_BY_NUMBER = {
  'Jan': "01",
  'Feb': "02",
  'Mar': "03",
  'Apr': "04",
  'May': "05",
  'Jun': "06",
  'Jul': "07",
  'Aug': "08",
  'Sep': "09",
  'Oct': "10",
  'Nov': "11",
  'Dec': "12"
}

export const STORES = {
  "ACURA_SASKATOON": "Acura Centre of Saskatoon",
  "COUNTRY_FORD": "Country Ford",
  "FFUN_COLLISION": "FFUN Collision",
  "GENESIS_SASKATOON": "Genesis Saskatoon",
  "KIA_PRINCE_ALBERT": "KIA of Prince Albert",
  "KIA_SASKATOON": "KIA of Saskatoon",
  "NIPAWIN_CHRYSLER_DODGE": "Nipawin Chrysler Dodge",
  "SASKATOON_NORTH_HYUNDAI": "Saskatoon North Hyundai",
  "SASKATOON_SOUTH_HYUNDAI": "Saskatoon South Hyundai",
  "SASKATOON_VOLKSWAGEN": "Saskatoon Volkswagen",
  "SOUTH20_DODGE": "South20 Dodge",
  "WEST_EDMONTON_HYUNDAI": "West Edmonton Hyundai",
  "COMMUNITY_MOTO_SALES": "Community Moto Sale",
  "FFUN_MOTOR_SPORTS_NIPAWIN": "FFUN Motor Sports – Nipawin",
  "FFUN_MOTOR_SPORTS_SASKATOON": "FFUN Motor Sports – Saskatoon",
  "FFUN_MOTOR_SPORTS_YORKTON": "FFUN Motor Sports – Yorkton",
  "REDLINE_HARLEY_DAVIDSON": "Redline Harley-Davidson",
  "SOUTH20_RV": "South20 RV",
  "THE_ROCK_HARLEY_DAVIDSON": "The Rock Harley-Davidson",
  "YORKTON_HARLEY_DAVIDSON": "Yorkton Harley-Davidson",
  "BUDGET_AUTO_SALES": "Budget Auto Sales",
  "DRIVENATION_SASKATOON": "DriveNation – Saskatoon",
  "DRIVENATION_REGINA": "DriveNation – Regina",
  "DRIVENATION_PRINCE_ALBER": "DriveNation – Prince Albert",
  "FFUN_CARS": "FFUN Cars",
  "NORTH_POINT_AUTO_SALES": "North Point Auto Sales",
  "PLATINUM_AUTOSPORTS": "Platinum AutoSports",
  "PURPLE_CAR": "Purple Car",
  "VILLAGE_AUTO_SALES": "Village Auto Sales"
}

export const PIE_COLORS = {
  "BLUE": "#1f77b4",
  "ORANGE": "#ff7f0e",
  "GREEN": "#2ca02c",
  "RED": "#d62728",
  "LILAC": "#9467bd",
  "BROWN": "#8c564b",
  "PINK": "#e377c2",
  "GREY": "#7f7f7f",
  "YELLOW": "#bcbd22",
  "TEAL": "#17becf",
  "CYAN": "#00FFFF",
  "MAGENTA": "#FF00FF"
}

export const CHARTS_COLORS = {
  "PURPLE": "#B983FF",
  "PURPLE_1": "#94B3FD",
  "PURPLE_2": "#94DAFF",
  "PURPLE_3": "#99FEFF",
}

export const DIVISIONS = {
  "AUTO": "Auto",
  "DRIVE_NATION": "DriveNation",
  "RECREATIONAL": "Rec",
  "CAROOGA": "Carooga",
  "VILLAGE": "Village",
  "USED": "Used",
  "FFUN_FINANCIAL": "FFUN Financial"
}

export const DEALER_REPORT_DAILY_DATA_ROW = [
  'total_new_delivered',
  'total_new_front_GP',
  'total_new_back_GP',
  'total_used_retail_delivered',
  'total_used_retail_front_GP',
  'total_used_retail_back_GP',
  'total_service_hours',
  'total_service_CP_hours',
  'total_service_CP_ROs',
  'total_service_total_GP',
  'total_parts_sales',
  'total_parts_retail_GP',
  'total_parts_W_S_GP',
  'total_collision_GP',
  'total_collision_GP'
]

export const DEFAULT_TABLE_TITLES = {
  'NEW_VEHICLES': {
    "LABEL": "New Vehicles",
    "TITLE_ONE": "Retail Delivered",
    "TITLE_TWO": "Retail Gross $",
    "DATA_VALUE_ONE": "total_new_delivered",
    "DATA_VALUE_TWO": ["total_new_front_GP", "total_new_back_GP"],
  },
  'USED_VEHICLES': {
    "LABEL": "Used Vehicles",
    "TITLE_ONE": "Retail Delivered",
    "TITLE_TWO": "Retail Gross $",
    "DATA_VALUE_ONE": "total_used_delivered",
    "DATA_VALUE_TWO": ["total_used_front_GP", "total_used_back_GP"],
  },
  'SERVICES': {
    "LABEL": "Services",
    // "TITLE_ONE": "Total Service Hours $",
    "TITLE_ONE": "Total Gross $",
    "DATA_VALUE_ONE": "total_service_GP",
    "BUDGET_VALUE_ONE": "total_service_GP",
    // "BUDGET_VALUE_ONE": "total_service_hours"
  },
  'PARTS': {
    "LABEL": "Parts",
    "TITLE_ONE": "Sales $",
    "TITLE_TWO": "Gross $",
    "DATA_VALUE_ONE": "total_parts_sales",
    "DATA_VALUE_TWO": "total_parts_retail_GP",
  },
  'APPAREL': {
    "LABEL": "Apparel",
    "TITLE_ONE": "Sales $",
    "TITLE_TWO": "Gross $",
    "DATA_VALUE_ONE": "total_apparel_sales",
    "DATA_VALUE_TWO": "total_apparel_gross",
  },
  'BOATS': {
    "LABEL": "Boats",
    "TITLE_ONE": "Retail Delivered",
    "TITLE_TWO": "Retail Gross $",
    "DATA_VALUE_ONE": "total_boats_delivered",
    "DATA_VALUE_TWO": ["total_boats_front_GP", "total_boats_back_GP"],
  },
  'TRAILERS': {
    "LABEL": "Trailers",
    "TITLE_ONE": "Retail Delivered",
    "TITLE_TWO": "Retail Gross $",
    "DATA_VALUE_ONE": "total_trailers_delivered",
    "DATA_VALUE_TWO": ["total_trailers_front_GP", "total_trailers_back_GP"],
  },
  'TOTAL': {
    "LABEL": "Total",
    "TITLE_ONE": "",
    "TITLE_TWO": ""
  },
}

export const EXECUTIVE_REPORTS_CATEGORIES = {
  "ALL": "All",
  "NEW_VEHICLES": "New Vehicles",
  "USED_VEHICLES": "Used Vehicles",
  "SERVICES": "Services",
  "PARTS": "Parts",
}