import React, { useEffect, useState } from "react";
import moment from "moment";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { MONTHS, YEARS, DAYS_IN_MONTH } from "../../../src/constants.js";
import axios from "axios";
import Links from "../../../src/Data/Links.js";
import CarLoading from "../../images/487.gif";
import calculateKPIPercentage from "../../utils/calculateKPIPercentage.js";
import calculateKPISorting from "../../utils/calculateKPISorting.js"
import getFiscals from "../../utils/getFiscals.js";
import calculateTrend from "../../utils/calculateTrend.js";
import calculateTotalHolidaysbyDates from "../../utils/calculateTotalHolidaysbyDates.js";
import calculateTotalHolidaysbyMonth from "../../utils/calculateTotalHolidaysbyMonth.js";

const TableHead = ({ type }) => (
    <thead>
     
        <tr className="text-center align-middle">


                <th colSpan="1" className="dn-header" style={{ whiteSpace: "nowrap", backgroundColor: "orange" }}>
                    Store Name
                </th>
          
   
            <th className="dn-header" style={{ whiteSpace: "nowrap", backgroundColor: "#b1d5dd" }} >
                Actual
            </th>

            <th className="dn-header" style={{ whiteSpace: "nowrap", backgroundColor: "#b1d5dd" }} >
                Budget
            </th>

            <th className="dn-header" style={{ whiteSpace: "nowrap", backgroundColor: "#b1d5dd" }} >
                KPI
            </th>
      
        </tr>
    </thead>
)





const DealershipTable = ({ division, apptData, apptBudgetData, soldData, soldBudgetData, gpData, gpBudgetData, days, getnoOfHolidaysbyDate, getnoOfHolidaysbyMonth, day }) => {
    const [stores, setStores] = useState([]);

    useEffect(() => {
        getStores();
    }, []);

    const getStores = () => {
        axios
            .get(Links.getAllStores)
            .then((res) => {
                let all_stores_in_current_division;
                if (division === 'Used') {
                    all_stores_in_current_division = res.data.stores.filter(store => store.division === 'DriveNation' || store.division === 'FFUN Cars')
                } else {
                    all_stores_in_current_division = res.data.stores.filter(store => store.division === division)
                };
                setStores(all_stores_in_current_division);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const calculateTotal = (data, field) => {
        let total = 0;
        if (data) {
            data.forEach((item) => {
                total += item[field] ? parseFloat(item[field]) : 0;
            });
        }
        return total;
    };
    
    const theSortedStores=stores && stores.length > 0 && stores.sort(function (a, b) {

        const gp_current_store_a = gpData.find(item => item.store_id === a.id);
        const gp_budget_current_store_a = gpBudgetData.find(item => item.store_id === a.id);

        const gp_current_store_b = gpData.find(item => item.store_id === b.id);
         const gp_budget_current_store_b = gpBudgetData.find(item => item.store_id === b.id);
        return   (parseInt(calculateKPISorting(gp_current_store_a?.total_gross_profit, gp_budget_current_store_a?.total_gross_profit)||0) < parseInt(calculateKPISorting(gp_current_store_b?.total_gross_profit, gp_budget_current_store_b?.total_gross_profit)||0))?1
       :(parseInt(calculateKPISorting(gp_current_store_b?.total_gross_profit, gp_budget_current_store_b?.total_gross_profit)||0)<parseInt(calculateKPISorting(gp_current_store_a?.total_gross_profit, gp_budget_current_store_a?.total_gross_profit)||0)? -1:0)
    });

 
 return (
        <>
            <h4>{division === "Auto" ? 'Automotive' : division === "Rec" ? 'Recreational' : "Used "} Dearlerships:</h4>
            {(!stores || stores.length === 0) && (
                (<div className="p-4 d-flex justify-content-center" >
                    <img src={CarLoading} />
                </div>)
            )}

            {stores && stores.length > 0 && (
                <table className="mb-5 mt-3 table table-bordered border-black table-fit table-striped">
                    <TableHead type='dealership' />
                    <tbody>
                        {stores.map((store) => {
                   
                        
                            const gp_current_store = gpData.find(item => item.store_id === store.id);
                            const gp_budget_current_store = gpBudgetData.find(item => item.store_id === store.id);
                     return (
                                <tr>
                                    <td className="text-capitalize"> {store.name} </td>
                                   
                                 

                                    <td className="text-center align-middle">
                                        {(gp_current_store?.total_gross_profit || 0).toLocaleString("en-CA", {
                                            minimumFractionDigits: 0,
                                            maximumFractionDigits: 0,
                                            style: "currency",
                                            currency: "CAD"
                                        })} </td>
                                 
                                    <td className="text-center align-middle">
                                        {(gp_budget_current_store?.total_gross_profit || 0).toLocaleString("en-CA", {
                                            minimumFractionDigits: 0,
                                            maximumFractionDigits: 0,
                                            style: "currency",
                                            currency: "CAD"
                                        })} </td>

                                    <td className="align-middle" style={{ fontWeight: 500, textAlign: "center", position: "relative" }}>
                                        <div className="progress" style={{ height: "100%", width: "100%", position: "absolute", right: "0%", top: "0%", zIndex: "-1", borderRadius: 0 }}>
                                            <div
                                                className="progress-bar"
                                                role="progressbar"
                                                style={{
                                                    backgroundColor: "#7CEF7C ",
                                                    width: calculateKPIPercentage((gp_current_store?.total_gross_profit || 0), (gp_budget_current_store?.total_gross_profit || 0))
                                                }}
                                            ></div>
                                        </div>
                                        {calculateKPIPercentage((gp_current_store?.total_gross_profit || 0), (gp_budget_current_store?.total_gross_profit || 0))}
                                    </td>
                       
                                </tr>
                            )
                        })}

                    </tbody>
                </table>
            )}
        </>

    )
}




export default function CeoDashboardModal({division,Modalmonth,Modalyear,allType="All"}) {
    const divisions = ['Auto', 'Rec', 'Used'];
    const currentYear = moment().format("YYYY");
    const currentMonth = moment().format("MMM");
    const d = new Date();
    const [day, setDay] = useState(d.getDate());
    const [month, setMonth] = useState(Modalmonth);
    const [year, setYear] = useState(currentYear);
    const [days, setDays] = useState(DAYS_IN_MONTH[currentMonth]);
    const [loading, setLoading] = useState(true);

    const [getnoOfHolidaysbyDate, setGetnoOfHolidaysbyDate] = useState(calculateTotalHolidaysbyDates(currentMonth, currentYear));
    const [getnoOfHolidaysbyMonth, setGetnoOfHolidaysbyMonth] = useState(calculateTotalHolidaysbyMonth(currentMonth, currentYear));

    const [totalSold, setTotalSold] = useState([]);
    const [totalSoldBudget, setTotalSoldBudget] = useState([]);
    const [totalAppt, setTotalAppt] = useState([]);
    const [totalApptBudget, setTotalApptBudget] = useState([]);
    const [grossProfit, setGrossProfit] = useState([]);
    const [grossProfitBudget, setGrossProfitBudget] = useState([]);
    const[allStaffData,setAllStaffData]=useState([]);
    const[staffKpiData,setStaffKpiData]=useState([]);

    const [totalSoldForRec, setTotalSoldForRec] = useState([]);
    const [totalSoldBudgetForRec, setTotalSoldBudgetForRec] = useState([]);
    const [totalApptForRec, setTotalApptForRec] = useState([]);
    const [totalApptBudgetForRec, setTotalApptBudgetForRec] = useState([]);
    const [grossProfitForRec, setGrossProfitForRec] = useState([]);
    const [grossProfitBudgetForRec, setGrossProfitBudgetForRec] = useState([]);

    const [totalSoldForUsed, setTotalSoldForUsed] = useState([]);
    const [totalSoldBudgetForUsed, setTotalSoldBudgetForUsed] = useState([]);
    const [totalApptForUsed, setTotalApptForUsed] = useState([]);
    const [totalApptBudgetForUsed, setTotalApptBudgetForUsed] = useState([]);
    const [grossProfitForUsed, setGrossProfitForUsed] = useState([]);
    const [grossProfitBudgetForUsed, setGrossProfitBudgetForUsed] = useState([]);

    // const [dailyDataForAuto, setDailyDataForAuto] = useState([]);
    // const [dailyDataForRec, setDailyDataForRec] = useState([]);
    // const [dailyDataForUsed, setDailyDataForUsed] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        getAutoData(year, month);
        getRecData(year, month);
        getUsedData(year, month);
        getStaffData(year, month);
        getVehicleDataforStaff(year, month);
        // getCurrentDayDailyData(year, month, day);
    }, []);

    {/* removed && dailyDataForAuto && dailyDataForRec && dailyDataForUsed from if condition and dependency */ }
    useEffect(() => {
        if (totalSold && totalSoldForRec && totalSoldForUsed) {
            setLoading(false)
        }
    }, [totalSold, totalSoldForRec, totalSoldForUsed])

    const getAutoData = (year, month) => {
        const stringifiedYear = getFiscals(month, year);
        const requestBody = {
            year: year,
            month: month,
            category: allType,
            divisions: ['Auto', 'Rec', 'Used'],
        };

        const requestBodyBudget = {
            fiscal_year: "FY" + stringifiedYear.toString().slice(-2),
            month: month,
            category: allType,
            divisions: ['Auto', 'Rec', 'Used'],
        };
        axios.all([
            axios.post(Links.getExecutiveReport, requestBody),
            axios.post(Links.getFilteredBudgetForExecutiveReport, requestBodyBudget)
        ])
            .then(axios.spread((data1, data2) => {
                console.log(data1)
                setTotalSold(data1.data.totalSold);
                setTotalAppt(data1.data.totalAppt);
                setGrossProfit(data1.data.dailyDataThisMonth);
                setTotalSoldBudget(data2.data.totalBudgetSold);
                setTotalApptBudget(data2.data.totalBudgetAppt);
                setGrossProfitBudget(data2.data.budgetThisMonth);
            }))
            .catch((err) => {
                console.log(err);
            });
    };

    const getRecData = (year, month) => {
        const stringifiedYear = getFiscals(month, year);
        const requestBody = {
            year: year,
            month: month,
            category: allType,
            divisions: ['Auto', 'Rec', 'Used'],
        };

        const requestBodyBudget = {
            fiscal_year: "FY" + stringifiedYear.toString().slice(-2),
            month: month,
            category: allType,
            divisions: ['Auto', 'Rec', 'Used'],
        };
        axios.all([
            axios.post(Links.getExecutiveReportForRec, requestBody),
            axios.post(Links.getFilteredBudgetForExecutiveReportForRec, requestBodyBudget)
        ])
            .then(axios.spread((data1, data2) => {
                console.log(data1)
                setTotalSoldForRec(data1.data.totalSold);
                setTotalApptForRec(data1.data.totalAppt);
                setGrossProfitForRec(data1.data.dailyDataThisMonth);
                setTotalSoldBudgetForRec(data2.data.totalBudgetSold);
                setTotalApptBudgetForRec(data2.data.totalBudgetAppt);
                setGrossProfitBudgetForRec(data2.data.budgetThisMonth);
            }))
            .catch((err) => {
                console.log(err);
            });
    };

    const getUsedData = (year, month) => {
        const stringifiedYear = getFiscals(month, year);
        const requestBody = {
            year: year,
            month: month,
            category: allType,
            divisions: ['Auto', 'Rec', 'Used'],
        };

        const requestBodyBudget = {
            fiscal_year: "FY" + stringifiedYear.toString().slice(-2),
            month: month,
            category: allType,
            divisions: ['Auto', 'Rec', 'Used'],
        };
        axios.all([
            axios.post(Links.getExecutiveReportForUsed, requestBody),
            axios.post(Links.getFilteredBudgetForExecutiveReportForUsed, requestBodyBudget)
        ])
            .then(axios.spread((data1, data2) => {
                setTotalSoldForUsed(data1.data.totalSold);
                setTotalApptForUsed(data1.data.totalAppt);
                setGrossProfitForUsed(data1.data.dailyDataThisMonth);
                setTotalSoldBudgetForUsed(data2.data.totalBudgetSold);
                setTotalApptBudgetForUsed(data2.data.totalBudgetAppt);
                setGrossProfitBudgetForUsed(data2.data.budgetThisMonth);
            }))
            .catch((err) => {
                console.log(err);
            });
    };


    const getStaffData = (year, month) => {
        const requestBody = {
            year: year,
            month: month,
            category: allType,
            divisions: ['Auto', 'Rec', 'Used'],
        };

        axios.post(Links.getStaffSummary, requestBody)
            .then(res => {
                setAllStaffData(res.data.staffSummary)
            }).catch(err => {
                console.log(err);
            });
    };


    const getVehicleDataforStaff = (year, month) => {
        const requestBody = {
            year: year,
            month: month,
            category: allType,
            divisions: ['Auto', 'Rec', 'Used'],
        };

        axios.post(Links.getVehicleDataforStaff, requestBody)
            .then(res => {
             
                setStaffKpiData(res.data.totals)
            }).catch(err => {
                console.log(err);
            });
    };
    // const getCurrentDayDailyData = (year, month, day) => {
    //     const requestBody = {
    //         year: year,
    //         month: month,
    //         day: day
    //     };
    //     axios.all([
    //         axios.post(Links.getAllAutoDailyDataByDate, requestBody),
    //         axios.post(Links.getAllRecDailyDataByData, requestBody),
    //         axios.post(Links.getAllUsedDailyDataByDate, requestBody),
    //     ])
    //         .then(axios.spread((data1, data2, data3) => {
    //             setDailyDataForAuto(data1.data.dailydata);
    //             setDailyDataForRec(data2.data.dailydata);
    //             setDailyDataForUsed(data3.data.dailydata);
    //         }))
    //         .catch((err) => {
    //             console.log(err);
    //         });
    // }

    const handleYearChange = (event) => {
        setYear(event.target.value);
    };

    const handleMonthChange = (event) => {
        setMonth(event.target.value);
    };

    const showResults = () => {
        setLoading(true);
        setDays(DAYS_IN_MONTH[month]);

        if (month == currentMonth && year == currentYear) {
            setDay(d.getDate());
        } else {
            const new_date = new Date(year, Object.values(MONTHS).indexOf(month) + 1, 0);
            setDay(new_date.getDate());
        }

        setGetnoOfHolidaysbyDate(calculateTotalHolidaysbyDates(month, year));
        setGetnoOfHolidaysbyMonth(calculateTotalHolidaysbyMonth(month, year));

        getAutoData(year, month);
        getRecData(year, month);
        getUsedData(year, month);
        getStaffData(year, month);
        getVehicleDataforStaff(year, month);
        // getCurrentDayDailyData(year, month, day);
    };

    const calculateTotal = (data, field) => {
        let total = 0;
        if (data) {
            data.forEach((item) => {
                total += item[field] ? parseFloat(item[field]) : 0;
            });
        }
        return total;
    };

   


    return (
        <div className="mx-auto" style={{ marginTop: "3rem", width: "100%" }}>
            <Grid container spacing={2}>
           

                <Grid item container xs={12}>
                    <Grid item xs={10}></Grid>
                    <Grid className="mx-auto" item xs={6}>
                        {loading ? (
                            <div style={{ marginTop: "3rem", right: "50%", top: "30%" }} className="text-center align-middle position-absolute" >
                                <img src={CarLoading} />
                            </div>
 
                        ) : (
                            <>
                            {
                                division=="Auto" &&  <DealershipTable division='Auto' apptData={totalAppt} apptBudgetData={totalApptBudget} soldData={totalSold} soldBudgetData={totalSoldBudget} gpData={grossProfit} gpBudgetData={grossProfitBudget} days={days} day={day} getnoOfHolidaysbyDate={getnoOfHolidaysbyDate} getnoOfHolidaysbyMonth={getnoOfHolidaysbyMonth} />
                                
                            }

                            {
                                division=="Rec" && <DealershipTable division='Rec' apptData={totalApptForRec} apptBudgetData={totalApptBudgetForRec} soldData={totalSoldForRec} soldBudgetData={totalSoldBudgetForRec} gpData={grossProfitForRec} gpBudgetData={grossProfitBudgetForRec} days={days} day={day} getnoOfHolidaysbyDate={getnoOfHolidaysbyDate} getnoOfHolidaysbyMonth={getnoOfHolidaysbyMonth} />
                            }

                            {
                                division=="Used" &&  <DealershipTable division='Used' apptData={totalApptForUsed} apptBudgetData={totalApptBudgetForUsed} soldData={totalSoldForUsed} soldBudgetData={totalSoldBudgetForUsed} gpData={grossProfitForUsed} gpBudgetData={grossProfitBudgetForUsed} days={days} day={day} getnoOfHolidaysbyDate={getnoOfHolidaysbyDate} getnoOfHolidaysbyMonth={getnoOfHolidaysbyMonth} />
                            }


                            </>
                        )}
                    </Grid>
                </Grid>

            </Grid>
        </div>
    );
}
