export default (dailyData, budget) => {
  if(dailyData && budget) {
    const percentage = ((parseInt(dailyData) / parseInt(budget)) * 100).toFixed() + "%"
    if(percentage === "NaN%" || percentage === 'Infinity%') {
      return '0%'
    } else {
      return percentage
    }
  } else {
    return '0%';
  }
};