import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Sidebar, Menu, MenuItem, SubMenu, useProSidebar } from "react-pro-sidebar";
// import "react-pro-sidebar/dist/css/styles.css";
import MicrosoftLogin from "react-microsoft-login";
import Cookies from "js-cookie";
import {
  Home,
  EditOutlined,
  AttachMoneyOutlined,
  AssessmentOutlined,
  PieChartOutlined,
  ExitToApp,
  Group
} from "@material-ui/icons";
import FFUN_FITT from "../images/FFUN FITT (9).png";
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import MenuIcon from '@mui/icons-material/Menu';
import BackspaceIcon from '@mui/icons-material/Backspace';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
// import AddReactionIcon from '@mui/icons-material/AddReaction';
// import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
// import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
// import GradingIcon from '@mui/icons-material/Grading';

export default function Leftnav({ href, setIsLeftNavShown, isLeftNavSHown, initialValue }) {
  const { collapseSidebar } = useProSidebar();
  const currentURL = window.location.href;
  const isAuthenticated = Cookies.get("user_data") || "";
  let stores = localStorage.getItem("stores")
    ? JSON.parse(localStorage.getItem("stores"))
    : "";
  let privileges;
  let divisions = "";
  let userData;
  let logOutData = {};

  let [isLogout, setIsLogout] = useState(false);
  const isMicrosoft = Cookies.get("isMicrosoft");
  if (Cookies.get("user_data")) {
    userData = JSON.parse(Cookies.get("user_data"));
    privileges = userData.privileges ? userData.privileges : "none";
  }
  if (localStorage.getItem("logoutMicrosoft")) {
    logOutData = JSON.parse(localStorage.getItem("logoutMicrosoft"));

  }

  // const [msalInstance, onMsalInstanceChange] = useState(logOutData);

  const authHandler = (err, data, msal) => {
    if (err !== null) {
      // alert("There is something wrong! Please contact the admin.")
    }
    else {
      logoutHandler(msal);
    }
  };

  const logoutHandler = (msalInstance) => {
    Cookies.remove("user_data");
    localStorage.removeItem("stores");
    Cookies.remove('isMicrosoft');
    msalInstance.logout();
  };

  if (stores !== "") {
    stores.map((store, id) => {
      divisions = divisions + " " + store.division;
    });
  }
  return (
    <span>
      <div
        style={{
          position: "fixed",
          height: "100vh",
          zIndex: "1",
          backgroundColor: "#1d1d1d",
          overflow: "auto"
        }}
      >
        <Sidebar
          defaultCollapsed={initialValue}
        >
          <Menu iconShape="square"
          >
            <div className="p-3 mx-auto">
              {isLeftNavSHown ? <> <img src={FFUN_FITT} height={40} />
                <BackspaceIcon className="menu-close-icon" onClick={() => {

                  isLeftNavSHown ? setIsLeftNavShown(false) : setIsLeftNavShown(true);
                  collapseSidebar()

                }} /></> :


                <MenuIcon className="menu-open-icon" onClick={() => {

                  isLeftNavSHown ? setIsLeftNavShown(false) : setIsLeftNavShown(true);
                  collapseSidebar()

                }} />
              }

            </div>


            {currentURL.includes("/signin") || isAuthenticated === "" ? ("")
              : (
                <>
                  <li style={{ paddingLeft: "21px", paddingTop: "10px" }}>
                    <span className="font-weight-bold">Menu</span>
                  </li>
                  <Menu>
                    <MenuItem component={<Link to="/" />} icon={<Home />}>
                      Home
                    </MenuItem>
                    {privileges.includes("1") && <MenuItem component={<Link to="/ffun-one-dashboard" />} icon={<TroubleshootIcon />}>
                    FFUN One Dashboard
                    </MenuItem>}
                    {/* <MenuItem icon={<ThumbUpAltIcon />}>
                    <Link to="/ffun-sop">FFUN WAY</Link>
                  </MenuItem> */}
                    <SubMenu label="Data Entry" icon={<EditOutlined />}>
                      {/* <MenuItem>
                      <Link to="/dataentry">OEM</Link>
                    </MenuItem> */}
                      {(divisions.includes("Auto") || userData.roles === "Sadmin") && (
                        <MenuItem component={<Link to="/dataentryts" />}>
                          Auto
                        </MenuItem>
                      )}






                      {(divisions.includes("DriveNation") ||
                        userData.roles === "Sadmin") && (
                          <MenuItem component={<Link to="/dataentrydrivenation" />}>
                            Drivenation
                          </MenuItem>
                        )}
                      {/* {(divisions.includes("Village") ||
                      userData.roles === "Sadmin") && (
                        <MenuItem component={<Link to="/dataentryvillage" />}>
                          Village
                        </MenuItem>
                      )} */}
                      {/* {(divisions.includes("Carooga") ||
                      userData.roles === "Sadmin") && (
                        <MenuItem component={<Link to="/dataentrycarooga" />}>
                          Carooga
                        </MenuItem>
                      )} */}
                      {(divisions.includes("FFUN Cars") ||
                        userData.roles === "Sadmin") && (
                          <MenuItem component={<Link to="/dataentryffuncars" />}>
                            FFUN Cars
                          </MenuItem>
                        )}
                      {(divisions.includes("Rec") || userData.roles === "Sadmin") && (
                        <MenuItem component={<Link to="/dataentryrec" />}>
                          Rec
                        </MenuItem>
                      )}
                      {(divisions.includes("FFUN Financial") || userData.roles === "Sadmin") && (
                        <MenuItem component={<Link to="/dataentryffunfinancial" />}>
                          FFUN Financial
                        </MenuItem>
                      )}
                      <MenuItem component={<Link to="/dataentrystaff" />}>
                        Staff
                      </MenuItem>
                    </SubMenu>
                    <SubMenu label="Budget" icon={<AttachMoneyOutlined />}>
                      {divisions.includes("Auto") || userData.roles === "Sadmin" ? (
                        <MenuItem component={<Link to="/budgetauto" />}>
                          Auto
                        </MenuItem>
                      ) : (
                        ""
                      )}
                      {divisions.includes("DriveNation") || userData.roles === "Sadmin" ? (
                        <MenuItem component={<Link to="/budgetdrivenation" />}>
                          Drivenation
                        </MenuItem>
                      ) : (
                        ""
                      )}
                      {/* {divisions.includes("Village") || userData.roles === "Sadmin" ? (
                      <MenuItem component={<Link to="/budgetvillage" />}>
                        Village
                      </MenuItem>
                    ) : (
                      ""
                    )} */}
                      {/* {divisions.includes("Carooga") || userData.roles === "Sadmin" ? (
                      <MenuItem component={<Link to="/budgetcarooga" />}>
                        Carooga
                      </MenuItem>
                    ) : (
                      ""
                    )} */}
                      {divisions.includes("FFUN Cars") ||
                        userData.roles === "Sadmin" ? (
                        <MenuItem component={<Link to="/budgetffuncars" />}>
                          FFUN Cars
                        </MenuItem>
                      ) : (
                        ""
                      )}
                      {divisions.includes("Rec") ||
                        userData.roles === "Sadmin" ? (
                        <MenuItem component={<Link to="/budgetrec" />}>
                          Rec
                        </MenuItem>
                      ) : (
                        ""
                      )}

                      {(divisions.includes("FFUN Financial") || userData.roles === "Sadmin") && (
                        <MenuItem component={<Link to="/budgetffunfinancial" />}>
                          FFUN Financial
                        </MenuItem>
                      )}

                      {userData.roles !== "normal" && (
                        <MenuItem component={<Link to="/filedrop" />}>
                          Automatic File Parser
                        </MenuItem>
                      )}

                    </SubMenu>
                    {/* <MenuItem icon={<PieChartOutlined />}>
                    <Link to="/dealer-reports">Dealer Reports</Link>
                  </MenuItem> */}

                    <SubMenu label="Dealer Reports" icon={<PieChartOutlined />}>
                      {/* <MenuItem>
                      <Link to="/dataentry">OEM</Link>
                    </MenuItem> */}
                      {divisions.includes("Auto") || userData.roles === "Sadmin" ? (
                        <MenuItem component={<Link to="/dealer-reports-auto" />}>

                          Auto

                        </MenuItem>
                      ) : (
                        ""
                      )}

                      {divisions.includes("DriveNation") || divisions.includes("FFUN Cars") ||
                        userData.roles === "Sadmin" ? (
                        <MenuItem component={<Link to="/dealer-reports-used" />}>

                          Used

                        </MenuItem>
                      ) : (
                        ""
                      )}

                      {/* {divisions.includes("Village") ||
                      userData.roles === "Sadmin" ? (
                      <MenuItem onClick={event => window.location.href = '/dealer-reports/Village'}>
                        <Link to="/dealer-reports/Village">
                          Village
                        </Link>
                      </MenuItem>
                    ) : (
                      ""
                    )}
                    {divisions.includes("Carooga") ||
                      userData.roles === "Sadmin" ? (
                      <MenuItem onClick={event => window.location.href = '/dealer-reports/Carooga'}>
                        <Link to="/dealer-reports/Carooga">
                          Carooga
                        </Link>
                      </MenuItem>
                    ) : (
                      ""
                    )} */}

                      {divisions.includes("Rec") || userData.roles === "Sadmin" ? (
                        <MenuItem component={<Link to="/dealer-reports-rec" />}>
                          Rec
                        </MenuItem>
                      ) : (
                        ""
                      )}
                      {/* <MenuItem>RS</MenuItem> */}
                    </SubMenu>
                    {userData.roles !== "normal" && (
                      <SubMenu label="Divisional Reports" icon={<AssessmentOutlined />}>
                        {(divisions.includes("Auto") || userData.roles === "Sadmin") && (
                          <MenuItem component={<Link to="/divisional-automative-reports" />}>
                            Auto
                          </MenuItem>
                        )}
                        {(divisions.includes("Rec") || userData.roles === "Sadmin") && (
                          <MenuItem component={<Link to="/divisional-recreational-reports" />}>
                            Rec
                          </MenuItem>)}
                        {(divisions.includes("Used") || userData.roles === "Sadmin") && (
                          <MenuItem component={<Link to="/divisional-used-reports" />}>
                            Used
                          </MenuItem>
                        )}
                        {(divisions.includes("FFUN Financial") || userData.roles === "Sadmin") && (
                          <MenuItem component={<Link to="/divisional-ffun-financial-reports" />}>
                            FFUN Financial
                          </MenuItem>
                        )}
                      </SubMenu>
                    )}
                    {userData.roles === "Sadmin" && (
                      <SubMenu
                        label="Executive Reports"
                        icon={<QueryStatsIcon />}
                      >
                        <MenuItem component={<Link to="/summary-report" />}>

                          Summary Report

                        </MenuItem>
                        <MenuItem component={<Link to="/staff-summary-report" />}>

                          Staff Summary Report

                        </MenuItem>
                        <MenuItem component={<Link to="/summary-report-gross" />}>

                          Summary Report Gross

                        </MenuItem>
                        <MenuItem component={<Link to="/summary-report-sold-units" />}>

                          Summary Report Sold Units

                        </MenuItem>
                        <MenuItem component={<Link to="/full-report" />}>

                          Full Report

                        </MenuItem>
                        <MenuItem component={<Link to="/monthly-report" />}>

                          Monthly Report

                        </MenuItem>
                      </SubMenu>
                    )}
                    {/* User management */}
                    {userData.roles === "Sadmin" && (
                      <SubMenu label="User management" icon={<Group />}>
                        <MenuItem component={<Link to="/user-add" />}>
                          Add user
                        </MenuItem>
                        <MenuItem component={<Link to="/user-manage" />}>
                          Manage users
                        </MenuItem>
                      </SubMenu>
                    )}
                    <MenuItem
                      onClick={() => {
                        if (isMicrosoft === "true") {
                          setIsLogout(true);
                        } else {
                          Cookies.remove("user_data");
                          Cookies.remove('isMicrosoft');
                          localStorage.removeItem("stores");
                          window.location = "/signin";
                        }
                      }}
                      icon={<ExitToApp />}
                    >
                      <div>Logout</div>
                    </MenuItem>
                  </Menu>
                  <div className="text-center">
                    {isLogout === true && (
                      <div className="d-none">
                        <MicrosoftLogin clientId="97892af2-6bc6-4a79-95aa-1fed374b50c4" authCallback={authHandler} />
                      </div>)}
                    {/* <button onClick={logoutHandler}>Logout</button> */}
                  </div>
                </>
              )}
          </Menu>
        </Sidebar >
      </div>
    </span>
  );
}