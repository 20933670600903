import React from "react";
import { calculatePercentageOfBudget } from "../../utils";



const Operations = ({ dailyData, budgetData, dailyDataRec, budgetDataRec, dailyDataUsed, budgetDataUsed }) => {
    const totalNewFrontGP = parseInt(dailyData.total_new_front_GP||0) +  parseInt(dailyDataRec.total_new_front_GP ||0)  + parseInt(dailyDataUsed.total_new_front_GP||0);
    const totalNewBackGP =parseInt(dailyData.total_new_back_GP||0)  + parseInt(dailyDataRec.total_new_back_GP||0)  + parseInt(dailyDataUsed.total_new_back_GP||0) ;
    const totalUsedFrontGP = parseInt(dailyData.total_used_front_GP||0)  + parseInt(dailyDataRec.total_used_front_GP||0)  + parseInt(dailyDataUsed.total_used_front_GP||0) ;
    const totalUsedBackGP =  parseInt(dailyData.total_used_back_GP||0)  +  parseInt(dailyDataRec.total_used_back_GP||0)  +  parseInt(dailyDataUsed.total_used_back_GP||0) ;
    const totalServiceGP = parseInt(dailyData.total_service_GP||0)  +  parseInt(dailyDataRec.total_service_GP||0)  +  parseInt(dailyDataUsed.total_service_GP||0) ;
    const totalApparelGross =  parseInt(dailyData.total_apparel_gross||0)  +  parseInt(dailyDataRec.total_apparel_gross||0)  +  parseInt(dailyDataUsed.total_apparel_gross||0) ;
    const totalBoatsFrontGP =  parseInt(dailyData.total_boats_front_GP||0)  +  parseInt(dailyDataRec.total_boats_front_GP||0)  +  parseInt(dailyDataUsed.total_boats_front_GP||0) ;
    const totalBoatsBackGP = parseInt(dailyData.total_boats_back_GP||0)  + parseInt(dailyDataRec.total_boats_back_GP||0)  +  parseInt(dailyDataUsed.total_boats_back_GP||0) ;
    const totalTrailersFrontGP =  parseInt(dailyData.total_trailers_front_GP||0)  +  parseInt(dailyDataRec.total_trailers_front_GP||0)  +  parseInt(dailyDataUsed.total_trailers_front_GP||0) ;
    const totalTrailersBackGP =  parseInt(dailyData.total_trailers_back_GP||0)  +  parseInt(dailyDataRec.total_trailers_back_GP||0)  +  parseInt(dailyDataUsed.total_trailers_back_GP||0) ;
    const totalPartsGP =  parseInt(dailyData.total_parts_retail_GP||0)  +  parseInt(dailyDataRec.total_parts_retail_GP||0)  +  parseInt(dailyDataUsed.total_parts_retail_GP||0) ;

    const totalDailyData = totalNewFrontGP + totalNewBackGP + totalUsedFrontGP + totalUsedBackGP + totalServiceGP + totalApparelGross + totalBoatsFrontGP + totalBoatsBackGP + totalTrailersFrontGP + totalTrailersBackGP + totalPartsGP;



    const totalNewFrontGPBudget = parseInt(budgetData.total_new_front_GP||0)  +  parseInt(budgetDataRec.total_new_front_GP||0)  +parseInt(budgetDataUsed.total_new_front_GP||0) ;
    const totalNewBackGPBudget =  parseInt(budgetData.total_new_back_GP||0)  + parseInt(budgetDataRec.total_new_back_GP||0)  +  parseInt(budgetDataUsed.total_new_back_GP||0) ;
    const totalUsedFrontGPBudget = parseInt(budgetData.total_used_front_GP||0)  + parseInt(budgetDataRec.total_used_front_GP||0)  + parseInt(budgetDataUsed.total_used_front_GP||0) ;
    const totalUsedBackGPBudget = parseInt(budgetData.total_used_back_GP||0)  + parseInt(budgetDataRec.total_used_back_GP||0)  +  parseInt(budgetDataUsed.total_used_back_GP||0) ;
    const totalNewDelivered = parseInt(dailyData.total_new_delivered||0)  + parseInt(dailyDataRec.total_new_delivered||0)  +parseInt(dailyDataUsed.total_new_delivered||0) ;
    const totalUsedDelivered =  parseInt(dailyData.total_used_delivered||0)  +  parseInt(dailyDataRec.total_used_delivered||0)  +  parseInt(dailyDataUsed.total_used_delivered||0) ;
    const totalNewDeliveredBudget =  parseInt(budgetData.total_new_delivered||0)  + parseInt(budgetDataRec.total_new_delivered||0)  +  parseInt(budgetDataUsed.total_new_delivered||0) ;
    const totalUsedDeliveredBudget =  parseInt(budgetData.total_used_delivered||0)  +  parseInt(budgetDataRec.total_used_delivered||0)  + parseInt(budgetDataUsed.total_used_delivered||0) ;
    const totalBoatsFrontGPBudget =  parseInt(budgetDataRec.total_boats_front_GP||0) ;
    const totalBoatsBackGPBudget = parseInt(budgetDataRec.total_boats_back_GP||0) ;
    const totalTrailersFrontGPBudget =  parseInt(budgetDataRec.total_trailers_front_GP||0) ;
    const totalTrailersBackGPBudget =  parseInt(budgetDataRec.total_trailers_back_GP||0) ;
    const totalServiceGPBudget =  parseInt(budgetData.total_service_GP||0)  + parseInt(budgetDataRec.total_service_GP||0)  +  parseInt(budgetDataUsed.total_service_GP||0) ;
    const totalApparelGrossBudget =  parseInt(budgetData.total_apparel_gross||0)  +  parseInt(budgetDataRec.total_apparel_gross||0)  + parseInt(budgetDataUsed.total_apparel_gross||0) ;
    const totalPartsGPBudget = parseInt(budgetData.total_parts_retail_GP||0)  +  parseInt(budgetDataRec.total_parts_retail_GP||0)  + parseInt(budgetDataUsed.total_parts_retail_GP||0) ;

    const totalBudgetData = totalNewFrontGPBudget + totalNewBackGPBudget + totalUsedFrontGPBudget + totalUsedBackGPBudget + totalServiceGPBudget + totalApparelGrossBudget + totalBoatsFrontGPBudget + totalBoatsBackGPBudget + totalTrailersFrontGPBudget + totalTrailersBackGPBudget + totalPartsGPBudget;


    return (
        <table
            className="table table-bordered border-black table-fit"
            style={{ marginBottom: 0 }}
        >
            <thead>
                <tr className="text-center">
                    <th
                        colSpan="1"
                        className="ceo-header"
                        style={{ backgroundColor: "#a2c1a2", fontSize: "1rem", width: 150 }}
                    >
                        Category
                    </th>
                    <th
                        colSpan="1"
                        className="ceo-header"
                        style={{ backgroundColor: "#a2c1a2", fontSize: "1rem" }}
                    >
                        Actual
                    </th>
                    <th
                        colSpan="1"
                        className="ceo-header"
                        style={{ backgroundColor: "#a2c1a2", fontSize: "1rem" }}
                    >
                        Target
                    </th>
                    <th
                        colSpan="1"
                        className="ceo-header"
                        style={{ backgroundColor: "#a2c1a2", fontSize: "1rem" }}
                    >
                        Monthly Trend
                    </th>


                </tr>
            </thead>
            <tbody>
                <tr>
                    <td
                        style={{
                            paddingTop: "10px",
                            textAlign: "left",
                            fontSize: "1rem",
                            fontWeight: "bold",
                            minWidth: "150px"
                        }}
                    >
                        New Vehicles

                    </td>

                    <td
                        style={{
                            paddingTop: "10px",
                            textAlign: "left",
                            fontSize: "1rem",
                            fontWeight: "bold",
                        }}
                    >
                        {parseInt(dailyData?.total_new_delivered || 0) + parseInt(dailyDataRec?.total_new_delivered || 0)}

                    </td>
                    <td
                        style={{
                            paddingTop: "10px",
                            textAlign: "left",
                            fontSize: "1rem",
                            fontWeight: "bold",
                        }}
                    >

                        {parseInt(budgetData?.total_new_delivered || 0) + parseInt(budgetDataRec?.total_new_delivered || 0)}

                    </td>

                    <td
                        style={{
                            fontWeight: "bold",
                            fontSize: "1rem",
                            position: "relative",
                            textAlign: "left",
                            minWidth: 150
                        }}
                    >
                        <div
                            className="progress"
                            style={{
                                height: "100%",
                                width: "100%",
                                position: "absolute",
                                right: "0%",
                                top: "0%",
                                zIndex: "-1",
                                borderRadius: "0px",
                            }}
                        >
                            <div
                                className="progress-bar"
                                role="progressbar"
                                style={{
                                    backgroundColor: "#7CEF7C ",
                                    width:
                                        calculatePercentageOfBudget(
                                            parseInt(dailyData?.total_new_delivered || 0) + parseInt(dailyDataRec?.total_new_delivered || 0),
                                            parseInt(budgetData?.total_new_delivered || 0) + parseInt(budgetDataRec?.total_new_delivered || 0))

                                }}
                                aria-valuenow="25"
                                aria-valuemin="0"
                                aria-valuemax="100"
                            ></div>
                        </div>
                        {
                            calculatePercentageOfBudget(
                                parseInt(dailyData?.total_new_delivered || 0) + parseInt(dailyDataRec?.total_new_delivered || 0),
                                parseInt(budgetData?.total_new_delivered || 0) + parseInt(budgetDataRec?.total_new_delivered || 0))}
                    </td>
                </tr>

                <tr>
                    <td
                        style={{
                            paddingTop: "10px",
                            textAlign: "left",
                            fontSize: "1rem",
                            fontWeight: "bold",
                        }}
                    >
                        Used Vehicles

                    </td>

                    <td
                        style={{
                            paddingTop: "10px",
                            textAlign: "left",
                            fontSize: "1rem",
                            fontWeight: "bold",
                        }}
                    >

                        {parseInt(dailyData?.total_used_delivered || 0) + parseInt(dailyDataRec?.total_used_delivered || 0) + parseInt(dailyDataUsed?.total_used_delivered || 0)}
                    </td>

                    <td
                        style={{
                            paddingTop: "10px",
                            textAlign: "left",
                            fontSize: "1rem",
                            fontWeight: "bold",
                        }}
                    >

                        {parseInt(budgetData?.total_used_delivered || 0) + parseInt(budgetDataRec?.total_used_delivered || 0) + parseInt(budgetDataUsed?.total_used_delivered || 0)}

                    </td>
                    <td
                        style={{
                            fontWeight: "bold",
                            fontSize: "1rem",
                            position: "relative",
                            textAlign: "left",
                            minWidth: 150
                        }}
                    >
                        <div
                            className="progress"
                            style={{
                                height: "100%",
                                width: "100%",
                                position: "absolute",
                                right: "0%",
                                top: "0%",
                                zIndex: "-1",
                                borderRadius: "0px",
                            }}
                        >
                            <div
                                className="progress-bar"
                                role="progressbar"
                                style={{
                                    backgroundColor: "#7CEF7C ",
                                    width:
                                        calculatePercentageOfBudget(
                                            parseInt(dailyData?.total_used_delivered || 0) + parseInt(dailyDataRec?.total_used_delivered || 0) + parseInt(dailyDataUsed?.total_used_delivered || 0),
                                            parseInt(budgetData?.total_used_delivered || 0) + parseInt(budgetDataRec?.total_used_delivered || 0) + parseInt(budgetDataUsed?.total_used_delivered || 0))

                                }}
                                aria-valuenow="25"
                                aria-valuemin="0"
                                aria-valuemax="100"
                            ></div>
                        </div>
                        {
                            calculatePercentageOfBudget(
                                parseInt(dailyData?.total_used_delivered || 0) + parseInt(dailyDataRec?.total_used_delivered || 0) + parseInt(dailyDataUsed?.total_used_delivered || 0),
                                parseInt(budgetData?.total_used_delivered || 0) + parseInt(budgetDataRec?.total_used_delivered || 0) + parseInt(budgetDataUsed?.total_used_delivered || 0))}
                    </td>
                </tr>
                <tr>
                    <td
                        style={{
                            paddingTop: "10px",
                            textAlign: "left",
                            fontSize: "1rem",
                            fontWeight: "bold",
                        }}
                    >
                        New Vehicles - Total Gross

                    </td>

                    <td
                        style={{
                            paddingTop: "10px",
                            textAlign: "left",
                            fontSize: "1rem",
                            fontWeight: "bold",
                        }}
                    >
                        ${(totalNewFrontGP + totalNewBackGP).toLocaleString()}

                    </td>
                    <td
                        style={{
                            paddingTop: "10px",
                            textAlign: "left",
                            fontSize: "1rem",
                            fontWeight: "bold",
                        }}
                    >
                        ${(totalNewFrontGPBudget + totalNewBackGPBudget).toLocaleString()}

                    </td>

                    <td
                        style={{
                            fontWeight: "bold",
                            fontSize: "1rem",
                            position: "relative",
                            textAlign: "left",
                            minWidth: 150
                        }}
                    >
                        <div
                            className="progress"
                            style={{
                                height: "100%",
                                width: "100%",
                                position: "absolute",
                                right: "0%",
                                top: "0%",
                                zIndex: "-1",
                                borderRadius: "0px",
                            }}
                        >
                            <div
                                className="progress-bar"
                                role="progressbar"
                                style={{
                                    backgroundColor: "#7CEF7C ",
                                    width:
                                        calculatePercentageOfBudget(
                                            (totalNewFrontGP + totalNewBackGP),
                                            (totalNewFrontGPBudget + totalNewBackGPBudget))

                                }}
                                aria-valuenow="25"
                                aria-valuemin="0"
                                aria-valuemax="100"
                            ></div>
                        </div>
                        {
                            calculatePercentageOfBudget(
                                (totalNewFrontGP + totalNewBackGP),
                                (totalNewFrontGPBudget + totalNewBackGPBudget))}
                    </td>
                </tr>
                <tr>
                    <td
                        style={{
                            paddingTop: "10px",
                            textAlign: "left",
                            fontSize: "1rem",
                            fontWeight: "bold",
                        }}
                    >
                        Used Vehicles - Total Gross

                    </td>

                    <td
                        style={{
                            paddingTop: "10px",
                            textAlign: "left",
                            fontSize: "1rem",
                            fontWeight: "bold",
                        }}
                    >
                        ${(totalUsedFrontGP + totalUsedBackGP).toLocaleString()}

                    </td>
                    <td
                        style={{
                            paddingTop: "10px",
                            textAlign: "left",
                            fontSize: "1rem",
                            fontWeight: "bold",
                        }}
                    >
                        ${(totalUsedFrontGPBudget + totalUsedBackGPBudget).toLocaleString()}

                    </td>


                    <td
                        style={{
                            fontWeight: "bold",
                            fontSize: "1rem",
                            position: "relative",
                            textAlign: "left",
                            minWidth: 150
                        }}
                    >
                        <div
                            className="progress"
                            style={{
                                height: "100%",
                                width: "100%",
                                position: "absolute",
                                right: "0%",
                                top: "0%",
                                zIndex: "-1",
                                borderRadius: "0px",
                            }}
                        >
                            <div
                                className="progress-bar"
                                role="progressbar"
                                style={{
                                    backgroundColor: "#7CEF7C ",
                                    width:
                                        calculatePercentageOfBudget(
                                            (totalUsedFrontGP + totalUsedBackGP),
                                            (totalUsedFrontGPBudget + totalUsedBackGPBudget))

                                }}
                                aria-valuenow="25"
                                aria-valuemin="0"
                                aria-valuemax="100"
                            ></div>
                        </div>
                        {
                            calculatePercentageOfBudget(
                                (totalUsedFrontGP + totalUsedBackGP),
                                (totalUsedFrontGPBudget + totalUsedBackGPBudget))}
                    </td>


                </tr>
                {dailyDataRec.total_boats_front_GP !== undefined && <tr>
                    <td
                        style={{
                            paddingTop: "10px",
                            textAlign: "left",
                            fontSize: "1rem",
                            fontWeight: "bold",
                        }}
                    >
                        Boats - Total Gross

                    </td>

                    <td
                        style={{
                            paddingTop: "10px",
                            textAlign: "left",
                            fontSize: "1rem",
                            fontWeight: "bold",
                        }}
                    >
                        ${(totalBoatsFrontGP + totalBoatsBackGP).toLocaleString()}

                    </td>
                    <td
                        style={{
                            paddingTop: "10px",
                            textAlign: "left",
                            fontSize: "1rem",
                            fontWeight: "bold",
                        }}
                    >
                        ${(totalBoatsFrontGPBudget + totalBoatsBackGPBudget).toLocaleString()}

                    </td>


                    <td
                        style={{
                            fontWeight: "bold",
                            fontSize: "1rem",
                            position: "relative",
                            textAlign: "left",
                            minWidth: 150
                        }}
                    >
                        <div
                            className="progress"
                            style={{
                                height: "100%",
                                width: "100%",
                                position: "absolute",
                                right: "0%",
                                top: "0%",
                                zIndex: "-1",
                                borderRadius: "0px",
                            }}
                        >
                            <div
                                className="progress-bar"
                                role="progressbar"
                                style={{
                                    backgroundColor: "#7CEF7C ",
                                    width:
                                        calculatePercentageOfBudget(
                                            totalBoatsFrontGP + totalBoatsBackGP,
                                            totalBoatsFrontGPBudget + totalBoatsBackGPBudget)

                                }}
                                aria-valuenow="25"
                                aria-valuemin="0"
                                aria-valuemax="100"
                            ></div>
                        </div>
                        {
                            calculatePercentageOfBudget(
                                totalBoatsFrontGP + totalBoatsBackGP,
                                totalBoatsFrontGPBudget + totalBoatsBackGPBudget)}
                    </td>


                </tr>
                }

                {dailyDataRec.total_boats_front_GP !== undefined && <tr>
                    <td
                        style={{
                            paddingTop: "10px",
                            textAlign: "left",
                            fontSize: "1rem",
                            fontWeight: "bold",
                        }}
                    >
                        Trailers - Total Gross

                    </td>

                    <td
                        style={{
                            paddingTop: "10px",
                            textAlign: "left",
                            fontSize: "1rem",
                            fontWeight: "bold",
                        }}
                    >
                        ${(totalTrailersFrontGP + totalTrailersBackGP).toLocaleString()}

                    </td>
                    <td
                        style={{
                            paddingTop: "10px",
                            textAlign: "left",
                            fontSize: "1rem",
                            fontWeight: "bold",
                        }}
                    >
                        ${(totalTrailersFrontGPBudget + totalTrailersBackGPBudget).toLocaleString()}

                    </td>


                    <td
                        style={{
                            fontWeight: "bold",
                            fontSize: "1rem",
                            position: "relative",
                            textAlign: "left",
                            minWidth: 150
                        }}
                    >
                        <div
                            className="progress"
                            style={{
                                height: "100%",
                                width: "100%",
                                position: "absolute",
                                right: "0%",
                                top: "0%",
                                zIndex: "-1",
                                borderRadius: "0px",
                            }}
                        >
                            <div
                                className="progress-bar"
                                role="progressbar"
                                style={{
                                    backgroundColor: "#7CEF7C ",
                                    width:
                                        calculatePercentageOfBudget(
                                            totalTrailersFrontGP + totalTrailersBackGP,
                                            totalTrailersFrontGPBudget + totalTrailersBackGPBudget)

                                }}
                                aria-valuenow="25"
                                aria-valuemin="0"
                                aria-valuemax="100"
                            ></div>
                        </div>
                        {
                            calculatePercentageOfBudget(
                                totalTrailersFrontGP + totalTrailersBackGP,
                                totalTrailersFrontGPBudget + totalTrailersBackGPBudget)}
                    </td>


                </tr>
                }

                {dailyDataRec.total_apparel_gross !== undefined && <tr>
                    <td
                        style={{
                            paddingTop: "10px",
                            textAlign: "left",
                            fontSize: "1rem",
                            fontWeight: "bold",
                        }}
                    >
                        Apparel - Total Gross

                    </td>

                    <td
                        style={{
                            paddingTop: "10px",
                            textAlign: "left",
                            fontSize: "1rem",
                            fontWeight: "bold",
                        }}
                    >
                        ${(dailyDataRec?.total_apparel_gross || 0).toLocaleString()}

                    </td>
                    <td
                        style={{
                            paddingTop: "10px",
                            textAlign: "left",
                            fontSize: "1rem",
                            fontWeight: "bold",
                        }}
                    >
                        ${(budgetDataRec?.total_apparel_gross || 0).toLocaleString()}

                    </td>


                    <td
                        style={{
                            fontWeight: "bold",
                            fontSize: "1rem",
                            position: "relative",
                            textAlign: "left",
                            minWidth: 150
                        }}
                    >
                        <div
                            className="progress"
                            style={{
                                height: "100%",
                                width: "100%",
                                position: "absolute",
                                right: "0%",
                                top: "0%",
                                zIndex: "-1",
                                borderRadius: "0px",
                            }}
                        >
                            <div
                                className="progress-bar"
                                role="progressbar"
                                style={{
                                    backgroundColor: "#7CEF7C ",
                                    width:
                                        calculatePercentageOfBudget(
                                            dailyDataRec?.total_apparel_gross || 0,
                                            budgetDataRec?.total_apparel_gross || 0)

                                }}
                                aria-valuenow="25"
                                aria-valuemin="0"
                                aria-valuemax="100"
                            ></div>
                        </div>
                        {
                            calculatePercentageOfBudget(
                                dailyDataRec?.total_apparel_gross || 0,
                                budgetDataRec?.total_apparel_gross || 0)}
                    </td>


                </tr>
                }





                <tr>
                    <td
                        style={{
                            paddingTop: "10px",
                            textAlign: "left",
                            fontSize: "1rem",
                            fontWeight: "bold",
                        }}
                    >
                        Parts - Total Gross

                    </td>

                    <td
                        style={{
                            paddingTop: "10px",
                            textAlign: "left",
                            fontSize: "1rem",
                            fontWeight: "bold",
                        }}
                    >
                        $
                        {(parseInt(dailyData?.total_parts_retail_GP || 0) + parseInt(dailyDataRec?.total_parts_retail_GP || 0) + parseInt(dailyDataUsed?.total_parts_retail_GP || 0)).toLocaleString()}

                    </td>

                    <td
                        style={{
                            paddingTop: "10px",
                            textAlign: "left",
                            fontSize: "1rem",
                            fontWeight: "bold",
                        }}
                    >
                        $
                        {(parseInt(budgetData?.total_parts_retail_GP || 0) + parseInt(budgetDataRec?.total_parts_retail_GP || 0) + parseInt(budgetDataUsed?.total_parts_retail_GP || 0)).toLocaleString()}

                    </td>

                    <td
                        style={{
                            fontWeight: "bold",
                            fontSize: "1rem",
                            position: "relative",
                            textAlign: "left",
                            minWidth: 150
                        }}
                    >
                        <div
                            className="progress"
                            style={{
                                height: "100%",
                                width: "100%",
                                position: "absolute",
                                right: "0%",
                                top: "0%",
                                zIndex: "-1",
                                borderRadius: "0px",
                            }}
                        >
                            <div
                                className="progress-bar"
                                role="progressbar"
                                style={{
                                    backgroundColor: "#7CEF7C ",
                                    width:
                                        calculatePercentageOfBudget(
                                            parseInt(dailyData?.total_parts_retail_GP || 0) + parseInt(dailyDataRec?.total_parts_retail_GP || 0) + parseInt(dailyDataUsed?.total_parts_retail_GP || 0),
                                            parseInt(budgetData?.total_parts_retail_GP || 0) + parseInt(budgetDataRec?.total_parts_retail_GP || 0) + parseInt(budgetDataUsed?.total_parts_retail_GP || 0))

                                }}
                                aria-valuenow="25"
                                aria-valuemin="0"
                                aria-valuemax="100"
                            ></div>
                        </div>
                        {
                            calculatePercentageOfBudget(
                                parseInt(dailyData?.total_parts_retail_GP || 0) + parseInt(dailyDataRec?.total_parts_retail_GP || 0) + parseInt(dailyDataUsed?.total_parts_retail_GP || 0),
                                parseInt(budgetData?.total_parts_retail_GP || 0) + parseInt(budgetDataRec?.total_parts_retail_GP || 0) + parseInt(budgetDataUsed?.total_parts_retail_GP || 0))}
                    </td>




                </tr>
                <tr>
                    <td
                        style={{
                            paddingTop: "10px",
                            textAlign: "left",
                            fontSize: "1rem",
                            fontWeight: "bold",
                        }}
                    >
                        Services - Total Gross

                    </td>

                    <td
                        style={{
                            paddingTop: "10px",
                            textAlign: "left",
                            fontSize: "1rem",
                            fontWeight: "bold",
                        }}
                    >
                        $
                        {(parseInt(dailyData?.total_service_GP || 0) + parseInt(dailyDataRec?.total_service_GP || 0) + parseInt(dailyDataUsed?.total_service_GP || 0)).toLocaleString()}
                    </td>

                    <td
                        style={{
                            paddingTop: "10px",
                            textAlign: "left",
                            fontSize: "1rem",
                            fontWeight: "bold",
                        }}
                    >
                        $
                        {(parseInt(budgetData?.total_service_GP || 0) + parseInt(budgetDataRec?.total_service_GP || 0) + parseInt(budgetDataUsed?.total_service_GP || 0)).toLocaleString()}

                    </td>
                    <td
                        style={{
                            fontWeight: "bold",
                            fontSize: "1rem",
                            position: "relative",
                            textAlign: "left",
                            minWidth: 150
                        }}
                    >
                        <div
                            className="progress"
                            style={{
                                height: "100%",
                                width: "100%",
                                position: "absolute",
                                right: "0%",
                                top: "0%",
                                zIndex: "-1",
                                borderRadius: "0px",
                            }}
                        >
                            <div
                                className="progress-bar"
                                role="progressbar"
                                style={{
                                    backgroundColor: "#7CEF7C ",
                                    width:
                                        calculatePercentageOfBudget(
                                            parseInt(dailyData?.total_service_GP || 0) + parseInt(dailyDataRec?.total_service_GP || 0) + parseInt(dailyDataUsed?.total_service_GP || 0),
                                            parseInt(budgetData?.total_service_GP || 0) + parseInt(budgetDataRec?.total_service_GP || 0) + parseInt(budgetDataUsed?.total_service_GP || 0))

                                }}
                                aria-valuenow="25"
                                aria-valuemin="0"
                                aria-valuemax="100"
                            ></div>
                        </div>
                        {
                            calculatePercentageOfBudget(
                                parseInt(dailyData?.total_service_GP || 0) + parseInt(dailyDataRec?.total_service_GP || 0) + parseInt(dailyDataUsed?.total_service_GP || 0),
                                parseInt(budgetData?.total_service_GP || 0) + parseInt(budgetDataRec?.total_service_GP || 0) + parseInt(budgetDataUsed?.total_service_GP || 0))}
                    </td>
                </tr>


                <tr>
                    <td
                        style={{
                            paddingTop: "10px",
                            textAlign: "left",
                            fontSize: "1rem",
                            fontWeight: "bold",
                            color: "#a2c1a2"
                        }}
                    >
                        Total - Total Gross

                    </td>

                    <td
                        style={{
                            paddingTop: "10px",
                            textAlign: "left",
                            fontSize: "1rem",
                            fontWeight: "bold",
                            color: "#a2c1a2"
                        }}
                    >
                        ${(totalDailyData).toLocaleString()}

                    </td>

                    <td
                        style={{
                            paddingTop: "10px",
                            textAlign: "left",
                            fontSize: "1rem",
                            fontWeight: "bold",
                            color: "#a2c1a2"
                        }}
                    >
                        ${(totalBudgetData).toLocaleString()}

                    </td>
                    <td
                        style={{
                            fontWeight: "bold",
                            fontSize: "1rem",
                            position: "relative",
                            textAlign: "left",
                            minWidth: 150
                        }}
                    >
                        <div
                            className="progress"
                            style={{
                                height: "100%",
                                width: "100%",
                                position: "absolute",
                                right: "0%",
                                top: "0%",
                                zIndex: "-1",
                                borderRadius: "0px",
                            }}
                        >
                            <div
                                className="progress-bar"
                                role="progressbar"
                                style={{
                                    backgroundColor: "#7CEF7C ",
                                    width:
                                        calculatePercentageOfBudget(
                                            totalDailyData,
                                            totalBudgetData)

                                }}
                                aria-valuenow="25"
                                aria-valuemin="0"
                                aria-valuemax="100"
                            ></div>
                        </div>
                        {
                            calculatePercentageOfBudget(
                                totalDailyData,
                                totalBudgetData)}
                    </td>
                </tr>


                {/* <tr>
                    <td
                        style={{
                            paddingTop: "10px",
                            textAlign: "left",
                            fontSize: "1rem",
                            fontWeight: "bold",
                        }}
                    >
                        Scheduled Appts shown

                    </td>

                    <td
                        style={{
                            paddingTop: "10px",
                            textAlign: "left",
                            fontSize: "1rem",
                            fontWeight: "bold",
                        }}
                    >

                        {parseInt(dailyData?.total_appt_show || 0) + parseInt(dailyDataRec?.total_appt_show || 0) + parseInt(dailyDataUsed?.total_appt_show || 0)}
                    </td>

                    <td
                        style={{
                            paddingTop: "10px",
                            textAlign: "left",
                            fontSize: "1rem",
                            fontWeight: "bold",
                        }}
                    >

                        {parseInt(budgetData?.total_appt_show || 0) + parseInt(budgetDataRec?.total_appt_show || 0) + parseInt(budgetDataUsed?.total_appt_show || 0)}
                    </td>
                    <td
                        style={{
                            fontWeight: "bold",
                            fontSize: "1rem",
                            position: "relative",
                            textAlign: "left",
                            minWidth: 150
                        }}
                    >
                        <div
                            className="progress"
                            style={{
                                height: "100%",
                                width: "100%",
                                position: "absolute",
                                right: "0%",
                                top: "0%",
                                zIndex: "-1",
                                borderRadius: "0px",
                            }}
                        >
                            <div
                                className="progress-bar"
                                role="progressbar"
                                style={{
                                    backgroundColor: "#7CEF7C ",
                                    width:
                                        calculatePercentageOfBudget(
                                            parseInt(dailyData?.total_appt_show || 0) + parseInt(dailyDataRec?.total_appt_show || 0) + parseInt(dailyDataUsed?.total_appt_show || 0),
                                            parseInt(budgetData?.total_appt_show || 0) + parseInt(budgetDataRec?.total_appt_show || 0) + parseInt(budgetDataUsed?.total_appt_show || 0))

                                }}
                                aria-valuenow="25"
                                aria-valuemin="0"
                                aria-valuemax="100"
                            ></div>
                        </div>
                        {
                            calculatePercentageOfBudget(
                                parseInt(dailyData?.total_appt_show || 0) + parseInt(dailyDataRec?.total_appt_show || 0) + parseInt(dailyDataUsed?.total_appt_show || 0),
                                parseInt(budgetData?.total_appt_show || 0) + parseInt(budgetDataRec?.total_appt_show || 0) + parseInt(budgetDataUsed?.total_appt_show || 0))}
                    </td>
                </tr>

                <tr>
                    <td
                        style={{
                            paddingTop: "10px",
                            textAlign: "left",
                            fontSize: "1rem",
                            fontWeight: "bold",
                        }}
                    >
                        Appointments

                    </td>

                    <td
                        style={{
                            paddingTop: "10px",
                            textAlign: "left",
                            fontSize: "1rem",
                            fontWeight: "bold",
                        }}
                    >

                        {parseInt(dailyData?.total_appt_made || 0) + parseInt(dailyDataRec?.total_appt_made || 0) + parseInt(dailyDataUsed?.total_appt_made || 0)}

                    </td>

                    <td
                        style={{
                            paddingTop: "10px",
                            textAlign: "left",
                            fontSize: "1rem",
                            fontWeight: "bold",
                        }}
                    >

                        {parseInt(budgetData?.total_appt_made || 0) + parseInt(budgetDataRec?.total_appt_made || 0) + parseInt(budgetDataUsed?.total_appt_made || 0)}
                    </td>
                    <td
                        style={{
                            fontWeight: "bold",
                            fontSize: "1rem",
                            position: "relative",
                            textAlign: "left",
                            minWidth: 150
                        }}
                    >
                        <div
                            className="progress"
                            style={{
                                height: "100%",
                                width: "100%",
                                position: "absolute",
                                right: "0%",
                                top: "0%",
                                zIndex: "-1",
                                borderRadius: "0px",
                            }}
                        >
                            <div
                                className="progress-bar"
                                role="progressbar"
                                style={{
                                    backgroundColor: "#7CEF7C ",
                                    width:
                                        calculatePercentageOfBudget(
                                            parseInt(dailyData?.total_appt_made || 0) + parseInt(dailyDataRec?.total_appt_made || 0) + parseInt(dailyDataUsed?.total_appt_made || 0),
                                            parseInt(budgetData?.total_appt_made || 0) + parseInt(budgetDataRec?.total_appt_made || 0) + parseInt(budgetDataUsed?.total_appt_made || 0))

                                }}
                                aria-valuenow="25"
                                aria-valuemin="0"
                                aria-valuemax="100"
                            ></div>
                        </div>
                        {
                            calculatePercentageOfBudget(
                                parseInt(dailyData?.total_appt_made || 0) + parseInt(dailyDataRec?.total_appt_made || 0) + parseInt(dailyDataUsed?.total_appt_made || 0),
                                parseInt(budgetData?.total_appt_made || 0) + parseInt(budgetDataRec?.total_appt_made || 0) + parseInt(budgetDataUsed?.total_appt_made || 0))}
                    </td>
                </tr>


                <tr>
                    <td
                        style={{
                            paddingTop: "10px",
                            textAlign: "left",
                            fontSize: "1rem",
                            fontWeight: "bold",
                        }}
                    >
                        Store Visit

                    </td>

                    <td
                        style={{
                            paddingTop: "10px",
                            textAlign: "left",
                            fontSize: "1rem",
                            fontWeight: "bold",
                        }}
                    >

                        {parseInt(dailyData?.total_store_visit || 0) + parseInt(dailyDataRec?.total_store_visit || 0) + parseInt(dailyDataUsed?.total_store_visit || 0)}
                    </td>

                    <td
                        style={{
                            paddingTop: "10px",
                            textAlign: "left",
                            fontSize: "1rem",
                            fontWeight: "bold",
                        }}
                    >
                      
                        {parseInt(budgetData?.total_store_visit || 0) + parseInt(budgetDataRec?.total_store_visit || 0) + parseInt(budgetDataUsed?.total_store_visit || 0)}

                    </td>
                    <td
                        style={{
                            fontWeight: "bold",
                            fontSize: "1rem",
                            position: "relative",
                            textAlign: "left",
                            minWidth: 150
                        }}
                    >
                        <div
                            className="progress"
                            style={{
                                height: "100%",
                                width: "100%",
                                position: "absolute",
                                right: "0%",
                                top: "0%",
                                zIndex: "-1",
                                borderRadius: "0px",
                            }}
                        >
                            <div
                                className="progress-bar"
                                role="progressbar"
                                style={{
                                    backgroundColor: "#7CEF7C ",
                                    width:
                                        calculatePercentageOfBudget(
                                            parseInt(dailyData?.total_store_visit || 0) + parseInt(dailyDataRec?.total_store_visit || 0) + parseInt(dailyDataUsed?.total_store_visit || 0),
                                            parseInt(budgetData?.total_store_visit || 0) + parseInt(budgetDataRec?.total_store_visit || 0) + parseInt(budgetDataUsed?.total_store_visit || 0))

                                }}
                                aria-valuenow="25"
                                aria-valuemin="0"
                                aria-valuemax="100"
                            ></div>
                        </div>
                        {
                            calculatePercentageOfBudget(
                                parseInt(dailyData?.total_store_visit || 0) + parseInt(dailyDataRec?.total_store_visit || 0) + parseInt(dailyDataUsed?.total_store_visit || 0),
                                parseInt(budgetData?.total_store_visit || 0) + parseInt(budgetDataRec?.total_store_visit || 0) + parseInt(budgetDataUsed?.total_store_visit || 0))}
                    </td>
                </tr>

                <tr>
                    <td
                        style={{
                            paddingTop: "10px",
                            textAlign: "left",
                            fontSize: "1rem",
                            fontWeight: "bold",
                        }}
                    >
                        Closing

                    </td>

                    <td
                        style={{
                            paddingTop: "10px",
                            textAlign: "left",
                            fontSize: "1rem",
                            fontWeight: "bold",
                        }}
                    >
                        {((totalNewDelivered + totalUsedDelivered) / dailyData?.total_store_visit || 0).toFixed(2)}%

                    </td>

                    <td
                        style={{
                            paddingTop: "10px",
                            textAlign: "left",
                            fontSize: "1rem",
                            fontWeight: "bold",
                        }}
                    >
                        {((totalNewDeliveredBudget + totalUsedDeliveredBudget) / budgetData?.total_store_visit || 0).toFixed(2)}%

                    </td>
                    <td
                        style={{
                            fontWeight: "bold",
                            fontSize: "1rem",
                            position: "relative",
                            textAlign: "left",
                            minWidth: 150
                        }}
                    >
                        <div
                            className="progress"
                            style={{
                                height: "100%",
                                width: "100%",
                                position: "absolute",
                                right: "0%",
                                top: "0%",
                                zIndex: "-1",
                                borderRadius: "0px",
                            }}
                        >
                            <div
                                className="progress-bar"
                                role="progressbar"
                                style={{
                                    backgroundColor: "#7CEF7C ",
                                    width:
                                        calculatePercentageOfBudget(
                                            dailyData?.total_store_visit || 0,
                                            budgetData?.total_store_visit || 0)

                                }}
                                aria-valuenow="25"
                                aria-valuemin="0"
                                aria-valuemax="100"
                            ></div>
                        </div>
                        {
                            calculatePercentageOfBudget(
                                dailyData?.total_store_visit || 0,
                                budgetData?.total_store_visit || 0)}
                    </td>
                </tr> */}


            </tbody>
        </table>
    )
}


export default Operations;