import React, { useEffect, useState } from "react";
import moment from "moment";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import {
  MONTHS,
  YEARS,
  EXECUTIVE_REPORTS_CATEGORIES,
} from "../../../src/constants.js";
import axios from "axios";
import Links from "../../../src/Data/Links.js";
import SummaryReportsTable from "../../component/Reports/SummaryReportsTable.js";
import SummaryReportsTableAllDivisions from "../../component/Reports/SummaryReportsTableAllDivisions.js";
import CarLoading from "../../images/487.gif";
import BarChart from "../../component/Charts/BarChart.js"
import getFiscals from '../../utils/getFiscals';

export default function SummaryReportSoldUnits() {
  const divisions = ['Auto', 'Rec', 'Used', 'FFUN Financial'];
  const currentYear = moment().format("YYYY");
  const currentMonth = moment().format("MMM");
  const [month, setMonth] = useState(currentMonth);
  const [year, setYear] = useState(currentYear);
  const [category, setCategory] = useState("All");
  const [loading, setLoading] = useState(true);
  const [showNumbers, setShowNumbers] = useState(true);
  const [showCharts, setShowCharts] = useState(false);

  const [totalSold, setTotalSold] = useState([]);
  const [totalSoldLastYear, setTotalSoldLastYear] = useState([]);
  const [totalSoldBudget, setTotalSoldBudget] = useState([]);
  const [totalSoldBudgetLastYear, setTotalSoldBudgetLastYear] = useState([]);
  const [totalAppt, setTotalAppt] = useState([]);
  const [totalApptLastYear, setTotalApptLastYear] = useState([]);

  const [totalSoldForRec, setTotalSoldForRec] = useState([]);
  const [totalSoldLastYearForRec, setTotalSoldLastYearForRec] = useState([]);
  const [totalSoldBudgetForRec, setTotalSoldBudgetForRec] = useState([]);
  const [totalSoldBudgetLastYearForRec, setTotalSoldBudgetLastYearForRec] = useState([]);
  const [totalApptForRec, setTotalApptForRec] = useState([]);
  const [totalApptLastYearForRec, setTotalApptLastYearForRec] = useState([]);

  const [totalSoldForUsed, setTotalSoldForUsed] = useState([]);
  const [totalSoldLastYearForUsed, setTotalSoldLastYearForUsed] = useState([]);
  const [totalSoldBudgetForUsed, setTotalSoldBudgetForUsed] = useState([]);
  const [totalSoldBudgetLastYearForUsed, setTotalSoldBudgetLastYearForUsed] = useState([]);
  const [totalApptForUsed, setTotalApptForUsed] = useState([]);
  const [totalApptLastYearForUsed, setTotalApptLastYearForUsed] = useState([]);

  const [totalSoldForFF, setTotalSoldForFF] = useState([]);
  const [totalSoldLastYearForFF, setTotalSoldLastYearForFF] = useState([]);
  const [totalSoldBudgetForFF, setTotalSoldBudgetForFF] = useState([]);
  const [totalSoldBudgetLastYearForFF, setTotalSoldBudgetLastYearForFF] = useState([]);
  const [totalApptForFF, setTotalApptForFF] = useState([]);
  const [totalApptLastYearForFF, setTotalApptLastYearForFF] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getDailyData(year, month);
    getDealerBudgetData(year, month);
  }, []);

  useEffect(() => {
    getDailyDataForRec(year, month);
    getDealerBudgetDataForRec(year, month);
  }, []);

  useEffect(() => {
    getDailyDataForUsed(year, month);
    getDealerBudgetDataForUsed(year, month);
  }, []);

  useEffect(() => {
    getDailyDataForFF(year, month);
    getDealerBudgetDataForFF(year, month);
  }, []);


  const getDailyData = (year, month) => {
    const requestBody = {
      year: year,
      month: month,
      category: category,
      divisions: divisions,
    };
    axios
      .post(Links.getExecutiveReport, requestBody)
      .then((result) => {
        setTotalSold(result.data.totalSold);
        setTotalSoldLastYear(result.data.totalSoldLastYear);
        setTotalAppt(result.data.totalAppt);
        setTotalApptLastYear(result.data.totalApptLastYear);
      })
      .then(() => {
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getDailyDataForRec = (year, month) => {
    const requestBody = {
      year: year,
      month: month,
      category: category,
      divisions: divisions,
    };
    axios
      .post(Links.getExecutiveReportForRec, requestBody)
      .then((result) => {
        setTotalSoldForRec(result.data.totalSold);
        setTotalSoldLastYearForRec(result.data.totalSoldLastYear);
        setTotalApptForRec(result.data.totalAppt);
        setTotalApptLastYearForRec(result.data.totalApptLastYear);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getDailyDataForUsed = (year, month) => {
    const requestBody = {
      year: year,
      month: month,
      category: category,
      divisions: divisions,
    };
    axios
      .post(Links.getExecutiveReportForUsed, requestBody)
      .then((result) => {
        setTotalSoldForUsed(result.data.totalSold);
        setTotalSoldLastYearForUsed(result.data.totalSoldLastYear);
        setTotalApptForUsed(result.data.totalAppt);
        setTotalApptLastYearForUsed(result.data.totalApptLastYear);
        // setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getDailyDataForFF = (year, month) => {
    const requestBody = {
      year: year,
      month: month,
      category: category,
      divisions: divisions,
    };
    axios
      .post(Links.getExecutiveReportForFF, requestBody)
      .then((result) => {
        setTotalSoldForFF(result.data.totalSold);
        setTotalSoldLastYearForFF(result.data.totalSoldLastYear);
        setTotalApptForFF(result.data.totalAppt);
        setTotalApptLastYearForFF(result.data.totalApptLastYear);
        // setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getDealerBudgetData = (year, month) => {
    const stringifiedYear = getFiscals(month, year);
    const requestBody = {
      fiscal_year: "FY" + stringifiedYear.toString().slice(-2),
      month: month,
      divisions: divisions,
      category: category,
    };
    axios
      .post(Links.getFilteredBudgetForExecutiveReport, requestBody)
      .then((result) => {
        setTotalSoldBudget(result.data.totalBudgetSold);
        setTotalSoldBudgetLastYear(result.data.totalBudgetSoldLastYear);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getDealerBudgetDataForRec = (year, month) => {
    const stringifiedYear = getFiscals(month, year);
    const requestBody = {
      fiscal_year: "FY" + stringifiedYear.toString().slice(-2),
      month: month,
      divisions: divisions,
      category: category,
    };
    axios
      .post(Links.getFilteredBudgetForExecutiveReportForRec, requestBody)
      .then((result) => {
        setTotalSoldBudgetForRec(result.data.totalBudgetSold);
        setTotalSoldBudgetLastYearForRec(result.data.totalBudgetSoldLastYear);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getDealerBudgetDataForUsed = (year, month) => {
    const stringifiedYear = getFiscals(month, year);
    const requestBody = {
      fiscal_year: "FY" + stringifiedYear.toString().slice(-2),
      month: month,
      divisions: divisions,
      category: category,
    };
    axios
      .post(Links.getFilteredBudgetForExecutiveReportForUsed, requestBody)
      .then((result) => {
        setTotalSoldBudgetForUsed(result.data.totalBudgetSold);
        setTotalSoldBudgetLastYearForUsed(result.data.totalBudgetSoldLastYear);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getDealerBudgetDataForFF = (year, month) => {
    const stringifiedYear = getFiscals(month, year);
    const requestBody = {
      fiscal_year: "FY" + stringifiedYear.toString().slice(-2),
      month: month,
      divisions: divisions,
      category: category,
    };
    axios
      .post(Links.getFilteredBudgetForExecutiveReportForFF, requestBody)
      .then((result) => {
        setTotalSoldBudgetForFF(result.data.totalBudgetSold);
        setTotalSoldBudgetLastYearForFF(result.data.totalBudgetSoldLastYear);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleYearChange = (event) => {
    setYear(event.target.value);
  };

  const handleMonthChange = (event) => {
    setMonth(event.target.value);
  };

  const handleCateogryChange = (event) => {
    setCategory(event.target.value);
  };

  const showResults = () => {
    setLoading(true);
    getDailyData(year, month);
    getDealerBudgetData(year, month);
    getDailyDataForRec(year, month);
    getDealerBudgetDataForRec(year, month);
    getDailyDataForUsed(year, month);
    getDealerBudgetDataForUsed(year, month);
    getDailyDataForFF(year, month);
    getDealerBudgetDataForFF(year, month);
  };

  const changeToggle = (e) => {
    if (e.target.value === 'Numbers') {
      setShowNumbers(true);
      setShowCharts(false);
    } else {
      setShowNumbers(false);
      setShowCharts(true);
    }
  };

  const passRightDivisonData = (division, type = 'dailydata') => {
    switch (division) {
      case 'Auto':
        if (type === 'dailydata' && totalSold) {
          return totalSold;
        } else if (type === 'budget' && totalSoldBudget) {
          return totalSoldBudget;
        }
        break;
      case 'Rec':
        if (type === 'dailydata' && totalSoldForRec) {
          return totalSoldForRec;
        } else if (type === 'budget' && totalSoldBudgetForRec) {
          return totalSoldBudgetForRec;
        }
        break;
      case 'Used':
        if (type === 'dailydata' && totalSoldForUsed) {
          return totalSoldForUsed
        } else if (type === 'budget' && totalSoldBudgetForUsed) {
          return totalSoldBudgetForUsed;
        }
        break;
      case 'FFUN Financial':
        if (type === 'dailydata' && totalSoldForFF) {
          return totalSoldForFF
        } else if (type === 'budget' && totalSoldBudgetForFF) {
          return totalSoldBudgetForFF;
        }
        break;
      default:
        if (type === 'dailydata' && totalSold) {
          return totalSold;
        } else if (type === 'budget' && totalSoldBudget) {
          return totalSoldBudget;
        }
    }
  };

  const calculateTotalSold = (divisionTotalSold) => {
    let totalSold = 0;
    if (divisionTotalSold) {
      divisionTotalSold.forEach((division) => {
        totalSold += division.total_sold;
      });
    }
    return totalSold;
  };

  const calculateTotalAppt = (divisionTotalAppt) => {
    let totalAppt = 0;
    if (divisionTotalAppt) {
      divisionTotalAppt.forEach((division) => {
        totalAppt += division.total_appt_made; //changed total_Appt to appt_appt_made due to back-end change of returend value name
      });
    }
    return totalAppt;
  };

  const calcUnitsBudgetedPercent = (numerator, denominator) => {
    let x = calculateTotalSold(numerator);
    let y = calculateTotalSold(denominator);
    if (y === 0 && x === 0) {
      return 0;
    } else if (y === 0 && x !== 0) {
      return 100;
    } else {
      let percent = (x / y) * 100;
      return percent.toFixed();
    }
  };

  const calcUnitsApptsPercent = (numerator, denominator) => {
    let x = calculateTotalSold(numerator);
    let y = calculateTotalAppt(denominator);
    if (y === 0 && x === 0) {
      return 0;
    } else if (y === 0 && x !== 0) {
      return 100;
    } else {
      let percent = (x / y) * 100;
      return percent.toFixed();
    }
  };


  const chartDataSoldBudge = {
    labels: divisions,
    datasets: [
      {
        label: 'current year',
        backgroundColor: '#161853',
        borderColor: '#161853',
        borderWidth: 1,
        hoverBackgroundColor: '#160040',
        hoverBorderColor: '#160040',
        data: [
          totalSold[0] ? calcUnitsBudgetedPercent(totalSold, totalSoldBudget) : 0,
          totalSoldForRec[0] ? calcUnitsBudgetedPercent(totalSoldForRec, totalSoldBudgetForRec) : 0,
          totalSoldForUsed[0] ? calcUnitsBudgetedPercent(totalSoldForUsed, totalSoldBudgetForUsed) : 0,
          totalSoldForFF[0] ? calcUnitsBudgetedPercent(totalSoldForFF, totalSoldBudgetForFF) : 0,
        ]
      },
      {
        label: 'last year',
        backgroundColor: '#EC255A',
        borderColor: '#EC255A',
        borderWidth: 2,
        hoverBackgroundColor: '#EC255A',
        hoverBorderColor: '#EC255A',
        data: [
          totalSoldLastYear[0] ? calcUnitsBudgetedPercent(totalSoldLastYear, totalSoldBudgetLastYear) : 0,
          totalSoldLastYearForRec[0] ? calcUnitsBudgetedPercent(totalSoldLastYearForRec, totalSoldBudgetLastYearForRec) : 0,
          totalSoldLastYearForUsed[0] ? calcUnitsBudgetedPercent(totalSoldLastYearForUsed, totalSoldBudgetLastYearForUsed) : 0,
          totalSoldLastYearForFF[0] ? calcUnitsBudgetedPercent(totalSoldLastYearForFF, totalSoldBudgetLastYearForFF) : 0
        ]
      }
    ],
    scales: {
      x: {
        title: {
          display: true,
          text: 'Number'
        }
      }
    }
  };

  const chartDataSoldAppt = {
    labels: divisions,
    datasets: [
      {
        label: 'current year',
        backgroundColor: '#161853',
        borderColor: '#161853',
        borderWidth: 1,
        hoverBackgroundColor: '#160040',
        hoverBorderColor: '#160040',
        data: [
          totalAppt[0] ? calcUnitsApptsPercent(totalSold, totalAppt) : 0,
          totalApptForRec[0] ? calcUnitsApptsPercent(totalSoldForRec, totalApptForRec) : 0,
          totalApptForUsed[0] ? calcUnitsApptsPercent(totalSoldForUsed, totalApptForUsed) : 0,
          totalApptForFF[0] ? calcUnitsApptsPercent(totalSoldForFF, totalApptForFF) : 0,
        ]
      },
      {
        label: 'last year',
        backgroundColor: '#EC255A',
        borderColor: '#EC255A',
        borderWidth: 2,
        hoverBackgroundColor: '#EC255A',
        hoverBorderColor: '#EC255A',
        data: [
          totalApptLastYear[0] ? calcUnitsApptsPercent(totalSoldLastYear, totalApptLastYear) : 0,
          totalApptLastYearForRec[0] ? calcUnitsApptsPercent(totalSoldLastYearForRec, totalApptLastYearForRec) : 0,
          totalApptLastYearForUsed[0] ? calcUnitsApptsPercent(totalSoldLastYearForUsed, totalApptLastYearForUsed) : 0,
          totalApptLastYearForFF[0] ? calcUnitsApptsPercent(totalSoldLastYearForFF, totalApptLastYearForFF) : 0,
        ]
      }
    ],
    scales: {
      x: {
        title: {
          display: true,
          text: 'Number'
        }
      }
    }
  };

  const percentageChartOptionsSoldBudge = {
    plugins: {
      datalabels: {
        formatter: (v) => {
          if (Number.isNaN(v)) {
            return 0 + "%"
          } else {
            return v + "%"
          }
        },
        align: 'end',
        anchor: 'end'
      }
    },
    scales: {
      y: {
        suggestedMax: 100,
        ticks: {
          callback: function (label) {
            return label + "%"
          },
        },
        // stepSize: 10,
        // beginAtZero: true,
        // max: 100,
        // min: 0,
      }
    },
  };

  const percentageChartOptionsSoldAppt = {
    plugins: {
      datalabels: {
        formatter: (v) => {
          if (Number.isNaN(v)) {
            return 0 + "%"
          } else {
            return v + "%"
          }
        },
        align: 'end',
        anchor: 'end'
      }
    },
    scales: {
      y: {
        suggestedMax: 100,
        ticks: {
          callback: function (label) {
            return label + "%"
          },
        },
        // stepSize: 10,
        // beginAtZero: true,
        // max: 100,
        // min: 0,
      }
    },
  };

  return (
    <div style={{  marginTop: "3rem", width: "100%" }}>
      <Grid container spacing={2}>
        <Grid item  container spacing={1}>
          <Grid item  xs={10} sm={6} md={2} xl={1}  spacing={1}>
            <FormControl variant="standard" sx={{ m: 1, width: "100%" }}>
              <InputLabel id="demo-simple-select-label">Year</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={year}
                label="Year"
                onChange={handleYearChange}
              >
                {YEARS.map((year, index) => (
                  <MenuItem key={index} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item  xs={10} sm={6} md={2} xl={1}  spacing={1}>
            <FormControl variant="standard" sx={{ m: 1, width: "100%" }}>
              <InputLabel id="demo-simple-select-label">Month</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={month}
                label="Age"
                onChange={handleMonthChange}
              >
                {Object.values(MONTHS).map((month, index) => (
                  <MenuItem key={index} value={month}>
                    {month}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item  xs={10} sm={6} md={2} xl={1}  spacing={1}>
            <FormControl variant="standard" sx={{ m: 1, width: "100%" }}>
              <InputLabel id="demo-simple-select-label">Category</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={category}
                label="Category"
                onChange={handleCateogryChange}
              >
                {Object.values(EXECUTIVE_REPORTS_CATEGORIES)
                  .slice(0, 3)
                  .map((month, index) => (
                    <MenuItem key={index} value={month}>
                      {month}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item  className="mt-3">
            <button
              className="p-2 mt-1 btn btn-secondary mx-3"
              type="submit"
              onClick={() => {
                showResults();
              }}
            >
              Show Details
            </button>
          </Grid>
          <Grid item className="mt-2">
            <button className={`p-2 mt-2 btn toggle ${showNumbers ? 'active' : ''}`} onClick={(e) => changeToggle(e)} value="Numbers">Numbers</button>
            <button className={`p-2 mt-2 btn toggle ${showCharts ? 'active' : ''}`} onClick={(e) => changeToggle(e)} value="Charts">Charts</button>
          </Grid>
        </Grid>

        <Grid item container xs={12}>
          <Grid item xs={10}></Grid>
          <Grid item xs={6}>
            {loading && (
              <>
                <div
                  style={{
                    
                    marginTop: "3rem",
                    right: "50%",
                    top: "30%",
                  }}
                  className="text-center position-absolute"
                >
                  <img src={CarLoading} />
                </div>
              </>
            )}
            {!loading && showNumbers && (
              <>
                <SummaryReportsTableAllDivisions
                  divisions={divisions}
                  dailyDataThisMonth={totalSold}
                  budgetThisMonth={totalSoldBudget}
                  dailyDataThisMonthForRec={totalSoldForRec}
                  budgetThisMonthForRec={totalSoldBudgetForRec}
                  dailyDataThisMonthForUsed={totalSoldForUsed}
                  budgetThisMonthForUsed={totalSoldBudgetForUsed}
                  dailyDataThisMonthForFF={totalSoldForFF}
                  budgetThisMonthForFF={totalSoldBudgetForFF}
                  type="soldUnits"
                />
                {divisions &&
                  divisions.map((division) => {
                    return (
                      <SummaryReportsTable
                        division={division}
                        budgetThisMonth={passRightDivisonData(division, 'budget')}
                        dailyDataThisMonth={passRightDivisonData(division)}
                        type="soldUnits"
                      />
                    );
                  })}
              </>
            )}
          </Grid>
        </Grid>
        {!loading && showCharts && (
          <>
            <Grid item xs={4} style={{ marginTop: '50px' }}>
              <BarChart title={'Sold Units / Budgeted Units'} data={chartDataSoldBudge} options={percentageChartOptionsSoldBudge} />
            </Grid>
            <Grid item xs={1}>
            </Grid>
            <Grid item xs={4} style={{ marginTop: '50px' }}>
              <BarChart title={'Sold Units / Appointments'} data={chartDataSoldAppt} options={percentageChartOptionsSoldAppt} />
            </Grid>
          </>
        )}
      </Grid>
    </div>
  );
}
