import React, { useState, useEffect } from "react";
import axios from 'axios';
import Cookies from 'js-cookie';
import Links from "../../Data/Links";
import monthData from "../../constants/monthData";
import dayData from "../../constants/dayData";
import DealerYearMonthSelector from "../../component/DataEntry/DealerYearMonthSelector";
import { connect, useSelector } from 'react-redux';
import { store, updateDataEntryFFRow, clearDataEntryFFRow, updateCurrentEditDayFF } from "../../redux";
import { calculateKPIPercentage } from "../../utils";
import getFiscals from '../../utils/getFiscals';

const DataEntryFfunFinancial = ({ props, editDay }) => {

  const [stores, setStores] = useState(localStorage.getItem('stores') ? JSON.parse(localStorage.getItem('stores')) : "");
  const [ffData, setFfData] = useState({});
  const [budget, setBudget] = useState({});
  const [filterVal, setFilterVal] = useState({
    store_id: 47,
    store_name: 'CreditMaxx',
    division: "FFUN Financial",
    company: 'FFUN',
    month: monthData[new Date().getMonth()],
    year: new Date().getFullYear(),
    fiscal_year: 'FY24',
  });

  let userData, currentUser, dataEntryObject;
  if (Cookies.get('user_data')) {
    userData = JSON.parse(Cookies.get('user_data'));
    currentUser = userData.userName;
    dataEntryObject = {
      transaction_lock: 1,
      locked_user: currentUser,
      store_id: filterVal.store_id,
      store_name: filterVal.store_name,
      company: filterVal.company,
      month: filterVal.month,
      year: filterVal.year,
    }
  }

  useEffect(() => {
    let storeObj = {};
    stores.length > 0 && stores.map(i => {
      if (i.division === "FFUN Financial") {
        storeObj[i.id] = i.name;
      }
    });
    setFilterVal({
      store_id: Object.keys(storeObj)[0],
      store_name: Object.values(storeObj)[0],
      division: "FFUN Financial",
      company: 'FFUN',
      month: monthData[new Date().getMonth()],
      year: new Date().getFullYear(),
      fiscal_year: 'FY24',
    })
    setStores(storeObj);
    window.scrollTo(0, 0)
  }, []);

  useEffect(() => {
    loadData();
  }, [filterVal])

  const loadData = (day) => {
    if (filterVal != null) {
      const year1 = getFiscals(filterVal.month, filterVal.year);
      filterVal.fiscal_year = 'FY' + year1.toString().substring(2);
      axios.post(Links.getFilteredFfunFinancialData, filterVal)
        .then(res => {
          setFfData(res.data.dailydata)
          if (parseInt(day) > 0) {
            store.dispatch(updateDataEntryFFRow({ ...filterVal, ...res.data.dailydata[day], 'day': day }));
          }
          axios.post(Links.getFfunFinancialBudgetForDailydata, filterVal)
            .then(result => {
              setBudget(result.data.budget);
            }).catch(err => {
              console.log(err);
            });
        }).catch(err => {
          console.log(err);
        });
    }
  }

  const [edit, setEdit] = useState({});

  const onChangeValue = (event, field) => {
    store.dispatch(updateDataEntryFFRow({ [field]: event.target.value ? event.target.value : 0 }));
  }

  const checkDatabaseTransactions = (day, dataEntry) => {
    // add a new record
    const dataEntryObjectForAdd = {
      ...dataEntry,
      ...dataEntryObject,
      transaction_locked_at: new Date().toISOString().slice(0, 19).replace("T", " "),
      day: day.toString()
    }

    axios.post(Links.addFfunFinancialDailyData, dataEntryObjectForAdd)
      .then(res => {
        if (res.data.recordExist && res.data.recordExist === true) {
          if (res.data.lockedUser !== currentUser) {
            alert(res.data.message)
            setEdit({ [day]: false });
            store.dispatch(updateCurrentEditDayFF({ "currentEditDayFF": 0 }));
            loadData(day);
          } else {
            setEdit({ [day]: true });
            loadData(day);
          }
        } else {
          loadData(day);
        }
      }).catch(err => {
        console.log(err);
      });
  };

  const checkDatabaseTransactionsForEdit = (day, id) => {
    // update an existing record
    const dataEntryObjectForEdit = {
      ...ffData[day],
      ...dataEntryObject,
      transaction_locked_at: new Date().toISOString().slice(0, 19).replace("T", " "),
      saveOrEdit: 'edit'
    };

    axios.patch(Links.updateFfunFinanciaDailyDataById + '/' + id, { row: dataEntryObjectForEdit })
      .then(res => {
        if (res.data.recordExist && res.data.recordExist === true) {
          if (res.data.lockedUser !== currentUser) {
            alert(res.data.message)
            setEdit({ [day]: false });
            store.dispatch(updateCurrentEditDayFF({ "currentEditDayFF": 0 }));
            loadData(day);
          } else {
            setEdit({ [day]: true });
            loadData(day)
          }
        } else {
          loadData(day);
        }
      }).catch(err => {
        console.log(err);
      });
  };

  const SaveRecord = (day, dataEntry) => {
    if (ffData[day]?.id) {
      axios.patch(Links.updateFfunFinanciaDailyDataById + '/' + ffData[day]?.id,
        {
          row: {
            ...dataEntry,
            transaction_lock: 0,
            locked_user: currentUser,
            transaction_locked_at: null,
            saveOrEdit: 'save'
          }
        })
        .then(res => {
          if (res.data.recordExist && res.data.recordExist === true) {
            alert("Timeout! Please try again.");
            window.location.reload();

          } else {
            alert("Data Entry has been updated.");
            setEdit({ [day]: false });
            loadData(null, 0);
            store.dispatch(clearDataEntryFFRow());
          }
          store.dispatch(updateCurrentEditDayFF({ "currentEditDayFF": 0 }))
        }).catch(err => {
          console.log(err);
        });
    }
  }

  const DeleteRecord = (day) => {
    if (editDay === 0 && window.confirm("Are you sure want to delete?")) {
      // const currentUser = JSON.parse(Cookies.get('user_data')).userName
      const dataEntryObjectForDelete = {
        ...ffData[day],
        ...dataEntryObject,
        day: day.toString(),
        saveOrEdit: 'edit'
      }
      axios.post(Links.deleteFfunFinancialDailyData, { row: dataEntryObjectForDelete })
        .then(result => {
          if (result.data.recordExist && result.data.recordExist === true) {
            alert(result.data.message);
            loadData(filterVal)
          } else {
            alert("Deleted succussfully!");
            store.dispatch(clearDataEntryFFRow());
            loadData(filterVal);
          }

        }).catch(err => {
          console.log(err);
        });
    } else {
      alert("Please save the data you are editing and try again.");
    }
  }

  // ------------------------------------------------------------------------------------------------------------------------

  const CreditMaxxTable = () => {
    const dataEntry = useSelector(state => state.dataEntryBudget.dataEntryFF);
    const creditMaxxTableFields = ["apps", "approvals", "tier_1", "tier_2", "tier_3", "tier_4", "deals", "deal_gp"];

    return (
      <table class="table table-bordered border-black" style={{ marginTop: 30 }}>
        <thead>
          <tr>
            <th className="dn-header-top" scope="col" style={{ "background-color": "#e9ecef" }}></th>
            <th className="dn-header-top" scope="col" style={{ "background-color": "#e9ecef" }}></th>
            <th className="dn-header-top" scope="col" style={{ "background-color": "#e9ecef" }}>Date</th>
            <th className="dn-header-top" scope="col" style={{ "background-color": "orange" }}>Apps</th>
            <th className="dn-header-top" scope="col" style={{ "background-color": "orange" }}>Approvals</th>
            <th className="dn-header-top" scope="col" style={{ "background-color": "orange" }}>Tier 1#</th>
            <th className="dn-header-top" scope="col" style={{ "background-color": "orange" }}>Tier 2#</th>
            <th className="dn-header-top" scope="col" style={{ "background-color": "orange" }}>Tier 3#</th>
            <th className="dn-header-top" scope="col" style={{ "background-color": "orange" }}>Tier 4#</th>
            <th className="dn-header-top" scope="col" style={{ "background-color": "orange" }}>Deals #</th>
            <th className="dn-header-top" scope="col" style={{ "background-color": "orange" }}>Deal GP $</th>
          </tr>
        </thead>
        <tbody>
          {dayData.map((day) => (
            <tr>
              <td>
                {edit[day] ?
                  <button className="button" onClick={() => SaveRecord(day, dataEntry)}>
                    Save
                  </button>
                  :
                  <button className="button"
                    onClick={() => {
                      if (editDay === 0) {
                        setEdit({ [day]: true })
                        ffData?.[day] ? checkDatabaseTransactionsForEdit(day, ffData[day].id) : checkDatabaseTransactions(day, dataEntry)
                        store.dispatch(updateCurrentEditDayFF({ "currentEditDayFF": day }))
                      }
                      else {
                        alert("Please save the data you are editing and try again.")
                      }
                    }}>
                    {ffData[day] ? 'Edit' : 'Add'}
                  </button>
                }
              </td>
              <td>
                <button className="button" onClick={() => DeleteRecord(day)}>
                  Delete
                </button>
              </td>
              <td className="dn-column">{day}</td>

              {creditMaxxTableFields.map(field => {
                if (field == 'deal_gp') {
                  {
                    return edit[day] ?
                      <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} className="dn-column">
                        <input onChange={(e) => onChangeValue(e, field)} type="number" className="dn-input" value={dataEntry[field]} />
                      </td>
                      : <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} className="dn-column">
                        {ffData[day] && ffData[day][field] ? '$' + parseInt(ffData[day][field]).toLocaleString() : '$' + 0}
                      </td>
                  }
                } else {
                  return edit[day] ?
                    <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} className="dn-column">
                      <input onChange={(e) => onChangeValue(e, field)} type="number" className="dn-input" value={dataEntry[field]} />
                    </td>
                    : <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} className="dn-column">
                      {ffData[day] && ffData[day][field] ? ffData[day][field] : 0}
                    </td>
                }
              })}

            </tr>
          ))}

          <tr>
            <td className="dn-column" scope="col" style={{ "background-color": "#e9ecef" }}></td>
            <td className="dn-column" scope="col" style={{ "background-color": "#e9ecef" }}></td>
            <td className="dn-column" scope="col" style={{ "background-color": "#e9ecef" }}>Total</td>
            {creditMaxxTableFields.map(field => {
              if (field == 'deal_gp') {
                return <td style={{ "background-color": "#e9ecef" }} className="dn-column">
                  {'$' + Object.values(ffData).map(item => item[field] ? parseInt(item[field]) : 0).reduce((a, b) => a + b, 0).toLocaleString()}
                </td>
              } else {
                return <td style={{ "background-color": "#e9ecef" }} className="dn-column">
                  {Object.values(ffData).map(item => item[field] ? parseInt(item[field]) : 0).reduce((a, b) => a + b, 0).toLocaleString()}
                </td>
              }
            })}

          </tr>
          <tr>
            <td className="dn-column" scope="col" style={{ "background-color": "#e9ecef" }}></td>
            <td className="dn-column" scope="col" style={{ "background-color": "#e9ecef" }}></td>
            <td className="dn-column" scope="col" style={{ "background-color": "#e9ecef" }}>Budget</td>
            {creditMaxxTableFields.map(field => {
              if (field == 'deal_gp') {
                return <td style={{ "background-color": "#e9ecef" }} className="dn-column">{budget && budget[field] ? '$' + parseInt(budget[field]).toLocaleString() : '$' + 0}</td>
              } else {
                return <td style={{ "background-color": "#e9ecef" }} className="dn-column">{budget && budget[field] ? parseInt(budget[field]).toLocaleString() : 0}</td>
              }
            })}

          </tr>
          <tr>
            <td className="dn-column" scope="col" style={{ "background-color": "#e9ecef" }}></td>
            <td className="dn-column" scope="col" style={{ "background-color": "#e9ecef" }}></td>
            <td className="dn-column" scope="col" style={{ "background-color": "#e9ecef" }}>KPI</td>
            {creditMaxxTableFields.map(field => {
              let field_total = Object.values(ffData).map(item => item[field] ? parseInt(item[field]) : 0).reduce((a, b) => a + b, 0);
              let field_budget = budget && budget[field] ? budget[field] : 0;
              return (
                <td style={{ "background-color": "#e9ecef" }} className="dn-column">{calculateKPIPercentage(field_total, field_budget)}</td>
              )
            })}
          </tr>
        </tbody>
      </table>
    )
  }

  const OneEarthTable = () => {
    const dataEntry = useSelector(state => state.dataEntryBudget.dataEntryFF);
    const OneEarthTableFields = ["new_auto_ffun_adv", "new_auto_gap", "new_auto_creditor", "used_auto_ffun_adv", "used_auto_warranty", "used_auto_gap", "used_auto_creditor", "rec_ffun_adv", "rec_warranty", "rec_gap", "rec_creditor"];

    const getTotalAdv = (data = null) => {
      if (data) {
        let new_auto_ffun_adv = data.new_auto_ffun_adv ? parseInt(data.new_auto_ffun_adv) : 0;
        let used_auto_ffun_adv = data.used_auto_ffun_adv ? parseInt(data.used_auto_ffun_adv) : 0;
        let rec_ffun_adv = data.rec_ffun_adv ? parseInt(data.rec_ffun_adv) : 0;
        let sum = new_auto_ffun_adv + used_auto_ffun_adv + rec_ffun_adv;
        return sum;
      } else {
        return 0;
      }
    };

    const getTotalWarranty = (data = null) => {
      if (data) {
        let used_auto_warranty = data.used_auto_warranty ? parseInt(data.used_auto_warranty) : 0;
        let rec_warranty = data.rec_warranty ? parseInt(data.rec_warranty) : 0;
        let sum = used_auto_warranty + rec_warranty;
        return sum;
      } else {
        return 0;
      }
    };

    const getTotalGap = (data = null) => {
      if (data) {
        let new_auto_gap = data.new_auto_gap ? parseInt(data.new_auto_gap) : 0;
        let used_auto_gap = data.used_auto_gap ? parseInt(data.used_auto_gap) : 0;
        let rec_gap = data.rec_gap ? parseInt(data.rec_gap) : 0;
        let sum = new_auto_gap + used_auto_gap + rec_gap;
        return sum;
      } else {
        return 0;
      }
    };

    const getTotalCreditor = (data = null) => {
      if (data) {
        let new_auto_creditor = data.new_auto_creditor ? parseInt(data.new_auto_creditor) : 0;
        let used_auto_creditor = data.used_auto_creditor ? parseInt(data.used_auto_creditor) : 0;
        let rec_creditor = data.rec_creditor ? parseInt(data.rec_creditor) : 0;
        let sum = new_auto_creditor + used_auto_creditor + rec_creditor;
        return sum;
      } else {
        return 0;
      }
    };

    let total = { ffunAdv: [], warranty: [], gap: [], creditor: [] };
    let totalBuget = { ffunAdv: getTotalAdv(budget), warranty: getTotalWarranty(budget), gap: getTotalGap(budget), creditor: getTotalCreditor(budget) };
    dayData.map((day) => {
      total.ffunAdv.push(getTotalAdv(ffData[day]));
      total.warranty.push(getTotalWarranty(ffData[day]));
      total.gap.push(getTotalGap(ffData[day]));
      total.creditor.push(getTotalCreditor(ffData[day]));
    })

    return (
      <table class="table table-bordered border-black" style={{ marginTop: 30 }}>
        <thead>
          <tr className="text-center">
            <th colSpan="3" className="dn-header-top" style={{ "background-color": "#e9ecef" }}></th>
            <th colSpan="3" className="dn-header-top" style={{ "background-color": "orange" }}>New Auto</th>
            <th colSpan="4" className="dn-header-top" style={{ "background-color": "#A2C1A2" }}>Used Auto</th>
            <th colSpan="4" className="auto-header-top" style={{ "background-color": "#B1D5DD" }}>Rec</th>
            <th colSpan="4" className="auto-header-top" style={{ "background-color": "#ccc" }}>Total</th>
          </tr>
          <tr>
            <th className="dn-header" scope="col" style={{ "background-color": "#e9ecef" }}></th>
            <th className="dn-header" scope="col" style={{ "background-color": "#e9ecef" }}></th>
            <th className="dn-header" scope="col" style={{ "background-color": "#e9ecef" }}>Date</th>
            <th className="dn-header" scope="col" style={{ "background-color": "orange" }}>OEPP</th>
            <th className="dn-header" scope="col" style={{ "background-color": "orange" }}>GAP</th>
            <th className="dn-header" scope="col" style={{ "background-color": "orange" }}>Creditor</th>
            <th className="dn-header" scope="col" style={{ "background-color": "#A2C1A2" }}>OEPP</th>
            <th className="dn-header" scope="col" style={{ "background-color": "#A2C1A2" }}>Warranty</th>
            <th className="dn-header" scope="col" style={{ "background-color": "#A2C1A2" }}>Gap</th>
            <th className="dn-header" scope="col" style={{ "background-color": "#A2C1A2" }}>Creditor</th>
            <th className="dn-header" scope="col" style={{ "background-color": "#B1D5DD" }}>OEPP</th>
            <th className="dn-header" scope="col" style={{ "background-color": "#B1D5DD" }}>Warranty</th>
            <th className="dn-header" scope="col" style={{ "background-color": "#B1D5DD" }}>Gap</th>
            <th className="dn-header" scope="col" style={{ "background-color": "#B1D5DD" }}>Creditor</th>
            <th className="dn-header" scope="col" style={{ "background-color": "#ccc" }}>OEPP</th>
            <th className="dn-header" scope="col" style={{ "background-color": "#ccc" }}>Warranty</th>
            <th className="dn-header" scope="col" style={{ "background-color": "#ccc" }}>Gap</th>
            <th className="dn-header" scope="col" style={{ "background-color": "#ccc" }}>Creditor</th>
          </tr>
        </thead>
        <tbody>
          {dayData.map((day) => (
            <tr>
              <td>
                {edit[day] ?
                  <button className="button" onClick={() => SaveRecord(day, dataEntry)}>
                    Save
                  </button>
                  :
                  <button className="button" onClick={() => {
                    if (editDay === 0) {
                      setEdit({ [day]: true })
                      ffData?.[day] ? checkDatabaseTransactionsForEdit(day, ffData[day].id) : checkDatabaseTransactions(day, dataEntry)
                      store.dispatch(updateCurrentEditDayFF({ "currentEditDayFF": day }))
                    }
                    else {
                      alert("Please save the data you are editing and try again.")
                    }
                  }}>
                    {ffData[day] ? 'Edit' : 'Add'}
                  </button>
                }</td>
              <td>
                <button className="button" onClick={() => DeleteRecord(day)}>
                  Delete
                </button>
              </td>
              <td>{day}</td>
              {OneEarthTableFields.map(field => (
                edit[day] ?
                  <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} className="dn-column">
                    <input onChange={(e) => onChangeValue(e, field)} type="number" className="dn-input" value={dataEntry[field]} />
                  </td>
                  : <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} className="dn-column">{ffData[day] && ffData[day][field] ? ffData[day][field] : 0}</td>
              ))}
              <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} className="dn-column">{getTotalAdv(ffData[day]).toLocaleString()}</td>
              <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} className="dn-column">{getTotalWarranty(ffData[day]).toLocaleString()}</td>
              <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} className="dn-column">{getTotalGap(ffData[day]).toLocaleString()}</td>
              <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} className="dn-column">{getTotalCreditor(ffData[day]).toLocaleString()}</td>
            </tr>
          ))}

          <tr>
            <td className="dn-column" scope="col" style={{ "background-color": "#e9ecef" }}></td>
            <td className="dn-column" scope="col" style={{ "background-color": "#e9ecef" }}></td>
            <td className="dn-column" scope="col" style={{ "background-color": "#e9ecef" }}>Total</td>
            {OneEarthTableFields.map(field => (
              <td style={{ "background-color": "#e9ecef" }} className="dn-column">
                {Object.values(ffData).map(item => parseInt(item[field] ? item[field] : 0)).reduce((a, b) => a + b, 0).toLocaleString()}
              </td>
            ))}
            <td style={{ "background-color": "#e9ecef" }} className="dn-column">{total.ffunAdv.reduce((a, b) => a + b, 0).toLocaleString()}</td>
            <td style={{ "background-color": "#e9ecef" }} className="dn-column">{total.warranty.reduce((a, b) => a + b, 0).toLocaleString()}</td>
            <td style={{ "background-color": "#e9ecef" }} className="dn-column">{total.gap.reduce((a, b) => a + b, 0).toLocaleString()}</td>
            <td style={{ "background-color": "#e9ecef" }} className="dn-column">{total.creditor.reduce((a, b) => a + b, 0).toLocaleString()}</td>
          </tr>
          <tr>
            <td className="dn-column" scope="col" style={{ "background-color": "#e9ecef" }}></td>
            <td className="dn-column" scope="col" style={{ "background-color": "#e9ecef" }}></td>
            <td className="dn-column" scope="col" style={{ "background-color": "#e9ecef" }}>Budget</td>
            {OneEarthTableFields.map(field => (
              <td style={{ "background-color": "#e9ecef" }} className="dn-column">{budget && budget[field] ? parseInt(budget[field]).toLocaleString() : 0}</td>
            ))}
            <td style={{ "background-color": "#e9ecef" }} className="dn-column">{totalBuget.ffunAdv.toLocaleString()}</td>
            <td style={{ "background-color": "#e9ecef" }} className="dn-column">{totalBuget.warranty.toLocaleString()}</td>
            <td style={{ "background-color": "#e9ecef" }} className="dn-column">{totalBuget.gap.toLocaleString()}</td>
            <td style={{ "background-color": "#e9ecef" }} className="dn-column">{totalBuget.creditor.toLocaleString()}</td>
          </tr>
          <tr>
            <td className="dn-column" scope="col" style={{ "background-color": "#e9ecef" }}></td>
            <td className="dn-column" scope="col" style={{ "background-color": "#e9ecef" }}></td>
            <td className="dn-column" scope="col" style={{ "background-color": "#e9ecef" }}>KPI</td>
            {OneEarthTableFields.map(field => {
              let field_total = Object.values(ffData).map(item => item[field] ? parseInt(item[field]) : 0).reduce((a, b) => a + b, 0);
              let field_budget = budget && budget[field] ? budget[field] : 0;
              return (
                <td style={{ "background-color": "#e9ecef" }} className="dn-column">{calculateKPIPercentage(field_total, field_budget)}</td>
              )
            })}
            <td style={{ "background-color": "#e9ecef" }} className="dn-column">{calculateKPIPercentage(total.ffunAdv.reduce((a, b) => a + b, 0), totalBuget.ffunAdv)}</td>
            <td style={{ "background-color": "#e9ecef" }} className="dn-column">{calculateKPIPercentage(total.warranty.reduce((a, b) => a + b, 0), totalBuget.warranty)}</td>
            <td style={{ "background-color": "#e9ecef" }} className="dn-column">{calculateKPIPercentage(total.gap.reduce((a, b) => a + b, 0), totalBuget.gap)}</td>
            <td style={{ "background-color": "#e9ecef" }} className="dn-column">{calculateKPIPercentage(total.creditor.reduce((a, b) => a + b, 0), totalBuget.creditor)}</td>
          </tr>

        </tbody>
      </table>
    )
  }


  return (
    <div style={{ margin: "2rem 2rem 2rem 0rem" }}>

      <DealerYearMonthSelector stores={stores} filterVal={filterVal} setFilterVal={setFilterVal} loadData={() => loadData()} />

      {filterVal.store_name === "CreditMaxx" && <CreditMaxxTable />}
      {filterVal.store_name === "One Earth" && <OneEarthTable />}

    </div>
  );
};

const mapStateToProps = state => ({
  // dataEntry: state.dataEntryBudget.dataEntryFF,
  editDay: state.currentEditDayUsed.currentEditDayUsed
});
export default connect(mapStateToProps)(DataEntryFfunFinancial);
