import React from 'react'
import { calculatePercentageOfBudget } from '../../utils';

const GroupRecapTable = (props) => {
  const { division, stores, dailyDataGrossProfit, dailyDataGrossProfitLastYearSameMonth,
    budgetTotalGrossProfit, budgetTotalGrossProfitLastYearSameMonth, usedStores } = props;

  let rankedStores = {};
  let rankedStoresUsed = {};
  if (division !== "Used") {
    // rankedStores=stores;
    rankedStores = Object.keys(dailyDataGrossProfit).length > 0 && Object.keys(budgetTotalGrossProfit).length > 0 ?
      stores.sort((a, b) => {
        let dailyDataforCurrentStoreA = dailyDataGrossProfit.find(key => a === key.store_name);
        let budgetforCurrentStoreA = budgetTotalGrossProfit.find(key => a === key.store_name);
        let dailyDataforCurrentStoreB = dailyDataGrossProfit.find(key => b === key.store_name);
        let budgetforCurrentStoreB = budgetTotalGrossProfit.find(key => b === key.store_name);

        dailyDataforCurrentStoreA = dailyDataforCurrentStoreA === undefined ? {} : dailyDataforCurrentStoreA;
        budgetforCurrentStoreA = budgetforCurrentStoreA === undefined ? {} : budgetforCurrentStoreA;
        dailyDataforCurrentStoreB = dailyDataforCurrentStoreB === undefined ? {} : dailyDataforCurrentStoreB;
        budgetforCurrentStoreB = budgetforCurrentStoreB === undefined ? {} : budgetforCurrentStoreB;
        return (parseInt(calculatePercentageOfBudget(dailyDataforCurrentStoreA?.total_gross_profit || 0, budgetforCurrentStoreA?.total_gross_profit || 0) || 0) < parseInt(calculatePercentageOfBudget(dailyDataforCurrentStoreB?.total_gross_profit || 0, budgetforCurrentStoreB?.total_gross_profit || 0) || 0)) ? 1

          : (parseInt(calculatePercentageOfBudget(dailyDataforCurrentStoreB?.total_gross_profit || 0, budgetforCurrentStoreB?.total_gross_profit || 0) || 0) < parseInt(calculatePercentageOfBudget(dailyDataforCurrentStoreA.total_gross_profit, budgetforCurrentStoreA?.total_gross_profit || 0) || 0) ? -1 : 0)


      }) : stores;
  }


  if (division === "Used") {
    // rankedStoresUsed=usedStores;
    rankedStoresUsed = Object.keys(dailyDataGrossProfit).length > 0 && Object.keys(budgetTotalGrossProfit).length > 0 ? usedStores.sort((a, b) => {
      let dailyDataforCurrentStoreA = dailyDataGrossProfit.find(key => a.id === key.store_id);
      let budgetforCurrentStoreA = budgetTotalGrossProfit.find(key => a.id === key.store_id);
      let dailyDataforCurrentStoreB = dailyDataGrossProfit.find(key => b.id === key.store_id);
      let budgetforCurrentStoreB = budgetTotalGrossProfit.find(key => b.id === key.store_id);

      dailyDataforCurrentStoreA = dailyDataforCurrentStoreA === undefined ? {} : dailyDataforCurrentStoreA;
      budgetforCurrentStoreA = budgetforCurrentStoreA === undefined ? {} : budgetforCurrentStoreA;
      dailyDataforCurrentStoreB = dailyDataforCurrentStoreB === undefined ? {} : dailyDataforCurrentStoreB;
      budgetforCurrentStoreB = budgetforCurrentStoreB === undefined ? {} : budgetforCurrentStoreB;

      return (parseInt(calculatePercentageOfBudget(dailyDataforCurrentStoreA?.total_gross_profit || 0, budgetforCurrentStoreA?.total_gross_profit || 0) || 0) < parseInt(calculatePercentageOfBudget(dailyDataforCurrentStoreB?.total_gross_profit || 0, budgetforCurrentStoreB?.total_gross_profit || 0) || 0)) ? 1

        : (parseInt(calculatePercentageOfBudget(dailyDataforCurrentStoreB?.total_gross_profit || 0, budgetforCurrentStoreB?.total_gross_profit || 0) || 0) < parseInt(calculatePercentageOfBudget(dailyDataforCurrentStoreA?.total_gross_profit || 0, budgetforCurrentStoreA?.total_gross_profit || 0) || 0) ? -1 : 0)

    })
      : usedStores
      ;
  }

  return (
    <table className="table table-bordered border-black table-fit" style={{ marginTop: 20 }}>
      <thead>
        <tr className="text-center">
          <th colSpan="1" className="dn-header-top" style={{ whiteSpace: 'nowrap', backgroundColor: 'orange' }}>Dealer</th>
          <th colSpan="1" className="dn-header-top" style={{ whiteSpace: 'nowrap', backgroundColor: 'orange' }}>Actual Gross</th>
          <th colSpan="1" className="dn-header-top" style={{ whiteSpace: 'nowrap', backgroundColor: 'orange' }}>Forecast</th>
          <th colSpan="1" className="dn-header-top" style={{ whiteSpace: 'nowrap', backgroundColor: 'orange' }} >% Achieved</th>
          <th colSpan="1" className="dn-header-top" style={{ whiteSpace: 'nowrap', backgroundColor: 'orange' }}>Last Year</th>
          <th colSpan="1" className="dn-header-top" style={{ whiteSpace: 'nowrap', backgroundColor: 'orange' }}>% of Last Year</th>
        </tr>
      </thead>
      <tbody>
        {division !== 'Used' && rankedStores && rankedStores.map((store) => {
          const dailyDataforCurrentStore = dailyDataGrossProfit.find(key => store === key.store_name);
          const budgetforCurrentStore = budgetTotalGrossProfit.find(key => store === key.store_name);
          const dailyDataforCurrentStoreLastYear = dailyDataGrossProfitLastYearSameMonth.find(key => store === key.store_name);
          const budgetforCurrentStoreLastYear = budgetTotalGrossProfitLastYearSameMonth.find(key => store === key.store_name);
          return <>
            <tr>
              <td style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>
                {dailyDataforCurrentStore ? dailyDataforCurrentStore.store_name : store}
              </td>
              <td style={{ fontWeight: 400, textAlign: 'center' }}>
                {dailyDataforCurrentStore && dailyDataforCurrentStore.total_gross_profit ?
                  dailyDataforCurrentStore.total_gross_profit.toLocaleString('en-CA', {
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0,
                    style: 'currency',
                    currency: 'CAD',
                  })
                  : '0'}
              </td>
              <td style={{ fontWeight: 400, textAlign: 'center' }}>
                {budgetforCurrentStore && budgetforCurrentStore.total_gross_profit ?
                  budgetforCurrentStore.total_gross_profit.toLocaleString('en-CA', {
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0,
                    style: 'currency',
                    currency: 'CAD',
                  })
                  : '0'}
              </td>
              <td style={{ fontWeight: 500, textAlign: 'center', position: 'relative' }}>
                <div class="progress" style={{ height: "100%", width: '100%', position: 'absolute', right: '0%', top: '0%', zIndex: '-1', borderRadius: '0px' }}>
                  <div class="progress-bar" role="progressbar" style={{ backgroundColor: '#7CEF7C ', width: dailyDataforCurrentStore && budgetforCurrentStore ? calculatePercentageOfBudget(dailyDataforCurrentStore.total_gross_profit, budgetforCurrentStore.total_gross_profit) : '0' }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                {dailyDataforCurrentStore && budgetforCurrentStore ?
                  calculatePercentageOfBudget(dailyDataforCurrentStore.total_gross_profit, budgetforCurrentStore.total_gross_profit)
                  : '0'}
              </td>
              <td style={{ fontWeight: 400, textAlign: 'center' }}>
                {dailyDataforCurrentStoreLastYear && dailyDataforCurrentStoreLastYear.total_gross_profit ?
                  dailyDataforCurrentStoreLastYear.total_gross_profit.toLocaleString('en-CA', {
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0,
                    style: 'currency',
                    currency: 'CAD',
                  })
                  : '0'}
              </td>
              <td style={{ fontWeight: 400, textAlign: 'center', position: 'relative' }}>
                <div class="progress" style={{ height: "100%", width: '100%', position: 'absolute', right: '0%', top: '0%', zIndex: '-1', borderRadius: '0px' }}>
                  <div class="progress-bar" role="progressbar" style={{ backgroundColor: '#7CEF7C ', width: dailyDataforCurrentStoreLastYear && budgetforCurrentStoreLastYear ? calculatePercentageOfBudget(dailyDataforCurrentStoreLastYear.total_gross_profit, budgetforCurrentStoreLastYear.total_gross_profit) : '0' }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                {dailyDataforCurrentStoreLastYear && budgetforCurrentStoreLastYear ? calculatePercentageOfBudget(dailyDataforCurrentStoreLastYear.total_gross_profit, budgetforCurrentStoreLastYear.total_gross_profit) : '0'}
              </td>
            </tr>
          </>
        })
        }
        {division === 'Used' && rankedStoresUsed && rankedStoresUsed.map((store) => {
          const dailyDataforCurrentStore = dailyDataGrossProfit.find(key => store.id === key.store_id);
          const budgetforCurrentStore = budgetTotalGrossProfit.find(key => store.id === key.store_id);
          const dailyDataforCurrentStoreLastYear = dailyDataGrossProfitLastYearSameMonth.find(key => store.id === key.store_id);
          const budgetforCurrentStoreLastYear = budgetTotalGrossProfitLastYearSameMonth.find(key => store.id === key.store_id);
          return <>
            <tr>
              <td style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>
                {dailyDataforCurrentStore ? usedStores.find(key => key.id === dailyDataforCurrentStore.store_id).division + ' ' + dailyDataforCurrentStore.store_name : store.name}
              </td>
              <td style={{ fontWeight: 400, textAlign: 'center' }}>
                {dailyDataforCurrentStore && dailyDataforCurrentStore.total_gross_profit ?
                  dailyDataforCurrentStore.total_gross_profit.toLocaleString('en-CA', {
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0,
                    style: 'currency',
                    currency: 'CAD',
                  })
                  : '0'}
              </td>
              <td style={{ fontWeight: 400, textAlign: 'center' }}>
                {budgetforCurrentStore && budgetforCurrentStore.total_gross_profit ?
                  budgetforCurrentStore.total_gross_profit.toLocaleString('en-CA', {
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0,
                    style: 'currency',
                    currency: 'CAD',
                  })
                  : '0'}
              </td>
              <td style={{ fontWeight: 500, textAlign: 'center', position: 'relative' }}>
                <div class="progress" style={{ height: "100%", width: '100%', position: 'absolute', right: '0%', top: '0%', zIndex: '-1', borderRadius: '0px' }}>
                  <div class="progress-bar" role="progressbar" style={{ backgroundColor: '#7CEF7C ', width: dailyDataforCurrentStore && budgetforCurrentStore ? calculatePercentageOfBudget(dailyDataforCurrentStore.total_gross_profit, budgetforCurrentStore.total_gross_profit) : '0' }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                {dailyDataforCurrentStore && budgetforCurrentStore ? calculatePercentageOfBudget(dailyDataforCurrentStore.total_gross_profit, budgetforCurrentStore.total_gross_profit) : '0'}
              </td>
              <td style={{ fontWeight: 400, textAlign: 'center' }}>
                {dailyDataforCurrentStoreLastYear && dailyDataforCurrentStoreLastYear.total_gross_profit ?
                  dailyDataforCurrentStoreLastYear.total_gross_profit.toLocaleString('en-CA', {
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0,
                    style: 'currency',
                    currency: 'CAD',
                  })
                  : '0'}
              </td>
              <td style={{ fontWeight: 400, textAlign: 'center', position: 'relative' }}>
                <div class="progress" style={{ height: "100%", width: '100%', position: 'absolute', right: '0%', top: '0%', zIndex: '-1', borderRadius: '0px' }}>
                  <div class="progress-bar" role="progressbar" style={{ backgroundColor: '#7CEF7C ', width: dailyDataforCurrentStoreLastYear && budgetforCurrentStoreLastYear ? calculatePercentageOfBudget(dailyDataforCurrentStoreLastYear.total_gross_profit, budgetforCurrentStoreLastYear.total_gross_profit) : '0' }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                {dailyDataforCurrentStoreLastYear && budgetforCurrentStoreLastYear ?
                  calculatePercentageOfBudget(dailyDataforCurrentStoreLastYear.total_gross_profit, budgetforCurrentStoreLastYear.total_gross_profit)
                  : '0'}
              </td>
            </tr>
          </>
        })
        }
      </tbody>
    </table>
  )
};

export default GroupRecapTable