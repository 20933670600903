import React from 'react'
import FFUN_Wheel from '../images/FFUN_Wheel.png'
import LeaderBoard from '../component/Reports/LeaderBoard'
import FFUN_Wheel_opacity from '../images/output-onlinepngtools.png'
export default function Home() {
  const now = new Date().toLocaleDateString()
  return (
    <>
      {/* <div className="main"> */}
        <div className='container'>
          <div className='row no-gutters'>
            <div  className='w-100' style={{ borderRadius: 4,padding: 20, border: '0px solid #adadad', boxShadow: '0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%)'}}>
              <h1 className='text-center'>FFUN Group Leader Board</h1>
              <p className='text-center'>last update: {now}</p>
              <div  className='row mx-auto text-center'  style={{width:300}} >
                <div className='row mx-auto"'  style={{width:105}}>
                  <div className='mr-1' style={{height:19,width:7,backgroundColor:"#fabf00"}}></div> <div className='font-bold' style={{width:50}}>Gold</div>
                </div>
                <div  className='row mx-auto"'  style={{width:111}}>
                  <div className='mr-1' style={{height:19,width:7,backgroundColor:"#C0C0C0"}}></div><div className='font-weight-bold'  style={{width:50}}>Silver</div> 
                </div>
                <div  className='row mx-auto"'  style={{width:100}}>
                  <div className='mr-1' style={{height:19,width:7,backgroundColor:"#B08D57"}}></div><div className='font-weight-bold'  style={{width:50}}>Bronze</div> 
                </div>
                </div>
              <LeaderBoard />
            </div>
            <div className='col-6'>
            </div>
          </div>
        </div>
      {/* </div> */}
    </>
  )
}
