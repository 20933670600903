import React, { useState, useEffect } from "react";
import { Paper, withStyles, Grid, TextField, Button, FormControlLabel, Checkbox } from '@material-ui/core';
import MicrosoftLogin from "react-microsoft-login";
import { Face, Fingerprint, Lock, AccountCircle } from '@material-ui/icons'
import { Redirect, Route } from "react-router-dom";
import Links from "../Data/Links";
import axios from 'axios';
import Cookies from 'js-cookie';
import { useHistory } from "react-router-dom";
import WavingHand from '../images/waving hand.png'
import { Alert } from "bootstrap";
// import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const fields = {
  userEmail: "",
  password: "",

};

const errors = {
  userEmail: "",
  password: ""
};

// const setCookies = function (users) {
//   if (!users || users === {}) {
//     Cookies.remove('user_data');
//   } else {
//     if (Cookies.get('user_data')) { //TODO: Check this
//       Cookies.remove('user_data')
//     };

//     // if (users.firebase_token) {
//     //   users['firebase_token'] = JSON.stringify(users.firebase_token);
//     // };

//   };
// };


export default function Signin() {

  let history = useHistory();
  if (Cookies.get('user_data') !== "" && Cookies.get('user_data') !== undefined) {
    // history.push("/home");
    window.location = '/home';
  }
  let [error, setError] = useState(errors);
  let [saveInfo, setSaveInfo] = useState(fields);
  const [notLoggedIn, setNotLoggedIn] = useState("");
  const OnChange = (e) => {
    const { name, value } = e.target;
    setSaveInfo(fields => ({ ...fields, [name]: value }));

    let emailPattern = new RegExp("^[A-Za-z\._0-9]+@[A-Za-z0-9]+\.[A-Za-z]+$");
    if (name == 'userEmail') {
      if (emailPattern.test(value) == false) {
        setError(error => ({ ...error, [name]: "Please enter a valid email!" }));
      } else {
        // setError(error => ({ ...error, [name]: "" }))
        setError(error => ({ ...error, [name]: "" }));
      };
    } else {
      setError(error => ({ ...error, [name]: "" }));
    }


  }

  const Login = () => {
    if (saveInfo.userEmail === '' && saveInfo.password === '') {
      if (saveInfo.userEmail == '' && saveInfo.password == '') {
        setError(error => ({ ...error, ["userEmail"]: "Please enter a valid email!" }));
        setError(error => ({ ...error, ["password"]: "Please enter a valid password!" }));
      }
      else if (saveInfo.userEmail == '') {
        setError(error => ({ ...error, ["userEmail"]: "Please enter a valid email!" }));

      }
      else {
        setError(error => ({ ...error, ["password"]: "Please enter a valid password!" }));
      }
    }

    else {
      axios.post(Links.userLogin, saveInfo)
        .then(res => {
          if (res.data !== null && res.data !== "Invalid password or email!" && res.data !== "Our team is setting up your account!") {
            const userData = res.data;
            if (userData.roles === "Sadmin") {
              axios.get(Links.getAllStores)
                .then(response => {
                  if (response) {
                    Cookies.set('user_data', JSON.stringify(userData));
                    localStorage.setItem('stores', JSON.stringify(response.data.stores));

                    window.location = '/home';


                  }
                  else {
                    alert("Something went wrong!");
                  }
                })
                .catch(error => {
                  alert("Something went wrong!");
                });
            }
            else {
              axios.post(Links.getStoreByMultipleId, { id: userData.stores })
                .then(res => {
                  Cookies.set('user_data', JSON.stringify(userData));
                  localStorage.setItem('stores', JSON.stringify(res.data.stores));

                  window.location = '/home';



                }).catch(err => {

                  alert("Something went wrong!");
                });
            }


          }
          else {
            alert(res.data);
          }


        }).catch(err => {

          alert("Something went wrong!");
        });
    }
  };


  const LoginMicrosoft = (email) => {


    axios.post(Links.userMicrosoftLogin, { userEmail: email })
      .then(res => {
        if (res.data !== null && res.data !== "" && res.data !== "Invalid password or email!" && res.data !== "Our team is setting up your account!") {
          const userData = res.data;
          if (userData.roles === "Sadmin") {
            axios.get(Links.getAllStores)
              .then(response => {
                if (response) {
                  Cookies.set('user_data', JSON.stringify(userData));
                  localStorage.setItem('stores', JSON.stringify(response.data.stores));

                  window.location = '/home';


                }
                else {
                  alert("Something went wrong!");
                }
              })
              .catch(error => {
                alert("Something went wrong!");
              });
          }
          else {
            axios.post(Links.getStoreByMultipleId, { id: userData.stores })
              .then(res => {
                Cookies.set('user_data', JSON.stringify(userData));
                localStorage.setItem('stores', JSON.stringify(res.data.stores));

                window.location = '/home';



              }).catch(err => {

                alert("Something went wrong!");
              });
          }

          Cookies.set('isMicrosoft', true);
        }
        else {
          setNotLoggedIn(email)
          alert("Please reach out to admin for further assistance!");
        }


      }).catch(err => {

        alert("Something went wrong!");
      });
  };

  const authHandler = (err, data, msal) => {
    if (err !== null) {
      Cookies.remove("user_data");
      localStorage.removeItem("stores");
      Cookies.remove('isMicrosoft');
      msal.logout();
      alert("There is something wrong! Please try sign in again or contact the admin.")
    }
    else {

      if (notLoggedIn !== data.account.userName) {
        LoginMicrosoft(data.account.userName);
      }

    }
    console.log(data);

  };



  return (
    <>
      <div className="p-4">

        <Grid container>

          <Grid item xs={12} >
            <h1 className="text-center text-dark mb-0" style={{ marginTop: '5%' }}>
              <strong style={{ color: '#54575a' }}>Welcome to FFUN FITT</strong>
            </h1>
          </Grid>
          <Grid item xs={12}>
            <h4 style={{ color: '#54575a' }} className="text-center">Stay FITT and Have FFUN</h4>
          </Grid>
        
        </Grid>
        <Grid container justifyContent="center">
          <Paper style={{
            padding: '40px',
            border: "2px solid #dbdbdb",
            marginTop: '10px',
            borderRadius: '10px',
            width: '470px'
          }} variant="elevation" elevation={24}>
            {/* <h4 className="mt-auto mb-5 text-center" >Please click <span style={{ color: '#F79020', fontWeight: "bold" }}>signin with microsoft</span> and enter your office email and password</h4> */}
            <h4 className="mt-auto mb-5 text-center" > <span style={{ color: '#F79020', fontWeight: "bold" }}>LOG IN</span> </h4>
            <div className="text-center">
              <MicrosoftLogin clientId="97892af2-6bc6-4a79-95aa-1fed374b50c4" authCallback={authHandler} />
            </div>

            <div className="my-4 w-100 row justify-content-center" style={{textAlign:"center"}}><hr className="w-25 mt-2 mr-1"/>&nbsp;&nbsp; OR &nbsp;&nbsp;<hr className="w-25 mt-2"/></div>
            <div className="mb-3">
              <Grid container spacing={4} alignItems="flex-end">
                <Grid item xs={1}>
                  <AccountCircle />
                </Grid>
                <Grid item md={true} sm={true} xs={true}>
                  <TextField onChange={e => OnChange(e)} value={saveInfo.userEmail} id="userEmail" label="User email" name="userEmail" type="email" fullWidth autoFocus required />
                  {error.userEmail !== "" && <div className="text-danger">{error.userEmail}</div>}
                </Grid>
              </Grid>
              <Grid container spacing={4} alignItems="flex-end">
                <Grid item xs={1}>
                  <Lock />
                </Grid>
                <Grid item md={true} sm={true} xs={true}>
                  <TextField id="username" onChange={e => OnChange(e)} label="Password" type="password" name="password" value={saveInfo.password} fullWidth required />
                  {error.password !== "" && <div className="text-danger">{error.password}</div>}
                </Grid>
              </Grid>

              <Grid container justifyContent="center" style={{ marginTop: '10px' }}>
                <Button className="mt-5 w-100 text-white" variant="outlined" disabled={error.userEmail !== "" && "disabled"} onClick={e => Login(e)} style={{ textTransform: "none", borderColor: 'white', backgroundColor: '#F79020' }}>Login</Button>
              </Grid>
            </div>
        


          </Paper>
        </Grid>
      </div>
    </>
  );
};

