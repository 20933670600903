import React, { useState, useEffect } from "react";
import axios from 'axios';
import Cookies from 'js-cookie';
import Links from "../../Data/Links";
import CreateRowForUsed from "../../component/DataEntry/CreateRowForUsed";
import TotalRowForUsed from "../../component/DataEntry/TotalRowForUsed";
import monthData from "../../constants/monthData";
import dayData from "../../constants/dayData";
import KPIRowForUsed from "../../component/DataEntry/KPIRowForUsed";
import THForUsed from "../../component/DataEntry/THForUsed";
import DealerYearMonthSelector from "../../component/DataEntry/DealerYearMonthSelector";
import ForecastRowForUsed from "../../component/DataEntry/ForecastRowForUsed";
import { connect } from 'react-redux';
import { store, updateDataEntryRow } from "../../redux";
import getFiscals from '../../utils/getFiscals';


const DataEntryVillage = ({ props, editDay }) => {
  const [stores, setStores] = useState(localStorage.getItem('stores') ? JSON.parse(localStorage.getItem('stores')) : "");
  const [vlgData, setVlgData] = useState({});
  const [budget, setBudget] = useState({});
  const [filterVal, setFilterVal] = useState({
    store_id: 39,
    store_name: 'Village Auto Sales',
    division: "Village",
    company: 'FFUN',
    month: monthData[new Date().getMonth()],
    fiscal_year: 'FY' +  getFiscals(monthData[new Date().getMonth()],new Date().getFullYear()).toString().substring(2),
    year: new Date().getFullYear()
  });

  let userData;
  if (Cookies.get('user_data')) {
    userData = JSON.parse(Cookies.get('user_data'));
  }

  useEffect(() => {
    let storeObj = {};
    stores.length > 0 && stores?.map(i => {
      if (i.division === "Village") {
        storeObj[i.id] = i.name;
      }
    });
    setStores(storeObj);
    window.scrollTo(0, 0)
    setFilterVal({
      store_id: Object.keys(storeObj)[0],
      store_name: Object.values(storeObj)[0],
      division: "Village",
      company: 'FFUN',
      month: monthData[new Date().getMonth()],
      year: new Date().getFullYear(),
      fiscal_year: 'FY' +  getFiscals(monthData[new Date().getMonth()],new Date().getFullYear()).toString().substring(2),
    })
  }, []);

  useEffect(() => {
    loadData();
  }, [filterVal])

  const loadData = (filterData = null, day) => {
    filterVal.fiscal_year = 'FY' +  getFiscals(filterVal.month,filterVal.year).toString().substring(2)
    axios.post(Links.getFilteredUsedData, filterVal)
      .then(res => {
        setVlgData(res.data.dailydata)
        if (parseInt(day) > 0) {
          store.dispatch(updateDataEntryRow({ ...filterVal, ...res.data.dailydata[day], 'day': day }));
        }
        axios.post(Links.getBudgetForUsedDailydata, filterVal)
          .then(result => {
            setBudget(result.data.budget);
          }).catch(err => {
            console.log(err);
          });
      }).catch(err => {
        console.log(err);
      });
  }
  let total = {
    used_retail_delivered: 0,
    used_retail_front_GP: 0,
    used_retail_back_GP: 0,
    service_total_hours: 0,
    service_CP_hours: 0,
    service_CP_ROs: 0,
    service_total_GP: 0,
    service_hours_sold: 0,
    service_hours_available: 0,
    service_no_of_tech: 0,
    service_booked_hours: 0,
    service_units_shipped: 0,
    appt_made: 0,
    appt_show: 0,
    store_visit: 0,
    lead: 0,
    sales_staff: 0,
    booked: 0,
    no_show: 0,
    phone_up: 0,
    fresh_up: 0,
    internet_lead:0,
  };
  return (
    <div style={{ margin: "2rem 2rem 2rem 0rem" }}>

      <DealerYearMonthSelector stores={stores} filterVal={filterVal} setFilterVal={setFilterVal} loadData={() => loadData()} />
      <table class="table table-bordered border-black" style={{ marginTop: 30 }}>
        <THForUsed />
        <tbody>
          {
            vlgData && dayData.map((i, n) => {
              total.used_retail_delivered = (vlgData[i] && vlgData[i].used_retail_delivered !== '') ? total.used_retail_delivered + parseInt(vlgData[i].used_retail_delivered) : total.used_retail_delivered;
              total.used_retail_front_GP = (vlgData[i] && vlgData[i].used_retail_front_GP !== '') ? total.used_retail_front_GP + parseInt(vlgData[i].used_retail_front_GP) : total.used_retail_front_GP;
              total.used_retail_back_GP = (vlgData[i] && vlgData[i].used_retail_back_GP !== '') ? total.used_retail_back_GP + parseInt(vlgData[i].used_retail_back_GP) : total.used_retail_back_GP;
              total.service_total_hours = (vlgData[i] && vlgData[i].service_total_hours !== '') ? total.service_totainternet_leadfresh_upl_hours + parseInt(vlgData[i].service_total_hours) : total.service_total_hours;
              total.service_CP_hours = (vlgData[i] && vlgData[i].service_CP_hours !== '') ? total.service_CP_hours + parseInt(vlgData[i].service_CP_hours) : total.service_CP_hours;
              total.service_CP_ROs = (vlgData[i] && vlgData[i].service_CP_ROs !== '') ? total.service_CP_ROs + parseInt(vlgData[i].service_CP_ROs) : total.service_CP_ROs;
              total.service_total_GP = (vlgData[i] && vlgData[i].service_total_GP !== '') ? total.service_total_GP + parseInt(vlgData[i].service_total_GP) : total.service_total_GP;
           
              total.service_hours_sold = (vlgData[i] && vlgData[i].service_hours_sold >0) ? total.service_hours_sold + parseInt(vlgData[i].service_hours_sold) : total.service_hours_sold;
              total.service_hours_available = (vlgData[i] && vlgData[i].service_hours_available >0) ? total.service_hours_available + parseInt(vlgData[i].service_hours_available) : total.service_hours_available;
              total.service_no_of_tech = (vlgData[i] && vlgData[i].service_no_of_tech >0) ? total.service_no_of_tech + parseInt(vlgData[i].service_no_of_tech) : total.service_no_of_tech;
              total.service_booked_hours = (vlgData[i] && vlgData[i].service_booked_hours >0) ? total.service_booked_hours + parseInt(vlgData[i].service_booked_hours) : total.service_booked_hours;
              total.service_units_shipped = (vlgData[i] && vlgData[i].service_units_shipped >0) ? total.service_units_shipped + parseInt(vlgData[i].service_units_shipped) : total.service_units_shipped; 

           
              total.appt_made = (vlgData[i] && vlgData[i].appt_made !== '') ? total.appt_made + parseInt(vlgData[i].appt_made) : total.appt_made;
              total.appt_show = (vlgData[i] && vlgData[i].appt_show !== '') ? total.appt_show + parseInt(vlgData[i].appt_show) : total.appt_show;
              total.store_visit = (vlgData[i] && vlgData[i].store_visit !== '') ? total.store_visit + parseInt(vlgData[i].store_visit) : total.store_visit;
              total.lead = (vlgData[i] && vlgData[i].lead !== '') ? total.lead + parseInt(vlgData[i].lead) : total.lead;
              total.internet_lead = (vlgData[i] && vlgData[i].internet_lead !== '') ? total.internet_lead + parseInt(vlgData[i].internet_lead) : total.internet_lead;
            
              total.sales_staff= (vlgData[i] && vlgData[i].sales_staff >0) ? total.sales_staff + parseInt(vlgData[i].sales_staff) : total.sales_staff;
              total.booked= (vlgData[i] && vlgData[i].booked >0) ? total.booked + parseInt(vlgData[i].booked) : total.booked;
              total.no_show= (vlgData[i] && vlgData[i].no_show >0) ? total.no_show + parseInt(vlgData[i].no_show) : total.no_show;
              total.phone_up= (vlgData[i] && vlgData[i].phone_up>0) ? total.phone_up + parseInt(vlgData[i].phone_up) : total.phone_up;
              total.fresh_up= (vlgData[i] && vlgData[i].fresh_up >0) ? total.fresh_up + parseInt(vlgData[i].fresh_up) : total.fresh_up;
            

            
              return <CreateRowForUsed row={vlgData} userData={userData} day={i} filterData={filterVal} loadData={loadData} editDay={editDay} />
            })
          }
          <TotalRowForUsed total={total} />
          <ForecastRowForUsed budget={budget} />
          <KPIRowForUsed total={total} budget={budget} />
        </tbody>
      </table>
    </div>
  );
};

const mapStateToProps = state => ({
  dataEntry: state.dataEntryBudget.dataEntry,
  editDay: state.currentEditDayUsed.currentEditDayUsed
  // totalOfRecdata: state.dataEntryBudget.total
});
export default connect(mapStateToProps)(DataEntryVillage);
// export default DataEntryVillage
