import React from 'react'
import { calculatePercentageOfBudget, calculateTotalGP } from '../../utils';

const GroupRecapYTDTable = (props) => {
  const { division, stores, dailyDataGrossProfitYTD, dailyDataGrossProfitYTDLastYear,
    budgetTotalGrossProfitYTD, budgetTotalGrossProfitYTDLastYear, usedStores } = props;


  let rankedStores = {};
  let rankedStoresUsed = {};
  if (division !== "Used") {
    // rankedStores=stores;
    rankedStores = Object.keys(dailyDataGrossProfitYTD).length > 0 && Object.keys(budgetTotalGrossProfitYTD).length > 0 ? stores.sort((a, b) => {
      let dailyDataforCurrentStoreA = dailyDataGrossProfitYTD.find(key => a === key.store_name);
      let budgetforCurrentStoreA = budgetTotalGrossProfitYTD.find(key => a === key.store_name);
      let dailyDataforCurrentStoreB = dailyDataGrossProfitYTD.find(key => b === key.store_name);
      let budgetforCurrentStoreB = budgetTotalGrossProfitYTD.find(key => b === key.store_name);
      dailyDataforCurrentStoreA = dailyDataforCurrentStoreA === undefined ? {} : dailyDataforCurrentStoreA;
      budgetforCurrentStoreA = budgetforCurrentStoreA === undefined ? {} : budgetforCurrentStoreA;
      dailyDataforCurrentStoreB = dailyDataforCurrentStoreB === undefined ? {} : dailyDataforCurrentStoreB;
      budgetforCurrentStoreB = budgetforCurrentStoreB === undefined ? {} : budgetforCurrentStoreB;


      return (parseInt(calculatePercentageOfBudget(dailyDataforCurrentStoreA.total_gross_profit, budgetforCurrentStoreA.total_gross_profit) || 0) < parseInt(calculatePercentageOfBudget(dailyDataforCurrentStoreB.total_gross_profit, budgetforCurrentStoreB.total_gross_profit) || 0)) ? 1

        : (parseInt(calculatePercentageOfBudget(dailyDataforCurrentStoreB.total_gross_profit, budgetforCurrentStoreB.total_gross_profit) || 0) < parseInt(calculatePercentageOfBudget(dailyDataforCurrentStoreA.total_gross_profit, budgetforCurrentStoreA.total_gross_profit) || 0) ? -1 : 0)

    }) : stores;
  }

  if (division === "Used") {
    // rankedStoresUsed=usedStores;
    rankedStoresUsed = Object.keys(dailyDataGrossProfitYTD).length > 0 && Object.keys(budgetTotalGrossProfitYTD).length > 0 ? usedStores.sort((a, b) => {
      let dailyDataforCurrentStoreA = dailyDataGrossProfitYTD.find(key => a.id === key.store_id);
      let budgetforCurrentStoreA = budgetTotalGrossProfitYTD.find(key => a.id === key.store_id);
      let dailyDataforCurrentStoreB = dailyDataGrossProfitYTD.find(key => b.id === key.store_id);
      let budgetforCurrentStoreB = budgetTotalGrossProfitYTD.find(key => b.id === key.store_id);
      dailyDataforCurrentStoreA = dailyDataforCurrentStoreA === undefined ? {} : dailyDataforCurrentStoreA;
      budgetforCurrentStoreA = budgetforCurrentStoreA === undefined ? {} : budgetforCurrentStoreA;
      dailyDataforCurrentStoreB = dailyDataforCurrentStoreB === undefined ? {} : dailyDataforCurrentStoreB;
      budgetforCurrentStoreB = budgetforCurrentStoreB === undefined ? {} : budgetforCurrentStoreB;


      return (parseInt(calculatePercentageOfBudget(dailyDataforCurrentStoreA.total_gross_profit, budgetforCurrentStoreA.total_gross_profit) || 0) < parseInt(calculatePercentageOfBudget(dailyDataforCurrentStoreB.total_gross_profit, budgetforCurrentStoreB.total_gross_profit) || 0)) ? 1

        : (parseInt(calculatePercentageOfBudget(dailyDataforCurrentStoreB.total_gross_profit, budgetforCurrentStoreB.total_gross_profit) || 0) < parseInt(calculatePercentageOfBudget(dailyDataforCurrentStoreA.total_gross_profit, budgetforCurrentStoreA.total_gross_profit) || 0) ? -1 : 0)

    }) : usedStores;
  }



  return (
    <table className="table table-bordered border-black table-fit" style={{ marginTop: 20, borderLeft: '5px solid #e9ecef' }}>
      <thead>
        <tr className="text-center">
          <th colSpan="1" className="dn-header-top" style={{ "backgroundColor": "#a2c1a2" }}>Actual Gross</th>
          <th colSpan="1" className="dn-header-top" style={{ "backgroundColor": "#a2c1a2" }}>Forecast</th>
          <th colSpan="1" className="dn-header-top" style={{ "backgroundColor": "#a2c1a2" }}>% Achieved</th>
          <th colSpan="1" className="dn-header-top" style={{ "backgroundColor": "#a2c1a2" }}>Last Year</th>
          <th colSpan="1" className="dn-header-top" style={{ whiteSpace: 'nowrap', "backgroundColor": "#a2c1a2" }}>% of Last Year</th>
        </tr>
      </thead>
      <tbody>
        {division !== 'Used' && rankedStores && rankedStores.map((store) => {
          const dailyDataGrossProfitYTDForCurrentStore = dailyDataGrossProfitYTD.find(key => store.toLowerCase() === key.store_name.toLowerCase());
          const dailyDataGrossProfitYTDLastYearForCurrentStore = dailyDataGrossProfitYTDLastYear.find(key => store.toLowerCase() === key.store_name.toLowerCase());
          const budgetTotalGrossProfitYTDForCurrentStore = budgetTotalGrossProfitYTD.find(key => store.toLowerCase() === key.store_name.toLowerCase());
          const budgetTotalGrossProfitYTDLastYearForCurrentStore = budgetTotalGrossProfitYTDLastYear.find(key => store.toLowerCase() === key.store_name.toLowerCase());
          return <>
            <tr>
              <td style={{ fontWeight: 400, textAlign: 'center' }}>
                {dailyDataGrossProfitYTDForCurrentStore && dailyDataGrossProfitYTDForCurrentStore.total_gross_profit ?
                  dailyDataGrossProfitYTDForCurrentStore.total_gross_profit.toLocaleString('en-CA', {
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0,
                    style: 'currency',
                    currency: 'CAD',
                  })
                  : '0'}
              </td>
              <td style={{ fontWeight: 400, textAlign: 'center' }}>
                {budgetTotalGrossProfitYTDForCurrentStore && budgetTotalGrossProfitYTDForCurrentStore.total_gross_profit ?
                  budgetTotalGrossProfitYTDForCurrentStore.total_gross_profit.toLocaleString('en-CA', {
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0,
                    style: 'currency',
                    currency: 'CAD',
                  })
                  : '0'}
              </td>
              <td style={{ fontWeight: 400, textAlign: 'center', position: 'relative' }}>
                <div class="progress" style={{ height: "100%", width: '100%', position: 'absolute', right: '0%', top: '0%', zIndex: '-1', borderRadius: '0px' }}>
                  <div class="progress-bar" role="progressbar" style={{ backgroundColor: '#7CEF7C ', width: dailyDataGrossProfitYTDForCurrentStore && budgetTotalGrossProfitYTDForCurrentStore ? calculatePercentageOfBudget(dailyDataGrossProfitYTDForCurrentStore.total_gross_profit, budgetTotalGrossProfitYTDForCurrentStore.total_gross_profit) : '0' }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                {dailyDataGrossProfitYTDForCurrentStore && budgetTotalGrossProfitYTDForCurrentStore ? calculatePercentageOfBudget(dailyDataGrossProfitYTDForCurrentStore.total_gross_profit, budgetTotalGrossProfitYTDForCurrentStore.total_gross_profit) : '0'}
              </td>
              <td style={{ fontWeight: 400, textAlign: 'center' }}>
                {dailyDataGrossProfitYTDLastYearForCurrentStore && dailyDataGrossProfitYTDLastYearForCurrentStore.total_gross_profit ?
                  dailyDataGrossProfitYTDLastYearForCurrentStore.total_gross_profit.toLocaleString('en-CA', {
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0,
                    style: 'currency',
                    currency: 'CAD',
                  })
                  : '0'}
              </td>
              <td style={{ fontWeight: 400, textAlign: 'center' }}>
                {dailyDataGrossProfitYTDLastYearForCurrentStore && budgetTotalGrossProfitYTDLastYearForCurrentStore ?
                  calculatePercentageOfBudget(dailyDataGrossProfitYTDLastYearForCurrentStore.total_gross_profit, budgetTotalGrossProfitYTDLastYearForCurrentStore.total_gross_profit)
                  : '0'}
              </td>
            </tr>
          </>
        })
        }
        {division === 'Used' && rankedStoresUsed && rankedStoresUsed.map((store) => {
          const dailyDataGrossProfitYTDForCurrentStore = dailyDataGrossProfitYTD.find(key => store.id === key.store_id);
          const dailyDataGrossProfitYTDLastYearForCurrentStore = dailyDataGrossProfitYTDLastYear.find(key => store.id === key.store_id);
          const budgetTotalGrossProfitYTDForCurrentStore = budgetTotalGrossProfitYTD.find(key => store.id === key.store_id);
          const budgetTotalGrossProfitYTDLastYearForCurrentStore = budgetTotalGrossProfitYTDLastYear.find(key => store.id === key.store_id);

          return <>
            <tr>
              <td style={{ fontWeight: 400, textAlign: 'center' }}>
                {dailyDataGrossProfitYTDForCurrentStore && dailyDataGrossProfitYTDForCurrentStore.total_gross_profit ?
                  dailyDataGrossProfitYTDForCurrentStore.total_gross_profit.toLocaleString('en-CA', {
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0,
                    style: 'currency',
                    currency: 'CAD',
                  })
                  : '0'}
              </td>
              <td style={{ fontWeight: 400, textAlign: 'center' }}>
                {budgetTotalGrossProfitYTDForCurrentStore && budgetTotalGrossProfitYTDForCurrentStore.total_gross_profit ?
                  budgetTotalGrossProfitYTDForCurrentStore.total_gross_profit.toLocaleString('en-CA', {
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0,
                    style: 'currency',
                    currency: 'CAD',
                  })
                  : '0'}
              </td>
              <td style={{ fontWeight: 400, textAlign: 'center', position: 'relative' }}>
                <div class="progress" style={{ height: "100%", width: '100%', position: 'absolute', right: '0%', top: '0%', zIndex: '-1', borderRadius: '0px' }}>
                  <div class="progress-bar" role="progressbar" style={{ backgroundColor: '#7CEF7C ', width: dailyDataGrossProfitYTDForCurrentStore && budgetTotalGrossProfitYTDForCurrentStore ? calculatePercentageOfBudget(dailyDataGrossProfitYTDForCurrentStore.total_gross_profit, budgetTotalGrossProfitYTDForCurrentStore.total_gross_profit) : '0' }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                {dailyDataGrossProfitYTDForCurrentStore && budgetTotalGrossProfitYTDForCurrentStore ? calculatePercentageOfBudget(dailyDataGrossProfitYTDForCurrentStore.total_gross_profit, budgetTotalGrossProfitYTDForCurrentStore.total_gross_profit) : '0'}
              </td>
              <td style={{ fontWeight: 400, textAlign: 'center' }}>
                {dailyDataGrossProfitYTDLastYearForCurrentStore && dailyDataGrossProfitYTDLastYearForCurrentStore.total_gross_profit ?
                  dailyDataGrossProfitYTDLastYearForCurrentStore.total_gross_profit.toLocaleString('en-CA', {
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0,
                    style: 'currency',
                    currency: 'CAD',
                  })
                  : '0'}
              </td>
              <td style={{ fontWeight: 400, textAlign: 'center' }}>
                {dailyDataGrossProfitYTDLastYearForCurrentStore && budgetTotalGrossProfitYTDLastYearForCurrentStore ?
                  calculatePercentageOfBudget(dailyDataGrossProfitYTDLastYearForCurrentStore.total_gross_profit, budgetTotalGrossProfitYTDLastYearForCurrentStore.total_gross_profit)
                  : '0'}
              </td>
            </tr>
          </>
        })
        }
      </tbody>
    </table>
  )
};

export default GroupRecapYTDTable