import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { connect } from 'react-redux';
import axios from 'axios';
import Links from "../../Data/Links";
import { store, clearTotalStoreGross, updateDataEntryRecRow } from "../../redux";
import CreateRowRec from "../../component/DataEntry/CreateRowRec";
import monthData from "../../constants/monthData";
import yearData from "../../constants/yearData";
import dayData from "../../constants/dayData";
import { calculateKPIPercentage } from "../../utils";
import getFiscals from '../../utils/getFiscals';
const DataEntryRec = ({ dataEntryRec, totalOfRecdata, editDay, props }) => {
  const [recData, setRecData] = useState({});
  const [stores, setStores] = useState(localStorage.getItem('stores') ? JSON.parse(localStorage.getItem('stores')) : "");
  const [budget, setBudget] = useState({});
  let [storeIdFromStoreName, setStoreIdFromStoreName] = useState('');
  const [filterVal, setFilterVal] = useState(
    localStorage.getItem('filterValRec') ? JSON.parse(localStorage.getItem('filterValRec')):{}
  );

  useEffect(() => {
    let storeObj = {};
    let storeNameToId = {};
    stores.length > 0 && stores.map(i => {
      if (i.division === "Rec") {
        //To Do: Prefill this filterVal variable using the first store  then run the loadData() function.

        storeObj[i.id] = i.name;
        storeNameToId[i.name] = i.id;
      }
    });
    setStores(storeObj);
    setStoreIdFromStoreName(storeNameToId);
    setFilterVal({
      store_id: Object.keys(storeObj)[0],
      store_name: Object.values(storeObj)[0],
      division: "Rec",
      company: 'FFUN',
      month: monthData[new Date().getMonth()],
      year: new Date().getFullYear(),
      fiscal_year: 'FY' + getFiscals(monthData[new Date().getMonth()], new Date().getFullYear()).toString().substring(2),
    })
    localStorage.setItem('filterValRec', JSON.stringify(filterVal));
    // loadData();
    window.scrollTo(0, 0)
  }, []);

  useEffect(() => {
    loadData();
  }, [filterVal])

  const handleChange = (field, event) => {
    if (field === 'store_name') {
      setFilterVal({ ...filterVal, [field]: event.target.value, 'store_id': storeIdFromStoreName !== "" ? storeIdFromStoreName[event.target.value] : Object.keys(stores).find(key => stores[key] === event.target.value) });
    } else {
      setFilterVal({ ...filterVal, [field]: event.target.value });
    }
    localStorage.setItem('filterValRec', JSON.stringify(filterVal));
  };
  const rowTotal = (field, total) => {

    if (!total) {
      total = {}
    }
    const used_retail_front_GP = parseInt(total.used_retail_front_GP) || 0
    const used_retail_back_GP = parseInt(total.used_retail_back_GP) || 0
    const new_retail_front_GP = parseInt(total.new_retail_front_GP) || 0
    const new_retail_back_GP = parseInt(total.new_retail_back_GP) || 0
    const service_total_GP = parseInt(total.service_total_GP) || 0
    const parts_total_GP = parseInt(total.parts_total_GP) || 0
    const parts_whole_sale_GP = parseInt(total.parts_whole_sale_GP) || 0
    const boatsBackGP = parseInt(total.boats_back_GP) || 0
    const boatsFrontGP = parseInt(total.boats_front_GP) || 0
    const trailersBackGP = parseInt(total.trailers_back_GP) || 0
    const trailersFrontGP = parseInt(total.trailers_front_GP) || 0
    const apparelGross = parseInt(total.apparel_gross) || 0
    const totalOfRecdata = used_retail_front_GP + used_retail_back_GP + service_total_GP + parts_total_GP + parts_whole_sale_GP + new_retail_front_GP + new_retail_back_GP +
      boatsBackGP + boatsFrontGP + trailersBackGP + trailersFrontGP + apparelGross;
    // if (isNaN(totalOfRecdata) || totalOfRecdata === 'NaN' || totalOfRecdata === 'Infinity' || totalOfRecdata === null) {
    //   totalOfRecdata = '0'
    // }

    if (filterVal.store_name === 'FMS-Sask' || filterVal.store_name === 'FMS-Yorkton' || filterVal.store_name === 'FFUN Motorsports Winnipeg East' || filterVal.store_name === 'FFUN Motorsports Winnipeg West'|| filterVal.store_name === 'FFUN Motorsports Portage' || filterVal.store_name === 'FFUN Motorsports Kenora') {
      return (
        <tr>
          <td className="dn-column"></td>
          <td className="dn-column"></td>
          <td className="dn-column">{field}</td>
          <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{total.new_retail_delivered ? parseInt(total.new_retail_delivered) : 0}</td>
          <td className="dn-column" style={{ "background-color": "#e9ecef" }}>${total.new_retail_front_GP ? parseInt(total.new_retail_front_GP)?.toLocaleString() : 0}</td>
          <td className="dn-column" style={{ "background-color": "#e9ecef" }}>${total.new_retail_back_GP ? parseInt(total.new_retail_back_GP)?.toLocaleString() : 0}</td>
          <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{total.used_retail_delivered ? total.used_retail_delivered : 0}</td>
          <td className="dn-column" style={{ "background-color": "#e9ecef" }}>${total.used_retail_front_GP ? parseInt(total.used_retail_front_GP)?.toLocaleString() : 0}</td>
          <td className="dn-column" style={{ "background-color": "#e9ecef" }}>${total.used_retail_back_GP ? parseInt(total.used_retail_back_GP)?.toLocaleString() : 0}</td>
          <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{total.boats_delivered ? total.boats_delivered : 0}</td>
          <td className="dn-column" style={{ "background-color": "#e9ecef" }}>${total.boats_front_GP ? parseInt(total.boats_front_GP)?.toLocaleString() : 0}</td>
          <td className="dn-column" style={{ "background-color": "#e9ecef" }}>${total.boats_back_GP ? parseInt(total.boats_back_GP)?.toLocaleString() : 0}</td>
          <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{total.trailers_delivered ? parseInt(total.trailers_delivered) : 0}</td>
          <td className="dn-column" style={{ "background-color": "#e9ecef" }}>${total.trailers_front_GP ? parseInt(total.trailers_front_GP)?.toLocaleString() : 0}</td>
          <td className="dn-column" style={{ "background-color": "#e9ecef" }}>${total.trailers_back_GP ? parseInt(total.trailers_back_GP)?.toLocaleString() : 0}</td>
          <td className="dn-column" style={{ "background-color": "#e9ecef" }}>${total.service_total_GP ? parseInt(total.service_total_GP)?.toLocaleString() : 0}</td>

          <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{total.service_total_hours ? total.service_total_hours : 0}</td>
          <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{total.service_CP_hours ? total.service_CP_hours : 0}</td>
          <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{total.service_CP_ROs ? total.service_CP_ROs : 0}</td>
          {/* <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{total.service_hours_available ? total.service_hours_available.toLocaleString() : 0}</td>

          <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{total.service_hours_sold ? total.service_hours_sold.toLocaleString() : 0}</td>

          <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{total.service_total_hours && total.service_hours_sold ? (total.service_hours_sold / total.service_total_hours).toFixed(2) : 0}</td> */}


          {/* <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{total.service_units_shipped ? total.service_units_shipped.toLocaleString() : 0}</td> */}
          {/* <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{total.service_no_of_tech ? total.service_no_of_tech.toLocaleString() : 0}</td>

          <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{total.service_journeyman ? total.service_journeyman.toLocaleString() : 0}</td>
          <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{total.service_apprentice ? total.service_apprentice.toLocaleString() : 0}</td>
          <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{total.service_advisors_needed ? total.service_advisors_needed.toLocaleString() : 0}</td> */}


          <td className="dn-column" style={{ "background-color": "#e9ecef" }}>${total.parts_sales ? parseInt(total.parts_sales)?.toLocaleString() : 0}</td>
          <td className="dn-column" style={{ "background-color": "#e9ecef" }}>${total.parts_total_GP ? parseInt(total.parts_total_GP)?.toLocaleString() : 0}</td>
          <td className="dn-column" style={{ "background-color": "#e9ecef" }}>${total.parts_whole_sale_GP ? parseInt(total.parts_whole_sale_GP)?.toLocaleString() : 0}</td>
          <td className="dn-column" style={{ "background-color": "#e9ecef" }}>${total.apparel_sales ? parseInt(total.apparel_sales).toLocaleString() : 0}</td>
          <td className="dn-column" style={{ "background-color": "#e9ecef" }}>${total.apparel_gross ? parseInt(total.apparel_gross).toLocaleString() : 0}</td>
          <td className="dn-column" style={{ "background-color": "#e9ecef" }}>${total.apparel_inventory_value ? parseInt(total.apparel_inventory_value).toLocaleString() : 0}</td>
          <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{total.appt_made ? parseInt(total.appt_made).toLocaleString() : 0}</td>
          <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{total.booked ? total.booked : 0}</td>
          <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{total.appt_show ? parseInt(total.appt_show).toLocaleString() : 0}</td>
          <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{total.store_visit ? parseInt(total.store_visit).toLocaleString() : 0}</td>

          <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{total.internet_lead ? parseInt(total.internet_lead).toLocaleString() : 0}</td>


          <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{total.fresh_up ? total.fresh_up : 0}</td>
          <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{total.phone_up ? total.phone_up : 0}</td>
          <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{total.lead ? parseInt(total.lead).toLocaleString() : 0}</td>
          <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{total.sales_staff ? total.sales_staff : 0}</td>

          {/* <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{total.no_show ? total.no_show : 0}</td> */}

          <td className="dn-column" style={{ "background-color": "#e9ecef" }}>${totalOfRecdata?.toLocaleString()}</td>
        </tr>
      )
    } else {
      return (
        <tr>
          <td className="dn-column"></td>
          <td className="dn-column"></td>
          <td className="dn-column">{field}</td>
          <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{total.new_retail_delivered ? parseInt(total.new_retail_delivered) : 0}</td>
          <td className="dn-column" style={{ "background-color": "#e9ecef" }}>${total.new_retail_front_GP ? parseInt(total.new_retail_front_GP)?.toLocaleString() : 0}</td>
          <td className="dn-column" style={{ "background-color": "#e9ecef" }}>${total.new_retail_back_GP ? parseInt(total.new_retail_back_GP)?.toLocaleString() : 0}</td>
          <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{total.used_retail_delivered ? total.used_retail_delivered : 0}</td>
          <td className="dn-column" style={{ "background-color": "#e9ecef" }}>${total.used_retail_front_GP ? parseInt(total.used_retail_front_GP)?.toLocaleString() : 0}</td>
          <td className="dn-column" style={{ "background-color": "#e9ecef" }}>${total.used_retail_back_GP ? parseInt(total.used_retail_back_GP)?.toLocaleString() : 0}</td>

          <td className="dn-column" style={{ "background-color": "#e9ecef" }}>${total.service_total_GP ? parseInt(total.service_total_GP)?.toLocaleString() : 0}</td>
          <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{total.service_total_hours ? total.service_total_hours : 0}</td>
          <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{total.service_CP_hours ? total.service_CP_hours : 0}</td>
          <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{total.service_CP_ROs ? total.service_CP_ROs : 0}</td>
          {/* <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{total.service_hours_available ? total.service_hours_available.toLocaleString() : 0}</td>


          <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{total.service_hours_sold ? total.service_hours_sold.toLocaleString() : 0}</td> */}

          {/* <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{total.service_units_shipped ? total.service_units_shipped.toLocaleString() : 0}</td> */}

          {/* <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{total.service_total_hours && total.service_hours_sold ? (total.service_hours_sold / total.service_total_hours).toFixed(2) : 0}</td>

          <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{total.service_no_of_tech ? total.service_no_of_tech.toLocaleString() : 0}</td>
          <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{total.service_journeyman ? total.service_journeyman.toLocaleString() : 0}</td>
          <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{total.service_apprentice ? total.service_apprentice.toLocaleString() : 0}</td>
          <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{total.service_advisors_needed ? total.service_advisors_needed.toLocaleString() : 0}</td> */}


          <td className="dn-column" style={{ "background-color": "#e9ecef" }}>${total.parts_sales ? parseInt(total.parts_sales)?.toLocaleString() : 0}</td>
          <td className="dn-column" style={{ "background-color": "#e9ecef" }}>${total.parts_total_GP ? parseInt(total.parts_total_GP)?.toLocaleString() : 0}</td>
          <td className="dn-column" style={{ "background-color": "#e9ecef" }}>${total.parts_whole_sale_GP ? parseInt(total.parts_whole_sale_GP)?.toLocaleString() : 0}</td>
          <td className="dn-column" style={{ "background-color": "#e9ecef" }}>${total.apparel_sales ? parseInt(total.apparel_sales)?.toLocaleString() : 0}</td>
          <td className="dn-column" style={{ "background-color": "#e9ecef" }}>${total.apparel_gross ? parseInt(total.apparel_gross)?.toLocaleString() : 0}</td>
          <td className="dn-column" style={{ "background-color": "#e9ecef" }}>${total.apparel_inventory_value ? parseInt(total.apparel_inventory_value)?.toLocaleString() : 0}</td>
          <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{total.appt_made ? parseInt(total.appt_made)?.toLocaleString() : 0}</td>
          <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{total.booked ? total.booked : 0}</td>
          <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{total.appt_show ? parseInt(total.appt_show)?.toLocaleString() : 0}</td>
          <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{total.store_visit ? parseInt(total.store_visit)?.toLocaleString() : 0}</td>

          <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{total.internet_lead ? parseInt(total.internet_lead)?.toLocaleString() : 0}</td>

          <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{total.fresh_up ? total.fresh_up : 0}</td>
          <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{total.phone_up ? total.phone_up : 0}</td>
          <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{total.lead ? parseInt(total.lead)?.toLocaleString() : 0}</td>
          <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{total.sales_staff ? total.sales_staff : 0}</td>

          {/* <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{total.no_show ? total.no_show : 0}</td> */}
          <td className="dn-column" style={{ "background-color": "#e9ecef" }}>${totalOfRecdata?.toLocaleString()}</td>
        </tr>
      )
    }
  }
  // const rowTotal = (field, total, totalOfRecdata = null) => {
  //   if (!total) {
  //     total = {}
  //   }
  //     if (totalOfRecdata == null) {
  //         const usedRetailFrontGP = parseInt(total.used_retail_front_GP) || 0
  //         const usedRetailBackGP = parseInt(total.used_retail_back_GP) || 0
  //         const serviceTotalGP = parseInt(total.service_total_GP) || 0
  //         const partsRetailGP = parseInt(total.parts_total_GP) || 0
  //         const partsRetailWSGP = parseInt(total.parts_W_S_GP) || 0
  //         const boatsBackGP = parseInt(total.boats_back_GP) || 0
  //         const boatsFrontGP = parseInt(total.boats_front_GP) || 0
  //         const trailersBackGP = parseInt(total.trailers_back_GP) || 0
  //         const trailersFrontGP = parseInt(total.trailers_front_GP) || 0
  //           totalOfRecdata = usedRetailFrontGP +
  //             usedRetailBackGP + serviceTotalGP + partsRetailGP + partsRetailWSGP
  //             + boatsBackGP + boatsFrontGP + trailersBackGP + trailersFrontGP;
  //       }
  //       if(filterVal.store_name === 'FMS-Sask' || filterVal.store_name === 'FMS-Yorkton') {
  //         <tr>
  //           <td className="dn-column"></td>
  //           <td className="dn-column"></td>
  //           <td className="dn-column">{field}</td>
  //           <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{total.new_retail_delivered ? total.new_retail_delivered : 0}</td>
  //           <td className="dn-column" style={{ "background-color": "#e9ecef" }}>${total.new_retail_front_GP ? parseInt(total.new_retail_front_GP)?.toLocaleString() : 0}</td>
  //           <td className="dn-column" style={{ "background-color": "#e9ecef" }}>${total.new_retail_back_GP ? parseInt(total.new_retail_back_GP)?.toLocaleString() : 0}</td>
  //           <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{total.used_retail_delivered ? total.used_retail_delivered : 0}</td>
  //           <td className="dn-column" style={{ "background-color": "#e9ecef" }}>${total.used_retail_front_GP ? parseInt(total.used_retail_front_GP)?.toLocaleString() : 0}</td>
  //           <td className="dn-column" style={{ "background-color": "#e9ecef" }}>${total.used_retail_back_GP ? parseInt(total.used_retail_back_GP)?.toLocaleString() : 0}</td>
  //           <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{total.boats_delivered ? total.boats_delivered : 0}</td>
  //           <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{total.boats_front_GP ? total.boats_front_GP : 0}</td>
  //           <td className="dn-column" style={{ "background-color": "#e9ecef" }}>${total.boats_back_GP ? parseInt(total.service_total_GP)?.toLocaleString() : 0}</td>
  //           <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{total.trailers_delivered ? total.trailers_delivered : 0}</td>
  //           <td className="dn-column" style={{ "background-color": "#e9ecef" }}>${total.trailers_front_GP ? parseInt(total.trailers_front_GP)?.toLocaleString() : 0}</td>
  //           <td className="dn-column" style={{ "background-color": "#e9ecef" }}>${total.trailers_back_GP ? parseInt(total.trailers_back_GP)?.toLocaleString() : 0}</td>
  //           <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{total.service_total_hours ? total.service_total_hours : 0}</td>
  //           <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{total.service_CP_hours ? total.service_CP_hours : 0}</td>
  //           <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{total.service_CP_ROs ? total.service_CP_ROs : 0}</td>
  //           <td className="dn-column" style={{ "background-color": "#e9ecef" }}>${total.service_total_GP ? parseInt(total.service_total_GP)?.toLocaleString() : 0}</td>
  //           <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{total.parts_sales ? total.parts_sales : 0}</td>
  //           <td className="dn-column" style={{ "background-color": "#e9ecef" }}>${total.parts_total_GP ? parseInt(total.parts_total_GP)?.toLocaleString() : 0}</td>
  //           <td className="dn-column" style={{ "background-color": "#e9ecef" }}>${total.parts_W_S_GP ? parseInt(total.parts_W_S_GP)?.toLocaleString() : 0}</td>
  //           <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{total.apparel_sales ? total.apparel_sales :0}</td>
  //           <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{total.apparel_gross ? total.apparel_gross : 0}</td>
  //           <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{total.apparel_inventory_value ? total.apparel_inventory_value: 0}</td>
  //           <td className="dn-column" style={{ "background-color": "#e9ecef" }}>${totalOfRecdata?.toLocaleString()}</td>
  //         </tr>
  //       } else {
  //         return (
  //           <tr>
  //             <td className="dn-column"></td>
  //             <td className="dn-column"></td>
  //             <td className="dn-column">{field}</td>
  //             <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{total.new_retail_delivered ? total.new_retail_delivered : 0}</td>
  //             <td className="dn-column" style={{ "background-color": "#e9ecef" }}>${total.new_retail_front_GP ? parseInt(total.new_retail_front_GP)?.toLocaleString() : 0}</td>
  //             <td className="dn-column" style={{ "background-color": "#e9ecef" }}>${total.new_retail_back_GP ? parseInt(total.new_retail_back_GP)?.toLocaleString() : 0}</td>
  //             <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{total.used_retail_delivered ? total.used_retail_delivered : 0}</td>
  //             <td className="dn-column" style={{ "background-color": "#e9ecef" }}>${total.used_retail_front_GP ? parseInt(total.used_retail_front_GP)?.toLocaleString() : 0}</td>
  //             <td className="dn-column" style={{ "background-color": "#e9ecef" }}>${total.used_retail_back_GP ? parseInt(total.used_retail_back_GP)?.toLocaleString() : 0}</td>
  //             <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{total.service_total_hours ? total.service_total_hours : 0}</td>
  //             <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{total.service_CP_hours ? total.service_CP_hours : 0}</td>
  //             <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{total.service_CP_ROs ? total.service_CP_ROs : 0}</td>
  //             <td className="dn-column" style={{ "background-color": "#e9ecef" }}>${total.service_total_GP ? parseInt(total.service_total_GP)?.toLocaleString() : 0}</td>
  //             <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{total.parts_sales ? total.parts_sales : 0}</td>
  //             <td className="dn-column" style={{ "background-color": "#e9ecef" }}>${total.parts_total_GP ? parseInt(total.parts_total_GP)?.toLocaleString() : 0}</td>
  //             <td className="dn-column" style={{ "background-color": "#e9ecef" }}>${total.parts_W_S_GP ? parseInt(total.parts_W_S_GP)?.toLocaleString() : 0}</td>
  //             <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{total.apparel_sales ? total.apparel_sales :0}</td>
  //             <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{total.apparel_gross ? total.apparel_gross : 0}</td>
  //             <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{total.apparel_inventory_value ? total.apparel_inventory_value: 0}</td>
  //             <td className="dn-column" style={{ "background-color": "#e9ecef" }}>${totalOfRecdata?.toLocaleString()}</td>
  //           </tr>
  //         )
  //       }

  // }
  const resultPercent = (field, total, budget) => {
    if (!total) {
      total = {}
    }
    if (!budget) {
      budget = {}
    }
    let budgetOfDailydata;
    const used_retail_front_GP = parseInt(total.used_retail_front_GP) || 0
    const used_retail_back_GP = parseInt(total.used_retail_back_GP) || 0
    const new_retail_front_GP = parseInt(total.new_retail_front_GP) || 0
    const new_retail_back_GP = parseInt(total.new_retail_back_GP) || 0
    const service_total_GP = parseInt(total.service_total_GP) || 0
    const parts_total_GP = parseInt(total.parts_total_GP) || 0
    const parts_whole_sale_GP = parseInt(total.parts_whole_sale_GP) || 0
    const boatsBackGP = parseInt(total.boats_back_GP) || 0
    const boatsFrontGP = parseInt(total.boats_front_GP) || 0
    const trailersBackGP = parseInt(total.trailers_back_GP) || 0
    const trailersFrontGP = parseInt(total.trailers_front_GP) || 0
    const apparelGross = parseInt(total.apparel_gross) || 0
    const totalOfRecdata = used_retail_front_GP + used_retail_back_GP + service_total_GP + parts_total_GP + parts_whole_sale_GP + new_retail_front_GP + new_retail_back_GP +
      boatsBackGP + boatsFrontGP + trailersBackGP + trailersFrontGP + apparelGross;

    if (filterVal.store_name === 'FMS-Sask' || filterVal.store_name === 'FMS-Yorkton' || filterVal.store_name === 'FFUN Motorsports Winnipeg East' || filterVal.store_name === 'FFUN Motorsports Winnipeg West'|| filterVal.store_name === 'FFUN Motorsports Portage' || filterVal.store_name === 'FFUN Motorsports Kenora') {
      const used_retail_front_GP = parseInt(budget.used_retail_front_GP) || 0
      const used_retail_back_GP = parseInt(budget.used_retail_back_GP) || 0
      const service_total_GP = parseInt(budget.service_total_GP) || 0
      const parts_total_GP = parseInt(budget.parts_total_GP) || 0
      const parts_whole_sale_GP = parseInt(budget.parts_whole_sale_GP) || 0
      const boatsBackGP = parseInt(budget.boats_back_GP) || 0
      const boatsFrontGP = parseInt(budget.boats_front_GP) || 0
      const trailersBackGP = parseInt(budget.trailers_back_GP) || 0
      const trailersFrontGP = parseInt(budget.trailers_front_GP) || 0
      const new_retail_front_GP = parseInt(budget.new_retail_front_GP) || 0
      const apparelGross = parseInt(budget.apparel_gross) || 0
      const new_retail_back_GP = parseInt(budget.new_retail_back_GP) || 0
      budgetOfDailydata = apparelGross + new_retail_front_GP + new_retail_back_GP + used_retail_front_GP + used_retail_back_GP + service_total_GP + parts_total_GP + parts_whole_sale_GP + boatsBackGP + boatsFrontGP + trailersBackGP + trailersFrontGP;
      return (
        <tr>
          <td className="dn-column"></td>
          <td className="dn-column"></td>
          <td className="dn-column">{field}</td>
          <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.new_retail_delivered, budget.new_retail_delivered)}</td>
          <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.new_retail_front_GP, budget.new_retail_front_GP)}</td>
          <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.new_retail_back_GP, budget.new_retail_back_GP)}</td>
          <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.used_retail_delivered, budget.used_retail_delivered)}</td>
          <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.used_retail_front_GP, budget.used_retail_front_GP)}</td>
          <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.used_retail_back_GP, budget.used_retail_back_GP)}</td>
     

          <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.boats_delivered, budget.boats_delivered)}</td>
          <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.boats_front_GP, budget.boats_front_GP)}</td>
          <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.boats_back_GP, budget.boats_back_GP)}</td>
          <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.trailers_delivered, budget.trailers_delivered)}</td>
          <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.trailers_front_GP, budget.trailers_front_GP)}</td>
          <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.trailers_back_GP, budget.trailers_back_GP)}</td>

          <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.service_total_GP, budget.service_total_GP)}</td>
          <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.service_total_hours, budget.service_total_hours)}</td>
       
          <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.service_CP_hours, budget.service_CP_hours)}</td>
          <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.service_CP_ROs, budget.service_CP_ROs)}</td>

          {/* <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.service_hours_available, budget.service_hours_available)}</td>
          <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.service_hours_sold, budget.service_hours_sold)}</td> */}



          {/* <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.service_units_shipped, budget.service_units_shipped)}</td> */}
          {/* <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage((total.service_hours_sold / total.service_total_hours), (budget.service_hours_sold / budget.service_total_hours))}</td>

          <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.service_no_of_tech, budget.service_no_of_tech)}</td>
          <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.service_journeyman, budget.service_journeyman)}</td>
          <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.service_apprentice, budget.service_apprentice)}</td>
          <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.service_advisors_needed, budget.service_advisors_needed)}</td> */}

          <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.parts_sales, budget.parts_sales)}</td>
          <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.parts_total_GP, budget.parts_total_GP)}</td>
          <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.parts_whole_sale_GP, budget.parts_whole_sale_GP)}</td>
          <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.apparel_sales, budget.apparel_sales)}</td>
          <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.apparel_gross, budget.apparel_gross)}</td>
          <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.apparel_inventory_value, budget.apparel_inventory_value)}</td>

          <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.appt_made, budget.appt_made)}</td>
          <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.booked, budget.booked)}</td>
          <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.appt_show, budget.appt_show)}</td>
          <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.store_visit, budget.store_visit)}</td>

          <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.internet_lead, budget.internet_lead)}</td>

          <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.fresh_up, budget.fresh_up)}</td>
          <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.phone_up, budget.phone_up)}</td>
          <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.lead, budget.lead)}</td>
          <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.sales_staff, budget.sales_staff)}</td>

          {/* <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.no_show, budget.no_show)}</td> */}


          <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(totalOfRecdata, budgetOfDailydata)}</td>
        </tr>
      )
    } else {
      const used_retail_front_GP = parseInt(budget.used_retail_front_GP) || 0
      const used_retail_back_GP = parseInt(budget.used_retail_back_GP) || 0
      const service_total_GP = parseInt(budget.service_total_GP) || 0
      const parts_total_GP = parseInt(budget.parts_total_GP) || 0
      const parts_whole_sale_GP = parseInt(budget.parts_whole_sale_GP) || 0
      const new_retail_front_GP = parseInt(budget.new_retail_front_GP) || 0
      const new_retail_back_GP = parseInt(budget.new_retail_back_GP) || 0
      const apparelGross = parseInt(budget.apparel_gross) || 0
      budgetOfDailydata = apparelGross + new_retail_front_GP + new_retail_back_GP + used_retail_front_GP + used_retail_back_GP + service_total_GP + parts_total_GP + parts_whole_sale_GP;
      return (
        <tr>
          <td className="dn-column"></td>
          <td className="dn-column"></td>
          <td className="dn-column">{field}</td>
          <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.new_retail_delivered, budget.new_retail_delivered)}</td>
          <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.new_retail_front_GP, budget.new_retail_front_GP)}</td>
          <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.new_retail_back_GP, budget.new_retail_back_GP)}</td>
          <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.used_retail_delivered, budget.used_retail_delivered)}</td>
          <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.used_retail_front_GP, budget.used_retail_front_GP)}</td>
          <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.used_retail_back_GP, budget.used_retail_back_GP)}</td>

          <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.service_total_GP, budget.service_total_GP)}</td>

          <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.service_total_hours, budget.service_total_hours)}</td>
          <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.service_CP_hours, budget.service_CP_hours)}</td>
          <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.service_CP_ROs, budget.service_CP_ROs)}</td>

{/* 
          <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.service_hours_available, budget.service_hours_available)}</td>
          
          <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.service_hours_sold, budget.service_hours_sold)}</td> */}



          {/* <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.service_units_shipped, budget.service_units_shipped)}</td> */}
          {/* <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage((total.service_hours_sold / total.service_total_hours), (budget.service_hours_sold / budget.service_total_hours))}</td>

          <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.service_no_of_tech, budget.service_no_of_tech)}</td>
          <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.service_journeyman, budget.service_journeyman)}</td>
          <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.service_apprentice, budget.service_apprentice)}</td>
          <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.service_advisors_needed, budget.service_advisors_needed)}</td> */}
          <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.parts_sales, budget.parts_sales)}</td>
          <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.parts_total_GP, budget.parts_total_GP)}</td>
          <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.parts_whole_sale_GP, budget.parts_W_S_GP)}</td>
          <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.apparel_sales, budget.apparel_sales)}</td>
          <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.apparel_gross, budget.apparel_gross)}</td>
          <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.apparel_inventory_value, budget.apparel_inventory_value)}</td>


          <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.appt_made, budget.appt_made)}</td>
          <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.booked, budget.booked)}</td>
          <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.appt_show, budget.appt_show)}</td>
          <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.store_visit, budget.store_visit)}</td>

          <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.internet_lead, budget.internet_lead)}</td>

          <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.fresh_up, budget.fresh_up)}</td>
          <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.phone_up, budget.phone_up)}</td>
          <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.lead, budget.lead)}</td>
          <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.sales_staff, budget.sales_staff)}</td>

          {/* <td className="auto-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(total.no_show, budget.no_show)}</td> */}


          <td className="dn-column" style={{ "background-color": "#e9ecef" }}>{calculateKPIPercentage(totalOfRecdata, budgetOfDailydata)}</td>
        </tr>
      )
    }

  }
  const loadData = (filterData = null, day) => {
    if (filterVal != null) {
      filterVal.fiscal_year = 'FY' + getFiscals(filterVal.month, filterVal.year).toString().substring(2);
      axios.post(Links.getFilteredRecData, filterVal)
        .then(res => {
          setRecData(res.data.dailydata)
          if (parseInt(day) > 0) {
            store.dispatch(updateDataEntryRecRow({ ...filterVal, ...res.data.dailydata[day], 'day': day }));
          }
          axios.post(Links.getRecBudgetForDailydata, filterVal)
            .then(result => {
              setBudget(result.data.budget);
            }).catch(err => {
              console.log(err);
            });
        }).catch(err => {
          console.log(err);
        });
    }
  }
  let total = {};
  // if(filterVal.store_name === 'FMS-Sask' || filterVal.store_name === 'FMS-Yorkton') {
  total = {
    new_retail_delivered: 0,
    new_retail_front_GP: 0,
    new_retail_back_GP: 0,
    used_appt: 0,
    used_retail_sold: 0,
    used_retail_delivered: 0,
    used_retail_front_GP: 0,
    used_retail_back_GP: 0,
    boats_delivered: 0,
    boats_front_GP: 0,
    boats_back_GP: 0,
    trailers_delivered: 0,
    trailers_front_GP: 0,
    trailers_back_GP: 0,
    service_total_hours: 0,
    service_CP_hours: 0,
    service_CP_ROs: 0,
    service_total_GP: 0,
    // service_hours_sold: 0,
    // service_hours_available: 0,
    // service_no_of_tech: 0,
    service_booked_hours: 0,
    service_units_shipped: 0,
    parts_sales: 0,
    parts_total_GP: 0,
    parts_whole_sale_GP: 0,
    apparel_sales: 0,
    apparel_gross: 0,
    apparel_inventory_value: 0,
    appt_made: 0,
    appt_show: 0,
    store_visit: 0,
    lead: 0,
    sales_staff: 0,
    booked: 0,
    no_show: 0,
    phone_up: 0,
    fresh_up: 0,
    internet_lead: 0,
    // service_journeyman:0,
    // service_apprentice:0,
    // service_advisors_needed:0
  }
  // } else {
  //   total = {
  //     new_retail_appt: 0,
  //     new_retail_sold: 0,
  //     new_retail_delivered: 0,
  //     new_retail_front_GP: 0,
  //     new_retail_back_GP: 0,
  //     used_appt: 0,
  //     used_retail_sold: 0,
  //     used_retail_delivered: 0,
  //     used_retail_front_GP: 0,
  //     used_retail_back_GP: 0,
  //     service_total_hours: 0,
  //     service_CP_hours: 0,
  //     service_CP_ROs: 0,
  //     service_total_GP: 0,
  //     parts_sales: 0,
  //     parts_total_GP: 0,
  //     parts_W_S_GP: 0,
  //     apparel_sales: 0,
  //     apparel_gross: 0,
  //     apparel_inventory_value: 0
  //   }
  // };
  let doneLooping = false
  return (
    <div style={{ margin: "2rem 2rem 2rem 0rem" }}>
   {filterVal?.store_name !== undefined && <>
      <div className="row" >
        <div className="col-5">
          <Box
            sx={{ minWidth: 120 }}
            style={{ width: "100%" }}
          >
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label" className="pt-2">Dealer</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={filterVal.store_name}
                label="Dealer"
                onChange={(e) => handleChange('store_name', e)}
              >
                {Object.values(stores).map(child => (
                  <MenuItem value={child}>{child}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </div>
        <div className="col-2">
          <Box
            style={{ display: "inline-block", width: "100%" }}
          >
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label " className="pt-2">Year</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={filterVal.year}
                label="Year"
                onChange={(e) => handleChange('year', e)}
              >
                {yearData.map(child => (
                  <MenuItem value={child}>{child}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </div>
        <div className="col-2">
          <Box
            style={{ display: "inline-block", width: "100%" }}
          >
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label" className="pt-2">Month</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Months"
                value={filterVal.month}
                onChange={(e) => handleChange('month', e)}
              >
                {monthData.map(child => (
                  <MenuItem value={child}>{child}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </div>
        <div className="col-3">
          <button className="p-2 mt-1 btn btn-secondary" type="submit"
            onClick={() => {
              loadData()
            }}
          >Show Details</button>
        </div>
      </div>

      <table class="table table-bordered border-black" style={{ marginTop: 30 }}>
        <thead>
          <tr className="text-center">
            <th colSpan="3" className="dn-header-top" style={{ "background-color": "#e9ecef" }}></th>
            <th colSpan="3" className="dn-header-top" style={{ "background-color": "orange" }} >New Vehicles</th>
            <th colSpan="3" className="dn-header-top" style={{ "background-color": "#a2c1a2" }}>Used Vehicles</th>
            {(filterVal.store_name === 'FMS-Sask' || filterVal.store_name === 'FMS-Yorkton' || filterVal.store_name === 'FFUN Motorsports Winnipeg East' || filterVal.store_name === 'FFUN Motorsports Winnipeg West'|| filterVal.store_name === 'FFUN Motorsports Portage' || filterVal.store_name === 'FFUN Motorsports Kenora') &&
              <>
                <th colSpan="3" className="dn-header-top" style={{ "background-color": "#6F4E37", "color": "#ffffff" }}>Boats</th>
                <th colSpan="3" className="dn-header-top" style={{ "background-color": "#B87333", "color": "#ffffff" }}>Trailers</th>
              </>
            }
            <th colSpan="4" className="dn-header-top" style={{ "background-color": "#b1d5dd" }}>Service</th>
            <th colSpan="3" className="dn-header-top" style={{ "background-color": "#8b8bb5", "color": "#ffffff" }}>Parts</th>
            <th colSpan="3" className="dn-header-top" style={{ "background-color": "#74bb9a", "color": "#ffffff" }}>Apparel</th>
            <th colSpan="9" className="auto-header-top" style={{ "background-color": "#b38bb5" }}>Sales</th>
            <th className="dn-header-top" style={{ "background-color": "orange" }} ></th>
          </tr>
          < tr >
            <th className="dn-header" scope="col" style={{ "background-color": "#e9ecef" }}></th>
            <th className="dn-header" scope="col" style={{ "background-color": "#e9ecef" }}></th>
            <th className="dn-header" scope="col" style={{ "background-color": "#e9ecef" }}>Date</th>
            <th className="dn-header" scope="col" style={{ "background-color": "orange" }}>Deliv`d</th>
            <th className="dn-header" scope="col" style={{ "background-color": "orange" }}>Front GP</th>
            <th className="dn-header" scope="col" style={{ "background-color": "orange" }}>Back GP</th>
            <th className="dn-header" scope="col" style={{ "background-color": "#a2c1a2" }}>Deliv`d</th>
            <th className="dn-header" scope="col" style={{ "background-color": "#a2c1a2" }}>Front GP</th>
            <th className="dn-header" scope="col" style={{ "background-color": "#a2c1a2" }}>Back GP</th>
            {
              (filterVal.store_name === 'FMS-Sask' || filterVal.store_name === 'FMS-Yorkton' || filterVal.store_name === 'FFUN Motorsports Winnipeg East' || filterVal.store_name === 'FFUN Motorsports Winnipeg West'|| filterVal.store_name === 'FFUN Motorsports Portage' || filterVal.store_name === 'FFUN Motorsports Kenora') && <>
                <th className="dn-header" scope="col" style={{ "background-color": "#6F4E37", color: 'white' }}>Deliv`d</th>
                <th className="dn-header" scope="col" style={{ "background-color": "#6F4E37", color: 'white' }}>Front GP</th>
                <th className="dn-header" scope="col" style={{ "background-color": "#6F4E37", color: 'white' }}>Back GP</th>
                <th className="dn-header" scope="col" style={{ "background-color": "#B87333", color: 'white' }}>Deliv`d</th>
                <th className="dn-header" scope="col" style={{ "background-color": "#B87333", color: 'white' }}>Front GP</th>
                <th className="dn-header" scope="col" style={{ "background-color": "#B87333", color: 'white' }}>Back GP</th>
              </>
            }
            <th className="dn-header" scope="col" style={{ "background-color": "#b1d5dd" }}>Total GP</th>
            <th className="dn-header" scope="col" style={{ "background-color": "#b1d5dd" }}>Total Hours</th>
            <th className="dn-header" scope="col" style={{ "background-color": "#b1d5dd" }}>CP Hours</th>
            <th className="dn-header" scope="col" style={{ "background-color": "#b1d5dd" }}>CP RO's</th>
            {/* <th className="dn-header" scope="col" style={{ "background-color": "#b1d5dd" }}>Hours Avail</th>
            <th className="dn-header" scope="col" style={{ "background-color": "#b1d5dd" }}>Total Flag Hours</th>

            <th className="dn-header" scope="col" style={{ "background-color": "#b1d5dd" }}>Tech Proficiency</th> */}
            {/* <th className="dn-header" scope="col" style={{ "background-color": "#b1d5dd" }}>No of Technicians</th> */}
            {/* <th className="dn-header" scope="col" style={{ "background-color": "#b1d5dd" }}>Booked Hours</th> */}
            {/* <th className="dn-header" scope="col" style={{ "background-color": "#b1d5dd" }}>Units Shipped</th> */}
            {/* <th className="auto-header" scope="col" style={{ "background-color": "#b1d5dd" }}>No of Technicians</th>
            <th className="auto-header" scope="col" style={{ "background-color": "#b1d5dd" }}>Journeyman Needed</th>
            <th className="auto-header" scope="col" style={{ "background-color": "#b1d5dd" }}>Apprentice Needed</th>
            <th className="auto-header" scope="col" style={{ "background-color": "#b1d5dd" }}>Advisors Needed</th> */}



            <th className="dn-header" scope="col" style={{ "background-color": "#8b8bb5", "color": "#ffffff" }}>Sales</th>
            <th className="dn-header" scope="col" style={{ "background-color": "#8b8bb5", "color": "#ffffff" }}>Retail GP</th>
            <th className="dn-header" scope="col" style={{ "background-color": "#8b8bb5", "color": "#ffffff" }}>W/S GP</th>

            <th scope="col" className="dn-header" style={{ "background-color": "#74bb9a", "color": "#ffffff" }}>Sales</th>
            <th scope="col" className="dn-header" style={{ "background-color": "#74bb9a", "color": "#ffffff" }}>Gross</th>
            <th scope="col" className="dn-header" style={{ "background-color": "#74bb9a", "color": "#ffffff" }}>Inventory Value</th>
            <th className="dn-header" scope="col" style={{ "background-color": "#b38bb5" }}>Appts Made</th>
            <th className="dn-header" scope="col" style={{ "background-color": "#b38bb5" }}>Appts Confirmed</th>
            <th className="dn-header" scope="col" style={{ "background-color": "#b38bb5" }}>Appts Shown</th>
            <th className="dn-header" scope="col" style={{ "background-color": "#b38bb5" }}>Store Visits</th>

            <th className="auto-header" scope="col" style={{ "background-color": "#b38bb5" }}>Int Leads</th>
            <th className="dn-header" scope="col" style={{ "background-color": "#b38bb5" }}>F/Up</th>
            <th className="dn-header" scope="col" style={{ "background-color": "#b38bb5" }}>Phone Up</th>
            <th className="dn-header" scope="col" style={{ "background-color": "#b38bb5" }}>Total Leads</th>
            <th className="dn-header" scope="col" style={{ "background-color": "#b38bb5" }}>Sales Staff</th>

            {/* <th className="dn-header" scope="col" style={{ "background-color": "#b38bb5" }}>No Show</th> */}

            <th className="dn-header" scope="col" style={{ "background-color": "orange" }}>Total Store Gross</th>

          </tr >
        </thead>
        <tbody>
          {
            recData && dayData.map((i, n) => {
              total.new_retail_delivered = (recData[i] && recData[i].new_retail_delivered !== '') ? total.new_retail_delivered + parseInt(recData[i].new_retail_delivered) : total.new_retail_delivered;
              total.new_retail_front_GP = (recData[i] && recData[i].new_retail_front_GP !== '') ? total.new_retail_front_GP + parseInt(recData[i].new_retail_front_GP) : total.new_retail_front_GP;
              total.new_retail_back_GP = (recData[i] && recData[i].new_retail_back_GP !== '') ? total.new_retail_back_GP + parseInt(recData[i].new_retail_back_GP) : total.new_retail_back_GP;
              total.used_retail_delivered = (recData[i] && recData[i].used_retail_delivered !== '') ? total.used_retail_delivered + parseInt(recData[i].used_retail_delivered) : total.used_retail_delivered;
              total.used_retail_front_GP = (recData[i] && recData[i].used_retail_front_GP !== '') ? total.used_retail_front_GP + parseInt(recData[i].used_retail_front_GP) : total.used_retail_front_GP;
              total.used_retail_back_GP = (recData[i] && recData[i].used_retail_back_GP !== '') ? total.used_retail_back_GP + parseInt(recData[i].used_retail_back_GP) : total.used_retail_back_GP;

              total.boats_delivered = (recData[i] && recData[i].boats_delivered !== '') ? total.boats_delivered + parseInt(recData[i].boats_delivered) : total.boats_delivered;
              total.boats_front_GP = (recData[i] && recData[i].boats_front_GP !== '') ? total.boats_front_GP + parseInt(recData[i].boats_front_GP) : total.boats_front_GP;
              total.boats_back_GP = (recData[i] && recData[i].boats_back_GP !== '') ? total.boats_back_GP + parseInt(recData[i].boats_back_GP) : total.boats_back_GP;
              total.trailers_delivered = (recData[i] && recData[i].trailers_delivered !== '') ? total.trailers_delivered + parseInt(recData[i].trailers_delivered) : total.trailers_delivered;
              total.trailers_front_GP = (recData[i] && recData[i].trailers_front_GP !== '') ? total.trailers_front_GP + parseInt(recData[i].trailers_front_GP) : total.trailers_front_GP;
              total.trailers_back_GP = (recData[i] && recData[i].trailers_back_GP !== '') ? total.trailers_back_GP + parseInt(recData[i].trailers_back_GP) : total.trailers_back_GP;

              total.service_total_hours = (recData[i] && recData[i].service_total_hours !== '') ? total.service_total_hours + parseInt(recData[i].service_total_hours) : total.service_total_hours;
              total.service_CP_hours = (recData[i] && recData[i].service_CP_hours !== '') ? total.service_CP_hours + parseInt(recData[i].service_CP_hours) : total.service_CP_hours;
              total.service_CP_ROs = (recData[i] && recData[i].service_CP_ROs !== '') ? total.service_CP_ROs + parseInt(recData[i].service_CP_ROs) : total.service_CP_ROs;
              total.service_total_GP = (recData[i] && recData[i].service_total_GP !== '') ? total.service_total_GP + parseInt(recData[i].service_total_GP) : total.service_total_GP;

              // total.service_hours_sold = (recData[i] && recData[i].service_hours_sold > 0) ? total.service_hours_sold + parseInt(recData[i].service_hours_sold) : total.service_hours_sold;
              // total.service_hours_available = (recData[i] && recData[i].service_hours_available > 0) ? total.service_hours_available + parseInt(recData[i].service_hours_available) : total.service_hours_available;
              // total.service_no_of_tech = (recData[i] && recData[i].service_no_of_tech > 0) ? total.service_no_of_tech + parseInt(recData[i].service_no_of_tech) : total.service_no_of_tech;
              total.service_booked_hours = (recData[i] && recData[i].service_booked_hours > 0) ? total.service_booked_hours + parseInt(recData[i].service_booked_hours) : total.service_booked_hours;
              // total.service_units_shipped = (recData[i] && recData[i].service_units_shipped > 0) ? total.service_units_shipped + parseInt(recData[i].service_units_shipped) : total.service_units_shipped;

              // total.service_journeyman = (recData[i] && recData[i].service_journeyman > 0) ? total.service_journeyman + parseInt(recData[i].service_journeyman) : total.service_journeyman;
              // total.service_apprentice = (recData[i] && recData[i].service_apprentice > 0) ? total.service_apprentice + parseInt(recData[i].service_apprentice) : total.service_apprentice;
              // total.service_advisors_needed = (recData[i] && recData[i].service_advisors_needed > 0) ? total.service_advisors_needed + parseInt(recData[i].service_advisors_needed) : total.service_advisors_needed;


              total.parts_sales = (recData[i] && recData[i].parts_sales !== '') ? total.parts_sales + parseInt(recData[i].parts_sales) : total.parts_sales;
              total.parts_total_GP = (recData[i] && recData[i].parts_total_GP !== '') ? total.parts_total_GP + parseInt(recData[i].parts_total_GP) : total.parts_total_GP;
              total.parts_whole_sale_GP = (recData[i] && recData[i].parts_whole_sale_GP !== '') ? total.parts_whole_sale_GP + parseInt(recData[i].parts_whole_sale_GP) : total.parts_whole_sale_GP;

              total.apparel_sales = (recData[i] && recData[i].apparel_sales !== '') ? total.apparel_sales + parseInt(recData[i].apparel_sales) : total.apparel_sales;
              total.apparel_gross = (recData[i] && recData[i].apparel_gross !== '') ? total.apparel_gross + parseInt(recData[i].apparel_gross) : total.apparel_gross;
              total.apparel_inventory_value = (recData[i] && recData[i].apparel_inventory_value !== '') ? total.apparel_inventory_value + parseInt(recData[i].apparel_inventory_value) : total.apparel_inventory_value;

              total.appt_made = (recData[i] && recData[i].appt_made !== '') ? total.appt_made + parseInt(recData[i].appt_made) : total.appt_made;
              total.appt_show = (recData[i] && recData[i].appt_show !== '') ? total.appt_show + parseInt(recData[i].appt_show) : total.appt_show;
              total.store_visit = (recData[i] && recData[i].store_visit !== '') ? total.store_visit + parseInt(recData[i].store_visit) : total.store_visit;

              total.internet_lead = (recData[i] && recData[i].internet_lead !== '') ? total.internet_lead + parseInt(recData[i].internet_lead) : total.internet_lead;


              total.sales_staff = (recData[i] && recData[i].sales_staff > 0) ? total.sales_staff + parseInt(recData[i].sales_staff) : total.sales_staff;
              total.booked = (recData[i] && recData[i].booked > 0) ? total.booked + parseInt(recData[i].booked) : total.booked;
              total.no_show = (recData[i] && recData[i].no_show > 0) ? total.no_show + parseInt(recData[i].no_show) : total.no_show;
              total.phone_up = (recData[i] && recData[i].phone_up > 0) ? total.phone_up + parseInt(recData[i].phone_up) : total.phone_up;
              total.fresh_up = (recData[i] && recData[i].fresh_up > 0) ? total.fresh_up + parseInt(recData[i].fresh_up) : total.fresh_up;
              total.lead = total.internet_lead + total.phone_up + total.fresh_up;
              return <CreateRowRec row={recData} dataEntryRec={dataEntryRec} day={i} filterData={filterVal} loadData={loadData} editDay={editDay}></CreateRowRec>
            })
          }
          {rowTotal('Total', total)}
          {rowTotal('Forecast', budget)}
          {resultPercent('KPI', total, budget)}
        </tbody>
      </table>
      </>}
    </div>
  );
};

const mapStateToProps = state => ({
  dataEntryRec: state.dataEntryBudget.dataEntryRec,
  editDay: state.currentEditDayRec.currentEditDayRec
  // totalOfRecdata: state.dataEntryBudget.total
});

export default connect(mapStateToProps)(DataEntryRec);
