import React, { useEffect, useState } from "react";
import moment from "moment";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { MONTHS, YEARS } from "../../constants.js";
import axios from "axios";
import Links from "../../Data/Links.js";
import CarLoading from "../../images/487.gif";
import dayData from "../../constants/dayData.js";
import { getFiscals, calculateKPIPercentage } from "../../utils";

// funciton to get ratio 
const getRatio = (a, b) => {
    if (a && b) {
        const index = (parseFloat(a) / parseFloat(b)).toFixed(2);
        if (index === "NaN" || index === 'Infinity') {
            return 0
        } else {
            return index
        }
    } else {
        return 0;
    }
}

// division tables 
const AutoTable = ({ dailyData, budgetData }) => {

    // please be aware: the order of auto_fiels matters
    const auto_fiels = [
        'total_new_delivered',
        'total_new_front_GP',
        'total_new_back_GP',
        'total_new_csi',
        'total_used_delivered',
        'total_used_front_GP',
        'total_used_back_GP',
        'total_service_sales',
        'total_service_GP',
        'total_service_total_hours',
        'total_service_csi',
        'total_service_CP_hours',
        'total_service_CP_ROs',
        'index',
        // 'total_service_hours_available',
        // 'total_service_hours_sold',
        // 'tech_proficiency',
        // 'total_service_no_of_tech',
        // 'total_service_journeyman',
        // 'total_service_apprentice',

        'total_parts_sales',
        'total_parts_total_GP',

        'total_appt_made',
        'total_booked',
        'total_appt_show',
        'total_store_visit',
        'total_internet_lead',
        'total_fresh_up',
        'total_phone_up',
        'total_lead',
        'total_sales_staff',

        'total_gross_profit'
    ];

    return (
        <div>
            <h4>Automotive Dealerships:</h4>
            <table class="table table-bordered border-black" style={{ marginTop: 30 }}>
                <thead>
                    <tr className="text-center">
                        <th colSpan="1" className="auto-header-top" style={{ "background-color": "#e9ecef" }}></th>
                        <th colSpan="4" className="auto-header-top" style={{ "background-color": "orange" }} >New Vehicles</th>
                        <th colSpan="3" className="auto-header-top" style={{ "background-color": "#a2c1a2" }}>Used Vehicles</th>
                        <th colSpan="7" className="auto-header-top" style={{ "background-color": "#b1d5dd" }}>Service</th>
                        <th colSpan="2" className="auto-header-top" style={{ "background-color": "#8b8bb5" }}>Parts</th>
                        <th colSpan="9" className="auto-header-top" style={{ "background-color": "#b38bb5" }}>Sales</th>
                        <th className="auto-header-top" style={{ "background-color": "orange" }}></th>
                    </tr>
                    < tr >
                        <th className="auto-header" scope="col" style={{ "background-color": "#e9ecef" }}>Date</th>
                        <th className="auto-header" scope="col" style={{ "background-color": "orange" }}>Retail Deliv`d</th>
                        <th className="auto-header" scope="col" style={{ "background-color": "orange" }}>Retail Front GP</th>
                        <th className="auto-header" scope="col" style={{ "background-color": "orange" }}>Retail Back GP</th>
                        <th className="auto-header" scope="col" style={{ "background-color": "orange" }}>CSI</th>

                        <th className="auto-header" scope="col" style={{ "background-color": "#a2c1a2" }}>Retail Deliv`d</th>
                        <th className="auto-header" scope="col" style={{ "background-color": "#a2c1a2" }}>Retail Front GP</th>
                        <th className="auto-header" scope="col" style={{ "background-color": "#a2c1a2" }}>Retail Back GP</th>

                        <th className="auto-header" scope="col" style={{ "background-color": "#b1d5dd" }}>Sales</th>
                        <th className="auto-header" scope="col" style={{ "background-color": "#b1d5dd" }}>Total GP</th>
                        <th className="auto-header" scope="col" style={{ "background-color": "#b1d5dd" }}>Total Hours</th>
                        <th className="auto-header" scope="col" style={{ "background-color": "#b1d5dd" }}>CSI</th>
                        <th className="auto-header" scope="col" style={{ "background-color": "#b1d5dd" }}>CP Hours</th>
                        <th className="auto-header" scope="col" style={{ "background-color": "#b1d5dd" }}>CP RO's</th>
                        <th className="auto-header" scope="col" style={{ "background-color": "#b1d5dd" }}>CP Hrs/RO</th>
                        {/* <th className="auto-header" scope="col" style={{ "background-color": "#b1d5dd" }}>Hours Avail</th>
                        <th className="auto-header" scope="col" style={{ "background-color": "#b1d5dd" }}>Total Flag Hours</th>
                        <th className="auto-header" scope="col" style={{ "background-color": "#b1d5dd" }}>Tech Proficiency</th>
                        <th className="auto-header" scope="col" style={{ "background-color": "#b1d5dd" }}>No of Technicians</th>
                        <th className="auto-header" scope="col" style={{ "background-color": "#b1d5dd" }}>Journeyman Needed</th>
                        <th className="auto-header" scope="col" style={{ "background-color": "#b1d5dd" }}>Apprentice Needed</th> */}




                        <th className="auto-header" scope="col" style={{ "background-color": "#8b8bb5" }}>Sales</th>
                        <th className="auto-header" scope="col" style={{ "background-color": "#8b8bb5" }}>Total GP</th>


                        <th className="auto-header" scope="col" style={{ "background-color": "#b38bb5" }}>Appts Made</th>
                        <th className="auto-header" scope="col" style={{ "background-color": "#b38bb5" }}>Appts Comfirmed</th>
                        <th className="auto-header" scope="col" style={{ "background-color": "#b38bb5" }}>Appts Shown</th>
                        <th className="auto-header" scope="col" style={{ "background-color": "#b38bb5" }}>Store Visits</th>
                        <th className="auto-header" scope="col" style={{ "background-color": "#b38bb5" }}>Int Leads</th>
                        <th className="auto-header" scope="col" style={{ "background-color": "#b38bb5" }}>F/Up</th>
                        <th className="auto-header" scope="col" style={{ "background-color": "#b38bb5" }}>Phone Up</th>
                        <th className="auto-header" scope="col" style={{ "background-color": "#b38bb5" }}>Total Leads</th>
                        <th className="auto-header" scope="col" style={{ "background-color": "#b38bb5" }}>Sales Staff</th>

                        <th className="auto-header" scope="col" style={{ "background-color": "orange" }}>Total Store Gross</th>
                    </tr >
                </thead>

                <tbody>
                    {/* daily data  */}
                    {dailyData && dayData.map((day) => {
                        const data = Object.values(dailyData).find(data => data.day == day);
                        return (
                            <tr>
                                <td className="dn-column">{day}</td>
                                {auto_fiels.map((field) => (
                                    <td className="dn-column">
                                        {(field.includes("GP") || field.includes("sales") || field.includes("gross")) && !field.includes("staff") && '$'}
                                        {field === 'index' || field === "tech_proficiency" ?
                                            field === 'index' ? getRatio(data?.total_service_CP_hours, data?.total_service_CP_ROs) : getRatio(data?.total_service_hours_sold, data?.total_service_total_hours)
                                            : (data?.[field] || 0).toLocaleString("en-CA", {
                                                minimumFractionDigits: 0,
                                                maximumFractionDigits: 0,
                                            })}
                                    </td>
                                ))}
                            </tr>
                        )
                    })}
                    {/* total  */}
                    <tr>
                        <td style={{ "background-color": "#e9ecef" }} className="dn-column">Total</td>
                        {auto_fiels.map((field) => (
                            <td style={{ "background-color": "#e9ecef" }} className="dn-column">
                                {(field.includes("GP") || field.includes("sales") || field.includes("gross")) && !field.includes("staff") && "$"}
                                {field === 'index' || field === "tech_proficiency" ?
                                    field === 'index' ? getRatio(
                                        dailyData.map(item => item.total_service_CP_hours).reduce((a, b) => (a||0) + (b||0), 0),
                                        dailyData.map(item => item.total_service_CP_ROs).reduce((a, b) => (a||0) + (b||0), 0)
                                    ) :
                                        getRatio(
                                            dailyData.map(item => item.total_service_hours_sold).reduce((a, b) => (a||0) + (b||0), 0),
                                            dailyData.map(item => item.total_service_total_hours).reduce((a, b) => (a||0) + (b||0), 0)
                                        )
                                    : dailyData.map(item => item[field] ? parseFloat(item[field]) : 0)
                                        .reduce((a, b) => (a||0) + (b||0), 0)
                                        .toLocaleString("en-CA", {
                                            minimumFractionDigits: 0,
                                            maximumFractionDigits: 0,
                                        })
                                }
                            </td>
                        ))}
                    </tr>
                    {/* budget  */}
                    <tr>
                        <td style={{ "background-color": "#e9ecef" }} className="dn-column">Budget</td>
                        {auto_fiels.map((field) => (
                            <td style={{ "background-color": "#e9ecef" }} className="dn-column">
                                {(field.includes("GP") || field.includes("sales") || field.includes("gross")) && !field.includes("staff") && "$"}
                                {field === 'index' || field === "tech_proficiency" ?
                                    field === 'index' ? getRatio(budgetData?.total_service_CP_hours, budgetData?.total_service_CP_ROs) : getRatio(budgetData?.total_service_hours_sold, budgetData?.total_service_total_hours)
                                    : (budgetData?.[field] || 0)
                                        .toLocaleString("en-CA", {
                                            minimumFractionDigits: 0,
                                            maximumFractionDigits: 0
                                        })
                                }
                            </td>
                        ))}
                    </tr>
                    {/* kpi  */}
                    <tr>
                        <td style={{ "background-color": "#e9ecef" }} className="dn-column">KPI</td>
                        {auto_fiels.map(field => {
                            let field_total = dailyData.map(item => item[field] ? parseFloat(item[field]) : 0).reduce((a, b) => (a||0) + (b||0), 0);
                            let field_budget = (budgetData && budgetData[field]) ? budgetData[field] : 0;
                            return (
                                <td style={{ "background-color": "#e9ecef" }} className="dn-column">
                                    {calculateKPIPercentage(field_total, field_budget)}
                                </td>
                            )
                        })}
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

const RecTable = ({ dailyData, budgetData }) => {
    // please be aware: the order of auto_fiels matters
    const rec_fiels = [
        'total_new_delivered',
        'total_new_front_GP',
        'total_new_back_GP',
        'total_used_delivered',
        'total_used_front_GP',
        'total_used_back_GP',
        'total_boats_delivered',
        'total_boats_front_GP',
        'total_boats_back_GP',
        'total_trailers_delivered',
        'total_trailers_front_GP',
        'total_trailers_back_GP',

        'total_service_GP',
        'total_service_total_hours',
        'total_service_CP_hours',
        'total_service_CP_ROs',
        // 'total_service_hours_available',
        // 'total_service_hours_sold',
        // 'tech_proficiency',
        // 'total_service_no_of_tech',
        // 'total_service_journeyman',
        // 'total_service_apprentice',

        'total_parts_sales',
        'total_parts_total_GP',
        'total_parts_whole_sale_GP',
        'total_apparel_sales',
        'total_apparel_gross',
        'total_apparel_inventory_value',

        'total_appt_made',
        'total_booked',
        'total_appt_show',
        'total_store_visit',
        'total_internet_lead',
        'total_fresh_up',
        'total_phone_up',
        'total_lead',
        'total_sales_staff',

        'total_gross_profit'
    ];

    return (
        <div>
            <h4>Recreational Dealerships:</h4>
            <table class="table table-bordered border-black" style={{ marginTop: 30 }}>
                <thead>
                    <tr className="text-center">
                        <th colSpan="1" className="dn-header-top" style={{ "background-color": "#e9ecef" }}></th>
                        <th colSpan="3" className="dn-header-top" style={{ "background-color": "orange" }} >New Vehicles</th>
                        <th colSpan="3" className="dn-header-top" style={{ "background-color": "#a2c1a2" }}>Used Vehicles</th>
                        <th colSpan="3" className="dn-header-top" style={{ "background-color": "#6F4E37", "color": "#ffffff" }}>Boats</th>
                        <th colSpan="3" className="dn-header-top" style={{ "background-color": "#B87333", "color": "#ffffff" }}>Trailers</th>
                        <th colSpan="4" className="dn-header-top" style={{ "background-color": "#b1d5dd" }}>Service</th>
                        <th colSpan="3" className="dn-header-top" style={{ "background-color": "#8b8bb5", "color": "#ffffff" }}>Parts</th>
                        <th colSpan="3" className="dn-header-top" style={{ "background-color": "#74bb9a", "color": "#ffffff" }}>Apparel</th>
                        <th colSpan="9" className="auto-header-top" style={{ "background-color": "#b38bb5" }}>Sales</th>
                        <th className="dn-header-top" style={{ "background-color": "orange" }} ></th>
                    </tr>
                    < tr >
                        <th className="dn-header" scope="col" style={{ "background-color": "#e9ecef" }}>Date</th>
                        <th className="dn-header" scope="col" style={{ "background-color": "orange" }}>Deliv`d</th>
                        <th className="dn-header" scope="col" style={{ "background-color": "orange" }}>Front GP</th>
                        <th className="dn-header" scope="col" style={{ "background-color": "orange" }}>Back GP</th>
                        <th className="dn-header" scope="col" style={{ "background-color": "#a2c1a2" }}>Deliv`d</th>
                        <th className="dn-header" scope="col" style={{ "background-color": "#a2c1a2" }}>Front GP</th>
                        <th className="dn-header" scope="col" style={{ "background-color": "#a2c1a2" }}>Back GP</th>
                        <th className="dn-header" scope="col" style={{ "background-color": "#6F4E37", color: 'white' }}>Deliv`d</th>
                        <th className="dn-header" scope="col" style={{ "background-color": "#6F4E37", color: 'white' }}>Front GP</th>
                        <th className="dn-header" scope="col" style={{ "background-color": "#6F4E37", color: 'white' }}>Back GP</th>
                        <th className="dn-header" scope="col" style={{ "background-color": "#B87333", color: 'white' }}>Deliv`d</th>
                        <th className="dn-header" scope="col" style={{ "background-color": "#B87333", color: 'white' }}>Front GP</th>
                        <th className="dn-header" scope="col" style={{ "background-color": "#B87333", color: 'white' }}>Back GP</th>

                        <th className="dn-header" scope="col" style={{ "background-color": "#b1d5dd" }}>Total GP</th>
                        <th className="dn-header" scope="col" style={{ "background-color": "#b1d5dd" }}>Total Hours</th>
                        <th className="dn-header" scope="col" style={{ "background-color": "#b1d5dd" }}>CP Hours</th>
                        <th className="dn-header" scope="col" style={{ "background-color": "#b1d5dd" }}>CP RO's</th>
                        {/* <th className="auto-header" scope="col" style={{ "background-color": "#b1d5dd" }}>Hours Avail</th>
                        <th className="auto-header" scope="col" style={{ "background-color": "#b1d5dd" }}>Total Flag Hours</th>
                        <th className="auto-header" scope="col" style={{ "background-color": "#b1d5dd" }}>Tech Proficiency</th>
                        <th className="auto-header" scope="col" style={{ "background-color": "#b1d5dd" }}>No of Technicians</th>
                        <th className="auto-header" scope="col" style={{ "background-color": "#b1d5dd" }}>Journeyman Needed</th>
                        <th className="auto-header" scope="col" style={{ "background-color": "#b1d5dd" }}>Apprentice Needed</th> */}


                        <th className="dn-header" scope="col" style={{ "background-color": "#8b8bb5", "color": "#ffffff" }}>Sales</th>
                        <th className="dn-header" scope="col" style={{ "background-color": "#8b8bb5", "color": "#ffffff" }}>Retail GP</th>
                        <th className="dn-header" scope="col" style={{ "background-color": "#8b8bb5", "color": "#ffffff" }}>W/S GP</th>
                        <th className="dn-header" scope="col" style={{ "background-color": "#74bb9a", "color": "#ffffff" }}>Sales</th>
                        <th className="dn-header" scope="col" style={{ "background-color": "#74bb9a", "color": "#ffffff" }}>Gross</th>
                        <th className="dn-header" scope="col" style={{ "background-color": "#74bb9a", "color": "#ffffff" }}>Inventory Value</th>

                        <th className="dn-header" scope="col" style={{ "background-color": "#b38bb5" }}>Appts Made</th>
                        <th className="dn-header" scope="col" style={{ "background-color": "#b38bb5" }}>Appts Confirmed</th>
                        <th className="dn-header" scope="col" style={{ "background-color": "#b38bb5" }}>Appts Shown</th>
                        <th className="dn-header" scope="col" style={{ "background-color": "#b38bb5" }}>Store Visits</th>
                        <th className="auto-header" scope="col" style={{ "background-color": "#b38bb5" }}>Int Leads</th>
                        <th className="auto-header" scope="col" style={{ "background-color": "#b38bb5" }}>F/Up</th>
                        <th className="auto-header" scope="col" style={{ "background-color": "#b38bb5" }}>Phone Up</th>
                        <th className="auto-header" scope="col" style={{ "background-color": "#b38bb5" }}>Total Leads</th>
                        <th className="auto-header" scope="col" style={{ "background-color": "#b38bb5" }}>Sales Staff</th>


                        <th className="dn-header" scope="col" style={{ "background-color": "orange" }}>Total Store Gross</th>
                    </tr >
                </thead>

                <tbody>
                    {/* daily data  */}
                    {dailyData && dayData.map((day) => {
                        const data = Object.values(dailyData).find(data => data.day == day);
                        return (
                            <tr>
                                <td className="dn-column">{day}</td>
                                {rec_fiels.map((field) => (
                                    <td className="dn-column">
                                        {(field.includes("GP") || field.includes("sales") || field.includes("gross")) && !field.includes("staff") && '$'}
                                        {field === 'tech_proficiency' ? getRatio(data?.total_service_hours_sold, data?.total_service_total_hours) : (data?.[field] || 0).toLocaleString("en-CA", {
                                            minimumFractionDigits: 0,
                                            maximumFractionDigits: 0
                                        })}
                                    </td>
                                ))}
                            </tr>
                        )
                    })}
                    {/* total  */}
                    <tr>
                        <td style={{ "background-color": "#e9ecef" }} className="dn-column">Total</td>
                        {rec_fiels.map((field) => (
                            <td style={{ "background-color": "#e9ecef" }} className="dn-column">
                                {(field.includes("GP") || field.includes("sales") || field.includes("gross")) && !field.includes("staff") && '$'}
                                {field === 'tech_proficiency' ? getRatio(
                                    dailyData.map(item => item.total_service_hours_sold).reduce((a, b) => (a||0) + (b||0), 0),
                                    dailyData.map(item => item.total_service_total_hours).reduce((a, b) => (a||0) + (b||0), 0)
                                ) : dailyData.map(item => item[field] ? parseFloat(item[field]) : 0)
                                    .reduce((a, b) => (a||0) + (b||0), 0)
                                    .toLocaleString("en-CA", {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0
                                    })}
                            </td>
                        ))}
                    </tr>
                    {/* budget  */}
                    <tr>
                        <td style={{ "background-color": "#e9ecef" }} className="dn-column">Budget</td>
                        {rec_fiels.map((field) => (
                            <td style={{ "background-color": "#e9ecef" }} className="dn-column">
                                {(field.includes("GP") || field.includes("sales") || field.includes("gross")) && !field.includes("staff") && '$'}
                                {field === 'tech_proficiency' ? getRatio(budgetData?.total_service_hours_sold, budgetData?.total_service_total_hours) : (budgetData?.[field] || 0).toLocaleString("en-CA", {
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 0
                                })}
                            </td>
                        ))}
                    </tr>
                    {/* kpi  */}
                    <tr>
                        <td style={{ "background-color": "#e9ecef" }} className="dn-column">KPI</td>
                        {rec_fiels.map(field => {
                            let field_total = dailyData.map(item => item[field] ? parseFloat(item[field]) : 0).reduce((a, b) => (a||0) + (b||0), 0);
                            let field_budget = budgetData && budgetData[field] ? budgetData[field] : 0;
                            return (
                                <td style={{ "background-color": "#e9ecef" }} className="dn-column">
                                    {calculateKPIPercentage(field_total, field_budget)}
                                </td>
                            )
                        })}
                    </tr>
                </tbody>

            </table>
        </div>
    )
}

const UsedTable = ({ dailyData, budgetData }) => {
    // please be aware: the order of auto_fiels matters
    const used_fiels = [
        'total_used_delivered',
        'total_used_front_GP',
        'total_used_back_GP',

        'total_service_GP',
        'total_service_total_hours',
        'total_service_CP_hours',
        'total_service_CP_ROs',
        // 'total_service_hours_available',
        // 'total_service_hours_sold',
        // 'tech_proficiency',
        // 'total_service_no_of_tech',
        // 'total_service_journeyman',
        // 'total_service_apprentice',
        'total_service_units_shipped',

        'total_appt_made',
        'total_booked',
        'total_appt_show',
        'total_store_visit',
        'total_internet_lead',
        'total_fresh_up',
        'total_phone_up',
        'total_lead',
        'total_sales_staff',
        'total_gross_profit'
    ];
    return (
        <div>
            <h4>Used Dealerships:</h4>
            <table class="table table-bordered border-black" style={{ marginTop: 30 }}>
                <thead>
                    <tr className="text-center">
                        <th colSpan="1" className="dn-header-top" style={{ "background-color": "#e9ecef" }}></th>
                        <th colSpan="3" className="dn-header-top" style={{ "background-color": "#a2c1a2" }}>Used Vehicles</th>
                        <th colSpan="5" className="dn-header-top" style={{ "background-color": "#b1d5dd" }}>Service</th>
                        <th colSpan="9" className="dn-header-top" style={{ "background-color": "#b38bb5" }}>Sales</th>
                        <th className="dn-header-top" style={{ "background-color": "orange" }}></th>
                    </tr>
                    <tr>
                        <th className="dn-header" scope="col" style={{ "background-color": "#e9ecef" }}>Date</th>
                        <th className="dn-header" scope="col" style={{ "background-color": "#a2c1a2" }}>Deliv`d</th>
                        <th className="dn-header" scope="col" style={{ "background-color": "#a2c1a2" }}>Front GP</th>
                        <th className="dn-header" scope="col" style={{ "background-color": "#a2c1a2" }}>Back GP</th>

                        <th className="dn-header" scope="col" style={{ "background-color": "#b1d5dd" }}>GP</th>
                        <th className="dn-header" scope="col" style={{ "background-color": "#b1d5dd" }}>Total Hours</th>
                        <th className="dn-header" scope="col" style={{ "background-color": "#b1d5dd" }}>CP Hours</th>
                        <th className="dn-header" scope="col" style={{ "background-color": "#b1d5dd" }}>CP RO's</th>
                        {/* <th className="auto-header" scope="col" style={{ "background-color": "#b1d5dd" }}>Hours Avail</th>
                        <th className="auto-header" scope="col" style={{ "background-color": "#b1d5dd" }}>Total Flag Hours</th>
                        <th className="auto-header" scope="col" style={{ "background-color": "#b1d5dd" }}>Tech Proficiency</th>
                        <th className="auto-header" scope="col" style={{ "background-color": "#b1d5dd" }}>No of Technicians</th>
                        <th className="auto-header" scope="col" style={{ "background-color": "#b1d5dd" }}>Journeyman Needed</th>
                        <th className="auto-header" scope="col" style={{ "background-color": "#b1d5dd" }}>Apprentice Needed</th> */}
                        <th className="auto-header" scope="col" style={{ "background-color": "#b1d5dd" }}>Units Shipped</th>


                        <th className="dn-header" scope="col" style={{ "background-color": "#b38bb5" }}>Appts Made</th>
                        <th className="dn-header" scope="col" style={{ "background-color": "#b38bb5" }}>Appts Confirmed</th>
                        <th className="dn-header" scope="col" style={{ "background-color": "#b38bb5" }}>Appts Shown</th>
                        <th className="dn-header" scope="col" style={{ "background-color": "#b38bb5" }}>Store Visits</th>
                        <th className="auto-header" scope="col" style={{ "background-color": "#b38bb5" }}>Int Leads</th>
                        <th className="auto-header" scope="col" style={{ "background-color": "#b38bb5" }}>F/Up</th>
                        <th className="auto-header" scope="col" style={{ "background-color": "#b38bb5" }}>Phone Up</th>
                        <th className="auto-header" scope="col" style={{ "background-color": "#b38bb5" }}>Total Leads</th>
                        <th className="auto-header" scope="col" style={{ "background-color": "#b38bb5" }}>Sales Staff</th>

                        <th className="dn-header" scope="col" style={{ "background-color": "orange" }}>Total Store Gross</th>
                    </tr>
                </thead>

                <tbody>
                    {/* daily data  */}
                    {dailyData && dayData.map((day) => {
                        const data = Object.values(dailyData).find(data => data.day == day);
                        return (
                            <tr>
                                <td className="dn-column">{day}</td>
                                {used_fiels.map((field) => (
                                    <td className="dn-column">
                                        {(field.includes("GP") || field.includes("sales") || field.includes("gross")) && !field.includes("staff") && '$'}
                                        {field === 'tech_proficiency' ? getRatio(data?.total_service_hours_sold, data?.total_service_total_hours) : (data?.[field] || 0).toLocaleString("en-CA", {
                                            minimumFractionDigits: 0,
                                            maximumFractionDigits: 0
                                        })}
                                    </td>
                                ))}
                            </tr>
                        )
                    })}
                    {/* total  */}
                    <tr>
                        <td style={{ "background-color": "#e9ecef" }} className="dn-column">Total</td>
                        {used_fiels.map((field) => (
                            <td style={{ "background-color": "#e9ecef" }} className="dn-column">
                                {(field.includes("GP") || field.includes("sales") || field.includes("gross")) && !field.includes("staff") && '$'}
                                {field === 'tech_proficiency' ? getRatio(
                                    dailyData.map(item => item.total_service_hours_sold).reduce((a, b) => (a||0) + (b||0), 0),
                                    dailyData.map(item => item.total_service_total_hours).reduce((a, b) => (a||0) + (b||0), 0)
                                ) : dailyData.map(item => item[field] ? parseFloat(item[field]) : 0)
                                    .reduce((a, b) => (a||0) + (b||0), 0)
                                    .toLocaleString("en-CA", {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0
                                    })}
                            </td>
                        ))}
                    </tr>
                    {/* budget  */}
                    <tr>
                        <td style={{ "background-color": "#e9ecef" }} className="dn-column">Budget</td>
                        {used_fiels.map((field) => (
                            <td style={{ "background-color": "#e9ecef" }} className="dn-column">
                                {(field.includes("GP") || field.includes("sales") || field.includes("gross")) && !field.includes("staff") && '$'}
                                {field === 'tech_proficiency' ? getRatio(budgetData?.total_service_hours_sold, budgetData?.total_service_total_hours) : (budgetData?.[field] || 0).toLocaleString("en-CA", {
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 0
                                })}
                            </td>
                        ))}
                    </tr>
                    {/* kpi  */}
                    <tr>
                        <td style={{ "background-color": "#e9ecef" }} className="dn-column">KPI</td>
                        {used_fiels.map(field => {
                            let field_total = dailyData.map(item => item[field] ? parseFloat(item[field]) : 0).reduce((a, b) => (a||0) + (b||0), 0);
                            let field_budget = budgetData && budgetData[field] ? budgetData[field] : 0;
                            return (
                                <td style={{ "background-color": "#e9ecef" }} className="dn-column">
                                    {calculateKPIPercentage(field_total, field_budget)}
                                </td>
                            )
                        })}
                    </tr>
                </tbody>

            </table>
        </div>
    )
}

// enterprise table
const AllTable = ({ dailyData, budgetData }) => {
    // please be aware: the order of auto_fiels matters
    const all_fields = [
        "total_new_delivered",
        "total_new_front_GP",
        "total_new_back_GP",
        "total_new_csi",
        "total_used_delivered",
        "total_used_front_GP",
        "total_used_back_GP",
        "total_boats_delivered",
        "total_boats_front_GP",
        "total_boats_back_GP",
        "total_trailers_delivered",
        "total_trailers_front_GP",
        "total_trailers_back_GP",
        'total_service_sales',
        'total_service_GP',
        'total_service_total_hours',
        'total_service_csi',
        'total_service_CP_hours',
        'total_service_CP_ROs',
        'index',
        // 'total_service_hours_available',
        // 'total_service_hours_sold',
        // 'tech_proficiency',
        // 'total_service_no_of_tech',
        // 'total_service_journeyman',
        // 'total_service_apprentice',
        'total_service_units_shipped',
        "total_parts_sales",
        "total_parts_total_GP",
        "total_parts_whole_sale_GP",
        "total_apparel_sales",
        "total_apparel_gross",
        "total_apparel_inventory_value",
        'total_appt_made',
        'total_booked',
        'total_appt_show',
        'total_store_visit',
        'total_internet_lead',
        'total_fresh_up',
        'total_phone_up',
        'total_lead',
        'total_sales_staff',
        "total_gross_profit",
    ];

    return (
        <div>
            <h4>Enterprise:</h4>
            <table class="table table-bordered border-black" style={{ marginTop: 30 }}>
                <thead>
                    <tr className="text-center">
                        <th colSpan="1" className="dn-header-top" style={{ "background-color": "#e9ecef" }}></th>
                        <th colSpan="4" className="dn-header-top" style={{ "background-color": "orange" }} >New Vehicles</th>
                        <th colSpan="3" className="dn-header-top" style={{ "background-color": "#a2c1a2" }}>Used Vehicles</th>
                        <th colSpan="3" className="dn-header-top" style={{ "background-color": "#6F4E37", "color": "#ffffff" }}>Boats</th>
                        <th colSpan="3" className="dn-header-top" style={{ "background-color": "#B87333", "color": "#ffffff" }}>Trailers</th>
                        <th colSpan="8" className="dn-header-top" style={{ "background-color": "#b1d5dd" }}>Service</th>
                        <th colSpan="3" className="dn-header-top" style={{ "background-color": "#8b8bb5", "color": "#ffffff" }}>Parts</th>
                        <th colSpan="3" className="dn-header-top" style={{ "background-color": "#74bb9a", "color": "#ffffff" }}>Apparel</th>
                        <th colSpan="9" className="auto-header-top" style={{ "background-color": "#b38bb5" }}>Sales</th>
                        <th className="dn-header-top" style={{ "background-color": "orange" }} ></th>
                    </tr>
                    < tr >
                        <th className="dn-header" scope="col" style={{ "background-color": "#e9ecef" }}>Date</th>
                        <th className="dn-header" scope="col" style={{ "background-color": "orange" }}>Deliv`d</th>
                        <th className="dn-header" scope="col" style={{ "background-color": "orange" }}>Front GP</th>
                        <th className="dn-header" scope="col" style={{ "background-color": "orange" }}>Back GP</th>
                        <th className="dn-header" scope="col" style={{ "background-color": "orange" }}>CSI</th>
                        <th className="dn-header" scope="col" style={{ "background-color": "#a2c1a2" }}>Deliv`d</th>
                        <th className="dn-header" scope="col" style={{ "background-color": "#a2c1a2" }}>Front GP</th>
                        <th className="dn-header" scope="col" style={{ "background-color": "#a2c1a2" }}>Back GP</th>
                        <th className="dn-header" scope="col" style={{ "background-color": "#6F4E37", color: 'white' }}>Deliv`d</th>
                        <th className="dn-header" scope="col" style={{ "background-color": "#6F4E37", color: 'white' }}>Front GP</th>
                        <th className="dn-header" scope="col" style={{ "background-color": "#6F4E37", color: 'white' }}>Back GP</th>
                        <th className="dn-header" scope="col" style={{ "background-color": "#B87333", color: 'white' }}>Deliv`d</th>
                        <th className="dn-header" scope="col" style={{ "background-color": "#B87333", color: 'white' }}>Front GP</th>
                        <th className="dn-header" scope="col" style={{ "background-color": "#B87333", color: 'white' }}>Back GP</th>

                        <th className="auto-header" scope="col" style={{ "background-color": "#b1d5dd" }}>Sales</th>
                        <th className="auto-header" scope="col" style={{ "background-color": "#b1d5dd" }}>Total GP</th>
                        <th className="auto-header" scope="col" style={{ "background-color": "#b1d5dd" }}>Total Hours</th>
                        <th className="auto-header" scope="col" style={{ "background-color": "#b1d5dd" }}>CSI</th>
                        <th className="auto-header" scope="col" style={{ "background-color": "#b1d5dd" }}>CP Hours</th>
                        <th className="auto-header" scope="col" style={{ "background-color": "#b1d5dd" }}>CP RO's</th>
                        <th className="auto-header" scope="col" style={{ "background-color": "#b1d5dd" }}>CP Hrs/RO</th>
                        {/* <th className="auto-header" scope="col" style={{ "background-color": "#b1d5dd" }}>Hours Avail</th>
                        <th className="auto-header" scope="col" style={{ "background-color": "#b1d5dd" }}>Total Flag Hours</th>
                        <th className="auto-header" scope="col" style={{ "background-color": "#b1d5dd" }}>Tech Proficiency</th>
                        <th className="auto-header" scope="col" style={{ "background-color": "#b1d5dd" }}>No of Technicians</th>
                        <th className="auto-header" scope="col" style={{ "background-color": "#b1d5dd" }}>Journeyman Needed</th>
                        <th className="auto-header" scope="col" style={{ "background-color": "#b1d5dd" }}>Apprentice Needed</th> */}
                        <th className="auto-header" scope="col" style={{ "background-color": "#b1d5dd" }}>Units Shipped</th>

                        <th className="dn-header" scope="col" style={{ "background-color": "#8b8bb5", "color": "#ffffff" }}>Sales</th>
                        <th className="dn-header" scope="col" style={{ "background-color": "#8b8bb5", "color": "#ffffff" }}>Retail GP</th>
                        <th className="dn-header" scope="col" style={{ "background-color": "#8b8bb5", "color": "#ffffff" }}>W/S GP</th>
                        <th className="dn-header" scope="col" style={{ "background-color": "#74bb9a", "color": "#ffffff" }}>Sales</th>
                        <th className="dn-header" scope="col" style={{ "background-color": "#74bb9a", "color": "#ffffff" }}>Gross</th>
                        <th className="dn-header" scope="col" style={{ "background-color": "#74bb9a", "color": "#ffffff" }}>Inventory Value</th>

                        <th className="dn-header" scope="col" style={{ "background-color": "#b38bb5" }}>Appts Made</th>
                        <th className="dn-header" scope="col" style={{ "background-color": "#b38bb5" }}>Appts Confirmed</th>
                        <th className="dn-header" scope="col" style={{ "background-color": "#b38bb5" }}>Appts Shown</th>
                        <th className="dn-header" scope="col" style={{ "background-color": "#b38bb5" }}>Store Visits</th>
                        <th className="auto-header" scope="col" style={{ "background-color": "#b38bb5" }}>Int Leads</th>
                        <th className="auto-header" scope="col" style={{ "background-color": "#b38bb5" }}>F/Up</th>
                        <th className="auto-header" scope="col" style={{ "background-color": "#b38bb5" }}>Phone Up</th>
                        <th className="auto-header" scope="col" style={{ "background-color": "#b38bb5" }}>Total Leads</th>
                        <th className="auto-header" scope="col" style={{ "background-color": "#b38bb5" }}>Sales Staff</th>

                        <th className="dn-header" scope="col" style={{ "background-color": "orange" }}>Total Store Gross</th>
                    </tr >
                </thead>

                <tbody>
                    {/* daily data  */}
                    {dailyData && dayData.map((day) => {
                        const data = dailyData.filter(data => data.day == day);
                        return (
                            <tr>
                                <td className="dn-column">{day}</td>
                                {all_fields.map((field) => (
                                    <td className="dn-column">
                                        {(field.includes("GP") || field.includes("sales") || field.includes("gross") || field.includes("value")) && !field.includes("staff") && "$"}
                                        {field === 'index' || field === "tech_proficiency" ?
                                            field === 'index' ? getRatio(data.map(item => item['total_service_CP_hours'] ? parseFloat(item['total_service_CP_hours']) : 0)
                                                .reduce((a, b) => (a||0) + (b||0), 0), data.map(item => item['total_service_CP_ROs'] ? parseFloat(item['total_service_CP_ROs']) : 0)
                                                    .reduce((a, b) => (a||0) + (b||0), 0))
                                                : getRatio(data.map(item => item['total_service_hours_sold'] ? parseFloat(item['total_service_hours_sold']) : 0)
                                                    .reduce((a, b) => (a||0) + (b||0), 0), data.map(item => item['total_service_total_hours'] ? parseFloat(item['total_service_total_hours']) : 0)
                                                        .reduce((a, b) => (a||0) + (b||0), 0))
                                            : data.map(item => item[field] ? parseFloat(item[field]) : 0)
                                                .reduce((a, b) => (a||0) + (b||0), 0)
                                                .toLocaleString("en-CA", {
                                                    minimumFractionDigits: 0,
                                                    maximumFractionDigits: 0
                                                })}
                                    </td>
                                ))}
                            </tr>
                        )
                    })}
                    {/* total  */}
                    <tr>
                        <td style={{ "background-color": "#e9ecef" }} className="dn-column">Total</td>
                        {all_fields.map((field) => (
                            <td style={{ "background-color": "#e9ecef" }} className="dn-column">
                                {(field.includes("GP") || field.includes("sales") || field.includes("gross") || field.includes("value")) && !field.includes("staff") && "$"}
                                {field === 'index' || field === "tech_proficiency" ?
                                    field === 'index' ? getRatio(
                                        dailyData.map(item => item.total_service_CP_hours).reduce((a, b) => (a||0) + (b||0), 0),
                                        dailyData.map(item => item.total_service_CP_ROs).reduce((a, b) => (a||0) + (b||0), 0)
                                    ) :
                                        getRatio(
                                            dailyData.map(item => item.total_service_hours_sold).reduce((a, b) => (a||0) + (b||0), 0),
                                            dailyData.map(item => item.total_service_total_hours).reduce((a, b) => (a||0) + (b||0), 0)
                                        )
                                    : dailyData.map(item => item[field] ? parseFloat(item[field]) : 0)
                                        .reduce((a, b) => (a||0) + (b||0), 0)
                                        .toLocaleString("en-CA", {
                                            minimumFractionDigits: 0,
                                            maximumFractionDigits: 0
                                        })
                                }
                            </td>
                        ))}
                    </tr>
                    {/* budget  */}
                    <tr>
                        <td style={{ "background-color": "#e9ecef" }} className="dn-column">Budget</td>
                        {all_fields.map((field) => (
                            <td style={{ "background-color": "#e9ecef" }} className="dn-column">
                                {(field.includes("GP") || field.includes("sales") || field.includes("gross") || field.includes("value")) && !field.includes("staff") && "$"}
                                {field === 'index' || field === "tech_proficiency" ?
                                    field === 'index' ? getRatio(
                                        budgetData.map(item => item.total_service_CP_hours).reduce((a, b) => (a||0) + (b||0), 0),
                                        budgetData.map(item => item.total_service_CP_ROs).reduce((a, b) => (a||0) + (b||0), 0)
                                    ) : getRatio(
                                        budgetData.map(item => item.total_service_hours_sold).reduce((a, b) => (a||0) + (b||0), 0),
                                        budgetData.map(item => item.total_service_total_hours).reduce((a, b) => (a||0) + (b||0), 0)
                                    )
                                    : budgetData.map(item => item[field] ? parseFloat(item[field]) : 0)
                                        .reduce((a, b) => (a||0) + (b||0), 0)
                                        .toLocaleString("en-CA", {
                                            minimumFractionDigits: 0,
                                            maximumFractionDigits: 0
                                        })
                                }
                            </td>
                        ))}
                    </tr>
                    {/* kpi  */}
                    <tr>
                        <td style={{ "background-color": "#e9ecef" }} className="dn-column">KPI</td>
                        {all_fields.map(field => {
                            let field_total = dailyData.map(item => item[field] ? parseFloat(item[field]) : 0).reduce((a, b) => (a||0) + (b||0), 0);
                            let field_budget = budgetData.map(item => item[field] ? parseFloat(item[field]) : 0).reduce((a, b) => (a||0) + (b||0), 0);
                            return (
                                <td style={{ "background-color": "#e9ecef" }} className="dn-column">
                                    {calculateKPIPercentage(field_total, field_budget)}
                                </td>
                            )
                        })}
                    </tr>
                </tbody>

            </table>
        </div>
    )
}


// Monthly Report
export default function MonthlyReport() {

    const divisions = ['Auto', 'Rec', 'Used', 'All'];
    const currentYear = moment().format("YYYY");
    const currentMonth = moment().format("MMM");

    const [division, setDivision] = useState('Auto');
    const [month, setMonth] = useState(currentMonth);
    const [year, setYear] = useState(currentYear);
    const [loading, setLoading] = useState(true);

    const [dailyDataForAuto, setDailyDataForAuto] = useState([]);
    const [budgetDataForAuto, setBudgetDataForAuto] = useState();

    const [dailyDataForRec, setDailyDataForRec] = useState([]);
    const [budgetDataForRec, setBudgetDataForRec] = useState();

    const [dailyDataForUsed, setDailyDataForUsed] = useState([]);
    const [budgetDataForUsed, setBudgetDataForUsed] = useState();

    const [dailyDataForAll, setDailyDataForAll] = useState([]);
    const [budgetDataForAll, setBudgetDataForAll] = useState([]);

    useEffect(() => {
        switch (division) {
            case 'Auto':
                getAutoData(year, month);
                break;
            case 'Rec':
                getRecData(year, month);
                break;
            case 'Used':
                getUsedData(year, month);
                break;
            case 'All':
                getAllData(year, month);
                break;
            default:
                getAutoData(year, month);
        }
    }, []);

    const getAutoData = (year, month) => {
        const stringifiedYear = getFiscals(month, year);
        axios.all([
            axios.post(Links.getDivisionalReport, { month: month, year: year, division: division, store_names: "" }),
            axios.post(Links.getFilteredBudgetForDivisionalReport, { month: month, fiscal_year: "FY" + stringifiedYear.toString().slice(-2), division: division, store_names: "" })
        ])
            .then(axios.spread((data1, data2) => {
                setDailyDataForAuto(data1.data.dailyDataSumUp);
                setBudgetDataForAuto(data2.data.budgetThisMonth[0]);
            }))
            .then(() => {
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const getRecData = (year, month) => {
        const stringifiedYear = getFiscals(month, year);
        axios.all([
            axios.post(Links.getDivisionalReportForRec, { month: month, year: year, division: division, store_names: "" }),
            axios.post(Links.getFilteredBudgetForDivisionalReportForRec, { month: month, fiscal_year: "FY" + stringifiedYear.toString().slice(-2), division: division, store_names: "" })
        ])
            .then(axios.spread((data1, data2) => {
                setDailyDataForRec(data1.data.dailyDataSumUp);
                setBudgetDataForRec(data2.data.budgetThisMonth[0]);
            }))
            .then(() => {
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const getUsedData = (year, month) => {
        const stringifiedYear = getFiscals(month, year);
        axios.all([
            axios.post(Links.getDivisionalReportForUsed, { month: month, year: year, division: division, store_names: "" }),
            axios.post(Links.getFilteredBudgetForDivisionalReportForUsed, { month: month, fiscal_year: "FY" + stringifiedYear.toString().slice(-2), division: division, store_names: "" })
        ])
            .then(axios.spread((data1, data2) => {
                setDailyDataForUsed(data1.data.dailyDataSumUp);
                setBudgetDataForUsed(data2.data.budgetThisMonth[0]);
            }))
            .then(() => {
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const getAllData = (year, month) => {
        const stringifiedYear = getFiscals(month, year);
        axios.all([
            axios.post(Links.getDivisionalReport, { month: month, year: year, division: division, store_names: "" }),
            axios.post(Links.getFilteredBudgetForDivisionalReport, { month: month, fiscal_year: "FY" + stringifiedYear.toString().slice(-2), division: division, store_names: "" }),
            axios.post(Links.getDivisionalReportForRec, { month: month, year: year, division: division, store_names: "" }),
            axios.post(Links.getFilteredBudgetForDivisionalReportForRec, { month: month, fiscal_year: "FY" + stringifiedYear.toString().slice(-2), division: division, store_names: "" }),
            axios.post(Links.getDivisionalReportForUsed, { month: month, year: year, division: division, store_names: "" }),
            axios.post(Links.getFilteredBudgetForDivisionalReportForUsed, { month: month, fiscal_year: "FY" + stringifiedYear.toString().slice(-2), division: division, store_names: "" })
        ])
            .then(axios.spread((data1, data2, data3, data4, data5, data6) => {
                setDailyDataForAll([...data1.data.dailyDataSumUp, ...data3.data.dailyDataSumUp, ...data5.data.dailyDataSumUp]);
                setBudgetDataForAll([data2.data.budgetThisMonth[0], data4.data.budgetThisMonth[0], data6.data.budgetThisMonth[0]]);
            }))
            .then(() => {
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleDivisionChange = (event) => {
        setDivision(event.target.value)
    }

    const handleYearChange = (event) => {
        setYear(event.target.value);
    };

    const handleMonthChange = (event) => {
        setMonth(event.target.value);
    };

    const showResults = () => {
        setLoading(true);
        switch (division) {
            case 'Auto':
                getAutoData(year, month);
                break;
            case 'Rec':
                getRecData(year, month);
                break;
            case 'Used':
                getUsedData(year, month);
                break;
            case 'All':
                getAllData(year, month);
                break;
            default:
                getAutoData(year, month);
        }
    };

    return (
        <div style={{ marginTop: "3rem", width: "100%" }}>
            <Grid container spacing={2}>
                {/* Division / Year / Month selection  */}
                <Grid item container spacing={1}>
                    <Grid item xs={10} sm={6} md={2} xl={1} spacing={1}>
                        <FormControl variant="standard" sx={{ m: 1, width: "100%" }}>
                            <InputLabel id="division-select-label">Division</InputLabel>
                            <Select
                                labelId="division-select-label"
                                id="division-select"
                                value={division}
                                label="Division"
                                onChange={handleDivisionChange}
                            >
                                {divisions.map((division, index) => (
                                    <MenuItem key={index} value={division}>
                                        {division}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={10} sm={6} md={2} xl={1} spacing={1}>
                        <FormControl variant="standard" sx={{ m: 1, width: "100%" }}>
                            <InputLabel id="demo-simple-select-label">Year</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={year}
                                label="Year"
                                onChange={handleYearChange}
                            >
                                {YEARS.map((year, index) => (
                                    <MenuItem key={index} value={year}>
                                        {year}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={10} sm={6} md={2} xl={1} spacing={1}>
                        <FormControl variant="standard" sx={{ m: 1, width: "100%" }}>
                            <InputLabel id="demo-simple-select-label">Month</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={month}
                                label="Age"
                                onChange={handleMonthChange}
                            >
                                {Object.values(MONTHS).map((month, index) => (
                                    <MenuItem key={index} value={month}>
                                        {month}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item className="mt-3">
                        <button
                            className="p-2 mt-1 btn btn-secondary mx-3"
                            type="submit"
                            onClick={() => {
                                showResults();
                            }}
                        >
                            Show Details
                        </button>
                    </Grid>
                </Grid>

                <Grid item container xs={12}>
                    <Grid item xs={10}></Grid>
                    <Grid item xs={6}>
                        {loading && (
                            <div style={{ marginTop: "3rem", right: "50%", top: "30%" }} className="text-center position-absolute" >
                                <img src={CarLoading} />
                            </div>
                        )}

                        {!loading && division == 'Auto' && (
                            <AutoTable dailyData={dailyDataForAuto} budgetData={budgetDataForAuto} />
                        )}

                        {!loading && division == 'Rec' && (
                            <RecTable dailyData={dailyDataForRec} budgetData={budgetDataForRec} />
                        )}

                        {!loading && division == 'Used' && (
                            <UsedTable dailyData={dailyDataForUsed} budgetData={budgetDataForUsed} />
                        )}

                        {!loading && division == 'All' && (
                            <AllTable dailyData={dailyDataForAll} budgetData={budgetDataForAll} />
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}
