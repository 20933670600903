import React, { useEffect, useState } from 'react'
import { calculatePercentageOfBudget } from '../../utils';
import axios from 'axios';
import Links from "../../../src/Data/Links.js";
import { MONTHS, DAYS_IN_MONTH } from "../../constants"
import CarLoading from '../../images/487.gif'
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import calculateTrend from "../../utils/calculateTrend.js";
import getFiscals from "../../utils/getFiscals.js";
import calculateTotalHolidaysbyDates from "../../utils/calculateTotalHolidaysbyDates.js";
import calculateTotalHolidaysbyMonth from "../../utils/calculateTotalHolidaysbyMonth.js";
const LeaderBoard = () => {
  const thisMonth = Object.values(MONTHS)[new Date().getMonth()];
  const thisYear = new Date().getFullYear();
  const d = new Date();
  const [day, setDay] = useState(d.getDate());
  const [days, setDays] = useState(DAYS_IN_MONTH[thisMonth]);
  const [autoStores, setAutoStores] = useState([]);
  const [autoStoresNames, setAutoStoresNames] = useState([]);
  const [year, setYear] = useState(thisYear);
  const [month, setMonth] = useState(thisMonth);
  const [rankedStores, setRankedStores] = useState([]);
  const [dailyDataThisMonth, setDailyDataThisMonth] = useState([]);
  const [monthlyDataVehicles, setMonthlyDataVehicles] = useState([]);
  const [budgetThisMonth, setBudgetThisMonth] = useState([]);
  const [dailyDataThisMonthForRec, setDailyDataThisMonthForRec] = useState([]);
  const [budgetThisMonthForRec, setBudgetThisMonthForRec] = useState([]);
  const [monthlyDataVehiclesRec, setMonthlyDataVehiclesRec] = useState([]);
  const [dailyDataThisMonthForUsed, setDailyDataThisMonthForUsed] = useState([]);
  const [budgetThisMonthForUsed, setBudgetThisMonthForUsed] = useState([]);
  const [monthlyDataVehiclesUsed, setMonthlyDataVehiclesUsed] = useState([]);
  const [loading, setLoading] = useState(true);
  const [getnoOfHolidaysbyDate, setGetnoOfHolidaysbyDate] = useState(calculateTotalHolidaysbyDates(thisMonth, thisYear));
  const [getnoOfHolidaysbyMonth, setGetnoOfHolidaysbyMonth] = useState(calculateTotalHolidaysbyMonth(thisMonth, thisYear));
  let autoRankBadge = 0;
  let recRankBadge = 0;
  let usedRankBadge = 0;


  useEffect(() => {
    getAutoStores()
  }, []);

  useEffect(() => {
    getDailyData(year, month);
    getDealerBudgetData(year, month);
    getDailyDataForUsed(year, month);
    getDealerBudgetDataForUsed(year, month);
    getDailyDataForRec(year, month);
    getDealerBudgetDataForRec(year, month);
  }, [autoStoresNames]);

  useEffect(() => {
    rankStores();
  }, [dailyDataThisMonth, dailyDataThisMonthForRec, dailyDataThisMonthForUsed, budgetThisMonth, budgetThisMonthForRec, budgetThisMonthForUsed]);

  const getDailyData = (year, month) => {
    if (autoStoresNames.length > 0) {
      const requestBody = {
        year: year,
        month: month,
        store_names: autoStoresNames,
        division: 'auto'
      }
      axios.post(Links.getDivisionalReport, requestBody)
        .then(result => {

          setMonthlyDataVehicles(result?.data?.dailyDataThisMonthDealerComparison);
          setDailyDataThisMonth(result?.data?.totalGrossProfit);
        })
        .catch(err => {
          console.log(err);
        });
    }
  };

  const getDailyDataForUsed = (year, month) => {
    if (autoStoresNames.length > 0) {
      const requestBody = {
        year: year,
        month: month,
        store_names: autoStoresNames,
        division: 'used'
      }
      axios.post(Links.getDivisionalReportForUsed, requestBody)
        .then(result => {
          setMonthlyDataVehiclesUsed(result?.data?.dailyDataThisMonthDealerComparison)
          setDailyDataThisMonthForUsed(result?.data?.totalGrossProfit);
        })
        .catch(err => {
          console.log(err);
        });
    }
  };

  const getDailyDataForRec = (year, month) => {
    if (autoStoresNames.length > 0) {
      const requestBody = {
        year: year,
        month: month,
        store_names: autoStoresNames,
        division: 'rec'
      }
      axios.post(Links.getDivisionalReportForRec, requestBody)
        .then(result => {

          setMonthlyDataVehiclesRec(result?.data?.dailyDataThisMonthDealerComparison);
          setDailyDataThisMonthForRec(result?.data?.totalGrossProfit);
        })
        .catch(err => {
          console.log(err);
        });
    }
  };

  const getDealerBudgetData = (year, month) => {
    if (autoStoresNames.length > 0) {
      const stringifiedYear = year.toString().slice(-2)
      const requestBody = {
        fiscal_year: 'FY' + getFiscals(month,year).toString().substring(2),
        month: month,
        store_names: autoStoresNames,
        division: 'auto'
      }
      axios.post(Links.getFilteredBudgetForDivisionalReport, requestBody)
        .then(result => {
          setBudgetThisMonth(result?.data?.totalGrossProfit);
        }).catch(err => {
          console.log(err);
        });
    }
  };

  const getDealerBudgetDataForUsed = (year, month) => {
    if (autoStoresNames.length > 0) {
      const stringifiedYear = year.toString().slice(-2)
      const requestBody = {
        fiscal_year: 'FY' + getFiscals(month,year).toString().substring(2),
        month: month,
        store_names: autoStoresNames,
        division: 'used'
      }
      axios.post(Links.getFilteredBudgetForDivisionalReportForUsed, requestBody)
        .then(result => {
          setBudgetThisMonthForUsed(result?.data?.totalGrossProfit);
        }).catch(err => {
          console.log(err);
        });
    }
  };

  const getDealerBudgetDataForRec = (year, month) => {
    if (autoStoresNames.length > 0) {
      const stringifiedYear = year.toString().slice(-2)
      const requestBody = {
        fiscal_year: 'FY' + getFiscals(month,year).toString().substring(2),
        month: month,
        store_names: autoStoresNames,
        division: 'rec'
      }

      axios.post(Links.getFilteredBudgetForDivisionalReportForRec, requestBody)
        .then(result => {

          setBudgetThisMonthForRec(result?.data?.totalGrossProfit);
        }).catch(err => {
          console.log(err);
        });
    }
  };

  const getAutoStores = () => {
    const autoStores = [];
    const autoStoresNames = [];
    const stores = JSON.parse(localStorage.stores);
    axios.get(Links.getAllStores)
      .then(response => {
        if (response) {
          response?.data?.stores.forEach(store => {
            if (store?.division !== 'Village' && store?.division !== 'Carooga' && store?.division !== 'FFUN Financial') {
              autoStores.push(store);
              autoStoresNames.push(store?.name);
            }
          })
        }
        setAutoStores(autoStores);
        setAutoStoresNames(autoStoresNames);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const rankStores = () => {
    const allStores = [];
    if (dailyDataThisMonth && budgetThisMonth) {
      autoStores.forEach((store) => {
        const dailyDataforCurrentStore = dailyDataThisMonth.find(key => store.id === key.store_id);
        const budgetforCurrentStore = budgetThisMonth.find(key => store.id === key.store_id);
        const dailyDataforCurrentStoreForRec = dailyDataThisMonthForRec.find(key => store.id === key.store_id);
        const budgetforCurrentStoreForRec = budgetThisMonthForRec.find(key => store.id === key.store_id);
        const dailyDataforCurrentStoreForUsed = dailyDataThisMonthForUsed.find(key => store.id === key.store_id);
        const budgetforCurrentStoreForUsed = budgetThisMonthForUsed.find(key => store.id === key.store_id);
        const soldDataforCurrentStore = monthlyDataVehicles.find(key => store.id === key.store_id);
        const soldDataforCurrentStoreRec = monthlyDataVehiclesRec.find(key => store.id === key.store_id);
        const soldDataforCurrentStoreUsed = monthlyDataVehiclesUsed.find(key => store.id === key.store_id);
        let percentageOfBudget = 0;
        const division = store?.division;
        let totalGrossProfit = 0;
        let totalGrossBudget = 0;
        let newSoldUnits = 0;
        let usedSoldUnits = 0;


        switch (division) {

          case 'Rec':
            if (dailyDataforCurrentStoreForRec) {
              percentageOfBudget = calculatePercentageOfBudget(dailyDataforCurrentStoreForRec?.total_gross_profit || 0, budgetforCurrentStoreForRec?.total_gross_profit || 0);
              totalGrossProfit = dailyDataforCurrentStoreForRec?.total_gross_profit;
              newSoldUnits = soldDataforCurrentStoreRec?.total_new_delivered  + soldDataforCurrentStoreRec?.total_trailers_delivered  + soldDataforCurrentStoreRec?.total_boats_delivered ;
              usedSoldUnits = soldDataforCurrentStoreRec?.total_used_delivered ;
            }
            if (budgetforCurrentStoreForRec) {
              totalGrossBudget = budgetforCurrentStoreForRec.total_gross_profit;

            }
            break;
          case 'FFUN Cars':
            if (dailyDataforCurrentStoreForUsed && budgetforCurrentStoreForUsed) {
              percentageOfBudget = calculatePercentageOfBudget(dailyDataforCurrentStoreForUsed?.total_gross_profit || 0, budgetforCurrentStoreForUsed?.total_gross_profit || 0);
              totalGrossProfit = dailyDataforCurrentStoreForUsed?.total_gross_profit;

              newSoldUnits = 0;
              usedSoldUnits = soldDataforCurrentStoreUsed?.total_used_delivered;

            }
            if (budgetforCurrentStoreForUsed) {
              totalGrossBudget = budgetforCurrentStoreForUsed?.total_gross_profit;

            }
            break;
          case 'DriveNation':
            if (dailyDataforCurrentStoreForUsed && budgetforCurrentStoreForUsed) {
              percentageOfBudget = calculatePercentageOfBudget(dailyDataforCurrentStoreForUsed?.total_gross_profit || 0, budgetforCurrentStoreForUsed?.total_gross_profit || 0);
              totalGrossProfit = dailyDataforCurrentStoreForUsed?.total_gross_profit;

              newSoldUnits = 0;
              usedSoldUnits = soldDataforCurrentStoreUsed?.total_used_delivered;
            }
            if (budgetforCurrentStoreForUsed) {
              totalGrossBudget = budgetforCurrentStoreForUsed?.total_gross_profit;

            }
            break;
          case 'Auto':
            if (dailyDataforCurrentStore && budgetforCurrentStore) {
              percentageOfBudget = calculatePercentageOfBudget(dailyDataforCurrentStore?.total_gross_profit || 0, budgetforCurrentStore?.total_gross_profit || 0);
              totalGrossProfit = dailyDataforCurrentStore?.total_gross_profit;

              newSoldUnits = soldDataforCurrentStore?.total_new_delivered;
              usedSoldUnits = soldDataforCurrentStore?.total_used_delivered;
            }
            if(dailyDataforCurrentStore){
              totalGrossProfit = dailyDataforCurrentStore?.total_gross_profit;
            }
            if (budgetforCurrentStore) {
              totalGrossBudget = budgetforCurrentStore?.total_gross_profit;

            }
            break;
          default:
            if (dailyDataforCurrentStore && budgetforCurrentStore) {
              percentageOfBudget = calculatePercentageOfBudget(dailyDataforCurrentStore?.total_gross_profit || 0, budgetforCurrentStore?.total_gross_profit || 0);
              totalGrossProfit = dailyDataforCurrentStore?.total_gross_profit;

              newSoldUnits = soldDataforCurrentStore?.total_new_delivered;
              usedSoldUnits = soldDataforCurrentStore?.total_used_delivered;
            }
            if (budgetforCurrentStore) {
              totalGrossBudget = budgetforCurrentStore?.total_gross_profit;

            }
            break;
        }
        allStores.push({
          id: store?.id,
          name: store?.name,
          division: store.division,
          totalGrossProfit: totalGrossProfit,
          totalGrossBudget: totalGrossBudget,
          newSoldUnits: newSoldUnits,
          usedSoldUnits: usedSoldUnits,
          percentageOfBudget: percentageOfBudget
        })
      })
    };
    const rankedStores = allStores.sort((a, b) => (parseInt(a.percentageOfBudget) < parseInt(b.percentageOfBudget)) ? 1 : (parseInt(b.percentageOfBudget) < parseInt(a.percentageOfBudget) ? -1 : 0));
    setRankedStores(rankedStores);
    setLoading(false)
  };

  if (loading) {
    return (
      <div style={{ marginTop: "6rem", right: '50%', top: '10%' }} className="text-center position-absolute">
        <img src={CarLoading} />
      </div>
    )
  } else {
    return (
      <table className="table table-bordered border-black table-fit table-striped" style={{ marginTop: 20 }}>
        <thead>
          <tr className="text-center">
            <th colSpan="1" className="dn-header-top" style={{ whiteSpace: 'nowrap', backgroundColor: 'white' }}>Rank</th>
            <th colSpan="1" className="dn-header-top" style={{ whiteSpace: 'nowrap', backgroundColor: 'white' }}>Division</th>
            <th colSpan="1" className="dn-header-top" style={{ whiteSpace: 'nowrap', backgroundColor: 'white' }}>Store</th>
            <th colSpan="1" className="dn-header-top" style={{ whiteSpace: 'nowrap', backgroundColor: 'white' }}>Total Sold Units - New</th>
            <th colSpan="1" className="dn-header-top" style={{ whiteSpace: 'nowrap', backgroundColor: 'white' }}>Sold Units - Used</th>
            <th colSpan="1" className="dn-header-top" style={{ whiteSpace: 'nowrap', backgroundColor: 'white' }}>Total GP - Budgeted</th>
            <th colSpan="1" className="dn-header-top" style={{ whiteSpace: 'nowrap', backgroundColor: 'white' }}>Total GP - Achieved</th>
            <th colSpan="1" className="dn-header-top" style={{ whiteSpace: 'nowrap', backgroundColor: 'white' }}>KPI</th>
            <th colSpan="1" className="dn-header-top" style={{ whiteSpace: 'nowrap', backgroundColor: 'white' }}> Trend&#x2191;&#x2193;</th>
          </tr>
        </thead>
        <tbody>
          {rankedStores && rankedStores.map((store, index) => {
            const { id, name, percentageOfBudget, division, totalGrossProfit, totalGrossBudget, newSoldUnits, usedSoldUnits } = store
            const rank = index + 1
            let showRankBadge = false;
            let badgeColor = "#ffffff"

            if (division === "Auto" && autoRankBadge < 3) {
              showRankBadge = true;
              autoRankBadge = autoRankBadge + 1;
              if (autoRankBadge === 1) {
                badgeColor = "#fabf00";
              }
              else if (autoRankBadge === 2) {
                badgeColor = "#C0C0C0";
              }
              else if (autoRankBadge === 3) {
                badgeColor = "#B08D57";
              }

            }

            if (division === "Rec" && recRankBadge < 3) {
              showRankBadge = true;
              recRankBadge = recRankBadge + 1;
              if (recRankBadge === 1) {
                badgeColor = "#fabf00";
              }
              else if (recRankBadge === 2) {
                badgeColor = "#C0C0C0";
              }
              else if (recRankBadge === 3) {
                badgeColor = "#B08D57";
              }


            }

            if ((division === "DriveNation" || division === "FFUN Cars") && usedRankBadge < 3) {
              showRankBadge = true;
              usedRankBadge = usedRankBadge + 1;
              if (usedRankBadge === 1) {
                badgeColor = "#fabf00";
              }
              else if (usedRankBadge === 2) {
                badgeColor = "#C0C0C0";
              }
              else if (usedRankBadge === 3) {
                badgeColor = "#B08D57";
              }

            }
            const trend = calculateTrend(totalGrossProfit || 0, totalGrossBudget || 0, days, getnoOfHolidaysbyDate, getnoOfHolidaysbyMonth, day);
            return <tr key={id}>
              <td style={{ backgroundColor: 'white', textAlign: 'center', backgroundColor: badgeColor }}>
                {showRankBadge === true && <MilitaryTechIcon style={{ color: "#ffffff" }} />}{rank}
              </td>
              <td style={{ backgroundColor: badgeColor, textAlign: 'center' }}>
                {division}
              </td>
              <td style={{ backgroundColor: badgeColor, textAlign: 'center' }}>
                {name}
              </td>
              <td style={{ backgroundColor: badgeColor, textAlign: 'center' }}>
                {newSoldUnits}
              </td>
              <td style={{ backgroundColor: badgeColor, textAlign: 'center' }}>
                {usedSoldUnits}
              </td>
              <td style={{ backgroundColor: badgeColor, textAlign: 'center' }}>
                ${parseInt(totalGrossBudget)?.toLocaleString()}
              </td>
              <td style={{ backgroundColor: badgeColor, textAlign: 'center' }}>
                ${parseInt(totalGrossProfit)?.toLocaleString()}
              </td>
              <td style={{ position: 'relative', textAlign: 'center' }}>
                <div className="progress" style={{ height: "100%", width: '100%', position: 'absolute', right: '0%', top: '0%', zIndex: '-1', borderRadius: '0px' }}>
                  <div className="progress-bar" role="progressbar" style={{ backgroundColor: '#7CEF7C ', width: percentageOfBudget }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                {percentageOfBudget}
              </td>

              <td style={{ backgroundColor: badgeColor, textAlign: 'center' }}>

                {trend}%{trend !== 0 ?
                  Math.sign(trend) === -1 ? <span style={{ color: "#dc3545", fontSize: 18 }}>&#x2193;</span>
                    : <span style={{ color: "#35dc66", fontSize: 18 }}>&#x2191;</span>
                  : ""}
              </td>
            </tr>
          })}
        </tbody>
      </table>
    )
  }
};

export default LeaderBoard