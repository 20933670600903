import React from "react";
import { Pie } from 'react-chartjs-2';

const PieChart = (props) => {
  const { title, data, options } = props;
  if(title && data && options) {
    return (
      <>
        <h3 className="text-center">{title}</h3>
        <Pie
          options={options}
          data={data}
        />
      </>
    )
  } else {
    return (
      <>
      </>
    )
  }
};

export default PieChart;