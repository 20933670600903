import React, { useEffect, useState } from "react";
import { Grid, InputLabel, MenuItem, FormControl, Select } from '@mui/material';
import moment from 'moment';
import axios from 'axios';
import Links from "../../../src/Data/Links.js";
import { getFiscals, calculateKPIPercentage } from "../../utils";
import { MONTHS, YEARS } from "../../../src/constants.js"

const FFUNFinancialReports = () => {
    const currentYear = moment().format('YYYY');
    const currentMonth = moment().format('MMM');
    const [month, setMonth] = useState(currentMonth);
    const [year, setYear] = useState(currentYear);
    const [creditMaxxData, setCreditMaxxData] = useState({});
    const [creditMaxxBudget, setCreditMaxxBudget] = useState({});
    const [oneEarthData, setOneEarthData] = useState({});
    const [oneEarthBudget, setOneEarthBudget] = useState({});

    useEffect(() => {
        loadCreditMaxxData();
        loadOneEarthData();
    }, [month, year])

    const loadCreditMaxxData = () => {
        let filterVal = {
            store_id: 47,
            store_name: 'CreditMaxx',
            division: "FFUN Financial",
            company: 'FFUN',
            month: month,
            year: year,
            fiscal_year: 'FY' + getFiscals(month, year).toString().substring(2)
        }
        axios.post(Links.getFilteredFfunFinancialData, filterVal)
            .then(res => {
                setCreditMaxxData(res.data.dailydata);
            }).catch(err => {
                console.log(err);
            });

        axios.post(Links.getFfunFinancialBudgetForDailydata, filterVal)
            .then(result => {
                setCreditMaxxBudget(result.data.budget);
            }).catch(err => {
                console.log(err);
            });

    }

    const loadOneEarthData = () => {
        let filterVal = {
            store_id: 48,
            store_name: 'One Earth',
            division: "FFUN Financial",
            company: 'FFUN',
            month: month,
            year: year,
            fiscal_year: 'FY' + getFiscals(month, year).toString().substring(2)
        }
        axios.post(Links.getFilteredFfunFinancialData, filterVal)
            .then(res => {
                setOneEarthData(res.data.dailydata);
            }).catch(err => {
                console.log(err);
            });

        axios.post(Links.getFfunFinancialBudgetForDailydata, filterVal)
            .then(result => {
                setOneEarthBudget(result.data.budget);
            }).catch(err => {
                console.log(err);
            });

    }

    const handleYearChange = (event) => {
        setYear(event.target.value);
    };

    const handleMonthChange = (event) => {
        setMonth(event.target.value);
    };

    const showResults = () => {
        loadCreditMaxxData();
        loadOneEarthData();
    };


    const CreditMaxxTable = (props) => {
        const fields = ["apps", "approvals", "tier_1", "tier_2", "tier_3", "tier_4", "deals", "deal_gp"];
        return (
            <table className="table table-bordered border-black table-fit" style={{ marginTop: 20 }}>
                <thead>
                    <tr>
                        <th className="dn-header" scope="col" ></th>
                        <th className="dn-header" scope="col" >Total</th>
                        <th className="dn-header" scope="col" >Budget</th>
                        <th className="dn-header" scope="col" >% Achieved</th>
                    </tr>
                </thead>

                <tbody>
                    {fields.map((field) => {
                        let field_total = Object.values(creditMaxxData).map(item => item[field] ? parseInt(item[field]) : 0).reduce((a, b) => a + b, 0);
                        let field_budget = creditMaxxBudget && creditMaxxBudget[field] ? parseInt(creditMaxxBudget[field]) : 0;
                        return (
                            <tr className="text-center">
                                <th className="dn-header text-capitalize" style={{ "backgroundColor": "orange" }}>
                                    {field == "deal_gp" ? "Deal GP" : field.replace("_", " ")}
                                </th>
                                <td>
                                    {field == "deal_gp" && "$"}{field_total.toLocaleString()}
                                </td>
                                <td>
                                    {field == "deal_gp" && "$"}{field_budget.toLocaleString()}
                                </td>
                                <td>
                                    {calculateKPIPercentage(field_total, field_budget)}
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        )
    }

    const OneEarthTable = (props) => {

        const calcTotal = (field) => {
            return Object.values(oneEarthData).map(item => item[field] ? parseInt(item[field]) : 0).reduce((a, b) => a + b, 0);
        }

        const calcTotalBudget = (field) => {
            return oneEarthBudget && oneEarthBudget[field] ? parseInt(oneEarthBudget[field]) : 0
        }

        const calcKPI = (field) => {
            return calculateKPIPercentage(calcTotal(field), calcTotalBudget(field))
        }


        let totalAdv = {
            total: calcTotal('new_auto_ffun_adv') + calcTotal('used_auto_ffun_adv') + calcTotal('rec_ffun_adv'),
            budget: calcTotalBudget('new_auto_ffun_adv') + calcTotalBudget('used_auto_ffun_adv') + calcTotalBudget('rec_ffun_adv')
        };
        let totalWarranty = {
            total: calcTotal('used_auto_warranty') + calcTotal('rec_warranty'),
            budget: calcTotalBudget('used_auto_warranty') + calcTotalBudget('rec_warranty')
        };
        let totalGap = {
            total: calcTotal('new_auto_gap') + calcTotal('used_auto_gap') + calcTotal('rec_gap'),
            budget: calcTotalBudget('new_auto_gap') + calcTotalBudget('used_auto_gap') + calcTotalBudget('rec_gap')
        };
        let totalCreditor = {
            total: calcTotal('new_auto_creditor') + calcTotal('used_auto_creditor') + calcTotal('rec_creditor'),
            budget: calcTotalBudget('new_auto_creditor') + calcTotalBudget('used_auto_creditor') + calcTotalBudget('rec_creditor')
        };

        return (
            <table className="table table-bordered border-black table-fit" style={{ marginTop: 20 }}>
                <thead>
                    <tr>
                        <th colSpan="2" className="dn-header" scope="col"></th>
                        <th className="dn-header" scope="col">Total</th>
                        <th className="dn-header" scope="col">Budget</th>
                        <th className="dn-header" scope="col">% Achieved</th>
                    </tr>
                </thead>
                <tbody className="text-center">
                    {/* new auto  */}
                    <tr>
                        <th rowSpan={3} className="dn-header text-capitalize" style={{ "backgroundColor": "orange" }}>New Auto</th>
                        <td className="dn-header text-capitalize" style={{ "backgroundColor": "orange" }}>OEPP</td>
                        <td>{calcTotal('new_auto_ffun_adv')}</td>
                        <td>{calcTotalBudget('new_auto_ffun_adv')}</td>
                        <td>{calcKPI(('new_auto_ffun_adv'))}</td>
                    </tr>
                    <tr>
                        <td className="dn-header text-capitalize" style={{ "backgroundColor": "orange" }}>GAP</td>
                        <td>{calcTotal('new_auto_gap')}</td>
                        <td>{calcTotalBudget('new_auto_gap')}</td>
                        <td>{calcKPI(('new_auto_gap'))}</td>
                    </tr>
                    <tr>
                        <td className="dn-header text-capitalize" style={{ "backgroundColor": "orange" }}>Creditor</td>
                        <td>{calcTotal('new_auto_creditor')}</td>
                        <td>{calcTotalBudget('new_auto_creditor')}</td>
                        <td>{calcKPI(('new_auto_creditor'))}</td>
                    </tr>
                    {/* used auto  */}
                    <tr>
                        <th rowSpan={4} className="dn-header text-capitalize" style={{ "backgroundColor": "#A2C1A2" }}>Used Auto</th>
                        <td className="dn-header text-capitalize" style={{ "backgroundColor": "#A2C1A2" }}>OEPP</td>
                        <td>{calcTotal('used_auto_ffun_adv')}</td>
                        <td>{calcTotalBudget('used_auto_ffun_adv')}</td>
                        <td>{calcKPI(('used_auto_ffun_adv'))}</td>
                    </tr>
                    <tr>
                        <td className="dn-header text-capitalize" style={{ "backgroundColor": "#A2C1A2" }}>Warranty</td>
                        <td>{calcTotal('used_auto_warranty')}</td>
                        <td>{calcTotalBudget('used_auto_warranty')}</td>
                        <td>{calcKPI(('used_auto_warranty'))}</td>
                    </tr>
                    <tr>
                        <td className="dn-header text-capitalize" style={{ "backgroundColor": "#A2C1A2" }}>GAP</td>
                        <td>{calcTotal('used_auto_gap')}</td>
                        <td>{calcTotalBudget('used_auto_gap')}</td>
                        <td>{calcKPI(('used_auto_gap'))}</td>
                    </tr>
                    <tr>
                        <td className="dn-header text-capitalize" style={{ "backgroundColor": "#A2C1A2" }}>Creditor</td>
                        <td>{calcTotal('used_auto_creditor')}</td>
                        <td>{calcTotalBudget('used_auto_creditor')}</td>
                        <td>{calcKPI(('used_auto_creditor'))}</td>
                    </tr>
                    {/* rec  */}
                    <tr>
                        <th rowSpan={4} className="dn-header text-capitalize" style={{ "backgroundColor": "#B1D5DD" }}>Rec</th>
                        <td className="dn-header text-capitalize" style={{ "backgroundColor": "#B1D5DD" }}>OEPP</td>
                        <td>{calcTotal('rec_ffun_adv')}</td>
                        <td>{calcTotalBudget('rec_ffun_adv')}</td>
                        <td>{calcKPI(('rec_ffun_adv'))}</td>
                    </tr>
                    <tr>
                        <td className="dn-header text-capitalize" style={{ "backgroundColor": "#B1D5DD" }}>Warranty</td>
                        <td>{calcTotal('rec_warranty')}</td>
                        <td>{calcTotalBudget('rec_warranty')}</td>
                        <td>{calcKPI(('rec_warranty'))}</td>
                    </tr>
                    <tr>
                        <td className="dn-header text-capitalize" style={{ "backgroundColor": "#B1D5DD" }}>GAP</td>
                        <td>{calcTotal('rec_gap')}</td>
                        <td>{calcTotalBudget('rec_gap')}</td>
                        <td>{calcKPI(('rec_gap'))}</td>
                    </tr>
                    <tr>
                        <td className="dn-header text-capitalize" style={{ "backgroundColor": "#B1D5DD" }}>Creditor</td>
                        <td>{calcTotal('rec_creditor')}</td>
                        <td>{calcTotalBudget('rec_creditor')}</td>
                        <td>{calcKPI(('rec_creditor'))}</td>
                    </tr>
                    {/* TOTALS  */}
                    <tr>
                        <th rowSpan={4} className="dn-header text-capitalize" style={{ "backgroundColor": "#ccc" }}>Total</th>
                        <td className="dn-header text-capitalize" style={{ "backgroundColor": "#ccc" }}>OEPP</td>
                        <td>{totalAdv.total ? totalAdv.total : 0}</td>
                        <td>{totalAdv.budget ? totalAdv.budget : 0}</td>
                        <td>{totalAdv.total && totalAdv.budget ? calculateKPIPercentage(totalAdv.total, totalAdv.budget) : 0}</td>
                    </tr>
                    <tr>
                        <td className="dn-header text-capitalize" style={{ "backgroundColor": "#ccc" }}>Warranty</td>
                        <td>{totalWarranty.total ? totalWarranty.total : 0}</td>
                        <td>{totalWarranty.budget ? totalWarranty.budget : 0}</td>
                        <td>{totalWarranty.total && totalWarranty.budget ? calculateKPIPercentage(totalWarranty.total, totalWarranty.budget) : 0}</td>
                    </tr>
                    <tr>
                        <td className="dn-header text-capitalize" style={{ "backgroundColor": "#ccc" }}>GAP</td>
                        <td>{totalGap.total ? totalGap.total : 0}</td>
                        <td>{totalGap.budget ? totalGap.budget : 0}</td>
                        <td>{totalGap.total && totalGap.budget ? calculateKPIPercentage(totalGap.total, totalGap.budget) : 0}</td>
                    </tr>
                    <tr>
                        <td className="dn-header text-capitalize" style={{ "backgroundColor": "#ccc" }}>Creditor</td>
                        <td>{totalCreditor.total ? totalCreditor.total : 0}</td>
                        <td>{totalCreditor.budget ? totalCreditor.budget : 0}</td>
                        <td>{totalCreditor.total && totalCreditor.budget ? calculateKPIPercentage(totalCreditor.total, totalCreditor.budget) : 0}</td>
                    </tr>
                </tbody>
            </table>
        )
    }

    return (
        <div style={{  marginTop: "3rem" }}>
            <Grid container spacing={2}>
                {/* Year/month selector  */}
                <Grid item  container spacing={1}>
                    <Grid item  xs={10} sm={6} md={2} xl={1}  spacing={1}>
                        <FormControl variant="standard" sx={{ m: 1, width: "100%" }}>
                            <InputLabel id="demo-simple-select-label">Year</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={year}
                                label="Year"
                                onChange={handleYearChange}
                            >
                                {YEARS.map((year, index) => (
                                    <MenuItem key={index} value={year}>{year}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item  xs={10} sm={6} md={2} xl={1}  spacing={1}>
                        <FormControl variant="standard" sx={{ m: 1, width: "100%" }}>
                            <InputLabel id="demo-simple-select-label">Month</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={month}
                                label="Age"
                                onChange={handleMonthChange}
                            >
                                {Object.values(MONTHS).map((month, index) => (
                                    <MenuItem key={index} value={month}>{month}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item  className="mt-3">
                        <button className="p-2 mt-1 btn btn-secondary mx-3" type="submit"
                            onClick={() => {
                                showResults()
                            }}
                        >Show Details</button>
                    </Grid>
                </Grid>

                {/* tables  */}
                <Grid item container>
                    <Grid item >
                        <Grid item style={{ width: "auto", borderRadius: 4, padding: 20, marginTop: 50, border: '0px solid #adadad', boxShadow: '0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%)' }}>
                            <h4>CreditMaxx</h4>
                            <CreditMaxxTable />
                        </Grid>
                        <Grid item style={{ width: "auto", borderRadius: 4, padding: 20, marginTop: 50, border: '0px solid #adadad', boxShadow: '0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%)' }}>
                            <h4>One Earth</h4>
                            <OneEarthTable />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
};

export default FFUNFinancialReports