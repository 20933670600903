import React, { useState, useEffect } from "react";
import axios from 'axios';
import Cookies from 'js-cookie';
import Links from "../../Data/Links";
import CreateRowForUsed from "../../component/DataEntry/CreateRowForUsed";
import monthData from "../../constants/monthData";
import dayData from "../../constants/dayData";
import TotalRowForUsed from "../../component/DataEntry/TotalRowForUsed";
import ForecastRowForUsed from "../../component/DataEntry/ForecastRowForUsed";
import KPIRowForUsed from "../../component/DataEntry/KPIRowForUsed";
import THForUsed from "../../component/DataEntry/THForUsed";
import DealerYearMonthSelector from "../../component/DataEntry/DealerYearMonthSelector";
import { connect } from 'react-redux';
import { store, updateDataEntryRow } from "../../redux";
import getFiscals from '../../utils/getFiscals';

const DataEntryCarooga = ({ props, editDay }) => {

  const [stores, setStores] = useState(localStorage.getItem('stores') ? JSON.parse(localStorage.getItem('stores')) : "");
  const [cargData, setCargData] = useState({});
  const [budget, setBudget] = useState({});
  const [filterVal, setFilterVal] = useState({
    store_id: 37,
    store_name: 'BC',
    division: "Carooga",
    company: 'FFUN',
    month: monthData[new Date().getMonth()],
    fiscal_year: 'FY' + getFiscals(monthData[new Date().getMonth()],new Date().getFullYear()).toString().substring(2),
    year: new Date().getFullYear()
  });

  let userData;
  if (Cookies.get('user_data')) {
    userData = JSON.parse(Cookies.get('user_data'));
  }

  useEffect(() => {
    let storeObj = {};
    stores.length > 0 && stores?.map(i => {
      if (i.division === "Carooga") {
        storeObj[i.id] = i.name;
      }
    });
    setStores(storeObj);
    window.scrollTo(0, 0)
    setFilterVal({
      store_id: Object.keys(storeObj)[0],
      store_name: Object.values(storeObj)[0],
      division: "Carooga",
      company: 'FFUN',
      month: monthData[new Date().getMonth()],
      year: new Date().getFullYear(),
      fiscal_year: 'FY' + getFiscals(monthData[new Date().getMonth()],new Date().getFullYear()).toString().substring(2),
    })
  }, []);

  useEffect(() => {
    loadData();
  }, [filterVal])

  const loadData = (filterData = null, day) => {
    filterVal.fiscal_year = 'FY' + getFiscals(filterVal.month,filterVal.year).toString().substring(2)
    axios.post(Links.getFilteredUsedData, filterVal)
      .then(res => {
        setCargData(res.data.dailydata);
        if (parseInt(day) > 0) {
          store.dispatch(updateDataEntryRow({ ...filterVal, ...res.data.dailydata[day], 'day': day }));
        }
        axios.post(Links.getBudgetForUsedDailydata, filterVal)
          .then(result => {
            setBudget(result.data.budget);
          }).catch(err => {
            console.log(err);
          });
      }).catch(err => {
        console.log(err);
      });
  }
  let total = {
    used_retail_delivered: 0,
    used_retail_front_GP: 0,
    used_retail_back_GP: 0,
    service_total_hours: 0,
    service_CP_hours: 0,
    service_CP_ROs: 0,
    service_total_GP: 0,
    appt_made: 0,
    appt_show: 0,
    store_visit: 0,
    lead: 0
  };
  return (
    <div style={{ margin: "2rem 2rem 2rem 0rem" }}>
      <DealerYearMonthSelector stores={stores} filterVal={filterVal} setFilterVal={setFilterVal} loadData={() => loadData()} />
      <table class="table table-bordered border-black" style={{ marginTop: 30 }}>
        <THForUsed />
        <tbody>
          {
            cargData && dayData.map((i, n) => {
              total.used_retail_delivered = (cargData[i] && cargData[i].used_retail_delivered !== '') ? total.used_retail_delivered + parseInt(cargData[i].used_retail_delivered) : total.used_retail_delivered;
              total.used_retail_front_GP = (cargData[i] && cargData[i].used_retail_front_GP !== '') ? total.used_retail_front_GP + parseInt(cargData[i].used_retail_front_GP) : total.used_retail_front_GP;
              total.used_retail_back_GP = (cargData[i] && cargData[i].used_retail_back_GP !== '') ? total.used_retail_back_GP + parseInt(cargData[i].used_retail_back_GP) : total.used_retail_back_GP;
              total.service_total_hours = (cargData[i] && cargData[i].service_total_hours !== '') ? total.service_total_hours + parseInt(cargData[i].service_total_hours) : total.service_total_hours;
              total.service_CP_hours = (cargData[i] && cargData[i].service_CP_hours !== '') ? total.service_CP_hours + parseInt(cargData[i].service_CP_hours) : total.service_CP_hours;
              total.service_CP_ROs = (cargData[i] && cargData[i].service_CP_ROs !== '') ? total.service_CP_ROs + parseInt(cargData[i].service_CP_ROs) : total.service_CP_ROs;
              total.service_total_GP = (cargData[i] && cargData[i].service_total_GP !== '') ? total.service_total_GP + parseInt(cargData[i].service_total_GP) : total.service_total_GP;
              total.appt_made = (cargData[i] && cargData[i].appt_made !== '') ? total.appt_made + parseInt(cargData[i].appt_made) : total.appt_made;
              total.appt_show = (cargData[i] && cargData[i].appt_show !== '') ? total.appt_show + parseInt(cargData[i].appt_show) : total.appt_show;
              total.store_visit = (cargData[i] && cargData[i].store_visit !== '') ? total.store_visit + parseInt(cargData[i].store_visit) : total.store_visit;
              total.lead = (cargData[i] && cargData[i].lead !== '') ? total.lead + parseInt(cargData[i].lead) : total.lead;
              return <CreateRowForUsed row={cargData} userData={userData} day={i} filterData={filterVal} loadData={loadData} editDay={editDay} />
            })
          }
          <TotalRowForUsed total={total} />
          <ForecastRowForUsed budget={budget} />
          <KPIRowForUsed total={total} budget={budget} />
        </tbody>
      </table>
    </div>
  );
};
const mapStateToProps = state => ({
  dataEntry: state.dataEntryBudget.dataEntry,
  editDay: state.currentEditDayUsed.currentEditDayUsed
  // totalOfRecdata: state.dataEntryBudget.total
});
export default connect(mapStateToProps)(DataEntryCarooga);
