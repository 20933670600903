import React from 'react'
import { Line } from 'react-chartjs-2';
import { MONTHS, YEARS } from "../../constants"
import getFiscals from '../../utils/getFiscals';
const LineChart = (props) => {
  const { mtdCharts, ytdCharts, title, year, month, labels } = props;
  const options = {
    scales: {
      y: {
        title: {
          display: true,
          text: 'Total Sold units'
        }
      },
      x: {
        title: {
          display: true,
          text: title.includes('Year') ? 'Month' : 'Day'
        }
      }
    },
  }
  const usedUnitsSold = []
  const newUnitsSold = []
  const usedApptSold = []
  const newApptSold = []
  const yearMonths = Object.values(MONTHS)
  const ytdMonths = yearMonths.slice(0, yearMonths.indexOf(month) + 1);
  if(title.includes('Year')) {
    ytdMonths.map((month, index) => {
      if(ytdCharts) {
        const valueForThisMonth = props.ytdCharts.find(key => key.month === month);
        if(valueForThisMonth) {
          usedUnitsSold.push(valueForThisMonth.total_used_sold)
          newUnitsSold.push(valueForThisMonth.total_new_sold)
          usedApptSold.push(valueForThisMonth.total_used_appt)
          newApptSold.push(valueForThisMonth.total_new_appt)
        } else {
          usedUnitsSold.push('0')
          newUnitsSold.push('0')
          usedApptSold.push('0')
          newApptSold.push('0')
        }
      }
    })

  } else {

    labels.map((day, index) => {
      if(mtdCharts) {
        const valueForThisMonth = mtdCharts.find(key => key.day === day)
        if(valueForThisMonth) {
          usedUnitsSold.push(valueForThisMonth.total_used_sold)
          newUnitsSold.push(valueForThisMonth.total_new_sold)
          usedApptSold.push(valueForThisMonth.total_used_appt)
          newApptSold.push(valueForThisMonth.total_new_appt)
        } else {
          usedUnitsSold.push('0')
          newUnitsSold.push('0')
          usedApptSold.push('0')
          newApptSold.push('0')
        }
      }
    })
  }

  if(props) {
    return (
    <><h3>{title}</h3>
    <Line
        datasetIdKey='id'
        options={options}
        data={{
        labels: title.includes('Year') ? ytdMonths : labels,
        datasets: [
            {
            id: 1,
            label: 'Used units sold',
            data: usedUnitsSold,
            borderColor: 'rgb(255, 99, 132)',
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
            fill: false,
            },
            {
            id: 2,
            label: 'New units sold',
            data: newUnitsSold,
            borderColor: 'rgb(53, 162, 235)',
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
            fill: false,
            },
            {
            id: 3,
            label: 'Appt shown',
            data: usedApptSold,
            borderColor: 'green',
            backgroundColor: 'green',
            fill: false,
            },
            {
            id: 4,
            label: 'Leads',
            data: newApptSold,
            borderColor: 'purple',
            backgroundColor: 'purple',
            fill: false,
            },
        ],
        }}
    /></>
    )
} else {
    return (
    <>
    </>
    )
}
};
export default LineChart