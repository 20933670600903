export const UPDATE_TOTAL_STORE_GROSS = "UPDATE_TOTAL_STORE_GROSS";
export const UPDATE_TOTAL_BUDGET = "UPDATE_TOTAL_BUDGET";
export const UPDATE_TOTAL_OF_CP_HRS_RO = 'UPDATE_TOTAL_OF_CP_HRS_RO';
export const UPDATE_DATA_ENTRY_ROW = 'UPDATE_DATA_ENTRY_ROW';
export const CLEAR_DATA_ENTRY_ROW = 'CLEAR_DATA_ENTRY_ROW';
export const UPDATE_BUDGET_ROW = 'UPDATE_BUDGET_ROW';
export const CLEAR_BUDGET_ROW = 'CLEAR_BUDGET_ROW';
export const UPDATE_DATA_ENTRY_REC_ROW = 'UPDATE_DATA_ENTRY_REC_ROW';
export const CLEAR_DATA_ENTRY_REC_ROW = 'CLEAR_DATA_ENTRY_REC_ROW';
export const UPDATE_BUDGET_REC_ROW = 'UPDATE_BUDGET_REC_ROW';
export const CLEAR_BUDGET_REC_ROW = 'CLEAR_BUDGET_REC_ROW';
export const UPDATE_CURRENT_EDIT_DAY_REC = 'UPDATE_CURRENT_EDIT_DAY_REC';
export const UPDATE_CURRENT_EDIT_DAY_AUTO = 'UPDATE_CURRENT_EDIT_DAY_AUTO';
export const UPDATE_CURRENT_EDIT_DAY_USED = 'UPDATE_CURRENT_EDIT_DAY_USED';
export const UPDATE_DATA_ENTRY_FF_ROW = 'UPDATE_DATA_ENTRY_FF_ROW';
export const CLEAR_DATA_ENTRY_FF_ROW = 'CLEAR_DATA_ENTRY_FF_ROW';
export const UPDATE_BUDGET_FF_ROW = 'UPDATE_BUDGET_FF_ROW';
export const CLEAR_BUDGET_FF_ROW = 'CLEAR_BUDGET_FF_ROW';
export const UPDATE_CURRENT_EDIT_DAY_FF = 'UPDATE_CURRENT_EDIT_DAY_FF';
export const UPDATE_STAFF_ROW = 'UPDATE_STAFF_ROW';
export const CLEAR_STAFF_ROW = 'CLEAR_STAFF_ROW';




